import { makeStyles } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";

const useStyles = makeStyles({
  tooltipCard: {
    padding: 10,
    color: "#fff",
    background: "#2E392A",
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    "& span:last-child": {
      fontWeight: 700,
    },
  },
  yLabel: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: 17,
  },
});

interface BarChartCustomProps {
  series: ApexOptions["series"];
  yType: string;
  yFormatter?: (val: number, opts?: any) => string;
  labelFormatter?: (val: number, opts?: any) => string;
  isLoading?: boolean;
}

const BarChartCustom: React.FC<BarChartCustomProps> = (props) => {
  const classes = useStyles();
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFD8D2"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "20%",
        barHeight: "20%",
      },
    },
    stroke: {
      width: 1,
      colors: ["#D91F05"],
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#626166",
          fontSize: "16px",
          fontFamily: "Open Sans, Arial, sans-serif",
          fontWeight: 400,
        },
        formatter: props.yFormatter,
      },
    },
    xaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#626166",
          fontSize: "16px",
          fontFamily: "Open Sans, Arial, sans-serif",
          fontWeight: 400,
        },
        formatter: (value: any) => {
          if (typeof value === "string" || typeof value === "number")
            return value;

          return value.map(
            (item: string) =>
              `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`
          );
        },
      },
    },
    grid: {
      show: true,
      borderColor: "rgba(176, 193, 225, 0.3)",
      strokeDashArray: 10,
      padding: {
        left: 1,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({
        series,
        seriesZ,
        seriesIndex,
        dataPointIndex,
        w,
      }: any) {
        const value = props.labelFormatter
            ? props.labelFormatter(series[seriesIndex][dataPointIndex])
            : series[seriesIndex][dataPointIndex],
          percentageList = w.globals.seriesZ[seriesIndex];

        const percentageSpan = percentageList.length
          ? "<span>" + percentageList[dataPointIndex].toFixed(2) + "%<span>"
          : "<div></div>";
        return (
          `<div class=${classes.tooltipCard}>` +
          "<span>" +
          value +
          "</span>" +
          percentageSpan +
          "</div>"
        );
      },
    },
  };

  return (
    <div
      style={{
        marginBottom: 10,
        marginTop: 24,
        paddingLeft: 7,
        opacity: props.isLoading ? 0.5 : 1,
      }}
    >
      <span className={classes.yLabel}>{props.yType}</span>
      <Chart type="bar" options={options} series={props.series} width="100%" />
    </div>
  );
};

export default BarChartCustom;
