import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { LinearProgress } from "@material-ui/core";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const SegmentoVolume: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const iocContext = useIoCContext();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () =>
      getDataChartService.getSegmentoVolume({
        ...filters,
        from,
        to,
      }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([]);

  useEffect(() => {
    if (!fetchData.value) return;
    const idx = fetchData.value!.columns.findIndex(
      (column) => column.name === "segmentName"
    );

    setOptions({
      plotOptions: {
        bar: { horizontal: true },
      },
      dataLabels: {
        enabled: true,
        style: { colors: appConfig.chartTheme.dataLabels.style.colors },
        formatter: (value) =>
          new Intl.NumberFormat("pt-BR", { style: "percent" }).format(
            Number(value) / 100
          ),
      },
      tooltip: {
        y: {
          formatter: (value) =>
            new Intl.NumberFormat("pt-BR").format(Number(value)),
        },
      },
      labels: fetchData.value!.rows.map((row) => row[idx]) as string[],
      xaxis: { categories: fetchData.value!.rows.map((row) => row[idx]) },
      legend: { position: "right" },
    });
  }, [fetchData.value]);

  useEffect(() => {
    if (!fetchData.value) return;
    setSeries(() => fetchData.value!.rows.map((row) => row[2]));
  }, [fetchData.value]);

  return (
    <>
      {fetchData.loading && <LinearProgress />}
      <Chart
        type="donut"
        options={options}
        series={series}
        width="100%"
        height="100%"
      />
    </>
  );
};

export { SegmentoVolume };
