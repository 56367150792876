import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { GridFilterModel, GridLinkOperator } from "@material-ui/data-grid";
import { StatusAtem } from "@modules/orders/dtos/IQueryOrderDataDTO";
import { IResultOrdersByStatusDTO } from "@modules/orders/dtos/IQueryOrdersByStatusDTO";
import { IQueryOrdersByStatus } from "@modules/orders/models/IQueryOrdersByStatus";
import { IQueryOrdersByStatusAggregatorsService } from "@modules/orders/models/IQueryOrdersByStatusAggregatorsService";
import { IClient } from "@utils/interfaces";
import { endOfDay, startOfDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IQuery } from "./interface";

interface FormQueryContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IResultOrdersByStatusDTO | null;
  searchFirstTime: boolean;
  ordersAggregators: { name: StatusAtem; value: number; total: number }[];
  loadingAggregators: boolean;
  query: IQuery & { status: StatusAtem | null };
  filterModel: GridFilterModel;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IResultOrdersByStatusDTO | null): void;
  setSearchFirstTime(value: boolean): void;
  setOrderAggregators: React.Dispatch<
    React.SetStateAction<{ name: StatusAtem; value: number; total: number }[]>
  >;
  setLoadingAggregators: React.Dispatch<React.SetStateAction<boolean>>;
  setQuery: React.Dispatch<
    React.SetStateAction<IQuery & { status: StatusAtem | null }>
  >;
}

const FormQueryContext = createContext<FormQueryContext>(
  {} as FormQueryContext
);

const FormQueryProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();

  const getAggregatorsOrdersService = iocContext.serviceContainer.get<
    IQueryOrdersByStatusAggregatorsService
  >(Types.Orders.IQueryOrdersByStatusAggregatorsService);

  const queryOrdersService = iocContext.serviceContainer.get<
    IQueryOrdersByStatus
  >(Types.Orders.IQueryOrdersByStatus);

  const [loading, setLoading] = useState(false);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IResultOrdersByStatusDTO | null>(
    null
  );
  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [loadingAggregators, setLoadingAggregators] = useState(true);
  const [ordersAggregators, setOrderAggregators] = useState<
    { name: StatusAtem; value: number; total: number }[]
  >([]);
  const [query, setQuery] = useState<IQuery & { status: StatusAtem | null }>({
    CNPJ: [],
    BP: [],
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    page: 1,
    limit: 100,
    status: null,
  });

  const fetchQuery = useCallback(async () => {
    try {
      setLoadingQuery(true);

      const queryResult = await queryOrdersService.execute({
        beginDate: query.startDate,
        endDate: query.endDate,
        advisorID: query.BP.map((value) => value.bpID),
        customerID: query.CNPJ.map((value) => value.customerID),
        page: query.page,
        limit: query.limit,
        status: query.status,
      });

      setDataQuery(queryResult);
    } catch (error) {
      console.error("error query my orders", error);
      enqueueSnackbar("Ocorreu um erro ao buscar as vendas", {
        variant: "error",
      });
    } finally {
      setLoadingQuery(false);
      setSearchFirstTime(false);
    }
  }, [
    enqueueSnackbar,
    query.BP,
    query.CNPJ,
    query.endDate,
    query.limit,
    query.page,
    query.startDate,
    queryOrdersService,
    query.status,
  ]);

  const fetchAggregations = useCallback(async () => {
    try {
      setLoadingAggregators(true);
      const respQuantity = await getAggregatorsOrdersService.execute({
        beginDate: query.startDate as Date,
        endDate: query.endDate as Date,
        advisorID: query.BP.map((value) => value.bpID),
        customerID: query.CNPJ.map((value) => value.customerID),
        status: query.status,
        action: "total-by-status",
      });
      const respMoney = await getAggregatorsOrdersService.execute({
        beginDate: query.startDate as Date,
        endDate: query.endDate as Date,
        advisorID: query.BP.map((value) => value.bpID),
        customerID: query.CNPJ.map((value) => value.customerID),
        status: query.status,
        action: "total-by-money",
      });

      const resp = respQuantity
        .filter((item) => {
          return item.name !== "EST";
        })
        .map((item) => {
          const result = respMoney.filter(
            (element) => element.name === item.name
          );
          return {
            ...item,
            total: result.length > 0 ? result[0].value : 0,
          };
        })
        .reduce(
          (
            result: {
              [x: string]: { name: StatusAtem; value: number; total: number };
            },
            current: any
          ) => {
            if (
              typeof result["FT"] !== "undefined" &&
              (current.name === "FT" || current.name === "DEV")
            ) {
              result["FT"].total = result["FT"].total + current.total;
              result["FT"].value = result["FT"].value + current.value;
              return result;
            }
            result[current.name] = {
              name: current.name,
              value: current.value,
              total: current.total,
            };
            return result;
          },
          {}
        );
      setOrderAggregators(
        Object.values(resp) as {
          name: StatusAtem;
          value: number;
          total: number;
        }[]
      );
    } catch (error) {
    } finally {
      setLoadingAggregators(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAggregatorsOrdersService,
    userState.state.bpID,
    userState.state.type,
    query.CNPJ,
    query.BP,
    query.endDate,
    query.startDate,
    query.status,
  ]);

  useEffect(() => {
    fetchAggregations();
  }, [fetchAggregations]);

  useEffect(() => {
    fetchQuery();
  }, [fetchQuery]);

  useEffect(() => {
    // reseta a página para a inicial caso alguma variável mude
    setQuery((state) => ({ ...state, page: 1 }));
  }, [query.startDate, query.endDate, query.CNPJ]);

  useEffect(() => {
    // pré-seleciona todos os assessores quando renderiza a página
    setQuery((old) => ({ ...old, BP: userState.state.assessorList }));
  }, [userState.state.assessorList]);

  const filterModel: GridFilterModel = useMemo(
    () => ({
      items: [
        {
          id: 1,
          value: "",
          columnField: "statusAtem",
          operatorValue: "equal",
        },
      ],
      linkOperator: GridLinkOperator.Or,
    }),
    []
  );

  return (
    <FormQueryContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        ordersAggregators,
        loadingAggregators,
        query,
        filterModel,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setOrderAggregators,
        setLoadingAggregators,
        setQuery,
      }}
    >
      {children}
    </FormQueryContext.Provider>
  );
};

const useFormQuery = (): FormQueryContext => {
  const context = useContext(FormQueryContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQuery deve ser utilizado dentro de um FormQueryProvider"
    );
  }
  return context;
};

export { FormQueryProvider, useFormQuery };
