import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IOrdersAggregatedDTO } from "../dtos/IOrdersAggregatedDTO";
import { IGetOrdersAggregatedPerDayService } from "../models/IGetOrdersAggregatedPerDayService";

@injectable()
export class GetOrdersAggregatedPerDayService
  implements IGetOrdersAggregatedPerDayService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  keyToSWR(data: {
    advisorID?: string;
    managerID?: string;
  }): string | string[] {
    return [
      `/orders/status/?advisorID=${data.advisorID}&managerID=${data.managerID}`,
      this.httpInstance.getAuthorization(),
    ];
  }

  execute(data: {
    advisorID?: string | string[];
    managerID?: string | string[];
  }): Promise<IOrdersAggregatedDTO[]> {
    return this.httpInstance.get("/orders/status", {
      params: {
        advisorID: data.advisorID,
        managerID: data.managerID,
        action: "today-orders",
      },
    });
  }
}
