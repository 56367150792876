import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import {
  IGetCentersResponse,
  IGetCentersService,
} from "../models/IGetCentersService";

@injectable()
export class GetCentersService implements IGetCentersService {
  //@ts-ignore
  @inject(Types.IHttpService) private httpinstance: IHttpService;
  execute(): Promise<IGetCentersResponse[]> {
    return this.httpinstance.get("/dashboard/filter?action=center");
  }
}
