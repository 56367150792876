import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  Chip,
  CircularProgress,
  createStyles,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ICreatePDFTitlesService } from "@modules/titles/models/ICreatePDFTitlesService";
import { IDownloadTitlesService } from "@modules/titles/models/IDownloadTitlesService";
import AppError from "@utils/AppError";
import { downloadFile } from "@utils/index";
import clsx from "clsx";
import { isBefore, isSameDay } from "date-fns";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useFormQueryTitle } from "./formQueryTitleContext";
import { IQuery } from "./interface";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    selectedChip: {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "&:focus": {
        backgroundColor: theme.palette.primaryLight.main,
      },
    },
    labelChip: { marginBottom: "0.5rem" },
    containerChip: {
      "& :not(:first-child)": {
        marginLeft: "4rem",
      },
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateField,
  } = useFormikContext<IQuery>();
  const formQueryTitleContext = useFormQueryTitle();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const userState = useUserState();

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);

  const downloadExcelTitlesService = iocContext.serviceContainer.get<
    IDownloadTitlesService
  >(Types.Titles.IDownloadTitlesService);
  const createPDFTitlesService = iocContext.serviceContainer.get<
    ICreatePDFTitlesService
  >(Types.Titles.ICreatePDFTitlesService);

  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadExcel = useCallback(async () => {
    try {
      setLoadingExcel(true);
      validateField("CNPJ");
      if (!values.startDate || !values.endDate) return;
      if (values.CNPJ.length === 0) return;
      const [url, fileName] = await downloadExcelTitlesService.execute({
        CNPJ: values.CNPJ.map((company) => company.customerID),
        beginDate: values.startDate,
        endDate: values.endDate,
        filterBy: values.filterBy,
        situation: values.situation,
      });
      downloadFile(url, fileName);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Ocorreu um erro ao baixar arquivo excel, tente novamente.",
        { variant: "error" }
      );
    } finally {
      setLoadingExcel(false);
    }
  }, [values, downloadExcelTitlesService, enqueueSnackbar, validateField]);

  useEffect(() => {
    // caso o assessor mude, então limpa-se o campo de CNPJs selecionados
    setFieldValue("CNPJ", []);
  }, [setFieldValue, userState.state.bpSelected]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={3}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple
          onChange={(value) => setFieldValue("CNPJ", value)}
          value={values.CNPJ}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          getOptionKey={(value) => value.customerID}
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          allSelected={values.CNPJ.length === userState.state.listCNPJ.length}
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
          errorField={Boolean(errors.CNPJ)}
          messageErrorField={errors.CNPJ as string}
          fieldTouched={Boolean(touched.CNPJ)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          format="dd/MM/yyyy"
          label="Início"
          value={values.startDate}
          onChange={(date) => {
            setFieldTouched("startDate", true);
            setFieldValue("startDate", date);
          }}
          error={!!errors.startDate && !!touched.startDate}
          helperText={!!touched.startDate && errors.startDate}
          onBlur={() => setFieldTouched("startDate", true)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={values.endDate}
          onChange={(date) => {
            setFieldTouched("endDate", true);
            setFieldValue("endDate", date);
          }}
          disabled={!values.startDate}
          shouldDisableDate={(day) => {
            if (day && values.startDate) {
              if (isSameDay(day, values.startDate)) {
                return false;
              }
              return isBefore(day, values.startDate);
            }
            return false;
          }}
          error={!!errors.endDate && !!touched.endDate}
          helperText={!!touched.endDate && errors.endDate}
          onBlur={() => setFieldTouched("endDate", true)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InputLabel className={classes.labelChip}>Situação</InputLabel>
        <Grid container className={classes.containerChip}>
          <Chip
            component="span"
            className={clsx({
              [classes.selectedChip]: values.situation === "all",
            })}
            label="Todos"
            onClick={() => setFieldValue("situation", "all")}
          />
          <Chip
            component="span"
            className={clsx({
              [classes.selectedChip]: values.situation === "open",
            })}
            label="Em aberto"
            onClick={() => setFieldValue("situation", "open")}
          />
          <Chip
            component="span"
            className={clsx({
              [classes.selectedChip]: values.situation === "closed",
            })}
            label="Baixado"
            onClick={() => setFieldValue("situation", "closed")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <InputLabel className={classes.labelChip}>Filtrar por</InputLabel>
        <Grid container className={classes.containerChip}>
          <Chip
            component="span"
            label="Emissão"
            className={clsx({
              [classes.selectedChip]: values.filterBy === "emissionDate",
            })}
            onClick={() => setFieldValue("filterBy", "emissionDate")}
          />
          <Chip
            component="span"
            label="Vencimento"
            className={clsx({
              [classes.selectedChip]: values.filterBy === "dueDate",
            })}
            onClick={() => setFieldValue("filterBy", "dueDate")}
          />
          <FormHelperText error={!!errors.filterBy && !!touched.filterBy}>
            {!!touched.filterBy && errors.filterBy}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid item container justify="center" xs={12} alignItems="center">
        <Button
          color="primary"
          type="submit"
          variant="contained"
          startIcon={
            formQueryTitleContext.loadingQuery && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
        >
          Consultar
        </Button>
        <Button
          variant="contained"
          startIcon={<GridOnIcon />}
          style={{ margin: "1rem" }}
          color="primary"
          onClick={downloadExcel}
          endIcon={
            loadingExcel && (
              <CircularProgress
                style={{ height: "2.5rem", width: "2.5rem" }}
                color="secondary"
              />
            )
          }
        >
          Baixar Excel
        </Button>
        <Button
          style={{ margin: "1rem" }}
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          color="primary"
          onClick={async () => {
            if (formQueryTitleContext.dataQuery.length) {
              await createPDFTitlesService.execute(
                formQueryTitleContext.dataQuery,
                {
                  beginDate: values.startDate as Date,
                  endDate: values.endDate as Date,
                }
              );
            }
          }}
          disabled={formQueryTitleContext.dataQuery.length === 0}
        >
          Baixar relatório
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
