import Layout from "@components/Layout";
import { Tag } from "@components/Tag";
import Title from "@components/Title";
import {
  ButtonBase,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
} from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  IGetActiveCostsDTO,
  StatusCost,
} from "@modules/costs/dtos/IGetActiveCostsDTO";
import { format, isAfter, isBefore, isSameDay } from "date-fns";
import React, { useMemo } from "react";
import { DialogEditSettingsCost } from "./DialogEditSettingsCost";
import { DialogRemoveSettingsCost } from "./DialogRemoveSettingsCost";
import {
  HistorySettingsCostsProvider,
  useHistorySettingsCostsContext,
} from "./HistorySettingsCostsContext";

const Container: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const historyCostsContext = useHistorySettingsCostsContext();

  const columnsActiveCosts: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      renderCell: (props) => {
        const status = props.value as StatusCost;
        return status === "EXPIRADO" ? (
          <Tag type="error">{status}</Tag>
        ) : status === "ATIVO" ? (
          <Tag type="success">{status}</Tag>
        ) : (
          <Tag type="warning">{status}</Tag>
        );
      },
      filterOperators: [
        {
          value: "equal",
          label: "igual à",
          InputComponent: (props: GridFilterInputValueProps) => {
            const { item, applyValue } = props;
            let status = item.value as StatusCost;

            console.log({ item, status });

            return (
              <Grid container spacing={1}>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "EXPIRADO" })}
                  >
                    <Tag type={status === "EXPIRADO" ? "error" : "grey"}>
                      EXPIRADO
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "ATIVO" })}
                  >
                    <Tag type={status === "ATIVO" ? "success" : "grey"}>
                      ATIVO
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "AGENDADO" })}
                  >
                    <Tag type={status === "AGENDADO" ? "warning" : "grey"}>
                      AGENDADO
                    </Tag>
                  </ButtonBase>
                </Grid>
              </Grid>
            );
          },
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params: GridCellParams): boolean => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      field: "center",
      headerName: "Centro",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "store",
      headerName: "Depósito",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "state",
      headerName: "UF",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "material",
      headerName: "Material",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 350 : undefined,
    },
    {
      field: "type",
      headerName: "Tipo",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "start_at",
      headerName: "Data de Início",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueFormatter: (props) => format(props.value as Date, "dd/MM/yyyy"),
    },
    {
      field: "end_at",
      headerName: "Data de Fim",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueFormatter: (props) =>
        props.value ? format(props.value as Date, "dd/MM/yyyy") : "",
    },
    {
      field: "actions",
      headerName: " ",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      sortable: false,
      filterable: false,
      renderCell: (props) => {
        const row = props.row as IGetActiveCostsDTO;
        const inTheFuture = isAfter(row.start_at, new Date());

        return inTheFuture ? (
          <Grid container justify="center">
            <IconButton
              onClick={() => {
                historyCostsContext.setOpenDialogEdit({
                  id: props.id as string,
                  open: true,
                  data: {
                    end_at: props.row.end_at,
                    start_at: props.row.start_at,
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                historyCostsContext.setOpenDialogDelete({
                  id: props.id as string,
                  open: true,
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        ) : (
          <></>
        );
      },
    },
  ];

  const rowsOrdered = useMemo(() => {
    const order = [...historyCostsContext.rowsHistoryCosts.content];
    order.sort((a, b) => {
      return +b.start_at - +a.start_at;
    });
    return order;
  }, [historyCostsContext.rowsHistoryCosts.content]);

  return (
    <Layout enableMargins>
      <Title>Histórico de Configuração de Margem</Title>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        style={{ margin: "1rem" }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            clearable={false}
            format="dd/MM/yyyy"
            label="Início"
            value={historyCostsContext.pagination.from}
            onChange={(date) => {
              if (date) {
                historyCostsContext.setPagination((oldState) => ({
                  ...oldState,
                  from: date,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <KeyboardDatePicker
            fullWidth
            label="Fim"
            autoOk
            format="dd/MM/yyyy"
            value={historyCostsContext.pagination.to}
            onChange={(date) => {
              if (date) {
                historyCostsContext.setPagination((oldState) => ({
                  ...oldState,
                  to: date,
                }));
              }
            }}
            shouldDisableDate={(day) => {
              if (day && historyCostsContext.pagination.from) {
                if (isSameDay(day, historyCostsContext.pagination.from)) {
                  return false;
                }
                return isBefore(day, historyCostsContext.pagination.from);
              }
              return false;
            }}
          />
        </Grid>
      </Grid>
      <div style={{ height: "100%" }}>
        <DataGrid
          columns={columnsActiveCosts}
          rows={rowsOrdered}
          loading={historyCostsContext.loadingTable}
          error={historyCostsContext.errorLoadingTable ? true : null}
          autoPageSize
          pagination
          paginationMode="server"
          onPageSizeChange={(limit) =>
            historyCostsContext.setPagination((oldState) => ({
              ...oldState,
              limit,
            }))
          }
          onPageChange={(page) =>
            historyCostsContext.setPagination((oldState) => ({
              ...oldState,
              page: page + 1,
            }))
          }
          pageSize={historyCostsContext.pagination.limit}
          page={historyCostsContext.pagination.page - 1}
          rowCount={historyCostsContext.rowsHistoryCosts.total}
        />
      </div>

      <DialogRemoveSettingsCost />
      <DialogEditSettingsCost />
    </Layout>
  );
};

const HistorySettingsCostsPage: React.FC = () => {
  return (
    <HistorySettingsCostsProvider>
      <Container />
    </HistorySettingsCostsProvider>
  );
};

export { HistorySettingsCostsPage };
