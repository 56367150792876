import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    backgroundTitle: {
      fontSize: "3rem",
      color: "#666",
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.up("sm")]: { fontSize: "6rem" },
    },
    container: { display: "flex", justifyContent: "center" },
  })
);

interface Props {
  title?: string;
}

const Title: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.backgroundTitle}>
        {title ? title : children}
      </Typography>
    </div>
  );
};

export default Title;
