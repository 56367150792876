import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { fileNameByHeaders, getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { IQueryFinancialInstallments } from "../dtos/IQueryFinancialInstallments";
import { IDownloadFinancialInstallmentsService } from "../models/IDownloadFinancialInstallmentsService";

@injectable()
export class DownloaFinancialInstallmentsService
  implements IDownloadFinancialInstallmentsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQueryFinancialInstallments
  ): Promise<[string, string | null]> {
    const { data: titleFile, headers } = await this.httpInstance.getWithHeaders<
      BinaryType
    >("/reports/financialDocsFBL5N", {
      params: {
        beginDate: getCorrectDate(data.beginDate),
        endDate: getCorrectDate(data.endDate),
        advisorID: data.advisorID?.join(","),
        customerIDs: data.customerIDs?.join(","),
        groupCustomer: data.groupCustomer.join(","),
        managerID: data.managerID.join(","),
        statusDoc: data.statusDoc.join(","),
        page: data.page,
        limit: data.limit,
      },
      responseType: "arraybuffer",
    });

    let url = window.URL.createObjectURL(
      new Blob([titleFile], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    return [url, fileNameByHeaders(headers)];
  }
}
