import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  IDataEditActiveCosts,
  IEditActiveCostService,
} from "@modules/costs/models/IEditActiveCostService";
import AppError from "@utils/AppError";
import { formatCurrency } from "@utils/index";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistoryCostsContext } from "./HistoryCostsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      loading: {
        width: "2rem !important",
        height: "2rem !important",
      },
    })
);

const DialogEditCost: React.FC = ({ children, ...props }) => {
  const classes = useStyles();
  const historyCostsContext = useHistoryCostsContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const editActiveCostService = iocContext.serviceContainer.get<
    IEditActiveCostService
  >(Types.Costs.IEditActiveCostService);

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [newValue, setNewValue] = useState<IDataEditActiveCosts | null>(null);

  const [suggestionPriceFormatted, setSuggestionPriceFormatted] = useState<
    string | null
  >(null);

  useEffect(() => {
    const parsed = suggestionPriceFormatted
      ? parseFloat(suggestionPriceFormatted.replace(/\D+/g, "")) / 1000000
      : undefined;

    setNewValue((values) => ({
      ...values,
      value: parsed,
    }));
  }, [suggestionPriceFormatted]);

  const onClose = useCallback(() => {
    historyCostsContext.setOpenDialogEdit({
      id: null,
      open: false,
      data: null,
    });
    setNewValue(null);
    setSuggestionPriceFormatted(null);
  }, [historyCostsContext]);

  useEffect(() => {
    setNewValue({ ...historyCostsContext.openDialogEdit.data });
  }, [historyCostsContext.openDialogEdit.data]);

  const editCost = useCallback(async () => {
    if (!historyCostsContext.openDialogEdit.id) return;
    if (!newValue) return;
    try {
      setLoadingEdit(true);
      await editActiveCostService.execute(
        historyCostsContext.openDialogEdit.id,
        { ...newValue }
      );
      enqueueSnackbar("Custo alterado com sucesso!", { variant: "success" });
      historyCostsContext.fetchHistoryCosts();
      onClose();
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao alterar custo", {
        variant: "error",
      });
    } finally {
      setLoadingEdit(false);
    }
  }, [
    historyCostsContext,
    editActiveCostService,
    enqueueSnackbar,
    newValue,
    onClose,
  ]);

  return (
    <Dialog open={historyCostsContext.openDialogEdit.open}>
      <DialogTitle>Deseja editar o custo?</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Valor"
              value={suggestionPriceFormatted}
              fullWidth
              onChange={(event) => {
                const realValue =
                  parseFloat(String(event.target.value).replace(/\D+/g, "")) /
                  1000000;

                const formated = formatCurrency(realValue, {
                  minimumFractionDigits: 6,
                });
                setSuggestionPriceFormatted(formated);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              label="Data de Início"
              format="dd/MM/yyyy"
              invalidDateMessage="Data inválida"
              value={newValue?.start_at}
              fullWidth
              onChange={(date) =>
                date &&
                setNewValue((oldState) =>
                  oldState
                    ? {
                        ...oldState,
                        start_at: date,
                      }
                    : null
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              label="Data de Fim"
              format="dd/MM/yyyy"
              value={newValue?.end_at}
              invalidDateMessage="Data inválida"
              fullWidth
              onChange={(date) =>
                date &&
                setNewValue((oldState) =>
                  oldState
                    ? {
                        ...oldState,
                        end_at: date,
                      }
                    : null
                )
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={editCost}
          variant="contained"
          color="primary"
          startIcon={
            loadingEdit && (
              <CircularProgress className={classes.loading} color="secondary" />
            )
          }
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogEditCost };
