import { fileNameByHeaders } from "@utils/index";
import { formatISO } from "date-fns";
import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetUserAccessesDTO } from "../dtos/IGetUserAccessesDTO";
import {
  IGetUserAccessesParams,
  IGetUserAccessesService,
} from "../models/IGetUserAccessesService";

@injectable()
export class GetUserAccessesService implements IGetUserAccessesService {
  public async downloadAccesses(
    data: IGetUserAccessesParams
  ): Promise<[string, string | null]> {
    const { data: excelFile, headers } = await this.httpInstance.getWithHeaders<
      BinaryType
    >("/beats/report", {
      params: {
        begin: data.begin,
        end: data.end,
      },
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(
      new Blob([excelFile], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    return [url, fileNameByHeaders(headers)];
  }
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getAccesses(
    data: IGetUserAccessesParams
  ): Promise<IGetUserAccessesDTO> {
    const resp = await this.httpInstance.get<IGetUserAccessesDTO>("/beats", {
      params: {
        ...data,
        begin: formatISO(data.begin as Date, { representation: "date" }),
        end: formatISO(data.end as Date, { representation: "date" }),
      },
    });
    return resp;
  }
}
