import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { IRemoveActiveCostService } from "@modules/costs/models/IRemoveActiveCostService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useHistorySettingsCostsContext } from "./HistorySettingsCostsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      loading: {
        width: "2rem !important",
        height: "2rem !important",
      },
    })
);

const DialogRemoveSettingsCost: React.FC = ({ children, ...props }) => {
  const classes = useStyles();
  const historyCostsContext = useHistorySettingsCostsContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const removeActiveCostService = iocContext.serviceContainer.get<
    IRemoveActiveCostService
  >(Types.Costs.IRemoveActiveCostService);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const onClose = useCallback(() => {
    historyCostsContext.setOpenDialogDelete({ id: null, open: false });
  }, [historyCostsContext]);

  const deleteCost = useCallback(async () => {
    if (!historyCostsContext.openDialogDelete.id) return;
    try {
      setLoadingDelete(true);
      await removeActiveCostService.execute(
        historyCostsContext.openDialogDelete.id
      );
      enqueueSnackbar("Custo removido com sucesso!", { variant: "success" });
      historyCostsContext.fetchHistoryCosts();
      onClose();
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao remover custo", {
        variant: "error",
      });
    } finally {
      setLoadingDelete(false);
    }
  }, [historyCostsContext, enqueueSnackbar, onClose, removeActiveCostService]);

  return (
    <Dialog open={historyCostsContext.openDialogDelete.open}>
      <DialogTitle>Deseja remover o custo?</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={deleteCost}
          variant="contained"
          color="primary"
          startIcon={
            loadingDelete && (
              <CircularProgress className={classes.loading} color="secondary" />
            )
          }
        >
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogRemoveSettingsCost };
