import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      iframe: { borderWidth: 0, width: "100%", height: "100%" },
      title: {
        fontSize: "2.2rem",
        fontWeight: "bold",
        margin: "1rem 0",
      },
      chartContainer: {
        flex: 1,
        width: "100%",
      },
      containerScroll: {
        width: "400vw",
        height: "100%",
      },
    })
);

export interface ChartContainerProps {
  loading?: boolean;
  title?: string;
  scrollInMobile?: boolean;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  loading,
  children,
  scrollInMobile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  return (
    <Paper style={{ width: "100%", height: "100%", display: "flex" }}>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography className={classes.title}>{title}</Typography>
          <Box
            className={classes.chartContainer}
            style={{
              overflowX: scrollInMobile && isMobile ? "scroll" : "hidden",
            }}
          >
            {scrollInMobile && isMobile ? (
              <Box className={classes.containerScroll}>{children}</Box>
            ) : (
              children
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export { ChartContainer };
