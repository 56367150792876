import { useUserState } from "@context/UserContext";
import {
  GetCentersDTO,
  GetManagersDTO,
  GetProductsDTO,
  GetSegmentsDTO,
  GetUFsDTO,
} from "@modules/dashboard/models/IGetFilterOptionsService";
import { IBpDTO } from "@modules/user/dtos/IBpDTO";
import { ICnpjDTO } from "@modules/user/dtos/ICnpjDTO";
import { addHours, endOfDay, endOfMonth, format, startOfMonth } from "date-fns";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface HomeDashboardContext {
  dateCharts: HomeDashboardDateCharts;
  filters: HomeDashboardFilters;
  openFilters: boolean;
  loadingAll: boolean;
  setFilters: React.Dispatch<React.SetStateAction<HomeDashboardFilters>>;
  setDateCharts: React.Dispatch<React.SetStateAction<HomeDashboardDateCharts>>;
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HomeDashboardContext = createContext({} as HomeDashboardContext);

export interface HomeDashboardFilters {
  cnpj: ICnpjDTO[];
  products: GetProductsDTO[];
  centers: GetCentersDTO[];
  segments: GetSegmentsDTO[];
  ufs: GetUFsDTO[];
  assessors: IBpDTO[];
  managers: GetManagersDTO[];
}

export interface HomeDashboardDateCharts {
  [propName: number]: {
    from: string;
    end: string;
    rawFrom: Date;
    rawEnd: Date;
  };
  selected: number;
}

export const HomeDashboardProvider: React.FC = ({ children }) => {
  const userState = useUserState();

  const [filters, setFilters] = useState<HomeDashboardFilters>({
    cnpj: [],
    products: [],
    centers: [],
    ufs: [],
    segments: [],
    assessors: [],
    managers: [],
  });
  const [openFilters, setOpenFilters] = useState(false);
  const [loadingAll, setLoadingAll] = useState(true);

  const todayDateCharts = useMemo(() => {
    const fromDate = format(new Date(), "yyyy-MM-dd");
    const toDate = format(addHours(endOfDay(new Date()), 4), "yyyy-MM-dd");

    return {
      from: fromDate,
      to: toDate,
      rawFrom: new Date(),
      rawEnd: endOfDay(new Date()),
    };
  }, []);

  const monthDateCharts = useMemo(() => {
    const fromDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const toDate = format(addHours(endOfMonth(new Date()), 4), "yyyy-MM-dd");

    return {
      from: fromDate,
      to: toDate,
      rawFrom: startOfMonth(new Date()),
      rawEnd: endOfMonth(new Date()),
    };
  }, []);

  const customizeDateCharts = useMemo(() => {
    const fromDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const toDate = format(addHours(endOfMonth(new Date()), 4), "yyyy-MM-dd");

    return {
      from: fromDate,
      to: toDate,
      rawFrom: startOfMonth(new Date()),
      rawEnd: endOfMonth(new Date()),
    };
  }, []);

  const [dateCharts, setDateCharts] = useState<HomeDashboardDateCharts>({
    0: {
      from: todayDateCharts.from,
      end: todayDateCharts.to,
      rawFrom: todayDateCharts.rawFrom,
      rawEnd: todayDateCharts.rawEnd,
    },
    1: {
      from: monthDateCharts.from,
      end: monthDateCharts.to,
      rawFrom: monthDateCharts.rawFrom,
      rawEnd: monthDateCharts.rawEnd,
    },
    2: {
      from: customizeDateCharts.from,
      end: customizeDateCharts.to,
      rawFrom: customizeDateCharts.rawFrom,
      rawEnd: customizeDateCharts.rawEnd,
    },
    selected: 0,
  });

  useEffect(() => {
    if (!userState.state.loadingAssessorList) {
      setFilters((old) => ({
        ...old,
        assessors: userState.state.assessorList,
      }));
    }
  }, [userState.state.assessorList, userState.state.loadingAssessorList]);

  return (
    <HomeDashboardContext.Provider
      value={{
        filters,
        dateCharts,
        openFilters,
        loadingAll,
        setOpenFilters,
        setFilters,
        setDateCharts,
        setLoadingAll,
      }}
    >
      {children}
    </HomeDashboardContext.Provider>
  );
};

export const useHomeDashboard = () => {
  const context = useContext(HomeDashboardContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "Não é possível utilizar useHomeDashboard fora de um HomeDashboardProvider"
    );
  }
  return context;
};
