import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { fileNameByHeaders, getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { IQueryOrdersByStatusDTO } from "../dtos/IQueryOrdersByStatusDTO";
import { IDownloadExcelOrdersByStatusService } from "../models/IDownloadExcelOrdersByStatusService";

@injectable()
export class DownloadExcelOrdersByStatusService
  implements IDownloadExcelOrdersByStatusService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQueryOrdersByStatusDTO
  ): Promise<[string, string | null]> {
    const { data: excelFile, headers } = await this.httpInstance.getWithHeaders<
      BinaryType
    >("/reports/ordersByStatus", {
      params: {
        beginDate: getCorrectDate(data.beginDate),
        endDate: getCorrectDate(data.endDate),
        managerID: data.managerID?.join(","),
        advisorID: data.advisorID?.join(","),
        customerIDs: data.customerID?.join(","),
      },
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(
      new Blob([excelFile], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    return [url, fileNameByHeaders(headers)];
  }
}
