import Layout from "@components/Layout";
import Title from "@components/Title";
import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import FormQuery from "./FormQuery";
import { FormQueryProvider } from "./FormQueryContext";
import TableOrders from "./TableOrders";

const Container: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  return (
    <Layout enableMargins fullHeight={!isMobile}>
      <Title title="Vendas com Margem" />
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <FormQuery />
        <TableOrders />
      </div>
    </Layout>
  );
};

const AppointmentSalesMargin: React.FC = () => {
  return (
    <FormQueryProvider>
      <Container />
    </FormQueryProvider>
  );
};

export { AppointmentSalesMargin };
