export const SettingsCostTypes = {
  IUploadSheetSettingsCostsService: Symbol("IUploadSheetSettingsCostsService"),
  IGetActiveSettingsCostsService: Symbol("IGetActiveSettingsCostsService"),
  ISearchSettingsCostService: Symbol("ISearchSettingsCostService"),
  IRemoveActiveSettingsCostService: Symbol("IRemoveActiveSettingsCostService"),
  IGetHistoryActiveSettingsCostsService: Symbol(
    "IGetHistoryActiveSettingsCostsService"
  ),
  IEditActiveSettingsCostService: Symbol("IEditActiveSettingsCostService"),
};
