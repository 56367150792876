import {
  ChartIcon,
  ChecklistIcon,
  CoustIcon,
  HelpIcon,
  HomeIcon,
  LockIcon,
  LogoutIcon,
} from "@components/Icons";
import appConfig from "@config/appConfig";
import ROUTES from "@config/routes";
import { Drawer, List, ListItem, Theme } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      borderRight: "0 solid white",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRight: "0 solid white",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(8) + 1,
      /* [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            }, */
      borderRight: "0 solid white",
    },
    menuIcon: {
      width: 80,
      height: 80,
      background: "#D91F05",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
    listItem: {
      justifyContent: "center",
      color: "white",
      padding: "20px 16px",
    },
  })
);

const Sidebar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const location = useLocation();

  const list = () => (
    <div
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        <ListItem
          button
          className={classes.listItem}
          selected={location.pathname === ROUTES.USER_ROUTES.HOME}
          component={Link}
          to={ROUTES.USER_ROUTES.HOME}
        >
          <HomeIcon />
        </ListItem>
        {/* <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          <EditIcon />
        </ListItem> */}
        <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          <ChecklistIcon />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          <CoustIcon />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          selected={location.pathname === ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
          component={Link}
          to={ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
        >
          <LockIcon />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          <ChartIcon />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          <HelpIcon />
        </ListItem>
        <ListItem
          button
          className={classes.listItem}
          selected={
            location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
          }
          component="a"
          href={
            process.env.REACT_APP_ENVIRONMENT === "homologation"
              ? appConfig.cognito.urlLogout.homologation
              : process.env.NODE_ENV === "production"
              ? appConfig.cognito.urlLogout.production
              : appConfig.cognito.urlLogout.development
          }
        >
          <LogoutIcon />
        </ListItem>
      </List>
    </div>
  );
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.menuIcon}>
        <Menu width={24} height={24} />
        {/* <IconButton onClick={toogleDrawer}>
                        {open ? (
                            <ChevronRight />
                        ) : (
                            <ChevronLeft />
                        )}

                    </IconButton> */}
      </div>
      {list()}
    </Drawer>
  );
};

export default Sidebar;
