import NoData from "@components/NoData";
import PieChart from "@components/PieChart";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { CircularProgress, Grid } from "@material-ui/core";
import { IChartDataService } from "@modules/financial/models/IChartDataService";
import { useFinancialDashboardContext } from "@pages/Financial/FinancialDashboard/FinancialDashboardContext";
import { IFinancialDashboardChartQuery } from "@pages/Financial/FinancialInstallments/interface";
import { formatCurrency } from "@utils/index";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

const CustomerGroupChart: React.FC<{
  filters: IFinancialDashboardChartQuery;
}> = ({ filters }) => {
  const iocContext = useIoCContext();

  const getChartService = iocContext.serviceContainer.get<IChartDataService>(
    Types.Financial.IChartDataService
  );

  const [series, setSeries] = useState<number[]>([-1]);
  const [labels, setLabels] = useState<string[]>([]);

  const { query, loaders, setLoaders } = useFinancialDashboardContext();
  const { enqueueSnackbar } = useSnackbar();

  const getCustomerGroupChart = useCallback(async () => {
    try {
      setLoaders((state) => ({
        ...state,
        isCustomerGroupChartLoading: true,
      }));
      const queryResult = await getChartService.getCustomerGroup({
        ...filters,
        from: query.from,
        to: query.to,
      });

      let newLabels = queryResult.rows
        .filter((item) => item.every((value) => value != null))
        .map((item) => item[1].split(" ").slice(0, 1).join(" "));

      let newSeries = queryResult.rows
        .filter((item) => item.every((value) => value != null))
        .map((item) => item[2]);

      setLabels(newLabels);
      setSeries(newSeries);
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro ao carregar gráfico de índice de atraso anual!" +
          error,
        {
          variant: "error",
        }
      );
    } finally {
      setLoaders((state) => ({
        ...state,
        isCustomerGroupChartLoading: false,
      }));
    }
  }, [
    enqueueSnackbar,
    filters,
    setLoaders,
    getChartService,
    query.from,
    query.to,
  ]);

  useEffect(() => {
    getCustomerGroupChart();
  }, [getCustomerGroupChart]);

  return series.length && series[0] === -1 ? (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Grid>
  ) : series.length > 0 ? (
    <PieChart
      series={series}
      labels={labels}
      labelFomatter={(value) => formatCurrency(value)}
      isLoading={loaders.isCustomerGroupChartLoading}
    />
  ) : (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <NoData>Nada encontrado</NoData>
    </Grid>
  );
};

export default CustomerGroupChart;
