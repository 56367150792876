import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IBpDTO } from "../dtos/IBpDTO";
import { IGetAssessorUsersService } from "../models/IGetAssessorUsersService";

@injectable()
export class GetAssessorUsersService implements IGetAssessorUsersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<IBpDTO[]> {
    return this.httpInstance.get<IBpDTO[]>("/bps");
  }
}
