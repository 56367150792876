import NewHeader from "@components/NewHeader";
import Sidebar from "@components/Sidebar";
import { useUserState } from "@context/UserContext";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    main: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },
  })
);

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const userState = useUserState();

  return (
    <div className={classes.root}>
      <Sidebar />
      <div style={{ width: "100%" }}>
        <NewHeader userName={userState.state.bpName} />
        <div className={classes.main}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
