import { IQueryTitlesDataDTO } from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { IUploadTitleImageDTO } from "@modules/titles/dtos/IUploadTitleImageDTO";
import { IClient } from "@utils/interfaces";
import React, { createContext, useCallback, useContext, useState } from "react";

interface IOpenModalUpload {
  openModalUpload: boolean;
  data: Omit<IUploadTitleImageDTO, "file">;
}

interface Image {
  link: string;
  id: string;
}

interface FormQueryTitleContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IQueryTitlesDataDTO[];
  searchFirstTime: boolean;
  openModalUpload: IOpenModalUpload;
  image: Image;
  openModalImage: boolean;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IQueryTitlesDataDTO[]): void;
  setSearchFirstTime(value: boolean): void;
  setOpenModalUpload(value: IOpenModalUpload): void;
  setImage(value: Image): void;
  setOpenModalImage(value: boolean): void;
}

const FormQueryTitleContext = createContext<FormQueryTitleContext>(
  {} as FormQueryTitleContext
);

const FormQueryTitleProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IQueryTitlesDataDTO[]>([]);
  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState<IOpenModalUpload>({
    data: { year: "", atemID: "", CNPJ: "", invoiceID: "" },
    openModalUpload: false,
  });
  const [image, setImage] = useState<Image>({} as Image);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  return (
    <FormQueryTitleContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        openModalUpload,
        image,
        openModalImage,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setOpenModalUpload,
        setImage,
        setOpenModalImage,
      }}
    >
      {children}
    </FormQueryTitleContext.Provider>
  );
};

const useFormQueryTitle = (): FormQueryTitleContext => {
  const context = useContext(FormQueryTitleContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQueryTitle deve ser utilizado dentro de um FormQueryTitleContext"
    );
  }
  return context;
};

export { useFormQueryTitle, FormQueryTitleProvider };
