import Layout from "@components/Layout";
import Title from "@components/Title";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { AppointmentPriceProvider } from "./AppointmentPriceContext";
import { FormQuery } from "./FormQuery";
import { FormQueryPricesValidationSchema } from "./FormQueryPricesValidationSchema";
import { IQuery } from "./interface";
import { TablePrices } from "./TablePrices";

const PageContainer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const initialValues: IQuery = {
    CNPJ: null,
    address: null,
    filialID: "",
    storeID: "",
    freightType: "CIF",
    branchName: "",
  };

  return (
    <Layout enableMargins fullHeight={!isMobile}>
      <Title>Consultar Preços</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={FormQueryPricesValidationSchema}
        onSubmit={async (values) => {}}
      >
        <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <FormQuery />
          <TablePrices />
        </Form>
      </Formik>
    </Layout>
  );
};

const AppointmentPriceCustomer: React.FC = () => {
  return (
    <AppointmentPriceProvider>
      <PageContainer />
    </AppointmentPriceProvider>
  );
};

export { AppointmentPriceCustomer };
