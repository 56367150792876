import Layout from "@components/Layout";
import NoData from "@components/NoData";
import Title from "@components/Title";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { IQueryInvoicesService } from "@modules/invoices/models/IQueryInvoicesService";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import FormQuery from "./FormQuery";
import {
  FormQueryInvoicesProvider,
  useFormQueryInvoices,
} from "./FormQueryInvoicesContext";
import FormQueryInvoicesValidationSchema from "./FormQueryInvoicesValidationSchema";
import { IQuery } from "./interface";
import ModalDownload from "./ModalDownload";
import TableInvoices from "./TableInvoices";

const AppointmentInvoices: React.FC = () => {
  const iocContext = useIoCContext();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const initialValues: IQuery = {
    CNPJ: [],
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  };

  return (
    <Layout enableMargins fullHeight={!isMobile}>
      <Title title="Consultar NFs" />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            formQueryInvoicesContext.setLoadingQuery(true);
            const queryInvoicesService = iocContext.serviceContainer.get<
              IQueryInvoicesService
            >(Types.Invoices.IQueryInvoicesService);

            const casted = FormQueryInvoicesValidationSchema.cast(values);
            if (!casted) return;

            const queries = values.CNPJ.map((company) => {
              const query = {
                CNPJ: company.CNPJ,
                beginDate: casted?.startDate as Date,
                endDate: casted?.endDate as Date,
              };

              return query;
            });

            if (queries) {
              const queriesDispatched = queries.map(async (query) => {
                const queryResult = await queryInvoicesService.execute(query);
                return queryResult;
              });

              const queriesResults = await Promise.all(queriesDispatched);
              const flatten = queriesResults.flatMap((results) => results);
              formQueryInvoicesContext.setDataQuery(flatten);
            }
          } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar notas fiscais", {
              variant: "error",
            });
          } finally {
            formQueryInvoicesContext.setLoadingQuery(false);
            formQueryInvoicesContext.setSearchFirstTime(false);
          }
        }}
        validationSchema={FormQueryInvoicesValidationSchema}
      >
        <Form>
          <FormQuery />
        </Form>
      </Formik>

      {formQueryInvoicesContext.searchFirstTime ? (
        <NoData>Faça uma busca para ver as NFs</NoData>
      ) : (
        <TableInvoices />
      )}
      <ModalDownload />
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <FormQueryInvoicesProvider>
      <AppointmentInvoices />
    </FormQueryInvoicesProvider>
  );
};

export default Container;
