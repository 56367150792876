import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/styles";
import { StatusDoc } from "@modules/financial/dtos/IFinancialInstallmentsDTO";
import { IChartDataService } from "@modules/financial/models/IChartDataService";
import { formatCurrency, OnKeypressEscape } from "@utils/index";
import React, { useEffect, useState } from "react";
import { IFinancialDashboardChartQuery } from "../FinancialInstallments/interface";
import { useFinancialDashboardContext } from "./FinancialDashboardContext";
import FinancialTotalsTable from "./FinancialTotalsTable";
import { TableRowData } from "./interface";

function createData(name: string, value: string): TableRowData {
  return { name, value };
}

const useStyle = makeStyles({
  drawerPaper: {
    width: 490,
  },
  closeIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
    padding: 40,
  },
  value: {
    color: "#313B45",
    fontFamily: "Noto Sans",
    fontWeight: 700,
    fontSize: 32,
    marginTop: 28,
    marginBottom: 10,
  },
  title: {
    color: "#0F71D0",
    fontFamily: "Open Sans, Roboto",
    fontWeight: 600,
    fontSize: 16,
  },
});

interface IconProps {
  color?: string;
  background?: string;
  borderColor?: string;
}

const IconContainer = styled("div")((props: IconProps) => ({
  height: 98,
  width: 98,
  borderRadius: 4,
  backgroundColor: props.background,
  border: `1px solid ${props.borderColor}`,
  color: props.borderColor,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

interface IFinancialTotalsDetailsProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  iconBgColor: string;
  iconColor: string;
  icon?: React.ReactNode;
  title: string;
  value: string;
  type: StatusDoc;
  filters: IFinancialDashboardChartQuery;
}

const FinancialTotalsDetails: React.FC<IFinancialTotalsDetailsProps> = (
  props
) => {
  const classes = useStyle();
  const iocContext = useIoCContext();
  const getChartService = iocContext.serviceContainer.get<IChartDataService>(
    Types.Financial.IChartDataService
  );
  const { query, incrementCounter } = useFinancialDashboardContext();
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [pagesTotal, setPagesTotal] = useState<number>(0);

  const fetch = useFetchData(
    () =>
      getChartService.getTotalsDetails(
        {
          ...props.filters,
          from: query.from,
          to: query.to,
        },
        props.type
      ),
    {
      useCallbackDeps: [props.filters, query.from, query.to],
      useEffectDeps: [props.filters, query.from, query.to],
    }
  );

  useEffect(() => {
    if (!fetch.value) return;
    const newType =
      props.type === "CLOSE"
        ? "NOT_EXPIRED"
        : props.type === "ACCUMULATED"
        ? "EXPIRED"
        : props.type;

    const filterQueryRows = fetch.value.rows
      .filter((item) => item.every((value) => value != null))
      .filter((item) => item[0] === newType);

    const newRows = filterQueryRows.map((item) => {
      const name = (item[2] as string)
        .split(" ")
        .map(
          (item) =>
            `${item.charAt(0).toUpperCase()}${item
              .slice(1)
              .toLocaleLowerCase()}`
        )
        .join(" ");
      return createData(name, formatCurrency(item[3]));
    });
    setPagesTotal(Math.ceil(newRows.length / 6));

    setRows(newRows);
  }, [fetch.value, props.type]);

  useEffect(() => {
    if (!fetch.firstLoading) {
      incrementCounter();
    }
  }, [fetch.firstLoading, incrementCounter]);

  useEffect(() => {
    OnKeypressEscape(() => props.setOpenDialog(false));
  });

  const ContentHeader: React.FC = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={4}
      >
        <IconContainer
          background={props.iconBgColor}
          borderColor={props.iconColor}
        >
          {props.icon}
        </IconContainer>
        <span className={classes.value}>{props.value}</span>
        <span className={classes.title}>{props.title}</span>
      </Box>
    );
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={props.openDialog}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        className={classes.closeIcon}
        onClick={() => props.setOpenDialog(false)}
      >
        <CloseRoundedIcon />
      </div>
      <ContentHeader />
      <FinancialTotalsTable
        isLoading={fetch.loading}
        rows={rows}
        pagesTotal={pagesTotal}
      />
    </Drawer>
  );
};

export default FinancialTotalsDetails;
