import baseRetiradaImg from "@assets/base-de-retirada.png";
import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import AppError from "@utils/AppError";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
// import razaoSocialImg from "../../../../assets/razao-social.png";
import { useAppointmentPriceContext } from "./AppointmentPriceContext";
import { IQuery } from "./interface";

const useStyles = makeStyles(() =>
  createStyles({
    itemSelect: {
      display: "flex",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    iconsDisabled: { opacity: "0.12" },
    titleDisabled: { opacity: "0.6" },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const appointmentContext = useAppointmentPriceContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();

  const getClientInfoService = iocContext.serviceContainer.get<
    IGetClientInfoService
  >(Types.Orders.IGetClientInfoService);

  useEffect(() => {
    async function fetch() {
      if (!values.CNPJ) return;
      try {
        appointmentContext.setLoadingCustomerData(true);
        const CNPJData = await getClientInfoService.execute(
          values.CNPJ.CNPJ,
          true
        );
        appointmentContext.setDataCustomer(CNPJData);
        setFieldValue("freightType", CNPJData.freightType);
      } catch (error) {
        if (error instanceof AppError) {
          return enqueueSnackbar(error.message, { variant: error.variant });
        }

        enqueueSnackbar("Ocorreu um erro ao buscar dados do cliente", {
          variant: "error",
        });
      } finally {
        appointmentContext.setLoadingCustomerData(false);
        appointmentContext.setLoadingFirstQuery(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ]);

  useEffect(() => {
    if (values.CNPJ !== null && appointmentContext.dataCustomer) {
      const withdrawBasis = appointmentContext.dataCustomer.withdrawBasis.find(
        (withdrawBasis) => withdrawBasis.shippingPoint.includes("D1")
      );
      setFieldValue("filialID", withdrawBasis?.filialID as string);
      setFieldValue("storeID", withdrawBasis?.store as string);
      setFieldValue("branchName", withdrawBasis?.branchName as string);

      if (appointmentContext.dataCustomer.address.length === 1) {
        setFieldValue("address", appointmentContext.dataCustomer.address[0]);
      }
    }
  }, [values.CNPJ, setFieldValue, appointmentContext.dataCustomer]);

  useEffect(() => {
    appointmentContext.setDataCustomer(null);
    appointmentContext.setLoadingFirstQuery(true);
    setFieldValue("filialID", "");
    setFieldValue("storeID", "");
    setFieldValue("branchName", "");
    setFieldValue("address", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CNPJ, userState.state.bpSelected]);

  useEffect(() => {
    // caso o assessor mude, então limpa-se o campo de CNPJs selecionados
    setFieldValue("CNPJ", null);
  }, [setFieldValue, userState.state.bpSelected]);

  const renderAddress = () => {
    if (!appointmentContext.dataCustomer) return null;
    return appointmentContext.dataCustomer.address.map((ele) => {
      return (
        <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
          {ele.street} - {ele.city} - {ele.UF}
        </MenuItem>
      );
    });
  };
  const renderWithdrawBasis = () => {
    if (!appointmentContext.dataCustomer) return null;

    return appointmentContext.dataCustomer.withdrawBasis.map((ele) => {
      return (
        <MenuItem
          key={`${ele.filialID}-${ele.store}-${ele.branchName}`}
          value={`${ele.filialID}-${ele.store}-${ele.branchName}`}
          className={classes.itemSelect}
        >
          <strong>{ele.name}</strong>-{ele.branchName}
        </MenuItem>
      );
    });
  };

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple={false}
          onChange={(value) => setFieldValue("CNPJ", value)}
          value={values.CNPJ}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
          errorField={Boolean(errors.CNPJ)}
          messageErrorField={errors.CNPJ as string}
          fieldTouched={Boolean(touched.CNPJ)}
        />
      </Grid>

      <Grid container item xs={12} sm={4} alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
        >
          <InputLabel>Endereço para entrega</InputLabel>
          <Select
            error={!!errors.address && !!touched.address}
            onBlur={() => setFieldTouched("address", true)}
            onChange={({ target }) => {
              if (appointmentContext.dataCustomer) {
                const address = appointmentContext.dataCustomer.address.find(
                  (ele) => ele.id === target.value
                );

                setFieldValue("address", address);

                if (address?.filialIdDefault) {
                  const store = appointmentContext.dataCustomer.withdrawBasis.find(
                    (withdrawBasis) => {
                      return withdrawBasis.filialID === address.filialIdDefault;
                    }
                  );
                  setFieldValue("filialID", store!.filialID);
                  setFieldValue("storeID", store!.store);
                  setFieldValue("branchName", store!.branchName);
                }
              }
            }}
            value={values.address ? values.address.id : ""}
            name="address"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <RoomIcon />
              </InputAdornment>
            }
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress style={{ width: 30, height: 30 }} />
                </InputAdornment>
              )
            }
          >
            {values.CNPJ && renderAddress()}
          </Select>
          <FormHelperText error={!!errors.address && !!touched.address}>
            {!!touched.address && errors.address}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} container alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
        >
          <InputLabel>Centro</InputLabel>
          <Select
            error={!!errors.filialID && !!touched.filialID}
            onBlur={() => setFieldTouched("filialID", true)}
            value={`${values.filialID}-${values.storeID}-${values.branchName}`}
            onChange={({ target }) => {
              setFieldValue("filialID", (target.value as string).split("-")[0]);
              setFieldValue("storeID", (target.value as string).split("-")[1]);
              setFieldValue(
                "branchName",
                (target.value as string).split("-")[2]
              );
            }}
            name="filialID"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width="24"
                  src={baseRetiradaImg}
                  alt="base de retirada ícone"
                  className={clsx({
                    [classes.iconNotDisabled]:
                      Boolean(values.CNPJ) &&
                      !appointmentContext.loadingCustomerData,
                    [classes.iconsDisabled]:
                      !Boolean(values.CNPJ) ||
                      appointmentContext.loadingCustomerData,
                  })}
                />
              </InputAdornment>
            }
            endAdornment={
              appointmentContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress style={{ width: 30, height: 30 }} />
                </InputAdornment>
              )
            }
          >
            {renderWithdrawBasis()}
          </Select>
          <FormHelperText error={!!errors.filialID && !!touched.filialID}>
            {!!touched.filialID && errors.filialID}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2}>
        <FormControl
          disabled={
            !Boolean(values.CNPJ) || appointmentContext.loadingCustomerData
          }
        >
          <FormLabel component="legend">Tipo de Frete</FormLabel>
          <RadioGroup
            aria-label="freightType"
            name="freightType"
            value={values.freightType}
            onChange={({ target }) =>
              setFieldValue("freightType", target.value)
            }
            row
          >
            <FormControlLabel
              value="CIF"
              control={<Radio color="primary" />}
              label="CIF"
            />
            <FormControlLabel
              value="FOB"
              control={<Radio color="primary" />}
              label="FOB"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Typography
          className={clsx({
            [classes.titleDisabled]: !Boolean(appointmentContext.dataCustomer),
          })}
        >
          Segmento:
        </Typography>
        <Typography>
          {appointmentContext.dataCustomer?.segment.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { FormQuery };
