import NoData from "@components/NoData";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { IQueryTitlesService } from "@modules/titles/models/IQueryTitlesService";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns/esm";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import FormQuery from "./FormQuery";
import {
  FormQueryTitleProvider,
  useFormQueryTitle,
} from "./formQueryTitleContext";
import FormQueryValidationSchema from "./FormQueryValidationSchema";
import { IQuery } from "./interface";
import ModalImage from "./ModalImage";
import ModalUpload from "./ModalUpload";
import TableTitles from "./TableTitles";

const AppointmentTitle: React.FC = () => {
  const iocContext = useIoCContext();
  const formQueryTitleContext = useFormQueryTitle();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const initialValues: IQuery = {
    CNPJ: [],
    situation: "all",
    filterBy: "dueDate",
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  };

  return (
    <Layout enableMargins fullHeight={!isMobile}>
      <Title title="Partidas individuais por cliente" />
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            formQueryTitleContext.setLoadingQuery(true);
            const queryTitlesService = iocContext.serviceContainer.get<
              IQueryTitlesService
            >(Types.Titles.IQueryTitlesService);

            const casted = FormQueryValidationSchema.cast(values);
            if (!casted) return;

            const queryResult = await queryTitlesService.execute({
              CNPJ: values.CNPJ.map((company) => company.customerID),
              beginDate: casted.startDate,
              endDate: casted.endDate,
              filterBy: casted.filterBy,
              situation: casted.situation,
            });
            formQueryTitleContext.setDataQuery(queryResult);
          } catch (error) {
            enqueueSnackbar("Ocorreu um erro ao buscar títulos", {
              variant: "error",
            });
          } finally {
            formQueryTitleContext.setLoadingQuery(false);
            formQueryTitleContext.setSearchFirstTime(false);
          }
        }}
        validationSchema={FormQueryValidationSchema}
      >
        <Form style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <FormQuery />

          {formQueryTitleContext.searchFirstTime ? (
            <NoData disableIcon>Faça uma busca para ver os títulos.</NoData>
          ) : (
            <TableTitles />
          )}
        </Form>
      </Formik>

      <ModalUpload />
      <ModalImage />
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <FormQueryTitleProvider>
      <AppointmentTitle />
    </FormQueryTitleProvider>
  );
};

export default Container;
