import { TypesRolesPermissions } from "@context/UserContext/RolesPermissions";
import {
  AppBar,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Autocomplete } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ROUTES from "../../config/routes";
import { useUserDispatch, useUserState } from "../../context/UserContext";
import DrawerMenu from "../DrawerMenu";
import { DialogSuccessUploadCosts } from "./DialogSuccessUploadCosts";
import { DialogUploadCosts } from "./DialogUploadCosts";
import { DialogUploadSettingsCosts } from "./DialogUploadSettingsCosts";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      appbar: { boxShadow: "none" },
      buttonsToolbar: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          alignItems: "center",
          "& button:not(:first-child)": { marginLeft: "1rem" },
        },
      },
      autocompleteRoot: {
        width: 300,
        display: "none",
        [theme.breakpoints.up("md")]: { display: "block" },
      },
      autocompleteInput: { color: "white" },
      autocompleteInputLabelRoot: { color: "white !important" },
      autocompleteInputLabel: { color: "white" },
      autocompleteInputUnderline: {
        "&:before": { borderBottomColor: "white" },
        "&:after": { borderBottomColor: "white" },
      },
      matchText: { color: theme.palette.primary.main },
    })
);

const ButtonsToolbarUser: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const dispatchUser = useUserDispatch();
  const location = useLocation();

  const [
    anchorElConsultas,
    setAnchorElConsultas,
  ] = useState<HTMLElement | null>(null);

  const [
    anchorElFinanceiro,
    setAnchorElFinanceiro,
  ] = useState<HTMLElement | null>(null);

  const [anchorElCustos, setAnchorElCustos] = useState<HTMLElement | null>(
    null
  );

  const [openDialogSend, setOpenDialogSend] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialogSettingsSend, setOpenDialogSettingsSend] = useState(false);

  return (
    <div className={classes.buttonsToolbar}>
      <Autocomplete
        options={userState.state.assessorList}
        className={classes.autocompleteRoot}
        getOptionLabel={(option) => `${option.bpID} - ${option.bpName}`}
        loading={userState.state.loadingAssessorList}
        value={userState.state.bpSelected}
        style={{
          display:
            location.pathname === ROUTES.USER_ROUTES.HOME ||
            location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_SALES
              ? "none"
              : "block",
        }}
        onChange={(e, value) =>
          dispatchUser({
            type: "APP_SET_ASSESSOR",
            payload: {
              bpID: value.bpID,
              type: value.type,
              bpName: value.bpName,
              userID: userState.state.userID,
            },
          })
        }
        disableClearable
        renderOption={(option, { inputValue }) => {
          const matches = match(
            `${option.bpID} - ${option.bpName}`,
            inputValue
          );
          const parts = parse(`${option.bpID} - ${option.bpName}`, matches);

          return (
            <React.Fragment>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                    className={clsx(part.highlight && classes.matchText)}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Carteira"
            InputProps={{
              ...params.InputProps,
              classes: {
                input: classes.autocompleteInput,
                underline: classes.autocompleteInputUnderline,
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: {
                root: classes.autocompleteInputLabelRoot,
                focused: classes.autocompleteInputLabel,
              },
            }}
          />
        )}
      />
      <Button color="inherit" component={Link} to={ROUTES.USER_ROUTES.HOME}>
        Início
      </Button>
      <Button
        color="inherit"
        component={Link}
        to={ROUTES.USER_ROUTES.SIMULATION_COSTS}
      >
        Simular Preços
      </Button>

      <Button
        onClick={({ currentTarget }) => setAnchorElFinanceiro(currentTarget)}
        color="inherit"
      >
        Financeiro
      </Button>
      <Menu
        anchorEl={anchorElFinanceiro}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorElFinanceiro)}
        onClose={() => setAnchorElFinanceiro(null)}
      >
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD}>
          Dashboard
        </MenuItem>
        <MenuItem
          component={Link}
          to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
        >
          Consultar Partidas
        </MenuItem>
      </Menu>

      <Button
        onClick={({ currentTarget }) => setAnchorElConsultas(currentTarget)}
        color="inherit"
      >
        Consultas
      </Button>
      <Menu
        anchorEl={anchorElConsultas}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorElConsultas)}
        onClose={() => setAnchorElConsultas(null)}
      >
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.APPOINTMENT_SALES}>
          Consultar vendas
        </MenuItem>

        {userState.hasPermission(
          TypesRolesPermissions.ROLE_SALES_MARKETING_ACCESS
        ) ? (
          <MenuItem
            component={Link}
            to={ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN}
          >
            Consultar vendas/marketing
          </MenuItem>
        ) : (
          <></>
        )}

        <MenuItem component={Link} to={ROUTES.USER_ROUTES.APPOINTMENT_PRICE}>
          Consultar preços
        </MenuItem>
        <MenuItem
          component={Link}
          to={ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER}
        >
          Consultar preços de cliente
        </MenuItem>
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.APPOINTMENT_WALLET}>
          Consultar carteira
        </MenuItem>
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}>
          Partidas individuais por cliente
        </MenuItem>
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.APPOINTMENT_INVOICES}>
          Consultar NFs
        </MenuItem>
        <MenuItem component={Link} to={ROUTES.USER_ROUTES.QUOTE_SALE}>
          Consultar cotas
        </MenuItem>
      </Menu>

      {userState.hasPermission(TypesRolesPermissions.ROLE_PRICING) ? (
        <>
          <Button
            onClick={({ currentTarget }) => setAnchorElCustos(currentTarget)}
            color="inherit"
          >
            Custos
          </Button>
          <Menu
            anchorEl={anchorElCustos}
            keepMounted
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            getContentAnchorEl={null}
            open={Boolean(anchorElCustos)}
            onClose={() => setAnchorElCustos(null)}
          >
            <MenuItem onClick={() => setOpenDialogSend(true)}>
              Cadastrar custos
            </MenuItem>
            <MenuItem component={Link} to={ROUTES.USER_ROUTES.ATIVE_COSTS}>
              Consultar custos ativos
            </MenuItem>
            <MenuItem component={Link} to={ROUTES.USER_ROUTES.HISTORY_COSTS}>
              Consultar historico / agendado
            </MenuItem>
            <MenuItem onClick={() => setOpenDialogSettingsSend(true)}>
              Cadastrar configuração de margem
            </MenuItem>

            <MenuItem component={Link} to={ROUTES.USER_ROUTES.SETTINGS_COSTS}>
              Consultar configurações de margem ativas
            </MenuItem>
            <MenuItem
              component={Link}
              to={ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS}
            >
              Consultar configurações de margem historico / agendado
            </MenuItem>
            <MenuItem component={Link} to={ROUTES.USER_ROUTES.SALES_MARGIN}>
              Consultar vendas margem
            </MenuItem>
          </Menu>
          <DialogUploadCosts
            open={openDialogSend}
            onClose={() => setOpenDialogSend(false)}
            onSuccess={() => setOpenDialogSuccess(true)}
          />
          <DialogUploadSettingsCosts
            open={openDialogSettingsSend}
            onClose={() => setOpenDialogSettingsSend(false)}
            onSuccess={() => setOpenDialogSuccess(true)}
          />
          <DialogSuccessUploadCosts
            open={openDialogSuccess}
            onClose={() => setOpenDialogSuccess(false)}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const Header = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <AppBar
        color="primary"
        position="sticky"
        className={clsx(classes.appbar)}
      >
        <Toolbar style={{ height: "100%" }}>
          <Grid container justify="space-between">
            <div>
              <IconButton
                onClick={() => setOpenDrawer((oldValue) => !oldValue)}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <ButtonsToolbarUser />
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={openDrawer} setOpen={setOpenDrawer} />
    </>
  );
};

export default Header;
