import {
  createStyles,
  IconButton,
  IconButtonProps,
  makeStyles,
  Tooltip,
  TooltipProps,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    reloadButton: {
      animationName: "$spin",
      animationDuration: "2s",
      animationIterationCount: "infinite",
      animationDirection: "reverse",
    },
    "@keyframes spin": {
      to: {
        transform: "rotate(360deg)",
      },
    },
  })
);

export interface ButtonReloadProps {
  reloading: boolean;
  reloadFunc: () => void;
  tooltipProps?: Omit<TooltipProps, "children">;
  iconButtonProps?: IconButtonProps;
}

const ButtonReload: React.FC<ButtonReloadProps> = ({
  reloading,
  reloadFunc,
  tooltipProps,
  iconButtonProps,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title="Recarregar" {...tooltipProps}>
      <IconButton
        onClick={() => {
          if (!reloading) {
            reloadFunc();
          }
        }}
        {...iconButtonProps}
      >
        <CachedIcon className={reloading ? classes.reloadButton : ""} />
      </IconButton>
    </Tooltip>
  );
};

export { ButtonReload };
