import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import {
  AuthCogCookieKeys,
  AuthenticationContextData,
  AuthenticationResponse,
  CognitoParsedToken,
} from "./IAuthContext";

import { IHttpService } from "@modules/infra/http/models/IHttpService";

import DashLoading from "@components/DashLoading";
import appConfig from "@config/appConfig";
import { Types } from "@ioc/types";
import { goToLogout } from "@utils/index";

const BUFFER_TIME_MS = 59 * 60 * 1e3;
const TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS = 30e3;

const ADMINMODE = "admin_portal_comercial";

export const AuthContext = createContext<AuthenticationContextData | null>(
  null
);

const baseUrl = () => {
  const host = window.location.hostname;

  const domain = host.split(".")[0];

  const testDomainList = ["localhost", "teste"];

  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (
    environment === "homologation" ||
    testDomainList.some((item) => domain.includes(item))
  ) {
    return appConfig.cognito.gateway.development;
  }
  return appConfig.cognito.gateway.production;
};

const authServiceStandalone = axios.create({
  baseURL: baseUrl(),
  timeout: appConfig.api.timeout,
});

interface PropsAuthProvider {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<PropsAuthProvider> = (
  props: PropsAuthProvider
) => {
  const [
    currentAuthState,
    setCurrentAuthState,
  ] = useState<AuthenticationContextData | null>(null);

  const iocContext = useIoCContext();
  const httpService = iocContext.serviceContainer.get<IHttpService>(
    Types.IHttpService
  );

  // Função para fazer a solicitação de atualização do token
  const refreshTokenRequest = async (
    currentTokenRefresh: string
  ): Promise<AuthenticationResponse> => {
    const { data: refreshTokenResponse } = await authServiceStandalone.post<
      AuthenticationResponse
    >("/auth/refresh", {
      token: currentTokenRefresh,
    });

    return refreshTokenResponse;
  };

  // Função para fazer de atualização do token
  const setTokenExpiration = useCallback(
    async (nextTokenRefreshTime: number): Promise<string | null> => {
      try {
        const data = await tokenRefresh(
          nextTokenRefreshTime - TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS
        );
        if (data) {
          const builtToken = `${data.TokenType} ${data.AccessToken}`;
          httpService.setAuthorization(builtToken);
          return builtToken;
        }
        return null;
      } catch (error) {
        console.error(
          "[setTokenExpiration]: Failed to refresh access token in setTokenExpirationStrategy",
          error
        );
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Função principal de atualização do token
  const tokenRefresh = useCallback(
    async (refreshTokenExpirationTime?: number) => {
      try {
        const cookies = new Cookies();
        const cookiesTokenRefresh = cookies.get(AuthCogCookieKeys.refreshToken);

        // Verifica se o token atual existe no cookies da sessao
        if (!cookiesTokenRefresh) {
          throw new Error("Refresh token not found");
        }

        // Verifica se o token do cookie atual da sessao ainda não expirou
        if (
          refreshTokenExpirationTime &&
          +new Date() <= refreshTokenExpirationTime
        ) {
          return null;
        }

        const refreshTokenResponse = await refreshTokenRequest(
          cookiesTokenRefresh
        );
        const nextTokenRefreshTime =
          jwtDecode<CognitoParsedToken>(refreshTokenResponse.AccessToken).exp *
            1e3 -
          BUFFER_TIME_MS;
        httpService.setTokenExpirationStrategy(
          async () => await setTokenExpiration(nextTokenRefreshTime)
        );
        return refreshTokenResponse;
      } catch (error) {
        console.error("[tokenRefresh]: Failed to refresh access token", error);
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(async () => {
    goToLogout();
  }, []);

  const bootstrapAuthentication = useCallback(async () => {
    try {
      const data = await tokenRefresh();
      if (!data) {
        await logout();
        return;
      }

      httpService.setAuthorization(`${data.TokenType} ${data.AccessToken}`);

      const authData: AuthenticationContextData = {
        email: data.meta.email,
        name: data.meta.name,
        groups: data.meta.groups,
        refreshToken: data.RefreshToken,
        listCNPJ: data.meta.permissionSet.CNPJ,
        subject: data.meta.id,
        token: data.AccessToken,
        adminMode: data.meta.groups.includes(ADMINMODE),
        hasAdminRole: data.meta.groups.includes(ADMINMODE),
        username: data.meta.username,
        userID: data.meta.id,
        isAdmin: Boolean(
          data.meta.permissionSet.ROLES.some((role) => role.includes("admin"))
        ),
        bpID: `${data.meta.permissionSet.BPID}`,
        listAssessorIDAccepted: data.meta.permissionSet.PORTFOLIO_IDS,
        roles: data.meta.permissionSet.ROLES,
        sub: data.meta.id,
        type: data.meta.permissionSet.ROLES.includes("admin")
          ? "admin"
          : "advisorID",
        SYSTEM_MODULES: data.meta.permissionSet.SYSTEM_MODULES,
        logout,
      };
      setCurrentAuthState(authData);
    } catch (error) {
      console.error(
        "[bootstrapAuthentication]: Failed to refresh access token",
        error
      );
      await logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    bootstrapAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={currentAuthState}>
      {currentAuthState && currentAuthState.token ? (
        props.children
      ) : (
        <DashLoading />
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
