import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadPDFInvoicesDTO } from "../dtos/IDownloadPDFInvoicesDTO";
import { IDownloadPDFInvoicesService } from "../models/IDownloadPDFInvoicesService";

@injectable()
export class DownloadPDFInvoicesService implements IDownloadPDFInvoicesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IDownloadPDFInvoicesDTO): Promise<string> {
    const file = await this.httpInstance.post<BinaryType>(
      "/invoices/nfes",
      data,
      {
        responseType: "arraybuffer",
      }
    );

    let url;
    if (data.codes.length > 1 || data.type === "all") {
      url = window.URL.createObjectURL(
        new Blob([file], { type: "application/zip" })
      );
    } else {
      if (data.type === "pdf") {
        url = window.URL.createObjectURL(
          new Blob([file], { type: "application/pdf" })
        );
      } else {
        url = window.URL.createObjectURL(
          new Blob([file], { type: "application/xml" })
        );
      }
    }
    return url;
  }
}
