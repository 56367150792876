import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IGetActiveSettingsCostsDTO } from "@modules/settingsCosts/dtos/IGetActiveSettingsCostsDTO";
import { IGetActiveSettingsCostsService } from "@modules/settingsCosts/models/IGetActiveSettingsCostsService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

export interface ActiveCostsContextProps {
  rowsActiveCosts: IGetActiveSettingsCostsDTO[];
  loadingTable: boolean;
  openDialogDelete: DialogDeleteProps;
  openDialogEdit: DialogEditProps;
  errorLoadingTable: boolean;
  setOpenDialogDelete: React.Dispatch<React.SetStateAction<DialogDeleteProps>>;
  setOpenDialogEdit: React.Dispatch<React.SetStateAction<DialogEditProps>>;
  fetchActiveCosts: () => Promise<void>;
}

export const ActiveSettingsCostsContext = React.createContext(
  {} as ActiveCostsContextProps
);

interface DialogDeleteProps {
  open: boolean;
  id: string | null;
}
interface DialogEditProps {
  open: boolean;
  id: string | null;
}

export const ActiveSettingsCostsProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const getActiveSettingsCostsService = iocContext.serviceContainer.get<
    IGetActiveSettingsCostsService
  >(Types.SettingsCosts.IGetActiveSettingsCostsService);

  const [rowsActiveCosts, setRowsActiveCosts] = useState<
    IGetActiveSettingsCostsDTO[]
  >([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [errorLoadingTable, setErrorLoadingTable] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState<DialogDeleteProps>({
    open: false,
    id: null,
  });
  const [openDialogEdit, setOpenDialogEdit] = useState<DialogEditProps>({
    open: false,
    id: null,
  });

  const fetchActiveCosts = useCallback(async () => {
    try {
      setLoadingTable(true);
      const resp = await getActiveSettingsCostsService.execute();
      setRowsActiveCosts(resp);
      setErrorLoadingTable(false);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, { variant: error.variant });
      } else {
        enqueueSnackbar("Erro ao baixar dados de custos", { variant: "error" });
      }
      setErrorLoadingTable(true);
    } finally {
      setLoadingTable(false);
    }
  }, [enqueueSnackbar, getActiveSettingsCostsService]);

  useEffect(() => {
    fetchActiveCosts();
  }, [fetchActiveCosts]);

  return (
    <ActiveSettingsCostsContext.Provider
      value={{
        rowsActiveCosts,
        loadingTable,
        openDialogDelete,
        openDialogEdit,
        errorLoadingTable,
        setOpenDialogDelete,
        fetchActiveCosts,
        setOpenDialogEdit,
      }}
    >
      {children}
    </ActiveSettingsCostsContext.Provider>
  );
};

export const useActiveSettingsCostsContext = () => {
  const context = React.useContext(ActiveSettingsCostsContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useActiveCostsContext não pode ser utilizado fora de um ActiveCostsProvider"
    );
  }
  return context;
};
