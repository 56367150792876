import * as Yup from "yup";

const SUPPORTED_FORMATS = ["application/pdf", "image/*"];

const FormUploadValidationSchema = Yup.object().shape({
  comments: Yup.string().lowercase(),
  file: Yup.mixed()
    .test("fileType", "Tipo de arquivo não suportado", (value) => {
      if (value) {
        for (let patternStr of SUPPORTED_FORMATS) {
          let pattern = new RegExp(patternStr);
          if (pattern.test(value.type)) {
            return true;
          }
        }
      }
      return false;
    })
    .required("Anexe um arquivo válido"),
});

export default FormUploadValidationSchema;
