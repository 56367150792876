import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICallJobsService, JobService } from "../models/ICallJobsService";

@injectable()
export class CallJobsService implements ICallJobsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: JobService): Promise<void> {
    await this.httpInstance.post(`/job/${data}`, undefined);
  }
}
