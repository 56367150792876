import { Box } from "@material-ui/core";
import { AnimatePresence, motion, PanInfo } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useSlider } from "./SlideContext";

const RenderSlide: React.FC<{ index: number }> = ({ children }) => {
  const slideContext = useSlider();
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (boxRef.current) {
      slideContext.setMaxHeight(boxRef.current.clientHeight);
    }
  }, [slideContext]);

  const sliderAnimation = slideContext.showMore
    ? {
        enter: { width: "auto" },
        center: { width: "auto" },
        exit: { opacity: 0, width: 0 },
      }
    : {
        enter: (direction: number) => {
          return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
          };
        },
        center: {
          x: 0,
          zIndex: 1,
          opacity: 1,
        },
        exit: (direction: number) => {
          return {
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
            zIndex: 0,
          };
        },
      };

  return (
    <AnimatePresence custom={slideContext.direction}>
      <Box
        // @ts-ignore
        ref={boxRef}
        component={motion.div}
        custom={slideContext.direction}
        initial="enter"
        animate="center"
        exit="exit"
        variants={sliderAnimation}
        {...(slideContext.showMore
          ? {
              style: { margin: "0 1rem", flex: 1 },
              layout: true,
              transition: { duration: 0.5 },
            }
          : {
              style: { flex: 1 },
              drag: "x",
              dragConstraints: { left: 0, right: 0 },
              dragElastic: 1,
              // @ts-ignore
              onDragEnd: (e: MouseEvent, { offset, velocity }: PanInfo) => {
                /**
                 * Experimenting with distilling swipe offset and velocity into a single variable, so the
                 * less distance a user has swiped, the more velocity they need to register as a swipe.
                 * Should accomodate longer swipes and short flicks without having binary checks on
                 * just distance thresholds and velocity > 0.
                 */
                const swipeConfidenceThreshold = 10000;
                const swipePower = (offset: number, velocity: number) => {
                  return Math.abs(offset) * velocity;
                };

                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  slideContext.changeSlide(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  slideContext.changeSlide(-1);
                }
              },
              transition: {
                x: { type: "spring", stiffness: 200, damping: 30 },
                opacity: { duration: 0.2 },
              },
            })}
      >
        {children}
      </Box>
    </AnimatePresence>
  );
};

/**
 * @see https://www.framer.com/docs/examples/#exit-animations
 */
const SlideItem: React.FC<{ index: number }> = ({ index, children }) => {
  const { showSlideInShowMore, showMore, sliderIdx } = useSlider();

  return !showMore ? (
    index === sliderIdx ? (
      <RenderSlide index={index}>{children}</RenderSlide>
    ) : (
      <></>
    )
  ) : showSlideInShowMore.some((indexToShow) => indexToShow === index) ? (
    <RenderSlide index={index}>{children}</RenderSlide>
  ) : (
    <></>
  );
};

export { SlideItem };
