import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveCostsDTO } from "../dtos/IGetActiveCostsDTO";
import { IEditActiveCostService } from "../models/IEditActiveCostService";

@injectable()
export class EditActiveCostService implements IEditActiveCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(
    activeCostID: string,
    data: Partial<Pick<IGetActiveCostsDTO, "value" | "start_at" | "end_at">>
  ): Promise<void> {
    return this.httpInstance.patch("/costs/" + activeCostID, data);
  }
}
