import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { LinearProgress } from "@material-ui/core";
import {
  IGetChartDataService,
  ProductGroupResponse,
  ProductStatusVolumeResponse,
} from "@modules/dashboard/models/IGetChartDataService";
import {
  HomeDashboardFilters,
  useHomeDashboard,
} from "@pages/Home/HomeDashboardContext";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";

export interface ProductGroupData {
  [propName: string]: number;
}

const ParticipacaoProdVolume: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const iocContext = useIoCContext();
  const { dateCharts } = useHomeDashboard();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchDataForDaily = useFetchData(
    () => getDataChartService.getProductStatusVolume({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchDataForMonthly = useFetchData(
    () => getDataChartService.getProductGroup({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([]);

  const productGroupData: ProductGroupData | null = useMemo(() => {
    if (dateCharts.selected !== 0) {
      if (!fetchDataForMonthly.value) {
        return null;
      }
    } else {
      if (!fetchDataForDaily.value) {
        return null;
      }
    }

    let data:
      | ProductGroupResponse
      | ProductStatusVolumeResponse = fetchDataForDaily.value!;
    if (dateCharts.selected !== 0) {
      data = fetchDataForMonthly.value!;
    }

    const nameIdx = data.columns.findIndex(
      (column) => column.name === "productGroup"
    );
    const quantityIdx = data.columns.findIndex(
      (column) => column.name === "quantity"
    );

    // @ts-ignore
    const groupProduct: ProductGroupData = data.rows.reduce((a, b) => {
      if (a[b[nameIdx]]) {
        a[b[nameIdx]] = a[b[nameIdx]] + (b[quantityIdx] as number);
      } else {
        a[b[nameIdx]] = b[quantityIdx] as number;
      }

      return a;
    }, {} as ProductGroupData);

    return groupProduct;
  }, [dateCharts.selected, fetchDataForDaily.value, fetchDataForMonthly.value]);

  useEffect(() => {
    if (!productGroupData) return;

    setOptions({
      plotOptions: { bar: { horizontal: true } },
      dataLabels: {
        enabled: true,
        style: { colors: appConfig.chartTheme.dataLabels.style.colors },
        formatter: (value) =>
          new Intl.NumberFormat("pt-BR", { style: "percent" }).format(
            Number(value) / 100
          ),
      },
      tooltip: {
        y: {
          formatter: (value) =>
            new Intl.NumberFormat("pt-BR").format(Number(value)),
        },
      },
      labels: Object.keys(productGroupData).map((key) => key),
      xaxis: { categories: Object.keys(productGroupData).map((key) => key) },
      legend: { position: "right" },
    });
  }, [productGroupData]);

  useEffect(() => {
    if (!productGroupData) return;
    setSeries(() =>
      Object.keys(productGroupData).map((key) => productGroupData[key])
    );
  }, [productGroupData]);

  return (
    <>
      {(fetchDataForDaily.loading || fetchDataForMonthly.loading) && (
        <LinearProgress />
      )}
      <Chart
        type="donut"
        options={options}
        series={series}
        width="100%"
        height="100%"
      />
    </>
  );
};

export { ParticipacaoProdVolume };
