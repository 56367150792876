import NoData from "@components/NoData";
import { Tag } from "@components/Tag";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import {
  FinancialInstallmentsContent,
  StatusDoc,
  StatusInvoice,
} from "@modules/financial/dtos/IFinancialInstallmentsDTO";
import {
  docStautusName,
  documentMask,
  formatCurrency,
  invoiceStatusName,
} from "@utils/index";
import { utcToZonedTime } from "date-fns-tz";
import formatTZ from "date-fns-tz/format";
import React from "react";
import { useFinancialInstallmentsContext } from "./FinancialInstallmentsContext";

const utcZone = "Europe/Lisbon";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "1rem",
      height: 850,
      [theme.breakpoints.up("md")]: { flex: 1 },
    },
    toolbarOpen: {
      backgroundColor: theme.palette.secondary.main,
      "& p": {
        color: "white",
      },
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperSituationClosed: {
      color: "#78C357",
      backgroundColor: "rgba(120,195,87,0.12)",
    },
    paperSituationOpen: {
      color: "#FFA722",
      backgroundColor: "rgba(255,204,0,0.12)",
    },
    paperSituationPartial: {
      color: theme.palette.secondary.main,
      backgroundColor: "rgba(255,167,34,0.12)",
    },
    paperSituationDue: {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(195,12,12,0.12)",
    },
    footerTextTable: {
      fontWeight: "bold",

      display: "flex",

      "& span": {
        display: "block",
        marginLeft: "1rem",
        fontWeight: "bold",
      },
    },
    footerTextColorClosed: {
      color: "#78C357",
    },
    footerTextColorTotal: {
      color: "#000",
    },
    tagSpacing: { [theme.breakpoints.up("xs")]: { margin: "1rem" } },
  })
);

const CustomToolbar: React.FC = () => {
  const classes = useStyles();
  const financialInstallmentsContext = useFinancialInstallmentsContext();

  return (
    <GridToolbarContainer>
      <Grid container justify="center">
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarDensitySelector />
        </Grid>

        <Grid container justify="space-around">
          {financialInstallmentsContext.totalValueByStatusDoc?.map((item) => {
            return (
              <Grid item>
                <Tag
                  className={classes.tagSpacing}
                  type={
                    item.name === "CLOSE"
                      ? "success"
                      : item.name === "NOT_EXPIRED"
                      ? "warning"
                      : "error"
                  }
                >
                  Total {docStautusName(item.name)}:{" "}
                  {formatCurrency(item.value)}
                </Tag>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

const TableFinancialInstallments: React.FC = () => {
  const financialInstallmentsContext = useFinancialInstallmentsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const columns: GridColDef[] = [
    {
      field: "customerCNPJ",
      headerName: "CNPJ",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => documentMask(params.value as string),
      width: 300,
    },
    {
      field: "bp",
      headerName: "ID SAP",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: "Razão Social",
      headerAlign: "center",
      align: "center",
      width: 350,
    },
    {
      field: "segmentDesc",
      headerName: "Segmento",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "state",
      headerName: "UF",
      width: 100,
    },
    {
      field: "advisorID",
      headerName: "Cod Assessor",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "advisorDesc",
      headerName: "Assessor",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "managerCode",
      headerName: "Cod Gerente",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "managerName",
      headerName: "Gerente",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "documentNumber",
      headerName: "Nro Documento",
      headerAlign: "center",
      align: "center",
      type: "number",
      width: 300,
    },
    {
      field: "item",
      headerName: "Item",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "documentType",
      headerName: "Tipo do Documento",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "yearDueDate",
      headerName: "Ano de Venc",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "description",
      headerName: "Descrição",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "statusInvoice",
      headerName: "Status Fatura",
      headerAlign: "center",
      align: "center",
      width: 300,
      valueGetter: (params) => {
        const row = params.row as FinancialInstallmentsContent;
        return { status: `${row.statusInvoice}:${row.statusDoc}` };
      },
      renderCell: (params) => {
        const data = params.getValue(params.id, params.field) as {
          value: number;
          status: string;
        };

        const statusList = data.status.split(":");
        const statusInvoice = statusList[0];
        const statusDoc = statusList[1];

        return (
          <>
            <Tag
              type={
                statusDoc === "EXPIRED"
                  ? "error"
                  : statusDoc === "NOT_EXPIRED"
                  ? "warning"
                  : "success"
              }
            >
              {invoiceStatusName(statusInvoice as StatusInvoice)}
            </Tag>
            {data.value > 0 ? (
              <Typography variant="caption">
                A pagar:&nbsp;
                {formatCurrency(data.value)}
              </Typography>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "statusDoc",
      headerName: "Status Doc",
      headerAlign: "center",
      align: "center",
      width: 300,
      valueGetter: (params) => {
        const row = params.row as FinancialInstallmentsContent;
        return { statusDoc: row.statusDoc };
      },
      renderCell: (params) => {
        const data = params.getValue(params.id, params.field) as {
          value: number;
          statusDoc: StatusDoc;
        };

        return (
          <>
            <Tag
              type={
                data.statusDoc === "NOT_EXPIRED"
                  ? "warning"
                  : data.statusDoc === "EXPIRED"
                  ? "error"
                  : "success"
              }
            >
              {docStautusName(data.statusDoc as StatusDoc)}
            </Tag>
            {data.value > 0 ? (
              <Typography variant="caption">
                A pagar:&nbsp;
                {formatCurrency(data.value)}
              </Typography>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "value",
      headerName: "Valor",
      headerAlign: "center",
      align: "center",
      type: "number",
      valueFormatter: (params) => formatCurrency(params.value as number),
      width: 300,
    },
    {
      field: "reference",
      headerName: "Referência",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "assignment",
      headerName: "Atribuído",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "documentDate",
      headerName: "Data do Documento",
      headerAlign: "center",
      align: "center",
      type: "date",
      width: 300,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "dueDate",
      headerName: "Vencimento",
      headerAlign: "center",
      align: "center",
      type: "date",
      width: 300,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "quittanceDate",
      headerName: "Data da Baixa",
      headerAlign: "center",
      align: "center",
      type: "date",
      width: 300,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "accountingPeriod",
      headerName: "Período Contábil",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "debitCreditCode",
      headerName: "Cod Deb/Cred",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "accountingRate",
      headerName: "Taxa Conta",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "division",
      headerName: "Divisão",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "paymentRef",
      headerName: "Pagto Referência",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "itemText",
      headerName: "Item Desc",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "keyRef",
      headerName: "Chave Ref",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "companyBank",
      headerName: "Cod Banco",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "accountID",
      headerName: "ID Conta",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "paymentForm",
      headerName: "Forma Pagto",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "LCRequesting",
      headerName: "LC Requisição",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "partnerNumber",
      headerName: "Num Parceiro",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "typeMovement",
      headerName: "Tipo de Movimento",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "invoice",
      headerName: "Fatura",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "keyRef1",
      headerName: "Ref 1",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "keyRef2",
      headerName: "Ref 2",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "keyRef3",
      headerName: "Ref 3",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
  ];

  return financialInstallmentsContext.dataQuery &&
    financialInstallmentsContext.dataQuery.content.length === 0 ? (
    <NoData>Não foram encontrados resultados!</NoData>
  ) : (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div style={{ height: isMobile ? 600 : "100%" }}>
        <DataGrid
          columns={columns}
          loading={financialInstallmentsContext.loadingQuery}
          rows={
            financialInstallmentsContext.dataQuery
              ? financialInstallmentsContext.dataQuery.content
              : []
          }
          components={{ Toolbar: CustomToolbar }}
          rowCount={financialInstallmentsContext.dataQuery?.total}
          pageSize={financialInstallmentsContext.query.limit}
          page={(financialInstallmentsContext.query.page ?? 1) - 1}
          pagination
          paginationMode="server"
          onPageSizeChange={(pageSize) => {
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              limit: pageSize,
            }));
          }}
          rowsPerPageOptions={[10, 20, 30, 50]}
          onPageChange={(newPage) => {
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              page: newPage + 1,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default TableFinancialInstallments;
