/* eslint-disable @typescript-eslint/ban-ts-comment */
import { goToLogout } from "@utils/index";
import Axios, { AxiosError, AxiosInstance } from "axios";
import { injectable } from "inversify";
import appConfig from "../../../../config/appConfig";
import AppError from "../../../../utils/AppError";
import { IHttpService } from "../models/IHttpService";

@injectable()
export default class AxiosHttpService implements IHttpService {
  // @ts-ignore
  private httpInstance: AxiosInstance;

  constructor() {
    this.httpInstance = Axios.create({
      timeout: appConfig.api.timeout,
      baseURL: this.getBaseUrl(),
    });
  }

  getBaseUrl() {
    const host = window.location.hostname;

    const domain = host.split(".")[0];

    const testDomainList = ["localhost", "teste"];

    const environment = process.env.REACT_APP_ENVIRONMENT;
    if (environment === "homologation") {
      return appConfig.api.url.homologation;
    } else if (testDomainList.some((item) => domain.includes(item))) {
      return appConfig.api.url.development;
    }
    return appConfig.api.url.production;
  }

  setTokenExpirationStrategy(
    tokenExpireStrategy: () => Promise<string | null>
  ) {
    this.httpInstance.interceptors.request.use(async (config) => {
      const newTokenProvided = await tokenExpireStrategy();
      if (newTokenProvided) {
        this.setAuthorization(newTokenProvided);
        config.headers.Authorization = newTokenProvided;
      }
      return config;
    });
  }

  setAuthorization(token: string): void {
    this.httpInstance.defaults.headers.common.Authorization = token;
  }

  getAuthorization(): string {
    return this.httpInstance.defaults.headers.common.Authorization;
  }

  getWithHeaders<T = any, U = any>(
    path: string,
    params?: {}
  ): Promise<{ data: T; headers: U }> {
    return this.httpInstance
      .get<T>(path, params)
      .then(({ data, headers }) => ({ data, headers }))
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  get<T = any>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  post<T = any>(path: string, body: any, params?: {}): Promise<T> {
    return this.httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  delete<T = any>(path: string, params?: {}): Promise<T> {
    return this.httpInstance
      .delete<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .patch<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  put<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .put<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }
}
