import DatePickerCustom, {
  IDatePickerCheckBox,
} from "@components/DatePickerCustom";
import SelectCustom, { renderOptionsProps } from "@components/SelectCustom";
import { useUserState } from "@context/UserContext";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { IDocumentTypeDTO } from "@modules/financial/dtos/IDocumentTypeDTO";
import { IGroupCustomerFilterDTO } from "@modules/financial/dtos/IGroupCustomerFilterDTO";
import { IManagerFilterDTO } from "@modules/financial/dtos/IManagerFilterDTO";
import { IBpDTO } from "@modules/user/dtos/IBpDTO";
import { OnKeypressEscape } from "@utils/index";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useFinancialDashboardContext } from "./FinancialDashboardContext";

const useStyles = makeStyles({
  drawerPaper: {
    width: 490,
    padding: 40,
  },
  closeIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
  },
  filterButton: {
    fontSize: "16px",
    color: "#D91F05",
    fontWeight: 600,
    cursor: "pointer",
    textAlign: "end",
    marginTop: 41,
  },
  checkboxRoot: {
    color: "#D91F05",
    "&$checked": {
      color: "#D91F05",
    },
  },
  formControl: {
    flexDirection: "row",
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 32,
    marginBottom: 10,
  },
});

interface FinancialDashboardFiltersProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PaymentMethodCheckBox extends IDatePickerCheckBox {}

interface TermCheckbox extends IDatePickerCheckBox {}

interface MonthCheckbox extends IDatePickerCheckBox {}

interface MonthCheckboxCustomProps {
  month: string;
  year: string;
  isSelected: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const FinancialDashboardFilters: React.FC<FinancialDashboardFiltersProps> = (
  props
) => {
  const classes = useStyles();
  const userState = useUserState();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCheckBox[]>(
    []
  );
  const [, /* term */ setTerm] = useState<TermCheckbox[]>([]);
  const { query, setQuery, filters } = useFinancialDashboardContext();
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<MonthCheckbox[]>([]);
  const [yearList, setYearList] = useState<MonthCheckbox[]>([]);

  useEffect(() => {
    setPaymentMethod(
      filters.paymentMethodFilter?.map((item) => ({
        ...item,
        checked: false,
      })) ?? []
    );
  }, [filters.paymentMethodFilter]);

  useEffect(() => {
    setTerm(
      filters.termFilter?.map((item) => ({
        ...item,
        checked: false,
      })) ?? []
    );
  }, [filters.termFilter]);

  useEffect(() => {
    let newYear = "";
    if (filters.yearFilter && filters.yearFilter.length > 0) {
      newYear = filters.yearFilter[0].id;
    }
    setYear(newYear);
    setYearList(
      filters.yearFilter?.map(
        (item) => ({ ...item, checked: false } as MonthCheckbox)
      ) ?? []
    );
  }, [filters.yearFilter]);

  useEffect(() => {
    let currentMonth = parseInt(
      new Date().toLocaleString("pt-BR", { month: "numeric" })
    );
    let newMonth =
      filters.monthFilter?.map((item) => ({
        ...item,
        checked: parseInt(item.id) <= currentMonth,
      })) ?? [];

    setMonth(newMonth);
  }, [filters.monthFilter]);

  useEffect(() => {
    let from = "",
      to = "";

    if (year === "-1") {
      let newYearList = yearList
        .filter((item) => item.checked)
        .map((item) => item)
        .reverse();
      if (!newYearList.length) return;

      from = format(
        new Date(`${newYearList[0].desc}-01-01T04:00:00.000Z`),
        "yyyy-MM-dd"
      );
      if (newYearList.length === 1) {
        to = format(
          new Date(parseInt(newYearList[0].desc), 12, 1),
          "yyyy-MM-dd"
        );
      } else {
        to = format(
          new Date(parseInt(newYearList[newYearList.length - 1].desc), 12, 1),
          "yyyy-MM-dd"
        );
      }
    } else {
      let newMonth = month.filter((item) => item.checked);
      if (newMonth.length > 0) {
        const newYear =
          year === "" ? new Date().getFullYear().toString() : year;

        from = format(
          new Date(
            `${newYear}-${newMonth[0].id.padStart(2, "0")}-01T04:00:00.000Z`
          ),
          "yyyy-MM-dd"
        );
        if (newMonth.length === 1) {
          to = format(
            new Date(parseInt(newYear as string), parseInt(newMonth[0].id), 1),
            "yyyy-MM-dd"
          );
        } else {
          to = format(
            new Date(
              parseInt(newYear as string),
              parseInt(newMonth[newMonth.length - 1].id),
              1
            ),
            "yyyy-MM-dd"
          );
        }
      }
    }
    setQuery((state) => ({
      ...state,
      from,
      to,
    }));
  }, [month, yearList, year, setQuery]);

  useEffect(() => {
    setQuery((state) => ({
      ...state,
      paymentMethod: paymentMethod.filter((item) => item.checked),
    }));
  }, [paymentMethod, setQuery]);

  useEffect(() => {
    OnKeypressEscape(() => props.setOpenDialog(false));
  });

  const handleChangeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: PaymentMethodCheckBox[],
    setData: React.Dispatch<React.SetStateAction<PaymentMethodCheckBox[]>>
  ) => {
    let paymentMethodToCheck = values.map((item) => {
      if (item.id === event.target.name) {
        item.checked = !item.checked;
      }
      return item;
    });

    setData(paymentMethodToCheck);
  };

  const compareAdvisor = (a: IBpDTO, b: IBpDTO) => {
    const idA = parseInt(a.bpID);
    const idB = parseInt(b.bpID);

    return idA - idB;
  };

  const compareManager = (a: IManagerFilterDTO, b: IManagerFilterDTO) => {
    const idA = parseInt(a.id);
    const idB = parseInt(b.id);

    return idA - idB;
  };

  return (
    <div>
      <Drawer
        variant="persistent"
        anchor="right"
        open={props.openDialog}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          className={classes.closeIcon}
          onClick={() => props.setOpenDialog(false)}
        >
          <CloseRoundedIcon />
        </div>
        <SelectCustom
          label="Assessor"
          placeholder="Filtrar por Assessor"
          value={query.advisorID}
          options={userState.state.assessorList
            .filter((item) => item.bpID !== "0000000000")
            .sort(compareAdvisor)}
          getOptionLabel={(value) => `${value.bpName}:${value.bpID}`}
          renderSelected={query.advisorID.map(
            (item) =>
              ({
                value: item,
                option: `${item.bpID} - ${item.bpName}`,
              } as renderOptionsProps<IBpDTO>)
          )}
          onChange={(value) => {
            setQuery((state) => ({
              ...state,
              advisorID: value,
            }));
          }}
          onDeleteItem={(value) => {
            const newValues = query.advisorID.filter((item) => item !== value);
            setQuery((state) => ({
              ...state,
              advisorID: newValues,
            }));
          }}
        />
        <SelectCustom
          label="Gerente"
          placeholder="Filtrar por Gerente"
          value={query.managerID}
          getOptionLabel={(value) => `${value.name}:${value.id}`}
          options={filters.managerFilter?.sort(compareManager) ?? []}
          renderSelected={query.managerID.map(
            (item) =>
              ({
                value: item,
                option: `${item.id} - ${item.name}`,
              } as renderOptionsProps<IManagerFilterDTO>)
          )}
          onChange={(value) => {
            setQuery((state) => ({
              ...state,
              managerID: value,
            }));
          }}
          onDeleteItem={(value) => {
            const newValues = query.managerID.filter((item) => item !== value);
            setQuery((state) => ({
              ...state,
              managerID: newValues,
            }));
          }}
        />
        {/* <SelectCustom
          label="Cliente"
          placeholder="Filtrar por Cliente"
          value={query.CNPJ}
          getOptionLabel={(value) =>
            `${value.companyName}:${documentMask(value.CNPJ)}`
          }
          options={userState.state.listCNPJ}
          renderSelected={query.CNPJ.map(
            (item) =>
            ({
              value: item,
              option: `${item.customerID} - ${item.companyName}`,
            } as renderOptionsProps<ICnpjDTO>)
          )}
          onChange={(value) => {
            setQuery((state) => ({
              ...state,
              CNPJ: value,
            }));
          }}
          onDeleteItem={(value) => {
            const newValues = query.CNPJ.filter((item) => item !== value);
            setQuery((state) => ({
              ...state,
              CNPJ: newValues,
            }));
          }}
        /> */}
        <SelectCustom
          label="Grupo de Cliente"
          placeholder="Filtrar por Grupo de Cliente"
          value={query.groupCustomer}
          getOptionLabel={(value) => `${value.desc}:${value.id}`}
          options={filters.groupCustomerFilter ?? []}
          renderSelected={query.groupCustomer.map(
            (item) =>
              ({
                value: item,
                option: `${item.id} - ${item.desc}`,
              } as renderOptionsProps<IDocumentTypeDTO>)
          )}
          onChange={(value) => {
            setQuery((state) => ({
              ...state,
              groupCustomer: value,
            }));
          }}
          onDeleteItem={(value) => {
            const newValues = query.groupCustomer.filter(
              (item) => item !== value
            );
            setQuery((state) => ({
              ...state,
              groupCustomer: newValues,
            }));
          }}
        />
        <FormControl component="fieldset">
          <span className={classes.label}>Forma de Pagamento</span>
          <FormGroup className={classes.formControl}>
            {paymentMethod?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="default"
                    checked={item.checked}
                    onChange={(ev) =>
                      handleChangeCheckBox(ev, paymentMethod, setPaymentMethod)
                    }
                    name={item.id}
                    classes={{ root: classes.checkboxRoot }}
                  />
                }
                label={`${item.id} - ${item.desc}`}
              />
            ))}
          </FormGroup>
        </FormControl>
        <SelectCustom
          label="Tipo de Documento"
          placeholder="Filtrar por Tipo de Documento"
          value={query.documentType}
          getOptionLabel={(value) => `${value.desc}:${value.id}`}
          options={filters.documentTypeFilter ?? []}
          renderSelected={query.documentType.map(
            (item) =>
              ({
                value: item,
                option: `${item.id} - ${item.desc}`,
              } as renderOptionsProps<IGroupCustomerFilterDTO>)
          )}
          onChange={(value) => {
            setQuery((state) => ({
              ...state,
              documentType: value,
            }));
          }}
          onDeleteItem={(value) => {
            const newValues = query.documentType.filter(
              (item) => item !== value
            );
            setQuery((state) => ({
              ...state,
              documentType: newValues,
            }));
          }}
        />
        <DatePickerCustom
          formTitle="Mês e ano"
          selectProps={{
            value: year,
            items: filters.yearFilter ?? [],
            setValue: setYear,
          }}
          list={year === "-1" ? yearList ?? [] : month}
          setList={year === "-1" ? setYearList : setMonth}
        />
        {/* <FormControl component="fieldset">
          <span className={classes.label}>Prazo</span>
          <FormGroup className={classes.formControl}>
            {term?.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    color="default"
                    checked={item.checked}
                    onChange={(ev) => handleChangeCheckBox(ev, term, setTerm)}
                    name={item.id}
                    classes={{ root: classes.checkboxRoot }}
                  />
                }
                label={item.desc}
              />
            ))}
          </FormGroup>
        </FormControl> */}
        <div style={{ flex: 1 }}></div>
        <Typography
          variant="h4"
          className={classes.filterButton}
          onClick={() => props.setOpenDialog(false)}
        >
          FILTRAR
        </Typography>
      </Drawer>
    </div>
  );
};

export default FinancialDashboardFilters;
