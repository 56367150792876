import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ILoginImageDataDTO } from "../dtos/ILoginImageDataDTO";
import { IListLoginImageService } from "../models/IListLoginImageService";

@injectable()
export class ListLoginImageService implements IListLoginImageService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<ILoginImageDataDTO[]> {
    return this.httpInstance.get<ILoginImageDataDTO[]>("/login-images");
  }
}
