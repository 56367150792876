import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface SliderContext {
  changeSlide: (direction: number, slideId?: number) => void;
  sliderIdx: number;
  direction: number;
  /**
   * Total de slides que serão renderizados na tela
   */
  slideCount: number;
  maxHeight: number;
  setMaxHeight: (height: number) => void;
  showMore: boolean;
  /**
   * Quantidade de slides que devem aparecer na tela
   */
  slideQuantity: number;
  /**
   * Indíces que serão renderizados em tela
   */
  showSlideInShowMore: number[];
}

export const SliderContext = React.createContext({} as SliderContext);

export interface ShowMoreSliderProps {
  timeToPass?: number;
  slideCount: number;
  showMore: boolean;
  slideQuantity: number;
  disableAutoPass?: boolean;
}

export interface SliderWithIndicatorProps {
  timeToPass?: number;
  slideCount: number;
  showMore: never;
  slideQuantity: never;
  disableAutoPass?: boolean;
}

export type SliderProviderProps =
  | ShowMoreSliderProps
  | SliderWithIndicatorProps;

export const SliderProvider: React.FC<SliderProviderProps> = ({
  slideCount,
  timeToPass = 4000,
  showMore = false,
  disableAutoPass = false,
  children,
  ...props
}) => {
  const [[sliderIdx, direction], setSliderIdx] = useState([0, 0]);
  const [maxHeight, defineMaxHeight] = useState(0);

  const changeSlide = useCallback(
    (direction: number, slideId?: number) => {
      if (slideId !== undefined) {
        setSliderIdx([slideId, direction]);
      } else {
        setSliderIdx(([slide]) => [
          slide + direction >= 0
            ? (slide + direction) % slideCount
            : slideCount - 1,
          direction,
        ]);
      }
    },
    [slideCount]
  );
  const setMaxHeight = (height: number) => {
    defineMaxHeight((oldHeight) => (oldHeight < height ? height : oldHeight));
  };

  const rotate = (index: number, lengthExit: number, qtdSlides: number) => {
    return [...new Array(lengthExit)].map((_, i) => (index + i) % qtdSlides);
  };

  const showSlideInShowMore = useMemo(() => {
    return rotate(sliderIdx, props.slideQuantity, slideCount);
  }, [props.slideQuantity, slideCount, sliderIdx]);

  useEffect(() => {
    if (disableAutoPass === false) {
      const changeSlideIdInterval = setInterval(() => {
        changeSlide(1);
      }, timeToPass);

      return () => {
        clearInterval(changeSlideIdInterval);
      };
    }
  }, [changeSlide, disableAutoPass, sliderIdx, timeToPass]);

  return (
    <SliderContext.Provider
      value={{
        changeSlide,
        sliderIdx,
        direction,
        slideCount,
        maxHeight,
        setMaxHeight,
        showMore,
        slideQuantity: props.slideQuantity,
        showSlideInShowMore,
      }}
    >
      {children}
    </SliderContext.Provider>
  );
};

export const useSlider = () => {
  const context = useContext(SliderContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useSlider não pode ser utilizado fora de um SliderProvider"
    );
  }
  return context;
};
