import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import { Index } from "flexsearch";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      chip: { margin: "1rem" },
      matchText: { color: theme.palette.primary.main },
      dialogPaper: { [theme.breakpoints.up("md")]: { minWidth: "70vw" } },
      iconNotDisabled: { opacity: "0.6" },
    })
);

interface SelectMultiple<T> extends SelectCNPJProps<T> {
  value: T[];
  onChange: (value: T[]) => void;
  multiple: true;
  getOptionKey: (value: T) => string;
}

interface SelectOne<T> extends SelectCNPJProps<T> {
  value: T | null;
  onChange: (value: T) => void;
  multiple: false;
  getOptionKey?: undefined;
}

export interface SelectCNPJProps<T> {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  errorField?: boolean;
  messageErrorField?: string;
  fieldTouched?: boolean;
  options: T[];
  loading: boolean;
  startIcon: React.ReactNode;
  getOptionLabel: (value: T) => string;
  allSelected?: boolean;
  disableRemoveAll?: boolean;
}

export type SelectAutocompleteProps<T> = SelectMultiple<T> | SelectOne<T>;

function AutoCompleteCNPJ<T>(props: SelectAutocompleteProps<T>) {
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indexSearch = useMemo(() => new Index({ tokenize: "forward" }), [
    props.options,
  ]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const maxOptions = 100;

  const addOptionsInIndex = useCallback(async () => {
    try {
      setLoadingOptions(true);
      const optionsIndex = props.options.map((option, index) => {
        return indexSearch.addAsync(index, props.getOptionLabel(option));
      });

      await Promise.all(optionsIndex);
    } catch (error) {
    } finally {
      setLoadingOptions(false);
    }
  }, [indexSearch, props]);

  useEffect(() => {
    addOptionsInIndex();
  }, [addOptionsInIndex]);

  return props.multiple ? (
    <Autocomplete
      multiple
      disableCloseOnSelect
      noOptionsText="Sem Opções"
      loadingText="Carregando opções"
      filterOptions={(options, { inputValue }) => {
        if (inputValue !== "") {
          const result = indexSearch.search(inputValue, { limit: maxOptions });
          const optionsFinded = result.map(
            (value) =>
              // @ts-ignore
              options[value]
          );
          return optionsFinded;
        } else {
          return options.slice(0, maxOptions);
        }
      }}
      options={props.options}
      disabled={props.loading || loadingOptions}
      getOptionLabel={props.getOptionLabel}
      onChange={(event, value) => props.onChange(value)}
      value={props.value}
      renderOption={(option, { inputValue, selected }) => {
        const optionName = props.getOptionLabel(option);
        const matches = match(optionName, inputValue);
        const parts = parse(optionName, matches);

        return (
          <React.Fragment>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                  className={clsx(part.highlight && classes.matchText)}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: props.loading ? (
              <InputAdornment position="end">
                <CircularProgress
                  color="primary"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                />
                {params.InputProps.endAdornment}
              </InputAdornment>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
          label={props.title}
          error={props.errorField && props.fieldTouched}
          helperText={props.fieldTouched && props.messageErrorField}
          variant="outlined"
        />
      )}
    />
  ) : (
    <Autocomplete
      noOptionsText="Sem Opções"
      loadingText="Carregando opções"
      filterOptions={(options, { inputValue }) => {
        if (inputValue !== "") {
          const result = indexSearch.search(inputValue, { limit: maxOptions });
          const optionsFinded = result.map(
            (value) =>
              // @ts-ignore
              options[value]
          );
          return optionsFinded;
        } else {
          return options.slice(0, maxOptions);
        }
      }}
      options={props.options}
      disabled={props.loading || loadingOptions}
      getOptionLabel={props.getOptionLabel}
      onChange={(event, value) => (value ? props.onChange(value) : null)}
      value={props.value}
      renderOption={(option, { inputValue }) => {
        const optionName = props.getOptionLabel(option);
        const matches = match(optionName, inputValue);
        const parts = parse(optionName, matches);

        return (
          <React.Fragment>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                  className={clsx(part.highlight && classes.matchText)}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: props.loading ? (
              <InputAdornment position="end">
                <CircularProgress
                  color="primary"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                />
                {params.InputProps.endAdornment}
              </InputAdornment>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
          label="Cliente"
          error={props.errorField && props.fieldTouched}
          helperText={props.fieldTouched && props.messageErrorField}
          variant="outlined"
        />
      )}
    />
  );
}

export function SelectAutocomplete<T>(props: SelectAutocompleteProps<T>) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  const isIphoneX = useMediaQuery(theme.breakpoints.down(400), {
    defaultMatches: true,
  });

  const limitTags = 2;

  return (
    <>
      <div
        style={{
          width: "100%",
          cursor: props.multiple ? "pointer" : undefined,
        }}
        onClick={() => (props.multiple ? props.setOpenDialog(true) : null)}
      >
        <FormControl
          fullWidth
          style={{ pointerEvents: props.multiple ? "none" : undefined }}
        >
          {props.multiple ? (
            <>
              <InputLabel>{props.title}</InputLabel>
              <Select
                error={props.errorField && props.fieldTouched}
                value={props.allSelected ? ["Todos"] : props.value}
                multiple
                renderValue={(selected) => (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {props.allSelected ? (
                      <Chip className={classes.chip} label="Todos" />
                    ) : (
                      <>
                        {(selected as T[]).slice(0, limitTags).map((value) => {
                          return (
                            <Chip
                              className={classes.chip}
                              key={props.getOptionKey(value)}
                              label={props.getOptionLabel(value)}
                            />
                          );
                        })}
                        {(selected as T[]).length > limitTags ? (
                          <Chip
                            className={classes.chip}
                            label={`+${(selected as T[]).length - limitTags}`}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                )}
                startAdornment={
                  <InputAdornment position="start">
                    {props.startIcon}
                  </InputAdornment>
                }
                endAdornment={
                  props.loading && (
                    <InputAdornment position="end">
                      <CircularProgress
                        color="primary"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                      />
                    </InputAdornment>
                  )
                }
              >
                <MenuItem value="Todos">Todos</MenuItem>
              </Select>
            </>
          ) : (
            <AutoCompleteCNPJ {...props} multiple={false} />
          )}

          <FormHelperText error={props.errorField && props.fieldTouched}>
            {props.fieldTouched && props.messageErrorField}
          </FormHelperText>
        </FormControl>
      </div>
      {props.multiple && (
        <Dialog
          open={props.openDialog}
          onClose={() => props.setOpenDialog(false)}
          classes={{ paper: classes.dialogPaper }}
          fullScreen={isMobile}
        >
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <Grid
              container
              style={{ marginBottom: "1.6rem" }}
              justify={
                isIphoneX ? "center" : isMobile ? "space-between" : "center"
              }
              alignItems="center"
              spacing={isIphoneX ? 1 : isMobile ? 0 : 1}
            >
              <Grid item>
                <Button
                  color="primary"
                  startIcon={<SelectAllIcon />}
                  variant="outlined"
                  onClick={() => props.onChange(props.options)}
                >
                  Adicionar todos
                </Button>
              </Grid>
              {props.disableRemoveAll ? (
                <></>
              ) : (
                <Grid item>
                  <Collapse in={props.value.length > 0}>
                    <Button
                      color="primary"
                      startIcon={<ClearAllIcon />}
                      variant="outlined"
                      onClick={() => props.onChange([])}
                    >
                      Remover todos
                    </Button>
                  </Collapse>
                </Grid>
              )}
            </Grid>
            <AutoCompleteCNPJ {...props} multiple />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => props.setOpenDialog(false)}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
