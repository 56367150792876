import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUploadTitleImageDataDTO } from "../dtos/IUploadTitleImageDataDTO";
import { IUploadTitleImageDTO } from "../dtos/IUploadTitleImageDTO";
import { IUploadTitleImageService } from "../models/IUploadTitleImageService";

@injectable()
export class UploadTitleImageService implements IUploadTitleImageService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;
  public async execute(data: IUploadTitleImageDTO) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("CNPJ", data.CNPJ);
    formData.append("atemID", data.atemID);
    formData.append("year", data.year);
    formData.append("invoiceID", data.invoiceID);
    if (data.comments) {
      formData.append("comments", data.comments);
    }

    const imageUploaded = await this.httpInstance.post<
      IUploadTitleImageDataDTO
    >("/titles", formData);

    return imageUploaded;
  }
}
