import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetTokenToKibanaChartsDTO } from "../dtos/IGetTokenToKibanaChartsDTO";
import { IGetTokenToKibanaChartsService } from "../models/IGetTokenToKibanaChartsService";

@injectable()
export class GetTokenToKibanaChartsService
  implements IGetTokenToKibanaChartsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<IGetTokenToKibanaChartsDTO> {
    return this.httpInstance.get<IGetTokenToKibanaChartsDTO>(
      "/authorization/kibana"
    );
  }

  // /rest/authorization/kibana
}
