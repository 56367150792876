import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { Price } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IGetPriceProductDTO } from "../dtos/IGetPriceProductDTO";
import { IGetPriceProductsService } from "../models/IGetPriceProductsService";

@injectable()
export class GetPriceProductsService implements IGetPriceProductsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IGetPriceProductDTO): Promise<Price> {
    const price = await this.httpInstance.get<Price>("/prices", {
      params: {
        customerID: data.customerID,
        productID: data.productID,
        filialID: data.filialID,
        payCond: data.deadlinePayment,
        transportationZone: data.transportationZone,
      },
    });
    return price;
  }
}
