import Layout from "@components/Layout";
import Title from "@components/Title";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IChangePasswordService } from "@modules/user/models/IChangePasswordService";
import AppError from "@utils/AppError";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as Yup from "yup";

const ChangePasswordPage: React.FC = () => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Layout>
      <Title title="Mudar senha" />
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            password: Yup.string().required("Password is required"),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("password")],
              "As senhas não conferem"
            ),
          })}
          onSubmit={async (values, props) => {
            try {
              setLoading(true);
              const changePasswordService = iocContext.serviceContainer.get<
                IChangePasswordService
              >(Types.Users.IChangePasswordService);

              const { message } = await changePasswordService.execute(
                values.password
              );
              enqueueSnackbar(message, { variant: "success" });
              props.resetForm();
            } catch (error) {
              if (error instanceof AppError) {
                return enqueueSnackbar(error.message, {
                  variant: error.variant,
                });
              }
              enqueueSnackbar("Ocorreu um erro ao alterar senha", {
                variant: "error",
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label="Nova senha"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword((oldValue) => !oldValue)
                              }
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        props.touched.password && props.errors.password
                      }
                      error={props.touched.password && !!props.errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="confirmPassword"
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      label="Confirme a senha"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword((oldValue) => !oldValue)
                              }
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        props.touched.confirmPassword &&
                        props.errors.confirmPassword
                      }
                      error={
                        props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                    />
                  </Grid>
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      startIcon={
                        loading && (
                          <CircularProgress
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            color="secondary"
                          />
                        )
                      }
                    >
                      {loading ? "Enviando..." : "Enviar"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Layout>
  );
};

export { ChangePasswordPage };
