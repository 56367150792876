import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import {
  AbstractCustomerDataResponse,
  AggregateQuoteSaleResponse,
  AssessorVolumeByDayResponse,
  AssessorVolumeByMonthResponse,
  AssessorVolumeResponse,
  AvgRateSalesResponse,
  ChartFilters,
  CustomerStatusVolumeResponse,
  IGetChartDataService,
  MarginLitterResponse,
  ParticipacaoFilialResponse,
  ProductGroupResponse,
  ProductStatusVolumeResponse,
  ProdutoVolumeResponse,
  SalesProjectionResponse,
  SegmentoVolumeResponse,
  StatusVolumeResponse,
  TargetSalesPerformanceResponse,
  TargetSalesResponse,
  TypeFreightVolumeResponse,
  UfVolumeResponse,
} from "../models/IGetChartDataService";

function sumProperties(arr: AggregateQuoteSaleResponse[], center: string) {
  let filteredArray = arr.filter(
    (sub: { centerID: string }) => sub.centerID === center
  );
  let result = filteredArray.reduce(
    (acc, current) => {
      acc.totalSale += current.day.sale;
      acc.totalBalance += current.day.balance;
      acc.totalBilled += current.day.billed;
      acc.totalQuote += current.day.quote;
      acc.totalToProfit += current.day.toProfit;
      acc.totalAccomplishedAccumulated += current.month.accomplishedAccumulated;
      acc.totalAccomplishedPercentage += current.month.accomplishedPercentage;
      acc.totalAccumulatedGoal += current.month.accumulatedGoal;
      acc.totalDifferenceGoalAccomplishedAccumulated +=
        current.month.differenceGoalAccomplishedAccumulated;
      acc.totalForecast += current.month.forecast;
      return acc;
    },
    {
      centerID: center,
      productGroup: "TOTAL",
      totalSale: 0,
      totalBalance: 0,
      totalBilled: 0,
      totalQuote: 0,
      totalToProfit: 0,
      totalAccomplishedAccumulated: 0,
      totalAccomplishedPercentage: 0,
      totalAccumulatedGoal: 0,
      totalDifferenceGoalAccomplishedAccumulated: 0,
      totalForecast: 0,
    }
  );
  return result;
}

@injectable()
export class GetChartDataService implements IGetChartDataService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  private mountParams(data: ChartFilters) {
    let filters = "";
    const timeFrom = data.from + "T04:00:00.000Z";
    const timeTo = data.to + "T03:59:59.999Z";

    if (data.ufs.length) {
      filters += `UF=${data.ufs.map((uf) => `'${uf.id}'`)}&`;
    }
    if (data.products.length) {
      filters += `groupProduct=${data.products.map(
        (product) => `'${product.id}'`
      )}&`;
    }
    if (data.assessors.length) {
      filters += `advisorID=${data.assessors.map(
        (advisor) => `'${advisor.bpID}'`
      )}&`;
    }
    if (data.segments.length) {
      filters += `segmentID=${data.segments.map(
        (segment) => `'${segment.id}'`
      )}&`;
    }
    if (data.centers.length) {
      filters += `filialID=${data.centers.map((filial) => `'${filial.id}'`)}&`;
    }
    if (data.cnpj.length) {
      filters += `customerID=${data.cnpj.map(
        (cnpj) => `'${cnpj.customerID}'`
      )}&`;
    }
    if (data.managers.length) {
      filters += `managerID=${data.managers.map(
        (manager) => `'${manager.id}'`
      )}&`;
    }

    return { timeFrom, timeTo, filters };
  }

  getAssessorVolume(data: ChartFilters): Promise<AssessorVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/advisor?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getAssessorVolumeByDay(
    data: ChartFilters
  ): Promise<AssessorVolumeByDayResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/advisor?groupPeriodBy=day&from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getParticipacaoProdutoVolume(
    data: ChartFilters
  ): Promise<ProdutoVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/product?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getParticipacaoUfVolume(data: ChartFilters): Promise<UfVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/uf?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getParticipacaoFilial(
    data: ChartFilters
  ): Promise<ParticipacaoFilialResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/center?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getSegmentoVolume(data: ChartFilters): Promise<SegmentoVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/segment?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getStatusVolume(data: ChartFilters): Promise<StatusVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/status?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getCustomerStatusVolume(
    data: ChartFilters
  ): Promise<CustomerStatusVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/customer_status?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getProductStatusVolume(
    data: ChartFilters
  ): Promise<ProductStatusVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/status_groupProduct?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getTypeFreightVolume(data: ChartFilters): Promise<TypeFreightVolumeResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/type_freight?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  abstractCustomerData(
    data: ChartFilters
  ): Promise<AbstractCustomerDataResponse[]> {
    const { filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/abstract_customer?groupPeriodBy=day&from='${data.from}'&to='${data.to}'&${filters}`
    );
  }

  getAvgRateSales(data: ChartFilters): Promise<AvgRateSalesResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/avg_rate_sales?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getSalesProjection(data: ChartFilters): Promise<SalesProjectionResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/estimate_rate_sales?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getMarginLitter(data: ChartFilters): Promise<MarginLitterResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/total_margin_litter?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getProductGroup(data: ChartFilters): Promise<ProductGroupResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/groupProduct?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getTargetSales(data: ChartFilters): Promise<TargetSalesResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/target_sales?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getTargetSalesPerformance(
    data: ChartFilters
  ): Promise<TargetSalesPerformanceResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/target_sales_performance?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  getAssessorVolumeByMonth(
    data: ChartFilters
  ): Promise<AssessorVolumeByMonthResponse> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/dashboard/data/advisor?groupPeriodBy=month&from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getAggregateQuoteSale(
    centerIDFilter: string
  ): Promise<AggregateQuoteSaleResponse[]> {
    const response = await this.httpInstance.get("/aggregate/quote-sale");
    const total = sumProperties(response, centerIDFilter);
    return [...response, total];
  }
}
