import React from "react";
import { makeStyles, createStyles, Typography, Link } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { WhatsappIcon } from "../../components/Icons";

const useStyles = makeStyles(() =>
  createStyles({
    gridContacts: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: "1.6rem",
    },
    icon: {
      fontSize: "1.6rem",
    },
    centerIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleContactsText: {
      fontWeight: "bold",
    },
  })
);

interface Props {
  phone: string;
  email: string;
  whatsapp: {
    link: string;
    phone: string;
  };
  qrcode: string;
}

const CallCenterData: React.FC<Props> = ({
  email,
  phone,
  qrcode,
  whatsapp,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.gridContacts}>
      <div className={classes.centerIcon}>
        <PhoneIcon className={classes.icon} />
      </div>
      <div>
        <Typography className={classes.titleContactsText}>Telefone:</Typography>
      </div>
      <div></div>
      <div>
        <Typography>{phone}</Typography>
      </div>

      <div className={classes.centerIcon}>
        <EmailIcon className={classes.icon} />
      </div>
      <div>
        <Typography className={classes.titleContactsText}>Email:</Typography>
      </div>
      <div></div>
      <div>
        <Typography>{email}</Typography>
      </div>

      <div className={classes.centerIcon}>
        <WhatsappIcon className={classes.icon} />
      </div>
      <div>
        <Typography className={classes.titleContactsText}>Whatsapp:</Typography>
      </div>
      <div></div>
      <div>
        <Link href={whatsapp.link} target="blank">
          <Typography>{whatsapp.link}</Typography>
        </Link>
        <Typography>{whatsapp.phone}</Typography>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div>
        <Typography component="p" variant="caption">
          ou abra sua câmera aqui:
        </Typography>
        <img
          style={{ width: "25rem", height: "25rem" }}
          src={qrcode}
          alt="qr code whatsapp"
        />
      </div>
    </div>
  );
};

export default CallCenterData;
