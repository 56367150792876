import {
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridSlotComponentProps,
} from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { documentMask, formatCurrency } from "@utils/index";
import { utcToZonedTime } from "date-fns-tz";
import formatTZ from "date-fns-tz/format";
import React, { useEffect } from "react";
import {
  IInvoicesSelecteToDownload,
  useFormQueryInvoices,
} from "./FormQueryInvoicesContext";

const utcZone = "Europe/Lisbon";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginTop: "3rem",
      height: 600,
      [theme.breakpoints.up("sm")]: { height: "100%" },
    },
  })
);

const CustomToolbar: React.FC = (props) => {
  const { state } = useGridSlotComponentProps();
  const formQueryInvoicesContext = useFormQueryInvoices();

  useEffect(() => {
    formQueryInvoicesContext.setInvoicesSelectedToDownload(
      state.selection.reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {} as IInvoicesSelecteToDownload)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selection]);

  return (
    <GridToolbarContainer>
      <Grid container justify="center">
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarDensitySelector />
        </Grid>

        <Grid item xs={12} md={3} lg={2} container justify="center">
          <Collapse in={Boolean(Object.keys(state.selection).length)}>
            <Button
              onClick={() =>
                formQueryInvoicesContext.setOpenModalDownload(true)
              }
              startIcon={<GetAppIcon />}
              color="primary"
              size={
                state.density.value === "compact"
                  ? "small"
                  : state.density.value === "comfortable"
                  ? "large"
                  : "medium"
              }
            >
              Baixar notas fiscais
            </Button>
          </Collapse>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

const TableInvoices: React.FC = () => {
  const classes = useStyles();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Núm. da nota",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "orderID",
      headerName: "Pedido",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "emissionDate",
      headerName: "Emissão",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "value",
      headerName: "Valor",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "number",
      valueFormatter: (props) => formatCurrency(props.value as number),
    },
    {
      field: "CNPJ",
      headerName: "CNPJ",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
      valueFormatter: (props) => documentMask(props.value as string),
    },
    {
      field: "companyName",
      headerName: "Razão Social",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 350 : undefined,
    },
  ];

  return (
    <div className={classes.table}>
      <DataGrid
        checkboxSelection
        rows={formQueryInvoicesContext.dataQuery}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
      />
    </div>
  );
};

export default TableInvoices;
