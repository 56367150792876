import {
  Badge,
  Box,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import React from "react";

const useStyles = makeStyles(({ ...theme }) =>
  createStyles({
    buttonsToolbar: {
      background: "#fff",
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& button:not(:first-child)": { marginLeft: "1rem" },
      },
      boxShadow: "0px 4px 4px rgba(176, 193, 225, 0.1)",
    },
    item: {
      color: "#D91F05",
      fontWeight: 600,
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      marginTop: "3.2rem",
      marginBottom: "2.6rem",
      marginRight: theme.spacing(3),
    },
  })
);
interface HeaderProps {
  userName: string;
}
const NewHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsToolbar}>
      <Box style={{ color: "#D91F05", marginRight: 40 }}>
        <Badge variant="dot" color="error">
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </Box>

      <Typography variant="h4" className={classes.item}>
        {props.userName.toLocaleUpperCase()}
      </Typography>
    </div>
  );
};

export default NewHeader;
