import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ISendEmailToNotVerifiedEmailUsers } from "../models/ISendEmailToNotVerifiedEmailUsers";

@injectable()
export class SendEmailToNotVerifiedEmailUsers
  implements ISendEmailToNotVerifiedEmailUsers {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<{ message: string }> {
    const response = await this.httpInstance.post<{ message: string }>(
      "/users/send-email-to-inactive-users",
      {}
    );
    return response;
  }
}
