import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import { useUserState } from "@context/UserContext";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isBefore, isSameDay } from "date-fns";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useFormQueryInvoices } from "./FormQueryInvoicesContext";
import { IQuery } from "./interface";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: { fontSize: "1.6rem", fontWeight: "bold" },
    itemSelectSubTitle: { fontSize: "1.2rem" },
    iconNotDisabled: { opacity: "0.6" },
    chip: { margin: "1rem" },
    matchText: { color: theme.palette.primary.main },
    dialogPaper: { [theme.breakpoints.up("md")]: { minWidth: "70vw" } },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IQuery>();
  const formQueryInvoicesContext = useFormQueryInvoices();
  const userState = useUserState();

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);

  useEffect(() => {
    // caso o assessor mude, então limpa-se o campo de CNPJs selecionados
    setFieldValue("CNPJ", []);
  }, [setFieldValue, userState.state.bpSelected]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={6}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple
          onChange={(value) => setFieldValue("CNPJ", value)}
          value={values.CNPJ}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          allSelected={values.CNPJ.length === userState.state.listCNPJ.length}
          getOptionKey={(value) => value.customerID}
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
          errorField={Boolean(errors.CNPJ)}
          messageErrorField={errors.CNPJ as string}
          fieldTouched={Boolean(touched.CNPJ)}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          format="dd/MM/yyyy"
          label="Início"
          value={values.startDate}
          onChange={(date) => {
            setFieldTouched("startDate", true);
            setFieldValue("startDate", date);
          }}
          error={!!errors.startDate && !!touched.startDate}
          helperText={!!touched.startDate && errors.startDate}
          onBlur={() => setFieldTouched("startDate", true)}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={values.endDate}
          onChange={(date) => {
            setFieldTouched("endDate", true);
            setFieldValue("endDate", date);
          }}
          disabled={!values.startDate}
          shouldDisableDate={(day) => {
            if (day && values.startDate) {
              if (isSameDay(day, values.startDate)) {
                return false;
              }
              return isBefore(day, values.startDate);
            }
            return false;
          }}
          error={!!errors.endDate && !!touched.endDate}
          helperText={!!touched.endDate && errors.endDate}
          onBlur={() => setFieldTouched("endDate", true)}
        />
      </Grid>
      <Grid item container justify="center" xs={12}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          startIcon={
            formQueryInvoicesContext.loadingQuery && (
              <CircularProgress
                color="secondary"
                style={{ height: 20, width: 20 }}
              />
            )
          }
        >
          Consultar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
