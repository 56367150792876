import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useFormQueryTitle } from "./formQueryTitleContext";

const useStyles = makeStyles(() =>
  createStyles({
    imgFluid: {
      width: "100%",
      height: "auto",
      verticalAlign: "middle",
    },
  })
);

const ModalImage: React.FC = () => {
  const classes = useStyles();

  const formQueryTitleContext = useFormQueryTitle();
  const { image, setOpenModalImage, openModalImage } = formQueryTitleContext;

  return (
    <Dialog open={openModalImage} onClose={() => setOpenModalImage(false)}>
      <DialogContent>
        <img
          className={classes.imgFluid}
          src={image.link}
          alt="imagem comprovante"
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalImage;
