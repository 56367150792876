import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IGetUserAccessesDTO } from "@modules/admin/dtos/IGetUserAccessesDTO";
import { IGetUserAccessesService } from "@modules/admin/models/IGetUserAccessesService";
import { downloadFile } from "@utils/index";
import AppError from "@utils/AppError";
import { startOfDay, endOfDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useState, useCallback, useEffect } from "react";

export interface UserAccessesContextProps {
  rowsUserAccesses: IGetUserAccessesDTO;
  loadingTable: boolean;
  loadingExcel: boolean;
  pagination: Pagination;
  errorLoadingTable: boolean;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  fetchUserAccesses: () => Promise<void>;
  downloadUserAccesses: () => Promise<void>;
}

export const UserAccessesContext = React.createContext(
  {} as UserAccessesContextProps
);

interface Pagination {
  page: number;
  limit: number;
  from: Date;
  to: Date;
}

export const UserAccessesProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const getUserAccessesService = iocContext.serviceContainer.get<
    IGetUserAccessesService
  >(Types.Admin.IGetUserAccessesService);

  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    page: 1,
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });

  const [rowsUserAccesses, setRowsUserAccesses] = useState<IGetUserAccessesDTO>(
    { content: [], total: 0, page: 0 }
  );

  const [loadingTable, setLoadingTable] = useState(true);
  const [errorLoadingTable, setErrorLoadingTable] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const fetchUserAccesses = useCallback(async () => {
    try {
      setLoadingTable(true);
      const resp = await getUserAccessesService.getAccesses({
        begin: pagination.from,
        end: pagination.to,
        limit: pagination.limit,
        page: pagination.page,
      });
      setRowsUserAccesses(resp);
      setErrorLoadingTable(false);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, { variant: error.variant });
      } else {
        enqueueSnackbar("Erro ao baixar dados de custos", { variant: "error" });
      }
      setErrorLoadingTable(true);
    } finally {
      setLoadingTable(false);
    }
  }, [
    enqueueSnackbar,
    getUserAccessesService,
    pagination.from,
    pagination.limit,
    pagination.page,
    pagination.to,
  ]);

  const downloadUserAccesses = useCallback(async () => {
    try {
      setLoadingExcel(true);
      const [buffer, filename] = await getUserAccessesService.downloadAccesses({
        begin: pagination.from,
        end: pagination.to,
      });

      downloadFile(buffer, filename);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, { variant: error.variant });
      } else {
        enqueueSnackbar("Erro ao baixar dados de acessos", {
          variant: "error",
        });
      }
    } finally {
      setLoadingExcel(false);
    }
  }, [enqueueSnackbar, getUserAccessesService, pagination.from, pagination.to]);

  useEffect(() => {
    fetchUserAccesses();
  }, [fetchUserAccesses]);

  return (
    <UserAccessesContext.Provider
      value={{
        downloadUserAccesses,
        fetchUserAccesses,
        loadingExcel,
        rowsUserAccesses,
        loadingTable,
        pagination,
        errorLoadingTable,
        setPagination,
      }}
    >
      {children}
    </UserAccessesContext.Provider>
  );
};

export const useUserAccessesContext = () => {
  const context = React.useContext(UserAccessesContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useUserAccessesContext não pode ser utilizado fora de um HistoryCostsProvider"
    );
  }
  return context;
};
