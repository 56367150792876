import { makeStyles } from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";

type PositionType = "top" | "right" | "bottom" | "left" | undefined;

const useStyles = makeStyles({
  legendText: {
    color: "#B0C1E1",
    fontSize: "12px",
    fontFamily: "Open Sans, Arial",
    fontWeight: 600,
  },
  legendColor: {
    border: "1px solid #FF0000",
  },
  tooTipCard: {
    padding: 6,
    color: "#000",
    display: "flex",
    flexDirection: "column",
    "& span:last-child": {
      fontWeight: 600,
    },
  },
});

const backgroundColors = [
  "#C5E1F7",
  "#FFD8D2",
  "#C0F3FE",
  "#FAFFBC",
  "#F0B4D7",
  "#B9E0E0",
  "#FFF2D1",
  "#AC7DCD",
  "#C2FFE7",
  "#FCCCA8",
];

const borderColors = [
  "#1A74CD",
  "#D91F05",
  "#30CAE9",
  "#C4D21E",
  "#9C1C67",
  "#0D9A9A",
  "#FDC742",
  "#692A96",
  "#0BB873",
  "#FB7918",
];

interface PieChartCustom {
  labels: string[];
  series: number[];
  labelFomatter?: (value: number) => void;
  isLoading?: boolean;
}

type IHorizontalAlign = "left" | "center" | "right";

const PieChartCustom: React.FC<PieChartCustom> = (props: PieChartCustom) => {
  const classes = useStyles();

  const options = {
    labels: props.labels,
    legend: {
      position: "bottom" as PositionType,
      formatter: (value: string) => {
        return `<span class=${classes.legendText}>${value}</span>`;
      },
      horizontalAlign: "left" as IHorizontalAlign,
      markers: {
        width: 16,
        height: 16,
        strokeWidth: 0,
        strokeColor: "#30CAE9",
        radius: 2,
        offsetY: 3.5,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 8,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Open Sans, Arial, sans-serif",
        fontWeight: "bold",
        colors: borderColors,
      },
      dropShadow: {
        enabled: false,
      },
      formatter: function (value: number) {
        return `${value.toFixed(0)}%`;
      },
    },
    colors: backgroundColors,
    fill: {
      colors: backgroundColors,
    },
    stroke: {
      colors: borderColors,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
    tooltip: {
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, w }: any) {
        const value = props.labelFomatter
          ? props.labelFomatter(series[seriesIndex])
          : series[seriesIndex];

        const percentage = (w.globals.seriesPercent[
          seriesIndex
        ][0] as number).toFixed(1);

        return (
          `<div class=${classes.tooTipCard} style='background: ${w.globals.colors[seriesIndex]}'>` +
          "<span>" +
          w.globals.labels[seriesIndex] +
          ": " +
          value +
          "</span>" +
          "<span>" +
          percentage +
          "%</span>" +
          "</div>"
        );
      },
      marker: {
        show: true,
      },
      items: {
        display: "flex",
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: props.isLoading ? 0.5 : 1,
      }}
    >
      <Chart
        type="donut"
        options={options}
        series={props.series}
        width="95%"
        height={"93%"}
      />
    </div>
  );
};

export default PieChartCustom;
