import baseRetiradaImg from "@assets/base-de-retirada.png";
import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  Button,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import RoomIcon from "@material-ui/icons/Room";
import TodayIcon from "@material-ui/icons/Today";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IGetFilterOptionsService } from "@modules/dashboard/models/IGetFilterOptionsService";
import { IGetWalletAssessorService } from "@modules/user/models/IGetWalletAssessorService";
import clsx from "clsx";
import { addHours, endOfDay, format, isValid } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHomeDashboard } from "./HomeDashboardContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      dialogPaper: { [theme.breakpoints.up("md")]: { minWidth: "70vw" } },
      iconNotDisabled: { opacity: "0.6" },
    })
);

const DialogFilters: React.FC = () => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const {
    openFilters,
    setOpenFilters,
    filters,
    setFilters,
    dateCharts,
    setDateCharts,
    setLoadingAll,
  } = useHomeDashboard();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const getFiltersService = iocContext.serviceContainer.get<
    IGetFilterOptionsService
  >(Types.Dashboard.IGetFilterOptionsService);
  const getWalletAssessorService = iocContext.serviceContainer.get<
    IGetWalletAssessorService
  >(Types.Users.IGetWalletAssessorService);

  const closeDialog = () => setOpenFilters(false);

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);
  const [openDialogProduct, setOpenDialogProduct] = useState(false);
  const [openDialogCenter, setOpenDialogCenter] = useState(false);
  const [openDialogSegment, setOpenDialogSegment] = useState(false);
  const [openDialogUF, setOpenDialogUF] = useState(false);
  const [openDialogAssessors, setOpenDialogAssessors] = useState(false);
  const [openDialogManagers, setOpenDialogManagers] = useState(false);

  const fetchProducts = useFetchData(() =>
    getFiltersService.getGroupProducts()
  );
  const fetchCustomers = useFetchData(() =>
    getWalletAssessorService.execute("0000000000")
  );
  const fetchCenters = useFetchData(() => getFiltersService.getCenters());
  const fetchSegments = useFetchData(() => getFiltersService.getSegments());
  const fetchUfs = useFetchData(() => getFiltersService.getUFs());
  const fetchManagers = useFetchData(() => getFiltersService.getManagers());

  useEffect(() => {
    setLoadingAll(
      fetchCustomers.loading ||
        fetchProducts.loading ||
        fetchSegments.loading ||
        fetchUfs.loading ||
        fetchCenters.loading ||
        userState.state.loadingAssessorList
    );
  }, [
    fetchCenters.loading,
    fetchCustomers.loading,
    fetchProducts.loading,
    fetchSegments.loading,
    fetchUfs.loading,
    setLoadingAll,
    userState.state.loadingAssessorList,
  ]);

  return (
    <Dialog
      open={openFilters}
      onClose={closeDialog}
      fullScreen={isMobile}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Filtros</DialogTitle>
      <DialogContent>
        <Grid item container spacing={2} alignItems="center" xs={12}>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogCNPJ}
              setOpenDialog={setOpenDialogCNPJ}
              multiple
              value={filters.cnpj}
              loading={fetchCustomers.loading}
              options={fetchCustomers.value!}
              title="Clientes"
              allSelected={filters.cnpj.length === 0}
              startIcon={
                <img
                  width="24"
                  src={razaoSocialImg}
                  alt="Cliente ícone"
                  className={classes.iconNotDisabled}
                />
              }
              onChange={(value) =>
                setFilters((old) => ({ ...old, cnpj: value.slice(0, 30) }))
              }
              getOptionKey={(value) => value.customerID}
              getOptionLabel={(value) =>
                `${value.customerID} - ${value.companyName}`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogProduct}
              setOpenDialog={setOpenDialogProduct}
              multiple
              value={filters.products}
              loading={fetchProducts.loading}
              options={fetchProducts.value!}
              title="Grupo de Produtos"
              startIcon={<LocalGasStationIcon />}
              allSelected={
                filters.products.length === fetchProducts.value?.length ||
                filters.products.length === 0
              }
              onChange={(value) =>
                setFilters((old) => ({ ...old, products: value }))
              }
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => value.desc}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogCenter}
              setOpenDialog={setOpenDialogCenter}
              multiple
              value={filters.centers}
              loading={fetchCenters.loading}
              options={fetchCenters.value!}
              title="Filial"
              allSelected={
                filters.centers.length === fetchCenters.value?.length ||
                filters.centers.length === 0
              }
              startIcon={
                <img
                  width="24"
                  src={baseRetiradaImg}
                  alt="base de retirada ícone"
                  className={clsx({
                    [classes.iconNotDisabled]: !fetchCenters.loading,
                  })}
                />
              }
              onChange={(value) =>
                setFilters((old) => ({ ...old, centers: value }))
              }
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => `${value.id} - ${value.desc}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogSegment}
              setOpenDialog={setOpenDialogSegment}
              multiple
              value={filters.segments}
              loading={fetchSegments.loading}
              options={fetchSegments.value!}
              title="Segmentos"
              startIcon={<CallSplitIcon />}
              allSelected={
                filters.segments.length === fetchSegments.value?.length ||
                filters.segments.length === 0
              }
              onChange={(value) =>
                setFilters((old) => ({ ...old, segments: value }))
              }
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => `${value.id} - ${value.desc}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogUF}
              setOpenDialog={setOpenDialogUF}
              multiple
              value={filters.ufs}
              loading={fetchUfs.loading}
              options={fetchUfs.value!}
              title="UF Destino"
              allSelected={
                filters.ufs.length === fetchUfs.value?.length ||
                filters.ufs.length === 0
              }
              startIcon={<RoomIcon />}
              onChange={(value) =>
                setFilters((old) => ({ ...old, ufs: value }))
              }
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => `${value.id}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogAssessors}
              setOpenDialog={setOpenDialogAssessors}
              multiple
              value={filters.assessors}
              loading={userState.state.loadingAssessorList}
              options={userState.state.assessorList}
              title="Assessores"
              allSelected={
                filters.assessors.length ===
                  userState.state.assessorList.length ||
                filters.assessors.length === 0
              }
              startIcon={<RoomIcon />}
              onChange={(value) => {
                // verifica se o assessor admin (0000) está presente na lista
                if (
                  !Boolean(
                    userState.state.assessorList.find(
                      (value) => value.bpID === appConfig.bpAdminID
                    )
                  )
                ) {
                  if (value.length > 0) {
                    // deve haver pelo menos um assessor caso o usuário admin não esteja presente na lista
                    return setFilters((state) => ({
                      ...state,
                      assessors: value,
                    }));
                  } else {
                    return enqueueSnackbar(
                      "Deve haver pelo menos 1 assessor selecionado",
                      { variant: "warning" }
                    );
                  }
                } else {
                  // se o assessor admin estiver presente, pode-se apagar todos os assessores da lista
                  return setFilters((state) => ({
                    ...state,
                    assessors: value,
                  }));
                }
              }}
              getOptionKey={(value) => value.bpID}
              getOptionLabel={(value) => `${value.bpID} - ${value.bpName}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <SelectAutocomplete
              openDialog={openDialogManagers}
              setOpenDialog={setOpenDialogManagers}
              multiple
              value={filters.managers}
              loading={fetchManagers.loading}
              options={fetchManagers.value ? fetchManagers.value : []}
              title="Gerentes"
              allSelected={Boolean(
                fetchManagers.value &&
                  (filters.managers.length === fetchManagers.value.length ||
                    filters.managers.length === 0)
              )}
              startIcon={<AssignmentIndIcon />}
              onChange={(value) =>
                setFilters((old) => ({ ...old, managers: value }))
              }
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => value.name}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} container direction="column">
            <TextField
              select
              label="Período"
              value={dateCharts.selected}
              onChange={(e) =>
                setDateCharts((old) => ({
                  ...old,
                  selected: (e.target.value as unknown) as number,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TodayIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={0}>Hoje</MenuItem>
              <MenuItem value={1}>Este mês</MenuItem>
              <MenuItem value={2}>Personalizado</MenuItem>
            </TextField>
          </Grid>
          <Grid item container xs={12} lg={4}>
            <Collapse in={dateCharts.selected === 2} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    format="dd/MM/yyyy"
                    label="Início"
                    value={dateCharts[2].rawFrom}
                    clearable={false}
                    onChange={(date: any) => {
                      if (isValid(date)) {
                        const fromDate = format(date, "yyyy-MM-dd");

                        // @ts-ignore
                        setDateCharts((oldState) => ({
                          ...oldState,
                          2: { ...oldState[2], from: fromDate, rawFrom: date },
                        }));
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    format="dd/MM/yyyy"
                    label="Fim"
                    clearable={false}
                    value={dateCharts[2].rawEnd}
                    onChange={(date: any) => {
                      if (isValid(date)) {
                        const newDate = format(
                          addHours(endOfDay(date), 4),
                          "yyyy-MM-dd"
                        );
                        console.log(newDate);
                        // @ts-ignore
                        setDateCharts((oldState) => ({
                          ...oldState,
                          2: { ...oldState[2], end: newDate, rawEnd: date },
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="contained" color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogFilters };
