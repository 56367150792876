import { ButtonReload } from "@components/ButtonReload";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  Fade,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IGetActiveCostsDTO } from "@modules/costs/dtos/IGetActiveCostsDTO";
import { ISearchCostService } from "@modules/costs/models/ISearchCostService";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import { checkCityZF, formatCurrency, getOperacionalCost } from "@utils/index";
import { Price } from "@utils/interfaces";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import {
  IProductPriceMargin,
  useSimulationsCostsContext,
} from "../SimulationCostsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      itemSelect: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      loading: {
        width: "3rem !important",
        height: "3rem !important",
      },
      negative: {
        color: theme.palette.primary.main,
        fontWeight: typography.fontWeightBold,
      },
      positive: {
        color: theme.palette.success.main,
        fontWeight: typography.fontWeightBold,
      },
    })
);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (value: number | string | undefined) => void;
  name: string;
}
/* 
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => onChange(values.value)}
      decimalSeparator=","
      allowedDecimalSeparators={[",", "."]}
      prefix="R$ "
    />
  );
} */

const RowPrice: React.FC<{ product: IProductPriceMargin }> = ({ product }) => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const simulationCostsContext = useSimulationsCostsContext();

  const getPriceProductService = iocContext.serviceContainer.get<
    IGetPriceProductsService
  >(Types.Orders.IGetPriceProductsService);
  const searchCostProductService = iocContext.serviceContainer.get<
    ISearchCostService
  >(Types.Costs.ISearchCostService);

  const [price, setPrice] = useState<Price>();
  const [cost, setCost] = useState<IGetActiveCostsDTO[]>([]);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [errorLoadingPrice, setErrorLoadingPrice] = useState(false);
  const [loadingCost, setLoadingCost] = useState(false);
  const [errorLoadingCost, setErrorLoadingCost] = useState(false);
  const [suggestionPriceFormatted, setSuggestionPriceFormatted] = useState<
    string | null
  >(null);
  const [deadlinePayment, setDeadlinePayment] = useState<number | null>(null);

  const suggestionPrice = useMemo(() => {
    if (!suggestionPriceFormatted) return null;
    return parseFloat(suggestionPriceFormatted.replace(/\D+/g, "")) / 10000;
  }, [suggestionPriceFormatted]);

  const priceFormatted = useMemo(() => {
    if (!price || errorLoadingPrice) return null;
    if (!simulationCostsContext.branchName) return null;
    if (!simulationCostsContext.filialId) return null;

    return (
      price.price +
      getOperacionalCost({
        branchName: simulationCostsContext.branchName,
        filialID: simulationCostsContext.filialId,
      })
    );
  }, [
    errorLoadingPrice,
    price,
    simulationCostsContext.branchName,
    simulationCostsContext.filialId,
    //simulationCostsContext.freightType,
  ]);
  const diffFormatted = useMemo(() => {
    if (!priceFormatted) return null;
    if (!suggestionPrice) return null;
    return suggestionPrice - priceFormatted;
  }, [priceFormatted, suggestionPrice]);
  const outputPrice = useMemo(() => {
    if (!suggestionPrice) return null;
    if (deadlinePayment === null) return null;
    if (!simulationCostsContext.dataCustomer) return null;
    if (!price || errorLoadingPrice) return null;

    const encargo = 0.0012;

    // transforma o preco em a vista
    const days = deadlinePayment - 0;

    const output = suggestionPrice / (1 + encargo * days);
    if (simulationCostsContext.freightType === "CIF") {
      return output - price.freight;
    } else {
      return output;
    }
  }, [
    deadlinePayment,
    errorLoadingPrice,
    price,
    simulationCostsContext.dataCustomer,
    simulationCostsContext.freightType,
    suggestionPrice,
  ]);
  const freightPrice = useMemo(() => {
    if (!price || errorLoadingPrice) return null;
    return simulationCostsContext.freightType === "CIF" ? price.freight : 0;
  }, [errorLoadingPrice, price, simulationCostsContext.freightType]);

  const marginPrice = useMemo(() => {
    if (!outputPrice) return null;
    if (cost.length === 0) return null;

    return outputPrice - cost[0].value;
  }, [cost, outputPrice]);

  const renderDeadlinePayment = (deadline: number) => {
    if (deadline === 0) {
      return (
        <MenuItem value={0} className={classes.itemSelect}>
          à vista
        </MenuItem>
      );
    }
    const values = Array.from(Array(deadline + 1).keys());

    return values.map((ele) => {
      return ele !== 0 ? (
        <MenuItem
          key={product.key}
          value={ele}
          className={classes.itemSelect}
        >{`${ele} ${ele !== 1 ? "dias" : "dia"}`}</MenuItem>
      ) : (
        <MenuItem key={product.key} value={ele} className={classes.itemSelect}>
          à vista
        </MenuItem>
      );
    });
  };

  const fetchPriceProduct = useCallback(async () => {
    if (deadlinePayment === null) return;
    if (!simulationCostsContext.addressSelected) return;
    if (!simulationCostsContext.filialId) return;
    if (!simulationCostsContext.dataCustomer) return;
    try {
      setLoadingPrice(true);
      const price = await getPriceProductService.execute({
        deadlinePayment: deadlinePayment,
        addressID: simulationCostsContext.addressSelected.id,
        customerID: simulationCostsContext.dataCustomer.id,
        filialID: simulationCostsContext.filialId.split("-")[0],
        productID: product.id,
        transportationZone:
          simulationCostsContext.addressSelected.transportationZone,
      });
      setPrice(price);
      setErrorLoadingPrice(false);
    } catch (error) {
      setErrorLoadingPrice(true);
    } finally {
      setLoadingPrice(false);
    }
  }, [
    deadlinePayment,
    getPriceProductService,
    product.id,
    simulationCostsContext.addressSelected,
    simulationCostsContext.dataCustomer,
    simulationCostsContext.filialId,
  ]);

  const fetchCostProduct = useCallback(async () => {
    if (!simulationCostsContext.filialId) return;
    if (!simulationCostsContext.addressSelected) return;
    try {
      setLoadingCost(true);
      const cost = await searchCostProductService.execute({
        center: simulationCostsContext.filialId,
        state: checkCityZF(
          simulationCostsContext.addressSelected.city,
          simulationCostsContext.filialId,
          simulationCostsContext.addressSelected.UF
        )
          ? "ZF"
          : simulationCostsContext.addressSelected.UF,
        material: [product.id],
        ...(simulationCostsContext.storeID !== "null" &&
        simulationCostsContext.storeID
          ? { store: simulationCostsContext.storeID }
          : {}),
      });
      setCost(cost);
      setErrorLoadingCost(false);
    } catch (error) {
      setErrorLoadingCost(true);
    } finally {
      setLoadingCost(false);
    }
  }, [
    product.id,
    searchCostProductService,
    simulationCostsContext.addressSelected,
    simulationCostsContext.filialId,
    simulationCostsContext.storeID,
  ]);

  useEffect(() => {
    fetchPriceProduct();
    fetchCostProduct();
  }, [fetchCostProduct, fetchPriceProduct]);

  return (
    <Fade
      in={Boolean(simulationCostsContext.productsSimulation.length)}
      timeout={1000}
    >
      <TableRow>
        <TableCell>
          <IconButton
            color="primary"
            onClick={() => {
              simulationCostsContext.setProductsSimulation((oldState) => {
                return oldState.filter(
                  (productFilter) => productFilter.key !== product.key
                );
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell>{`${product.id} - ${product.description}`}</TableCell>
        <TableCell>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              onChange={({ target }) =>
                setDeadlinePayment(Number(target.value))
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={deadlinePayment}
            >
              {renderDeadlinePayment(
                product.payCond
                  ? product.payCond
                  : (simulationCostsContext.dataCustomer?.payCond as number)
              )}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          {errorLoadingPrice ? (
            <ButtonReload
              reloading={loadingPrice}
              reloadFunc={fetchPriceProduct}
            />
          ) : loadingPrice ? (
            <CircularProgress color="primary" className={classes.loading} />
          ) : priceFormatted ? (
            formatCurrency(priceFormatted, { minimumFractionDigits: 4 })
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          {freightPrice !== null ? (
            formatCurrency(freightPrice, { minimumFractionDigits: 4 })
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          <TextField
            variant="outlined"
            fullWidth
            value={suggestionPriceFormatted}
            onChange={(event) => {
              const realValue =
                parseFloat(String(event.target.value).replace(/\D+/g, "")) /
                10000;

              const formated = formatCurrency(realValue, {
                minimumFractionDigits: 4,
              });
              setSuggestionPriceFormatted(formated);
            }}
          />
        </TableCell>
        <TableCell>
          {outputPrice &&
            formatCurrency(outputPrice, { minimumFractionDigits: 6 })}
        </TableCell>
        <TableCell
          className={clsx({
            [classes.negative]: diffFormatted ? diffFormatted < 0 : false,
            [classes.positive]: diffFormatted ? diffFormatted > 0 : false,
          })}
        >
          {diffFormatted &&
            formatCurrency(diffFormatted, { minimumFractionDigits: 4 })}
        </TableCell>
        <TableCell
          className={clsx({
            [classes.negative]: marginPrice ? marginPrice < 0 : false,
            [classes.positive]: marginPrice ? marginPrice > 0 : false,
          })}
        >
          {errorLoadingCost ? (
            <ButtonReload
              reloading={loadingCost}
              reloadFunc={fetchCostProduct}
            />
          ) : loadingCost ? (
            <CircularProgress color="primary" className={classes.loading} />
          ) : marginPrice ? (
            formatCurrency(marginPrice as number, { minimumFractionDigits: 4 })
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    </Fade>
  );
};

export { RowPrice };
