import { ChartContainer } from "@components/ChartContainer";
import { AbstractCustomerTable } from "@components/ChartsDashboard/AbstractCustomerTable";
import { FreteVolume } from "@components/ChartsDashboard/FreteVolume";
import { ParticipacaoFilial } from "@components/ChartsDashboard/ParticipacaoFilial";
import { ParticipacaoProdVolume } from "@components/ChartsDashboard/ParticipacaoProdVolume";
import { ParticipacaoUfVolume } from "@components/ChartsDashboard/ParticipacaoUfVolume";
import { SegmentoVolume } from "@components/ChartsDashboard/SegmentoVolume";
import { StatusBoxes } from "@components/ChartsDashboard/StatusBoxes";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHomeDashboard } from "./HomeDashboardContext";
import { AssessorVolume } from "@components/ChartsDashboard/AssessorVolume";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      donutChart: { height: "35vh" },
    })
);

const DailyCharts: React.FC = () => {
  const classes = useStyles();
  const { filters, dateCharts, loadingAll } = useHomeDashboard();

  return (
    <>
      <Grid item xs={12}>
        <StatusBoxes
          filters={filters}
          from={dateCharts[dateCharts.selected].from as string}
          to={dateCharts[dateCharts.selected].end as string}
        />
      </Grid>
      <Grid item xs={12} lg={6} className={clsx(classes.donutChart)}>
        <ChartContainer title="Participação por Produto" loading={loadingAll}>
          <ParticipacaoProdVolume
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} lg={6} className={clsx(classes.donutChart)}>
        <ChartContainer title="Participação por filial" loading={loadingAll}>
          <ParticipacaoFilial
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} lg={6} className={clsx(classes.donutChart)}>
        <ChartContainer title="Participação por segmento" loading={loadingAll}>
          <SegmentoVolume
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} lg={6} className={clsx(classes.donutChart)}>
        <ChartContainer title="Participação por frete" loading={loadingAll}>
          <FreteVolume
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} className={clsx(classes.donutChart)}>
        <ChartContainer
          title="Participação por UF Destino"
          loading={loadingAll}
        >
          <ParticipacaoUfVolume
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} style={{ height: "80vh" }}>
        <ChartContainer
          title="Gráfico por Assessor - volume em M3"
          loading={loadingAll}
          scrollInMobile
        >
          <AssessorVolume
            filters={filters}
            from={dateCharts[dateCharts.selected].from as string}
            to={dateCharts[dateCharts.selected].end as string}
          />
        </ChartContainer>
      </Grid>
      <Grid item xs={12}>
        <AbstractCustomerTable
          filters={filters}
          from={dateCharts[dateCharts.selected].from as string}
          to={dateCharts[dateCharts.selected].end as string}
        />
      </Grid>
    </>
  );
};

export { DailyCharts };
