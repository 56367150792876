import { Chip, makeStyles } from "@material-ui/core";
import { CloseTwoTone, ExpandLess, ExpandMore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { createStyles } from "@material-ui/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import React, { useRef, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    matchText: { color: theme.palette.primary.main },
    cardSelect: {
      marginTop: 10,
      display: "flex",
      alignItems: "center",
      paddingLeft: 16,
      background: "#F4F7F9",
      borderRadius: 4,
      fontSize: "12px",
      "& input": {
        border: "none",
        height: 40,
        background: "#F4F7F9",
        width: "100%",
        "&:focus": {
          outline: "none",
        },
      },
      "& input.MuiAutocomplete-inputFocused": {
        border: "none",
      },
    },
    chip: {
      paddingTop: 20,
      paddingBottom: 20,
      borderRadius: 40,
      marginRight: 8,
      marginTop: 13,
      cursor: "pointer",
      fontSize: 12,
    },
    placeholder: {
      display: "none",
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "12px",
      color: "#626166",
    },
    paper: {
      borderRadius: 4,
      marginTop: 12,
      boxShadow: "unset",
    },
    list: {
      position: "absolute",
      width: "calc(100% + 16px)",
      marginLeft: "-16px",
      listStyle: "none",
      borderRadius: 8,
      background: "#F4F7F9",
      color: "#626166",
      "& li": {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 20,
      },
      "& li:first-child": {
        paddingTop: 24,
      },
      "& li:last-child": {
        paddingBottom: 24,
      },
      /* "& li.Mui-selected": {
        color: "white",
        background: "#CCC",
      }, */
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      "& span:last-child": {
        fontWeight: 400,
        fontSize: 12,
        color: "#B0C1E1",
      },
    },
    noOptions: {
      position: "absolute",
      width: "calc(100% + 40px)",
      marginLeft: "-16px",
      background: "#F4F7F9",
      borderRadius: 8,
    },
    closeIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FFD8D2",
      color: "#D91F05",
      borderRadius: "12px",
      width: 20,
      height: 20,
      marginLeft: 12,
    },
    label: {
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 10,
    },
  })
);
export interface renderOptionsProps<T> {
  option: string;
  value: T;
}

interface SelectCustomProps<T> {
  label: string;
  placeholder: string;
  value: T[];
  options: T[];
  getOptionLabel: (value: T) => string;
  renderSelected: renderOptionsProps<T>[];
  onChange: (value: T[]) => void;
  onDeleteItem?: (value: T) => void;
}

interface CloseIconProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export default function SelectCustom<T>(props: SelectCustomProps<T>) {
  const classes = useStyles();
  const field = useRef<HTMLInputElement>();
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const CloseIcon: React.FC<CloseIconProps> = (props) => {
    return (
      <div className={classes.closeIcon} onClick={props.onClick}>
        <CloseTwoTone fontSize="small" />
      </div>
    );
  };

  const closePopper = () => setOpenSelect(false);
  const openPopper = () => setOpenSelect(true);

  return (
    <div style={{ marginTop: 32 }}>
      <span className={classes.label}>{props.label}</span>
      <div className={classes.cardSelect}>
        <Autocomplete
          fullWidth
          multiple
          disableCloseOnSelect
          onOpen={openPopper}
          open={openSelect}
          onBlur={() => setOpenSelect(false)}
          onClose={closePopper}
          noOptionsText="Sem opções"
          loadingText="Carregando opções"
          options={props.options}
          value={props.value}
          renderInput={(params) => {
            return (
              <div
                ref={params.InputProps.ref}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="text"
                  //@ts-ignore
                  ref={field}
                  {...params.inputProps}
                  placeholder={props.placeholder}
                />
                <div style={{ marginRight: "10px", cursor: "pointer" }}>
                  {openSelect ? (
                    <ExpandLess onClick={() => setOpenSelect(false)} />
                  ) : (
                    <ExpandMore
                      onClick={() => {
                        field.current?.focus();
                        setOpenSelect(true);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          }}
          onChange={(e, value) => props.onChange(value)}
          getOptionLabel={props.getOptionLabel}
          renderOption={(option, { inputValue, selected }) => {
            const optionName = props.getOptionLabel(option);
            const matches = match(optionName, inputValue);
            const parts = parse(optionName, matches);

            return (
              <React.Fragment>
                <div>
                  {parts.map((part, index) => (
                    <div
                      className={clsx(
                        part.highlight && classes.matchText,
                        classes.listItem
                      )}
                    >
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 600,
                          fontSize: 16,
                        }}
                      >
                        {part.text.split(":")[0]}
                      </span>
                      <span>{part.text.split(":")[1]}</span>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            );
          }}
          classes={{
            paper: classes.paper,
            listbox: classes.list,
            noOptions: classes.noOptions,
          }}
        />
      </div>
      <div>
        {props.renderSelected.map((item) => (
          <Chip
            icon={
              <CloseIcon
                onClick={() =>
                  props.onDeleteItem ? props.onDeleteItem(item.value) : null
                }
              />
            }
            label={item.option}
            color="primary"
            variant="outlined"
            className={classes.chip}
          />
        ))}
      </div>
    </div>
  );
}
