import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDownloadPDFBoletoTitleDTO } from "../dtos/IDownloadPDFBoletoTitleDTO";
import { IDownloadPDFBoletoTitlesService } from "../models/IDownloadPDFBoletoTitlesService";

@injectable()
export class DownloadPDFBoletoTitlesService
  implements IDownloadPDFBoletoTitlesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IDownloadPDFBoletoTitleDTO[]): Promise<string> {
    const pdfFile = await this.httpInstance.post<BinaryType>(
      "/titles/boleto",
      data,
      {
        responseType: "arraybuffer",
      }
    );
    let url;
    if (data.length > 1) {
      url = window.URL.createObjectURL(
        new Blob([pdfFile], { type: "application/zip" })
      );
    } else {
      url = window.URL.createObjectURL(
        new Blob([pdfFile], { type: "application/pdf" })
      );
    }
    return url;
  }
}
