import { useCallback, useEffect, useState } from "react";

export interface FetchData<T> {
  loading: boolean;
  errorLoading: boolean;
  firstLoading: boolean;
  value: T | null;
  fetch: () => Promise<void>;
}

export function useFetchData<T>(
  callback: () => Promise<T>,
  options?: {
    useEffectDeps?: any[];
    useCallbackDeps?: any[];
    appendValue?: { fieldToAppend: string };
    abortRequest?: () => void;
  }
): FetchData<T> {
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [firstLoading, setFirstLoading] = useState(true);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const valueFetched = await Promise.resolve(callback());
      if (options && options.appendValue) {
        const { fieldToAppend } = options.appendValue;
        // @ts-ignore
        setValue((oldState) =>
          oldState
            ? {
                ...valueFetched,
                [fieldToAppend]: [
                  // @ts-ignore
                  ...oldState[fieldToAppend],
                  // @ts-ignore
                  ...valueFetched[fieldToAppend],
                ],
              }
            : valueFetched
        );
      } else {
        setValue(valueFetched);
      }
      setErrorLoading(false);
      setFirstLoading(false);
    } catch (error) {
      setErrorLoading(true);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(options && options.useCallbackDeps ? options.useCallbackDeps : [])]);

  if (!options) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      fetch();
      if (options.abortRequest) {
        return () => {
          options.abortRequest!();
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(options.useEffectDeps ? options.useEffectDeps : [])]);
  }

  return { loading, errorLoading, value, firstLoading, fetch };
}
