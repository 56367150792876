import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GridOnIcon from "@material-ui/icons/GridOn";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IDownloadExcelOrdersMarginByStatusService } from "@modules/orders/models/IDownloadExcelOrdersMarginByStatusService";
import AppError from "@utils/AppError";
import { downloadFile, nameStatusAtem } from "@utils/index";
import clsx from "clsx";
import { isBefore, isSameDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useFormQuery } from "./FormQueryContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    iconNotDisabled: {
      opacity: "0.6",
    },
    labelChip: { marginBottom: "0.5rem" },
    containerChip: {
      "& :not(:first-child)": {
        marginLeft: "4rem",
      },
    },
    selectedChip: {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "&:focus": {
        backgroundColor: theme.palette.primaryLight.main,
      },
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const formQueryContext = useFormQuery();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const downloadExcelOrdersService = iocContext.serviceContainer.get<
    IDownloadExcelOrdersMarginByStatusService
  >(Types.Orders.IDownloadExcelOrdersMarginByStatusService);

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);
  const [openDialogBP, setOpenDialogBP] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadExcel = useCallback(async () => {
    if (!formQueryContext.query.startDate || !formQueryContext.query.endDate)
      return;
    try {
      setLoadingExcel(true);
      const [url, fileName] = await downloadExcelOrdersService.execute({
        beginDate: formQueryContext.query.startDate,
        endDate: formQueryContext.query.endDate,
        advisorID: formQueryContext.query.BP.map((value) => value.bpID),
        customerID: formQueryContext.query.CNPJ.map(
          (value) => value.customerID
        ),
        page: 1,
        limit: 100,
        status: formQueryContext.query.status,
        action: "",
      });
      if (url) {
        downloadFile(url, fileName);
      } else {
        enqueueSnackbar("Sem resultados para os clientes selecionados", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Ocorreu um erro ao baixar arquivo excel, tente novamente.",
        { variant: "error" }
      );
    } finally {
      setLoadingExcel(false);
    }
  }, [
    downloadExcelOrdersService,
    enqueueSnackbar,
    formQueryContext.query.BP,
    formQueryContext.query.CNPJ,
    formQueryContext.query.endDate,
    formQueryContext.query.startDate,
    formQueryContext.query.status,
  ]);

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <SelectAutocomplete
          openDialog={openDialogBP}
          setOpenDialog={setOpenDialogBP}
          multiple
          onChange={(value) => {
            // verifica se o assessor admin (0000) está presente na lista
            if (
              !Boolean(
                userState.state.assessorList.find(
                  (value) => value.bpID === appConfig.bpAdminID
                )
              )
            ) {
              if (value.length > 0) {
                // deve haver pelo menos um assessor caso o usuário admin não esteja presente na lista
                return formQueryContext.setQuery((state) => ({
                  ...state,
                  BP: value,
                }));
              } else {
                return enqueueSnackbar(
                  "Deve haver pelo menos 1 assessor selecionado",
                  {
                    variant: "warning",
                  }
                );
              }
            } else {
              // se o assessor admin estiver presente, pode-se apagar todos os assessores da lista
              return formQueryContext.setQuery((state) => ({
                ...state,
                BP: value,
              }));
            }
          }}
          value={formQueryContext.query.BP}
          options={userState.state.assessorList}
          loading={userState.state.loadingAssessorList}
          title="Assessor"
          allSelected={
            formQueryContext.query.BP.length ===
            userState.state.assessorList.length
          }
          getOptionKey={(value) => value.bpID}
          getOptionLabel={(option) => `${option.bpID} - ${option.bpName}`}
          startIcon={<AssignmentIndIcon />}
          disableRemoveAll={
            !Boolean(
              userState.state.assessorList.find(
                (value) => value.bpID === appConfig.bpAdminID
              )
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple
          onChange={(value) =>
            formQueryContext.setQuery((state) => ({ ...state, CNPJ: value }))
          }
          value={formQueryContext.query.CNPJ}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          allSelected={
            formQueryContext.query.CNPJ.length ===
            userState.state.listCNPJ.length
          }
          getOptionKey={(value) => value.customerID}
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          clearable={false}
          format="dd/MM/yyyy"
          label="Início"
          value={formQueryContext.query.startDate}
          onChange={(date) => {
            if (date) {
              formQueryContext.setQuery((state) => ({
                ...state,
                startDate: date,
              }));
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={formQueryContext.query.endDate}
          onChange={(date) => {
            if (date)
              formQueryContext.setQuery((state) => ({
                ...state,
                endDate: date,
              }));
          }}
          shouldDisableDate={(day) => {
            if (day && formQueryContext.query.startDate) {
              if (isSameDay(day, formQueryContext.query.startDate)) {
                return false;
              }
              return isBefore(day, formQueryContext.query.startDate);
            }
            return false;
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel className={classes.labelChip}>Filtrar por</InputLabel>
        <Grid container className={classes.containerChip}>
          <Chip
            component="span"
            label={nameStatusAtem("FT")}
            className={clsx({
              [classes.selectedChip]: formQueryContext.query.status === "FT",
            })}
            onClick={() =>
              formQueryContext.setQuery((state) => ({
                ...state,
                status: state.status === "FT" ? null : "FT",
              }))
            }
          />
          <Chip
            component="span"
            label={nameStatusAtem("LIB")}
            className={clsx({
              [classes.selectedChip]: formQueryContext.query.status === "LIB",
            })}
            onClick={() =>
              formQueryContext.setQuery((state) => ({
                ...state,
                status: state.status === "LIB" ? null : "LIB",
              }))
            }
          />
          <Chip
            component="span"
            label={nameStatusAtem("BL")}
            className={clsx({
              [classes.selectedChip]: formQueryContext.query.status === "BL",
            })}
            onClick={() =>
              formQueryContext.setQuery((state) => ({
                ...state,
                status: state.status === "BL" ? null : "BL",
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid item container justify="center" alignItems="center" xs={12}>
        <Button
          variant="contained"
          startIcon={<GridOnIcon />}
          style={{ margin: "1rem" }}
          color="primary"
          onClick={downloadExcel}
          endIcon={
            loadingExcel && (
              <CircularProgress
                style={{ height: "2.5rem", width: "2.5rem" }}
                color="secondary"
              />
            )
          }
        >
          Baixar Excel
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
