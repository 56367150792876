export const UserTypes = {
  IListUsersService: Symbol("IListUsersService"),
  ICreateUserService: Symbol("ICreateUserService"),
  IBlockUserService: Symbol("IBlockUserService"),
  IUnlockUserService: Symbol("IUnlockUserService"),
  IUpdateUSerService: Symbol("IUpdateUSerService"),
  ISendEmailToNotVerifiedEmailUsers: Symbol(
    "ISendEmailToNotVerifiedEmailUsers"
  ),
  IChangePasswordService: Symbol("IChangePasswordService"),
  IGetWalletAssessorService: Symbol("IGetWalletAssessorService"),
  IDownloadExcelWalletAssessorService: Symbol(
    "IDownloadExcelWalletAssessorService"
  ),
  IGetAssessorUsersService: Symbol("IGetAssessorUsersService"),
  IGetTokenToKibanaChartsService: Symbol("IGetTokenToKibanaChartsService"),
  IGetCentersService: Symbol("IGetCentersService"),
};
