import * as Yup from "yup";

const FormQueryInvoicesValidationSchema = Yup.object().shape({
  CNPJ: Yup.array().of(Yup.object()).required("Selecione um CNPJ"),
  startDate: Yup.date()
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data de ínicio"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "A data final não pode ser anterior a inicial")
    .typeError("Digite uma data válida")
    .nullable()
    .required("Selecione a data final"),
});

export default FormQueryInvoicesValidationSchema;
