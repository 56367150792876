import { alpha, Box, useTheme } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSlider } from "./SlideContext";

export const SlideIndicator: React.FC = () => {
  const slideContext = useSlider();
  const theme = useTheme();
  const slideIndicatorAnimation = {
    unselected: {
      // deixa o fundo transparente
      background: alpha("#fff", 0),
      scale: 1,
    },
    selected: { background: theme.palette.grey[800], scale: 1.3 },
    diselect: {
      // deixa o fundo transparente
      background: alpha("#fff", 0),
      scale: 1,
    },
  };

  return (
    <Box display="flex" justifyContent="center" mt={2}>
      {Array.from(Array(slideContext.slideCount)).map((slideIndicator, idx) => {
        return (
          <AnimatePresence key={idx}>
            <Box
              component={motion.div}
              variants={slideIndicatorAnimation}
              initial={
                slideContext.sliderIdx === idx ? "selected" : "unselected"
              }
              animate={
                slideContext.sliderIdx === idx ? "selected" : "unselected"
              }
              exit="diselect"
              transition={{
                duration: 1,
              }}
              onClick={() => {
                if (slideContext.sliderIdx < idx) {
                  slideContext.changeSlide(-1, idx);
                } else {
                  slideContext.changeSlide(1, idx);
                }
              }}
              mr={0.5}
              sx={{
                borderRadius: "50%",
                height: "1rem",
                width: "1rem",
                // @ts-ignore
                cursor: "pointer",
                border: "1px solid #fff",
                borderColor: theme.palette.grey[800],
              }}
            />
          </AnimatePresence>
        );
      })}
    </Box>
  );
};
