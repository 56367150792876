import appConfig from "@config/appConfig";
import Actions from "./actions/actions";
import State from "./State";

export default function UserReducer(
  state: State,
  action: Actions
): State | any {
  switch (action.type) {
    case "APP_SET_USER_LIST_CNPJ": {
      return { ...state, listCNPJ: action.payload.listCNPJ };
    }
    case "APP_SET_LOADING_LIST_CNPJ": {
      return { ...state, loadingCNPJ: action.payload };
    }
    case "APP_SET_ASSESSOR": {
      // salva o bp no local storage
      localStorage.setItem(
        appConfig.keyStorage.bpSelected(action.payload.userID),
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        bpID: action.payload.bpID,
        type: action.payload.type,
        bpSelected: action.payload,
      };
    }
    case "APP_SET_ASSESSOR_LIST": {
      return { ...state, assessorList: action.payload };
    }
    case "APP_SET_ASSESSOR_LOADING_LIST": {
      return { ...state, loadingAssessorList: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
