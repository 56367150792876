import { AdminTypes } from "@modules/admin/container/types";
import { CostTypes } from "@modules/costs/container/types";
import { DashboardType } from "@modules/dashboard/container/types";
import { FinancialType } from "@modules/financial/container/types";
import { InvoicesTypes } from "@modules/invoices/container/types";
import { CallJobsTypes } from "@modules/jobs/container/types";
import { LoginImagesTypes } from "@modules/loginImages/container/types";
import { LogsTypes } from "@modules/logs/container/types";
import { TitlesTypes } from "@modules/titles/container/types";
import { UserTypes } from "@modules/user/container/types";
import { HandoutsType } from "../modules/handouts/container/types";
import { OrdersType } from "../modules/orders/container/types";
import { SettingsCostTypes } from "../modules/settingsCosts/container/types";

export const Types = {
  IHttpService: Symbol("IHttpService"),
  Orders: { ...OrdersType },
  Handouts: { ...HandoutsType },
  Titles: { ...TitlesTypes },
  Invoices: { ...InvoicesTypes },
  Users: { ...UserTypes },
  LoginImages: { ...LoginImagesTypes },
  Logs: { ...LogsTypes },
  Costs: { ...CostTypes },
  SettingsCosts: { ...SettingsCostTypes },
  Admin: { ...AdminTypes },
  Jobs: { ...CallJobsTypes },
  Dashboard: { ...DashboardType },
  Financial: { ...FinancialType },
};
