import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import {
  IQuerySalesAnalyticsDTO,
  IResultSalesAnalyticsDTO,
} from "../dtos/IQuerySalesAnalyticsDTO";
import { IQuerySalesAnalyticsService } from "../models/IQuerySalesAnalyticsService";

@injectable()
export class QuerySalesAnalyticsService implements IQuerySalesAnalyticsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  getProducts(): Promise<string[]> {
    return this.httpInstance.get<string[]>("/orders/products");
  }
  public async execute({
    page = 1,
    limit = 50,
    ...data
  }: IQuerySalesAnalyticsDTO): Promise<IResultSalesAnalyticsDTO> {
    return this.httpInstance.get<IResultSalesAnalyticsDTO>(
      "/customer/marketing-plan/analytics",
      {
        params: {
          from: getCorrectDate(data.beginDate),
          to: getCorrectDate(data.endDate),
          partnerID: data.partnerID,
          documents: data.documents?.join(","),
          categories: data.categories?.join(","),
          page,
          limit,
        },
      }
    );
  }
}
