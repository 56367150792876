import NoData from "@components/NoData";
import { Tag } from "@components/Tag";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  ButtonBase,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridSlotComponentProps,
} from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { IDownloadPDFBoletoTitleDTO } from "@modules/titles/dtos/IDownloadPDFBoletoTitleDTO";
import {
  IQueryTitlesDataDTO,
  SituationTitle,
} from "@modules/titles/dtos/IQueryTitlesDataDTO";
import { IDownloadPDFBoletoTitlesService } from "@modules/titles/models/IDownloadPDFBoletoTitlesService";
import AppError from "@utils/AppError";
import { documentMask, formatCurrency } from "@utils/index";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import formatTZ from "date-fns-tz/format";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { nameSituationTitle } from "../../../utils";
import { useFormQueryTitle } from "./formQueryTitleContext";

const utcZone = "Europe/Lisbon";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "1rem",
      height: 850,
      [theme.breakpoints.up("md")]: { flex: 1 },
    },
    toolbarOpen: {
      backgroundColor: theme.palette.secondary.main,
      "& p": {
        color: "white",
      },
    },
    paperSituation: {
      padding: "0 1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paperSituationClosed: {
      color: "#78C357",
      backgroundColor: "rgba(120,195,87,0.12)",
    },
    paperSituationOpen: {
      color: "#FFA722",
      backgroundColor: "rgba(255,204,0,0.12)",
    },
    paperSituationPartial: {
      color: theme.palette.secondary.main,
      backgroundColor: "rgba(255,167,34,0.12)",
    },
    paperSituationDue: {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(195,12,12,0.12)",
    },
    footerTextTable: {
      fontWeight: "bold",

      display: "flex",

      "& span": {
        display: "block",
        marginLeft: "1rem",
        fontWeight: "bold",
      },
    },
    footerTextColorClosed: {
      color: "#78C357",
    },
    footerTextColorTotal: {
      color: "#000",
    },
    tagSpacing: { [theme.breakpoints.up("xs")]: { margin: "1rem" } },
  })
);

const CustomToolbar: React.FC = () => {
  const { state } = useGridSlotComponentProps();
  const classes = useStyles();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const downloadPDFBoletoService = iocContext.serviceContainer.get<
    IDownloadPDFBoletoTitlesService
  >(Types.Titles.IDownloadPDFBoletoTitlesService);

  const [loadingDownloadBoleto, setLoadingDownloadBoleto] = useState(false);

  const downloadBoletos = useCallback(async () => {
    try {
      setLoadingDownloadBoleto(true);

      const getAllTitlesSelected = state.selection
        .map((key) => {
          return state.rows.idRowsLookup[key];
        })
        .map((title) => {
          const data = title as IQueryTitlesDataDTO;
          const dataBoleto: IDownloadPDFBoletoTitleDTO = {
            Buzei: data.Buzei,
            atemID: data.atemID,
            invoiceID: data.id,
            year: data.year,
          };
          return dataBoleto;
        });

      const url = await downloadPDFBoletoService.execute(
        getAllTitlesSelected as IDownloadPDFBoletoTitleDTO[]
      );

      window.open(url);
    } catch (error) {
      console.error(error);
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao baixar o(s) boleto(s)", {
        variant: "error",
      });
    } finally {
      setLoadingDownloadBoleto(false);
    }
  }, [
    downloadPDFBoletoService,
    enqueueSnackbar,
    state.rows.idRowsLookup,
    state.selection,
  ]);

  const countTotalByStatus = useMemo(() => {
    return Object.keys(state.rows.idRowsLookup).reduce((a, x) => {
      const b = state.rows.idRowsLookup[x] as IQueryTitlesDataDTO;
      if (a[b.situation]) {
        a[b.situation] += b.value;
      } else {
        a[b.situation] = b.value;
      }
      return a;
    }, {} as { [propName in SituationTitle]: number });
  }, [state.rows.idRowsLookup]);

  return (
    <GridToolbarContainer>
      <Grid container justify="center">
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={4} md={3} lg={1} container justify="center">
          <GridToolbarDensitySelector />
        </Grid>

        <Grid item xs={12} md={3} lg={2} container justify="center">
          <Button
            startIcon={
              loadingDownloadBoleto ? (
                <CircularProgress
                  color="secondary"
                  style={{ height: "2.5rem", width: "2.5rem" }}
                />
              ) : (
                <GetAppIcon />
              )
            }
            size={
              state.options.density === "standard"
                ? "medium"
                : state.options.density === "comfortable"
                ? "large"
                : "small"
            }
            color="primary"
            onClick={downloadBoletos}
            disabled={Object.keys(state.selection).length === 0}
          >
            Baixar boleto
          </Button>
        </Grid>

        <Grid container justify="space-around">
          {Object.keys(countTotalByStatus).map((key) => {
            const status = key as SituationTitle;
            return (
              <Grid item>
                <Tag
                  className={classes.tagSpacing}
                  type={
                    status === "closed"
                      ? "success"
                      : status === "partial"
                      ? "middle-warning"
                      : status === "open"
                      ? "warning"
                      : "error"
                  }
                >
                  Total {nameSituationTitle(status)}:{" "}
                  {formatCurrency(countTotalByStatus[status])}
                </Tag>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

const TableTitles: React.FC = () => {
  const classes = useStyles();
  const formQueryTitleContext = useFormQueryTitle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const renderButtonUpload = (data: IQueryTitlesDataDTO) => {
    if (data.situation === "closed" && data.closed) {
      return format(new Date(data.closed), "dd/MM/yyyy");
    } else {
      if (data.imageTitle) {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <Tooltip title="Ver comprovante" placement="top">
                <IconButton
                  onClick={() => {
                    if (data.imageTitle) {
                      formQueryTitleContext.setImage({
                        id: data.imageTitle.id,
                        link: data.imageTitle.link,
                      });
                      formQueryTitleContext.setOpenModalImage(true);
                    }
                  }}
                >
                  <PhotoLibraryIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Button
                color="primary"
                onClick={() =>
                  formQueryTitleContext.setOpenModalUpload({
                    openModalUpload: true,
                    data: {
                      CNPJ: data.CNPJ,
                      atemID: data.atemID,
                      year: data.year,
                      invoiceID: data.id,
                    },
                  })
                }
              >
                Reenviar comprovante
              </Button>
            </Grid>
          </Grid>
        );
      }
      return (
        <Button
          color="primary"
          onClick={() =>
            formQueryTitleContext.setOpenModalUpload({
              openModalUpload: true,
              data: {
                CNPJ: data.CNPJ,
                atemID: data.atemID,
                year: data.year,
                invoiceID: data.id,
              },
            })
          }
        >
          Enviar Comprovante
        </Button>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Título",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Buzei",
      headerName: "Parcela",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "emissionDate",
      headerName: "Emissão",
      type: "date",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "dueDate",
      headerName: "Vencimento",
      type: "date",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                utcZone
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "value",
      headerName: "Valor",
      type: "number",
      valueFormatter: (params) => formatCurrency(params.value as number),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "situation",
      headerName: "Situação",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const row = params.row as IQueryTitlesDataDTO;
        return { amountToBepaid: row.amountToBepaid, situation: row.situation };
      },
      renderCell: (params) => {
        const data = params.getValue(params.id, params.field) as {
          amountToBepaid: number;
          situation: SituationTitle;
        };

        return (
          <>
            <Tag
              type={
                data.situation === "closed"
                  ? "success"
                  : data.situation === "partial"
                  ? "middle-warning"
                  : data.situation === "open"
                  ? "warning"
                  : "error"
              }
            >
              {nameSituationTitle(data.situation as SituationTitle)}
            </Tag>
            {data.amountToBepaid > 0 ? (
              <Typography variant="caption">
                A pagar:&nbsp;
                {formatCurrency(data.amountToBepaid)}
              </Typography>
            ) : (
              <></>
            )}
          </>
        );
      },
      filterOperators: [
        {
          value: "equal",
          label: "igual à",
          InputComponent: (props: GridFilterInputValueProps) => {
            const { item, applyValue } = props;
            console.log(props);

            return (
              <Grid container spacing={1}>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "closed" })}
                  >
                    <Tag type={item.value === "closed" ? "success" : "grey"}>
                      {nameSituationTitle("closed")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "partial" })}
                  >
                    <Tag
                      type={
                        item.value === "partial" ? "middle-warning" : "grey"
                      }
                    >
                      {nameSituationTitle("partial")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "open" })}
                  >
                    <Tag type={item.value === "open" ? "warning" : "grey"}>
                      {nameSituationTitle("open")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "due" })}
                  >
                    <Tag type={item.value === "due" ? "error" : "grey"}>
                      {nameSituationTitle("due")}
                    </Tag>
                  </ButtonBase>
                </Grid>
              </Grid>
            );
          },
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params): boolean => {
              const value = params.value as { situation: string };
              return value.situation === filterItem.value;
            };
          },
        },
      ],
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "CNPJ",
      headerName: "CNPJ",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => documentMask(params.value as string),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "companyName",
      headerName: "Razão Social",
      headerAlign: "center",
      align: "center",
      flex: isMobile ? undefined : 2,
      width: isMobile ? 350 : undefined,
    },
    {
      field: "buttonSendReceipt",
      headerName: "Baixa",
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        renderButtonUpload(params.row as IQueryTitlesDataDTO),
      flex: isMobile ? undefined : 2,
      width: isMobile ? 250 : undefined,
    },
  ];

  return formQueryTitleContext.dataQuery &&
    formQueryTitleContext.dataQuery.length ? (
    <Paper className={classes.paperTable}>
      <DataGrid
        columns={columns}
        rows={formQueryTitleContext.dataQuery}
        components={{ Toolbar: CustomToolbar }}
        isRowSelectable={(params: GridRowParams) =>
          (params.row as IQueryTitlesDataDTO).bankDoc !== ""
        }
        checkboxSelection
      />
    </Paper>
  ) : (
    <NoData>Não foram encontrados resultados!</NoData>
  );
};

export default TableTitles;
