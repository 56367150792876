import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IMarkImageAsSeenDataDTO } from "../dtos/IMarkImageAsSeenDataDTO";
import { IMarkImageAsSeenDTO } from "../dtos/IMarkImageAsSeenDTO";
import { IMarkImageAsSeenService } from "../models/IMarkImageAsSeenService";

@injectable()
export class MarkImageAsSeenService implements IMarkImageAsSeenService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IMarkImageAsSeenDTO) {
    const markOk = await this.httpInstance.post<IMarkImageAsSeenDataDTO>(
      "/titles/seen-image-title",
      data
    );
    return markOk;
  }
}
