import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Payment } from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GridOnIcon from "@material-ui/icons/GridOn";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IGroupCustomerFilterDTO } from "@modules/financial/dtos/IGroupCustomerFilterDTO";
import { IManagerFilterDTO } from "@modules/financial/dtos/IManagerFilterDTO";
import { IDownloadFinancialInstallmentsService } from "@modules/financial/models/IDownloadFinancialInstallmentsService";
import AppError from "@utils/AppError";
import { docStautusName, downloadFile } from "@utils/index";
import { isBefore, isSameDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useFinancialInstallmentsContext } from "./FinancialInstallmentsContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    iconsDisabled: {
      opacity: "0.12",
    },
    iconNotDisabled: {
      opacity: "0.6",
    },
    selectedChip: {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "&:focus": {
        backgroundColor: theme.palette.primaryLight.main,
      },
    },
    labelChip: { marginBottom: "0.5rem" },
    containerChip: {
      "& :not(:first-child)": {
        marginLeft: "4rem",
      },
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const financialInstallmentsContext = useFinancialInstallmentsContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();
  const userState = useUserState();

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);
  const [openDialogGroupCustomer, setOpenDialogGroupCustomer] = useState(false);
  const [openDialogAdvisor, setOpenDialogAdvisor] = useState(false);
  const [openDialogStatus, setOpenDialogStatus] = useState(false);
  const [openDialogManager, setOpenDialogManager] = useState(false);

  const downloadFinancialInstalmentsService = iocContext.serviceContainer.get<
    IDownloadFinancialInstallmentsService
  >(Types.Financial.IDownloadFinancialInstallmentsService);

  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadExcel = useCallback(async () => {
    try {
      setLoadingExcel(true);

      if (
        !financialInstallmentsContext.query.startDate ||
        !financialInstallmentsContext.query.endDate
      )
        return;

      const [url, fileName] = await downloadFinancialInstalmentsService.execute(
        {
          CNPJ: financialInstallmentsContext.query.CNPJ.map(
            (company) => company.customerID
          ),
          advisorID: financialInstallmentsContext.query.advisorID.map(
            (value) => value.bpID
          ),
          customerIDs: financialInstallmentsContext.query.CNPJ.map(
            (company) => company.customerID
          ),
          groupCustomer: financialInstallmentsContext.query.groupCustomer.map(
            (customer) => customer.id
          ),
          managerID: financialInstallmentsContext.query.managerID.map(
            (manager) => manager.id
          ),
          statusDoc: financialInstallmentsContext.query.statusDoc,
          beginDate: financialInstallmentsContext.query.startDate,
          endDate: financialInstallmentsContext.query.endDate,
          page: financialInstallmentsContext.query.page ?? 1,
          limit: financialInstallmentsContext.query.limit ?? 10,
        }
      );
      downloadFile(url, fileName);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Ocorreu um erro ao baixar arquivo excel, tente novamente.",
        { variant: "error" }
      );
    } finally {
      setLoadingExcel(false);
    }
  }, [
    downloadFinancialInstalmentsService,
    enqueueSnackbar,
    financialInstallmentsContext.query.advisorID,
    financialInstallmentsContext.query.CNPJ,
    //financialInstallmentsContext.query.customerIDs,
    financialInstallmentsContext.query.groupCustomer,
    financialInstallmentsContext.query.managerID,
    financialInstallmentsContext.query.statusDoc,
    financialInstallmentsContext.query.endDate,
    financialInstallmentsContext.query.startDate,
    financialInstallmentsContext.query.limit,
    financialInstallmentsContext.query.page,
  ]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <SelectAutocomplete
          openDialog={openDialogAdvisor}
          setOpenDialog={setOpenDialogAdvisor}
          multiple
          onChange={(value) => {
            // verifica se o assessor admin (0000) está presente na lista
            if (
              !Boolean(
                userState.state.assessorList.find(
                  (value) => value.bpID === appConfig.bpAdminID
                )
              )
            ) {
              if (value.length > 0) {
                // deve haver pelo menos um assessor caso o usuário admin não esteja presente na lista
                return financialInstallmentsContext.setQuery((state) => ({
                  ...state,
                  advisorID: value,
                }));
              } else {
                return enqueueSnackbar(
                  "Deve haver pelo menos 1 assessor selecionado",
                  {
                    variant: "warning",
                  }
                );
              }
            } else {
              // se o assessor admin estiver presente, pode-se apagar todos os assessores da lista
              return financialInstallmentsContext.setQuery((state) => ({
                ...state,
                advisorID: value,
              }));
            }
          }}
          value={financialInstallmentsContext.query.advisorID}
          options={userState.state.assessorList}
          loading={userState.state.loadingAssessorList}
          title="Assessor"
          allSelected={
            financialInstallmentsContext.query.advisorID.length ===
            userState.state.assessorList.length
          }
          getOptionKey={(value) => value.bpID}
          getOptionLabel={(option) => `${option.bpID} - ${option.bpName}`}
          startIcon={<AssignmentIndIcon />}
          disableRemoveAll={
            !Boolean(
              userState.state.assessorList.find(
                (value) => value.bpID === appConfig.bpAdminID
              )
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple
          onChange={(value) =>
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              CNPJ: value,
            }))
          }
          value={financialInstallmentsContext.query.CNPJ}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          getOptionKey={(value) => value.customerID}
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          allSelected={
            financialInstallmentsContext.query.CNPJ.length ===
            userState.state.listCNPJ.length
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectAutocomplete
          openDialog={openDialogGroupCustomer}
          setOpenDialog={setOpenDialogGroupCustomer}
          multiple
          onChange={(value) =>
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              groupCustomer: value,
            }))
          }
          value={financialInstallmentsContext.query.groupCustomer}
          options={
            financialInstallmentsContext.groupCustomerFilter
              ? financialInstallmentsContext.groupCustomerFilter
              : ([] as IGroupCustomerFilterDTO[])
          }
          loading={userState.state.loadingCNPJ}
          title="Grupo de Cliente"
          getOptionKey={(value) => value.id}
          getOptionLabel={(value) => `${value.id} - ${value.desc}`}
          allSelected={
            financialInstallmentsContext.query.groupCustomer.length ===
            financialInstallmentsContext.groupCustomerFilter?.length
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Grupo de Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectAutocomplete
          openDialog={openDialogManager}
          setOpenDialog={setOpenDialogManager}
          multiple
          onChange={(value) =>
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              managerID: value,
            }))
          }
          value={financialInstallmentsContext.query.managerID}
          options={
            financialInstallmentsContext.managerFilter
              ? financialInstallmentsContext.managerFilter
              : ([] as IManagerFilterDTO[])
          }
          loading={userState.state.loadingCNPJ}
          title="Gerente"
          getOptionKey={(value) => value.id}
          getOptionLabel={(value) => `${value.id} - ${value.name}`}
          allSelected={
            financialInstallmentsContext.query.managerID.length ===
            financialInstallmentsContext.managerFilter?.length
          }
          startIcon={<AssignmentIndIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          format="dd/MM/yyyy"
          label="Início"
          value={financialInstallmentsContext.query.startDate}
          onChange={(date) => {
            if (date) {
              financialInstallmentsContext.setQuery((state) => ({
                ...state,
                startDate: date,
              }));
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={financialInstallmentsContext.query.endDate}
          onChange={(date) => {
            if (date) {
              financialInstallmentsContext.setQuery((state) => ({
                ...state,
                endDate: date,
              }));
            }
          }}
          shouldDisableDate={(day) => {
            if (day && financialInstallmentsContext.query.startDate) {
              if (
                isSameDay(day, financialInstallmentsContext.query.startDate)
              ) {
                return false;
              }
              return isBefore(
                day,
                financialInstallmentsContext.query.startDate
              );
            }
            return false;
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectAutocomplete
          openDialog={openDialogStatus}
          setOpenDialog={setOpenDialogStatus}
          multiple
          onChange={(value) =>
            financialInstallmentsContext.setQuery((state) => ({
              ...state,
              statusDoc: value,
            }))
          }
          value={financialInstallmentsContext.query.statusDoc}
          options={financialInstallmentsContext.statusFilter}
          loading={userState.state.loadingCNPJ}
          title="Status do Documento"
          getOptionKey={(value) => value}
          getOptionLabel={(value) => docStautusName(value)}
          allSelected={
            financialInstallmentsContext.query.statusDoc.length ===
            financialInstallmentsContext.statusFilter.length
          }
          startIcon={<Payment />}
        />
      </Grid>
      <Grid item container justify="center" xs={12} alignItems="center">
        <Button
          variant="contained"
          startIcon={<GridOnIcon />}
          style={{ margin: "1rem" }}
          color="primary"
          onClick={downloadExcel}
          endIcon={
            loadingExcel && (
              <CircularProgress
                style={{ height: "2.5rem", width: "2.5rem" }}
                color="secondary"
              />
            )
          }
        >
          Baixar Excel
        </Button>
        {/* <Button
          style={{ margin: "1rem" }}
          variant="contained"
          startIcon={<PictureAsPdfIcon />}
          color="primary"
          onClick={async () => {
            if (formQueryTitleContext.dataQuery.length) {
              await createPDFTitlesService.execute(
                formQueryTitleContext.dataQuery,
                {
                  beginDate: values.startDate as Date,
                  endDate: values.endDate as Date,
                }
              );
            }
          }}
          disabled={formQueryTitleContext.dataQuery.length === 0}
        >
          Baixar relatório
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default FormQuery;
