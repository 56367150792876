import { IClient } from "@utils/interfaces";
import React, { createContext, useContext, useState } from "react";

interface AppointmentPriceContext {
  loadingCustomerData: boolean;
  loadingPrices: boolean;
  loadingFirstQuery: boolean;
  dataCustomer: IClient | null;
  setLoadingCustomerData: (value: boolean) => void;
  setDataCustomer: (data: IClient | null) => void;
  setLoadingPrices: (value: boolean) => void;
  setLoadingFirstQuery: (value: boolean) => void;
}

const AppointmentPriceContext = createContext<AppointmentPriceContext>(
  {} as AppointmentPriceContext
);

const AppointmentPriceProvider: React.FC = ({ children }) => {
  const [loadingCustomerData, setLoadingCustomerData] = useState(false);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [loadingFirstQuery, setLoadingFirstQuery] = useState(true);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);

  return (
    <AppointmentPriceContext.Provider
      value={{
        loadingFirstQuery,
        loadingCustomerData,
        dataCustomer,
        loadingPrices,
        setLoadingCustomerData,
        setDataCustomer,
        setLoadingPrices,
        setLoadingFirstQuery,
      }}
    >
      {children}
    </AppointmentPriceContext.Provider>
  );
};

const useAppointmentPriceContext = () => {
  const context = useContext(AppointmentPriceContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useAppointmentPriceContext deve ser utilizado dentro de um AppointmentPriceProvider"
    );
  }
  return context;
};

export { useAppointmentPriceContext, AppointmentPriceProvider };
