import { createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/data-grid";

function hexToRGBWithAlpha(hex: string, alpha: number | string) {
  if (hex.length !== 7) {
    throw new Error("A cor deve possuir pelo menos 7 caracaters. Ex: #XXXXXX");
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export default createTheme(
  {
    spacing: 10,
    typography: {
      htmlFontSize: 10,
      fontFamily: ["Open Sans", "Roboto"].join(","),
    },
    palette: {
      primary: {
        main: "#C30C0C",
      },
      primaryLight: {
        main: "#F7E1E1",
      },
      secondary: {
        main: "#FFA722",
      },
      error: {
        main: "#C30C0C",
      },
      success: {
        main: "#5AC45F",
      },
      text: {
        primary: "rgba(0,0,0,0.6)",
        disabled: "rgba(0,0,0,0.12)",
      },
      background: {
        default: "#F5F5F5",
      },
      hexToRGBWithAlpha,
    },
  },
  ptBR
);

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    primaryLight: Palette["primary"];
    hexToRGBWithAlpha: (hex: string, alpha: number | string) => string;
  }
  interface PaletteOptions {
    primaryLight: PaletteOptions["primary"];
    hexToRGBWithAlpha?: (hex: string, alpha: number | string) => string;
  }
}
