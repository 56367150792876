import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUpdateUserDTO } from "../dtos/IUpdateUserDTO";
import { IUpdateUserService } from "../models/IUpdateUserService";

@injectable()
export class UpdateUSerService implements IUpdateUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(data: IUpdateUserDTO): Promise<void> {
    return this.httpInstance.put("/users", data);
  }
}
