import BarChartCustom from "@components/BarChart";
import NoData from "@components/NoData";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { CircularProgress, Grid } from "@material-ui/core";
import { IChartDataService } from "@modules/financial/models/IChartDataService";
import { useFinancialDashboardContext } from "@pages/Financial/FinancialDashboard/FinancialDashboardContext";
import { IFinancialDashboardChartQuery } from "@pages/Financial/FinancialInstallments/interface";
import { capitalizeWords, formatCurrency, formatDecimal } from "@utils/index";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

const TotalByMonthChart: React.FC<{
  filters: IFinancialDashboardChartQuery;
}> = ({ filters }) => {
  const iocContext = useIoCContext();

  const getChartService = iocContext.serviceContainer.get<IChartDataService>(
    Types.Financial.IChartDataService
  );

  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([-1]);

  const { query, loaders, setLoaders } = useFinancialDashboardContext();
  const { enqueueSnackbar } = useSnackbar();

  const getTotalByMonthChart = useCallback(async () => {
    try {
      setLoaders((state) => ({
        ...state,
        isTotalByMonthChartLoading: true,
      }));

      const queryResult = await getChartService.getTotalByMonth({
        ...filters,
        from: query.from,
        to: query.to,
      });

      let data = queryResult.rows
        .filter((item) => item.every((value) => value != null))
        .slice(0, 10)
        .map((item) => {
          const newDate = new Date(
            item[0].replace(/-/g, "/").replace(/T.+/, "")
          ).toLocaleString("pr-BR", {
            month: "short",
          });
          return {
            x: capitalizeWords(newDate),
            y: item[1],
          };
        });
      if (data.length > 0) {
        setSeries([{ data }]);
      }
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar gráfico de total por mês!", {
        variant: "error",
      });
    } finally {
      setLoaders((state) => ({
        ...state,
        isTotalByMonthChartLoading: false,
      }));
    }
  }, [
    enqueueSnackbar,
    filters,
    setLoaders,
    getChartService,
    query.from,
    query.to,
  ]);

  useEffect(() => {
    getTotalByMonthChart();
  }, [getTotalByMonthChart]);

  return series.length && series[0] === -1 ? (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Grid>
  ) : series.length > 0 ? (
    <BarChartCustom
      series={series}
      yType="Reais"
      yFormatter={(value) => formatDecimal(value)}
      labelFormatter={(value) => formatCurrency(value)}
      isLoading={loaders.isTotalByMonthChartLoading}
    />
  ) : (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <NoData>Nada encontrado</NoData>
    </Grid>
  );
};

export default TotalByMonthChart;
