import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useSimulationsCostsContext } from "../SimulationCostsContext";
import { RowPrice } from "./RowPrice";

const TableCost: React.FC = () => {
  const simulationCostsContext = useSimulationsCostsContext();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Produto</TableCell>
            <TableCell>Prazo</TableCell>
            <TableCell>Preço</TableCell>
            <TableCell>Frete</TableCell>
            <TableCell>Preço Sugerido</TableCell>
            <TableCell>Preço de Partida</TableCell>
            <TableCell>Diferença</TableCell>
            <TableCell>Margem</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {simulationCostsContext.productsSimulation.map((product) => {
            return <RowPrice key={product.key} product={product} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TableCost };
