import { parseISO } from "date-fns";
import { version } from "../../package.json";

export default {
  api: {
    url: {
      production: "https://middleware.grupoatem.com.br/rest",
      homologation: "https://middleware-dev.grupoatem.com.br:8440/rest",
      development: "https://middleware-dev.grupoatem.com.br/rest",
    },
    urlCognito: {
      production: "https://gateway.grupoatem.com.br/",
    },
    kibanaHost: "https://banana.grupoatem.com.br",
    timeout: 480000, // 8 minutos
    refreshInterval: 0,
  },
  cognito: {
    gateway: {
      production: "https://gateway.grupoatem.com.br",
      homologation: "http://localhost:8080TESTE",
      development: "https://gateway-dev.grupoatem.com.br",
    },
    urlLogout: {
      development:
        "http://localhost:3000/#/logout?url=http://localhost:3001&rules=portal_comercial",
      homologation:
        "https://authcog.grupoatem.com.br/#/logout?url=https://testecomercial.grupoatem.com.br&rules=portal_comercial",
      production:
        "https://authcog.grupoatem.com.br/#/logout?url=https://comercial.grupoatem.com.br&rules=portal_comercial",
    },
  },
  keycloack: {
    urlServer: "https://auth.grupoatem.com.br/auth",
    clientID: "portal-cliente-frontweb",
    realm: "master",
    urlLogout: {
      development:
        "https://auth.grupoatem.com.br/auth/realms/master/protocol/openid-connect/logout?redirect_uri=http://localhost:3000",
      production:
        "https://auth.grupoatem.com.br/auth/realms/master/protocol/openid-connect/logout?redirect_uri=https://comercial.grupoatem.com.br/",
      homologation:
        "https://auth.grupoatem.com.br/auth/realms/master/protocol/openid-connect/logout?redirect_uri=https://comercial.grupoatem.com.br:8888/",
    },
    roleAdmin: "admin_portal_comercial",
    roleUser: "user",
    roles: {
      rolePricing: "portal_comercial:pricing",
      roleSalesMarketingAccess: "portal_comercial:sales-marketing-access",
      roleSuperuser: "portal_comercial:superuser",
      roleViewAccesses: "portal_comercial:view-accesses",
      roleTriggerJobs: "portal_comercial:trigger_jobs",
    },
  },
  version: {
    number: version,
    date: parseISO("2023-10-20"),
  },
  keyStorage: {
    bpSelected: (id: string) => `@portal-BPSelected:${id}`,
    chartsToken: "@portal-chartsToken",
  },
  keysChart: {
    /**
     * [prod,tabela] Filial x Produto - 01
     */
    1: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(params:(sort:(columnIndex:2,direction:asc)))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Produto,field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:' ',field:order.filial.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:split,type:terms)),params:(perPage:10,percentageCol:'',row:!f,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!t,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'%5Bprod,tabela%5D%20Filial%20x%20Produto%20-%2001',type:table))",
      id: "e7dfd520-156c-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,nuvem] Clientes com mais volume adquirido - 02
     */
    2: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,%20DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(field:order.companyName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:segment,type:terms)),params:(maxFontSize:72,minFontSize:18,orientation:single,scale:linear,showLabel:!t),title:'%5Bprod,nuvem%5D%20Clientes%20com%20mais%20volume%20adquirido%20-%2002',type:tagcloud))",
      id: "d681b610-14d4-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,pizza] Volume de Produtos - 03
     */
    3: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,%20DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Produto,field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!t,labels:(last_level:!t,show:!t,truncate:100,values:!t),legendPosition:right,type:pie),title:'%5Bprod,pizza%5D%20Volume%20de%20Produtos%20-%2003',type:pie))",
      id: "8f90ced0-14de-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,grafico,linha] Assessor x Volume por mês - 04
     */
    4: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT, DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:order.deliveryDate,interval:M,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-15m,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(field:order.advisor.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:group,type:terms)),params:(addLegend:!t,addTimeMarker:!t,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,rotate:0,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t),labels:(),legendPosition:right,row:!t,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value),(id:ValueAxis-2,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:RightAxis-1,position:right,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%5Bprod,grafico,linha%5D%20Assessor%20x%20Volume%20por%20m%C3%AAs%20-%2004',type:line))",
      id: "4d3b22b0-1579-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,tabela] Assessor x Produto e Volume - 05
     */
    5: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,%20DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(params:(sort:(columnIndex:1,direction:desc)))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Produto,field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:'0ESPACO0',field:order.advisor.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:split,type:terms)),params:(perPage:10,percentageCol:'',row:!f,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!t,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'%5Bprod,tabela%5D%20Assessor%20x%20Produto%20e%20Volume%20-%2005',type:table))",
      id: "ba278cb0-156f-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,barra] Assessor - 06
     */
    6: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,%20DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(colors:(Quantidade:%23890F02))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(field:order.advisor.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:segment,type:terms)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:200),position:left,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!f),labels:(show:!t),legendPosition:right,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:histogram,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:histogram,valueAxes:!((id:ValueAxis-1,labels:(filter:!t,rotate:75,show:!t,truncate:100),name:LeftAxis-1,position:bottom,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%5Bprod,barra%5D%20Assessor%20-%2006',type:histogram))",
      id: "b31661d0-14d9-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,barra] Filial x Produto - 07
     */
    7: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(colors:(Quantidade:%23890F02),legendOpen:!t)),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:' ',field:order.filial.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:3),schema:split,type:terms),(enabled:!t,id:'3',params:(customLabel:produto,field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500),schema:segment,type:terms)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,rotate:0,show:!t,truncate:300),position:left,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t,valueAxis:ValueAxis-1),labels:(show:!t),legendPosition:right,row:!t,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:histogram,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:histogram,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:75,show:!t,truncate:100),name:BottomAxis-1,position:bottom,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%20%5Bprod,barra%5D%20Filial%20x%20Produto%20-%2007',type:histogram))",
      id: "ffd81f70-14db-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,barra] Assessor x Produto - 08
     */
    8: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT,%20DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(colors:(Quantidade:%23890F02))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:'0ESPACO0',field:order.advisor.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:3),schema:split,type:terms),(enabled:!t,id:'3',params:(customLabel:'0ESPACO0',field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50000),schema:segment,type:terms)),params:(addLegend:!t,addTimeMarker:!f,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:left,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!f),labels:(show:!t),legendPosition:right,row:!t,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:histogram,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:histogram,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:BottomAxis-1,position:bottom,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%20%5Bprod,barra%5D%20Assessor%20x%20Produto%20-%2008',type:histogram))",
      id: "3889e4c0-17c5-11ec-8afb-67f93a8f09c3",
    },
    /**
     * [prod,tabela] Status x Produto e Volume - 09
     */
    9: {
      chartState:
        "(filters:!(),linked:!f,query:(language:kuery,query:''),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Produto,field:order.productName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50000),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:' ',field:order.statusAtem.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50000000),schema:split,type:terms)),params:(perPage:20,percentageCol:'',row:!f,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!t,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'%20%5Bprod,tabela%5D%20Status%20x%20Produto%20e%20Volume%20-%2009',type:table))",
      id: "349878c0-1574-11ec-86b7-e5a392ec312f",
    },
    /**
     * [prod,grafico,linha] Filial x Volume por mês - 10
     */
    10: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT, DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:order.deliveryDate,interval:M,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-15m,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(field:order.filial.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:group,type:terms)),params:(addLegend:!t,addTimeMarker:!t,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%20%5Bprod,grafico,linha%5D%20Filial%20x%20Volume%20por%20m%C3%AAs%20-%2010',type:line))",
      id: "1f94c550-17d1-11ec-8afb-67f93a8f09c3",
    },
    /**
     * [prod, dashboard] - Totalizadores Status vendas - 15
     */
    15: {
      chartState:
        "(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:TesteDash,viewMode:view)",
      id: "bc6b4ae0-1a40-11ec-a6c6-3950f708b6fd",
    },
    /**
     * [prod,pizza] Volume por UF - 16
     */
    16: {
      chartState:
        "(filters:!(),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:UF,field:order.state.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:5000000),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!t,labels:(last_level:!t,show:!t,truncate:100,values:!t),legendPosition:right,type:pie),title:'%20%5Bprod,pizza%5D%20Volume%20por%20UF%20-%2016',type:pie))",
      id: "1bae3810-1b0d-11ec-9819-1bfd5017479c",
    },
    /**
     * [prod,pizza] Volume por Segmento - 17
     */
    17: {
      chartState:
        "(filters:!(),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Segmento,field:order.segmento.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50000000),schema:segment,type:terms)),params:(addLegend:!t,addTooltip:!t,isDonut:!t,labels:(last_level:!t,show:!t,truncate:100,values:!t),legendPosition:right,type:pie),title:'%5Bprod,pizza%5D%20Volume%20por%20Segmento%20-%2017',type:pie))",
      id: "4afc05e0-1b10-11ec-9819-1bfd5017479c",
    },
    /**
     * [prod,grafico,linha] Segmento x Volume por mês - 18
     */
    18: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT, DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(colors:(ATEM:%23890F02))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:order.deliveryDate,interval:M,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-15m,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(field:order.segmento.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000),schema:group,type:terms)),params:(addLegend:!t,addTimeMarker:!t,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%5Bprod,grafico,linha%5D%20Segmento%20x%20Volume%20por%20m%C3%AAs%20-%2018',type:line))",
      id: "05ee1660-22e4-11ec-bc78-69840a24263c",
    },
    /**
     * [prod,grafico,linha] Volume Atem por mês - 19
     */
    19: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT, DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(vis:(colors:(ATEM:%23890F02,Atem:%23890F02,Quantidade:%23890F02))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:ATEM,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:order.deliveryDate,interval:M,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-15m,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram)),params:(addLegend:!t,addTimeMarker:!t,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:ATEM),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:ATEM),type:value))),title:'%5Bprod,grafico,linha%5D%20Volume%20Atem%20por%20m%C3%AAs%20-%2019',type:line))",
      id: "2d205220-22e9-11ec-bc78-69840a24263c",
    },
    /**
     * [prod,grafico,linha] Cliente x Volume por mês - 20
     */ 20: {
      chartState:
        "(filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'24208e40-14cc-11ec-aede-15a1754deff0',key:order.statusAtem.keyword,negate:!f,params:!(FT,DEV),type:phrases,value:'FT, DEV'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(order.statusAtem.keyword:FT)),(match_phrase:(order.statusAtem.keyword:DEV))))))),linked:!f,query:(language:kuery,query:''),uiState:(),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(drop_partials:!f,extended_bounds:(),field:order.deliveryDate,interval:M,min_doc_count:1,scaleMetricValues:!f,timeRange:(from:now-15m,to:now),useNormalizedEsInterval:!t),schema:segment,type:date_histogram),(enabled:!t,id:'3',params:(field:order.companyName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:6),schema:group,type:terms)),params:(addLegend:!t,addTimeMarker:!t,addTooltip:!t,categoryAxes:!((id:CategoryAxis-1,labels:(filter:!t,show:!t,truncate:100),position:bottom,scale:(type:linear),show:!t,style:(),title:(),type:category)),grid:(categoryLines:!t),labels:(),legendPosition:right,seriesParams:!((data:(id:'1',label:Quantidade),drawLinesBetweenPoints:!t,interpolate:linear,lineWidth:2,mode:normal,show:!t,showCircles:!t,type:line,valueAxis:ValueAxis-1)),thresholdLine:(color:%23E7664C,show:!f,style:full,value:10,width:1),times:!(),type:line,valueAxes:!((id:ValueAxis-1,labels:(filter:!f,rotate:0,show:!t,truncate:100),name:LeftAxis-1,position:left,scale:(mode:normal,type:linear),show:!t,style:(),title:(text:Quantidade),type:value))),title:'%5Bprod,grafico,linha%5D%20Cliente%20x%20Volume%20por%20m%C3%AAs%20-%2020',type:line))",
      id: "f84780d0-22ea-11ec-bc78-69840a24263c",
    },
    /**
     * [tabela] Status x Cliente - 21
     */
    21: {
      chartState:
        "(filters:!(),linked:!f,query:(language:kuery,query:''),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Cliente,field:order.companyName.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:500000000),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:' ',field:order.statusAtem.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:split,type:terms)),params:(perPage:10,percentageCol:'',row:!f,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!t,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'[tabela] Status x Cliente - 21',type:table))",
      id: "a0c77f50-53a9-11ec-b693-e19532f4359b",
    },
    /**
     * [tabela] Status x Filial - 22
     */
    22: {
      chartState:
        "(filters:!(),linked:!f,query:(language:kuery,query:''),uiState:(vis:(params:(sort:(columnIndex:!n,direction:!n)))),vis:(aggs:!((enabled:!t,id:'1',params:(customLabel:Quantidade,field:order.quantity),schema:metric,type:sum),(enabled:!t,id:'2',params:(customLabel:Filial,field:order.filial.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:bucket,type:terms),(enabled:!t,id:'3',params:(customLabel:' ',field:order.statusAtem.keyword,missingBucket:!f,missingBucketLabel:Missing,order:desc,orderBy:'1',otherBucket:!f,otherBucketLabel:Other,size:50),schema:split,type:terms)),params:(perPage:10,percentageCol:'',row:!f,showMetricsAtAllLevels:!f,showPartialRows:!f,showTotal:!t,sort:(columnIndex:!n,direction:!n),totalFunc:sum),title:'[tabela] Status x Filial - 22',type:table))",
      id: "51792690-53ab-11ec-b693-e19532f4359b",
    },
  },
  /**
   * @see https://apexcharts.com/docs/colors/
   */
  chartTheme: {
    dataLabels: { style: { colors: ["#fff"] } },
    fill: { colors: ["#C30C0C"] },
  },
  barChartTheme: {
    dataLabels: { style: { colors: ["#333"] } },
    fill: { colors: ["#C30C0C"] },
  },
  bpAdminID: "0000000000",
};
