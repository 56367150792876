import NoData from "@components/NoData";
import { Tag } from "@components/Tag";
import {
  ButtonBase,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
} from "@material-ui/data-grid";
import { StatusAtem } from "@modules/orders/dtos/IQueryOrderDataDTO";
import {
  formatCurrency,
  formatCurrencyPriceProduct,
  formatDecimal,
  nameStatusAtem,
} from "@utils/index";
import { utcToZonedTime } from "date-fns-tz";
import formatTZ from "date-fns-tz/format";
import React from "react";
import { useFormQuery } from "./FormQueryContext";

const manausTZ = "America/Manaus";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "4rem",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey["900"],
        borderRadius: ".4rem",
      },
    },
    iconImg: {
      filter:
        "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%);",
    },
    negativeValue: { color: theme.palette.primary.main },
    positiveValue: { color: theme.palette.success.main },
    tagSpacing: {
      [theme.breakpoints.up("xs")]: { margin: "1rem" },
    },
  })
);

const TableOrders: React.FC = () => {
  const classes = useStyles();
  const formQueryContext = useFormQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const columns: GridColDef[] = [
    {
      field: "id_sap_customer",
      headerName: "BP",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "companyName",
      headerName: "Razão social",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "statusAtem",
      headerName: "Status",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (props) => {
        const status = props.getValue(props.id, props.field) as StatusAtem;

        return (
          <Tag
            type={
              status === "LIB"
                ? "warning"
                : status === "FT"
                ? "success"
                : status === "EST"
                ? "black"
                : status === "DEV"
                ? "middle-warning"
                : "error"
            }
          >
            {nameStatusAtem(status)}
          </Tag>
        );
      },
      filterOperators: [
        {
          value: "equal",
          label: "igual à",
          InputComponent: (props: GridFilterInputValueProps) => {
            const { item, applyValue } = props;

            return (
              <Grid container spacing={1}>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "BL" })}
                  >
                    <Tag type={item.value === "BL" ? "error" : "grey"}>
                      {nameStatusAtem("BL")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "FT" })}
                  >
                    <Tag type={item.value === "FT" ? "success" : "grey"}>
                      {nameStatusAtem("FT")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "LIB" })}
                  >
                    <Tag type={item.value === "LIB" ? "warning" : "grey"}>
                      {nameStatusAtem("LIB")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "EST" })}
                  >
                    <Tag type={item.value === "EST" ? "black" : "grey"}>
                      {nameStatusAtem("EST")}
                    </Tag>
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase
                    onClick={() => applyValue({ ...item, value: "DEV" })}
                  >
                    <Tag
                      type={item.value === "DEV" ? "middle-warning" : "grey"}
                    >
                      {nameStatusAtem("DEV")}
                    </Tag>
                  </ButtonBase>
                </Grid>
              </Grid>
            );
          },
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
              !filterItem.columnField ||
              !filterItem.value ||
              !filterItem.operatorValue
            ) {
              return null;
            }

            return (params: GridCellParams): boolean => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
      // sortComparator: (v1, v2, param1, param2) =>
      //   getNumberStatusAtem(param1.value as StatusAtem) -
      //   getNumberStatusAtem(param2.value as StatusAtem),
    },
    {
      field: "productName",
      headerName: "Produto",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "quantity",
      headerName: "Quantidade",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        new Intl.NumberFormat("pt-BR").format(
          props.getValue(props.id, props.field) as number
        ),
    },
    {
      field: "deliveryDate",
      headerName: "Data de entrega",
      width: 250,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                manausTZ
              ),
              "dd/MM/yyyy"
            )
          : "",
    },
    {
      field: "numberOrder",
      headerName: "Ordem de venda",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "filialID",
      headerName: "Filial",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deposit",
      headerName: "Deposito",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "hourDeliveryDate",
      headerName: "Hora Fat",
      width: 250,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (props) => props.getValue(props.id, "deliveryDate"),
      valueFormatter: (props) =>
        props.getValue(props.id, props.field) &&
        props.getValue(props.id, "statusAtem") === "FT"
          ? formatTZ(
              utcToZonedTime(
                props.getValue(props.id, props.field) as Date,
                manausTZ
              ),
              "HH:mm"
            )
          : "",
    },
    {
      field: "freightType",
      headerName: "Frete",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "condPag",
      headerName: "Cond. Pag.",
      width: 180,
      headerAlign: "center",
      align: "center",
      valueGetter: (props) => ({
        payment: props.getValue(props.id, "payment"),
        payCond: props.getValue(props.id, "payCond"),
      }),
      valueFormatter: (props) => {
        const field = props.getValue(props.id, props.field) as {
          payment: number;
          payCond: number;
        };
        return `${field.payment}-${field.payCond}`;
      },
    },
    {
      field: "price",
      headerName: "Vl. unitário",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatCurrencyPriceProduct(
          props.getValue(props.id, props.field) as number
        ),
    },
    {
      field: "totalPrice",
      headerName: "Vl. total",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
    },
    {
      field: "margemTotal",
      headerName: "Margem Total Est",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "margemLitro",
      headerName: "Margem Litro Est",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "margemTotalPB",
      headerName: "Margem Total PB",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "margemLitroPB",
      headerName: "Margem Litro PB",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "margemTotalPortal",
      headerName: "Margem Total Portal",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "margemLitroPortal",
      headerName: "Margem Litro Portal",
      width: 150,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.getValue(props.id, props.field)
          ? formatCurrency(props.getValue(props.id, props.field) as number)
          : "-",
    },
    {
      field: "NFId",
      headerName: "Num. NF",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  return formQueryContext.dataQuery &&
    formQueryContext.dataQuery.content.length === 0 ? (
    <NoData>Não foram encontrados resultados</NoData>
  ) : (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Paper style={{ margin: "1rem 0" }}>
        <Grid
          container
          justify="space-around"
          style={{ padding: formQueryContext.loadingAggregators ? "1rem" : 0 }}
        >
          {formQueryContext.loadingAggregators ? (
            <CircularProgress
              color="primary"
              style={{ width: "2.5rem", height: "2.5rem" }}
            />
          ) : (
            formQueryContext.ordersAggregators.map((aggregator) => {
              return (
                <Grid item key={aggregator.name}>
                  <Tag
                    className={classes.tagSpacing}
                    type={
                      aggregator.name === "LIB"
                        ? "warning"
                        : aggregator.name === "FT"
                        ? "success"
                        : aggregator.name === "EST"
                        ? "black"
                        : aggregator.name === "DEV"
                        ? "middle-warning"
                        : "error"
                    }
                  >
                    {aggregator.name}: {formatDecimal(aggregator.value)} /{" "}
                    {formatCurrency(aggregator.total)}
                  </Tag>
                </Grid>
              );
            })
          )}
        </Grid>
      </Paper>
      <div style={{ height: isMobile ? 600 : "100%" }}>
        <DataGrid
          columns={columns}
          loading={formQueryContext.loadingQuery}
          rows={
            formQueryContext.dataQuery ? formQueryContext.dataQuery.content : []
          }
          rowCount={formQueryContext.dataQuery?.total}
          pageSize={formQueryContext.query.limit}
          page={formQueryContext.query.page - 1}
          pagination
          paginationMode="server"
          onPageSizeChange={(pageSize) => {
            formQueryContext.setQuery((state) => ({
              ...state,
              limit: pageSize,
            }));
          }}
          onPageChange={(newPage) => {
            formQueryContext.setQuery((state) => ({
              ...state,
              page: newPage + 1,
            }));
          }}
          filterModel={formQueryContext.filterModel}
        />
      </div>
    </div>
  );
};

export default TableOrders;
