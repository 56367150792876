import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ICancelOrderService } from "../models/ICancelOrderService";

@injectable()
export class CancelOrderService implements ICancelOrderService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(numberOrder: string) {
    await this.httpInstance.delete("/orders", {
      params: {
        numberOrder,
      },
    });
  }
}
