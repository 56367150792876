import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IFinancialChartFilters } from "@pages/Financial/FinancialDashboard/interface";
import { format } from "date-fns";
import { inject, injectable } from "inversify";
import { IFinancialDashboardChartDTO } from "../dtos/IFinancialDashboardChartDTO";
import { StatusDoc } from "../dtos/IFinancialInstallmentsDTO";
import { IChartDataService } from "../models/IChartDataService";

@injectable()
export class ChartDataService implements IChartDataService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  private mountParams(data: IFinancialChartFilters) {
    let filters = "";
    const timeFrom = data.from + "T04:00:00.000Z";
    const timeTo = data.to + "T03:59:59.999Z";

    if (data.advisorID.length) {
      filters += `advisorID=${data.advisorID.map((item) => `'${item.bpID}'`)}&`;
    }

    if (data.managerID.length) {
      filters += `managerID=${data.managerID.map(
        (manager) => `'${manager.id}'`
      )}&`;
    }

    if (data.groupCustomer.length) {
      filters += `customerGroupID=${data.groupCustomer.map(
        (group) => `'${group.id}'`
      )}&`;
    }

    if (data.documentType.length) {
      filters += `documentType=${data.documentType.map(
        (type) => `'${type.id}'`
      )}&`;
    }

    if (data.paymentMethod.length) {
      filters += `paymentForm=${data.paymentMethod.map(
        (method) => `'${method.id}'`
      )}&`;
    }

    return { timeFrom, timeTo, filters };
  }

  private dateParamsDebitRate() {
    const currentTime = new Date();
    const beforeMonth = format(currentTime, "yyyy-MM-dd");
    const nextDay = format(
      new Date(currentTime.setDate(currentTime.getDate() + 1)),
      "yyyy-MM-dd"
    );

    const timeFrom = beforeMonth + "T04:00:00.000Z";
    const timeTo = nextDay + "T03:59:59.999Z";

    return { timeFrom, timeTo };
  }

  private dateParamsExpiredRate() {
    const currentTime = new Date();
    const currentDay = format(currentTime, "yyyy-MM-dd");
    const beforeMonth = format(
      new Date(currentTime.setMonth(currentTime.getMonth() - 1)),
      "yyyy-MM-dd"
    );

    const timeFrom = beforeMonth + "T04:00:00.000Z";
    const timeTo = currentDay + "T03:59:59.999Z";

    return { timeFrom, timeTo };
  }

  private dateParamsNotExpiredRate() {
    const currentTime = new Date();
    const beforeMonth = format(currentTime, "yyyy-MM-dd");
    const nextMonth = format(
      new Date(currentTime.setMonth(currentTime.getMonth() + 1)),
      "yyyy-MM-dd"
    );

    const timeFrom = beforeMonth + "T04:00:00.000Z";
    const timeTo = nextMonth + "T03:59:59.999Z";

    return { timeFrom, timeTo };
  }

  public async getAdvisorChart(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/advisor_due?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getMangerChart(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/manager_due?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getTotalByMonth(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/total_due?from='${timeFrom}'&to='${timeTo}'&${filters}`,
      {
        params: {
          groupPeriodBy: "month",
        },
      }
    );
  }

  public async getOverallDelayRate(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/overall_delay_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getRankingOfDebtorCustomers(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/customer_due?from='${timeFrom}'&to='${timeTo}'&${filters}`,
      {
        params: {
          limit: "10",
        },
      }
    );
  }

  public async getDebitSegment(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/segment_due?from='${timeFrom}'&to='${timeTo}'&${filters}`,
      {
        params: {
          limit: "6",
        },
      }
    );
  }

  public async getCustomerGroup(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/customer_group_due?from='${timeFrom}'&to='${timeTo}'&${filters}`,
      {
        params: {
          limit: "10",
        },
      }
    );
  }

  public async getDebitRate(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { filters } = this.mountParams(data);
    let { timeFrom, timeTo } = this.dateParamsDebitRate();

    return this.httpInstance.get(
      `/financialDocs/dashboard/deliquency_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getExpiredRate(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { filters } = this.mountParams(data);
    let { timeFrom, timeTo } = this.dateParamsExpiredRate();

    return this.httpInstance.get(
      `/financialDocs/dashboard/deliquency_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getNotExpiredRate(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { filters } = this.mountParams(data);
    let { timeFrom, timeTo } = this.dateParamsNotExpiredRate();

    return this.httpInstance.get(
      `/financialDocs/dashboard/deliquency_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getAccumlulated(
    data: IFinancialChartFilters
  ): Promise<IFinancialDashboardChartDTO> {
    const { timeFrom, timeTo, filters } = this.mountParams(data);

    return this.httpInstance.get(
      `/financialDocs/dashboard/deliquency_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`
    );
  }

  public async getTotalsDetails(
    data: IFinancialChartFilters,
    type: StatusDoc
  ): Promise<IFinancialDashboardChartDTO> {
    let { filters, timeFrom, timeTo } = this.mountParams(data);

    if (type === "CLOSE") {
      timeFrom = this.dateParamsDebitRate().timeFrom;
      timeTo = this.dateParamsDebitRate().timeTo;
    }

    if (type === "EXPIRED") {
      timeFrom = this.dateParamsExpiredRate().timeFrom;
      timeTo = this.dateParamsExpiredRate().timeTo;
    }

    if (type === "NOT_EXPIRED") {
      timeFrom = this.dateParamsNotExpiredRate().timeFrom;
      timeTo = this.dateParamsNotExpiredRate().timeTo;
    }

    return this.httpInstance.get(
      `/financialDocs/dashboard/deliquency_rate?from='${timeFrom}'&to='${timeTo}'&${filters}`,
      {
        params: {
          groupByCustomer: true,
        },
      }
    );
  }
}
