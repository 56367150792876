import { UserAccessesPage } from "@pages/Admin/UserAccesses";
import { AppointmentMarketingPlan } from "@pages/Appointment/AppointmentMarketingPlan";
import { AppointmentPrice } from "@pages/Appointment/AppointmentPrice";
import { AppointmentPriceCustomer } from "@pages/Appointment/AppointmentPriceCustomer";
import { AppointmentSales } from "@pages/Appointment/AppointmentSales";
import { AppointmentWallet } from "@pages/Appointment/AppointmentWallet";
import { ChangePasswordPage } from "@pages/ChangePassword";
import { ActiveCostsPage } from "@pages/Costs/ActiveCosts";
import { HistoryCostsPage } from "@pages/Costs/HistoryCosts";
import { SimulationCostsPage } from "@pages/Costs/SimulationCosts";
import { HomePage } from "@pages/Home";
import { ActiveSettingsCostsPage } from "@pages/Costs/ActiveSettingsCosts";
import React from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import ROUTES from "../../config/routes";
import AppointmentInvoices from "../../pages/Appointment/AppointmentInvoices";
import AppointmentTitle from "../../pages/Appointment/AppointmentTitle";
import CallCenters from "../../pages/CallCenters";
import { HistorySettingsCostsPage } from "@pages/Costs/HistorySettingsCosts";
import { AppointmentSalesMargin } from "@pages/Costs/AppointmentSales";
import { FinancialDashboard } from "@pages/Financial/FinancialDashboard";
import { FinancialInstallments } from "@pages/Financial/FinancialInstallments";
import { QuoteSalePage } from "@pages/Home/QuoteSale";
import Welcome from "@pages/Welcome";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path={ROUTES.USER_ROUTES.HOME} exact component={Welcome} />
        <Route path={ROUTES.USER_ROUTES.DASHBOARD} exact component={HomePage} />

        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
          component={AppointmentTitle}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_INVOICES}
          component={AppointmentInvoices}
        />
        <Route path={ROUTES.USER_ROUTES.CALL_CENTERS} component={CallCenters} />
        <Route
          path={ROUTES.USER_ROUTES.CHANGE_PASSWORD}
          component={ChangePasswordPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_PRICE}
          component={AppointmentPrice}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_SALES}
          component={AppointmentSales}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_WALLET}
          component={AppointmentWallet}
        />
        <Route
          path={ROUTES.USER_ROUTES.ATIVE_COSTS}
          component={ActiveCostsPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.HISTORY_COSTS}
          component={HistoryCostsPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER}
          component={AppointmentPriceCustomer}
        />
        <Route
          path={ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN}
          component={AppointmentMarketingPlan}
        />
        <Route
          path={ROUTES.USER_ROUTES.SIMULATION_COSTS}
          component={SimulationCostsPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.SETTINGS_COSTS}
          component={ActiveSettingsCostsPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS}
          component={HistorySettingsCostsPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.SALES_MARGIN}
          component={AppointmentSalesMargin}
        />
        <Route
          path={ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
          component={UserAccessesPage}
        />
        <Route
          path={ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD}
          component={FinancialDashboard}
        />
        <Route
          path={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
          component={FinancialInstallments}
        />
        <Route path={ROUTES.USER_ROUTES.QUOTE_SALE} component={QuoteSalePage} />

        <Redirect path="*" to={ROUTES.USER_ROUTES.HOME} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
