import { BoxInfo } from "@components/BoxInfo";
import { Slider } from "@components/Slider";
import { SlideItem } from "@components/Slider/SlideItem";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { FetchData, useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import TableChartIcon from "@material-ui/icons/TableChart";
import {
  AssessorVolumeResponse,
  IGetChartDataService,
} from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { formatCurrency, formatPercent } from "@utils/index";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import moment from "moment";
import {
  TypeColumnDialog,
  SetColumnHeaderTable,
} from "@utils/setColumnHeaderTable";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      gridBox: {
        margin: "0.5rem 0",
        overflowX: "hidden",
        flexDirection: "column",
        "& > div": { padding: "1rem" },
      },
      paperDialog: { [theme.breakpoints.up("sm")]: { minWidth: "85vw" } },
      tableContainer: {
        height: "100%",
        [theme.breakpoints.up("sm")]: { height: "50vh" },
      },
      marginPositive: { color: theme.palette.success.main },
      marginNegative: { color: theme.palette.error.main },
    })
);

interface DialogTableProps {
  data: FetchData<AssessorVolumeResponse>;
  open: boolean;
  onClose: () => void;
  from: string;
  to: string;
}

const DialogTable: React.FC<DialogTableProps> = ({
  open,
  onClose,
  data,
  from,
  to,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const tableData = useMemo(() => {
    if (!data.value) return [];
    const advisorNameIdx = data.value.columns.findIndex(
      (column) => column.name === "advisorName"
    );
    const advisorID = data.value.columns.findIndex(
      (column) => column.name === "advisorID"
    );
    const advisorVolumeIdx = data.value.columns.findIndex(
      (column) => column.name === "quantity"
    );
    const advisorMarginIdx = data.value.columns.findIndex(
      (column) => column.name === "litterMargin"
    );
    const quantityPreviousMonthIdx = data.value.columns.findIndex(
      (column) => column.name === "quantityPreviousMonth"
    );
    const marginPreviousMonthIdx = data.value.columns.findIndex(
      (column) => column.name === "litterMarginPreviousMonth"
    );
    const marginPreviousYearIdx = data.value.columns.findIndex(
      (column) => column.name === "litterMarginPreviousYear"
    );
    const quantityPreviousYearIdx = data.value.columns.findIndex(
      (column) => column.name === "quantityPreviousYear"
    );

    return data.value.rows.map((value) => {
      return {
        id: value[advisorID],
        name: value[advisorNameIdx],
        value: value[advisorVolumeIdx],
        margin: value[advisorMarginIdx],
        quantityPreviousMonth: value[quantityPreviousMonthIdx],
        marginPreviousMonth: value[marginPreviousMonthIdx],
        quantityPreviousYear: value[quantityPreviousYearIdx],
        marginPreviousYear: value[marginPreviousYearIdx],
        performancePreviousMonth: SetColumnHeaderTable.setPercentual(
          value[advisorVolumeIdx] as number,
          value[quantityPreviousMonthIdx] as number
        ),
        performancePreviousYear: SetColumnHeaderTable.setPercentual(
          value[advisorVolumeIdx] as number,
          value[quantityPreviousYearIdx] as number
        ),
      };
    });
  }, [data.value]);

  const flagYearSameOrAfter2022 =
    moment(from).isSameOrAfter(moment("2022-01-01"), "years") &&
    moment(to).isSameOrAfter(moment("2022-01-01"), "years");

  const flagFromToSameMonth = moment(from).isSame(
    moment(moment(to).subtract(1, "days")),
    "months"
  );

  const columns: GridColDef[] =
    flagYearSameOrAfter2022 && flagFromToSameMonth
      ? [
          {
            field: "id",
            headerName: "Bp",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "name",
            headerName: "Assessor",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "margin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousMonth",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_MONTH
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "performancePreviousMonth",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_MONTH
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "performancePreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          // {
          //   field: "marginPreviousMonth",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_MONTH
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
          // {
          //   field: "marginPreviousYear",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_YEAR
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
        ]
      : flagYearSameOrAfter2022 && !flagFromToSameMonth
      ? [
          {
            field: "id",
            headerName: "Bp",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "name",
            headerName: "Assessor",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "margin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "performancePreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          // {
          //   field: "marginPreviousYear",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_YEAR
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
        ]
      : [
          {
            field: "id",
            headerName: "Bp",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "name",
            headerName: "Assessor",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "margin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
        ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      classes={{ paper: classes.paperDialog }}
    >
      <DialogTitle>Assessores</DialogTitle>
      <DialogContent>
        <DialogContentText>V = Volume</DialogContentText>
        <DialogContentText>M = Margem por litro</DialogContentText>
        <DialogContentText>
          D = Desempenho relacionado do período selecionado com anterior
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <div className={classes.tableContainer}>
          <DataGrid
            columns={columns}
            rows={tableData}
            disableSelectionOnClick
            rowsPerPageOptions={[100]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface ProductGroupDataWithLitterMargin {
  [propName: string]: { value: number; litterMargin: number };
}

const AssessorBoxes: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const iocContext = useIoCContext();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () => getDataChartService.getAssessorVolume({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const [openDialog, setOpenDialog] = useState(false);

  const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];

  return (
    <Grid container justify="space-evenly" className={classes.gridBox}>
      <Slider showMore={!isMobile} slideQuantity={4} disableAutoPass>
        {fetchData.value &&
          fetchData.value.rows.map((assessor, idx) => {
            return (
              <SlideItem key={idx} index={idx}>
                <BoxInfo
                  colorBoxIcon={colors[idx % colors.length]}
                  title={assessor[1]}
                  value={new Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 0,
                  }).format(assessor[2])}
                  margin={assessor[3]}
                  icon={<AccountCircle style={{ color: "#fff" }} />}
                  loading={fetchData.loading}
                />
              </SlideItem>
            );
          })}
      </Slider>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Chip
          label="Ver Tabela"
          size="small"
          color="primary"
          variant="outlined"
          icon={<TableChartIcon />}
          onClick={() => setOpenDialog(true)}
        />
        <DialogTable
          data={fetchData}
          onClose={() => setOpenDialog(false)}
          open={openDialog}
          from={from}
          to={to}
        />
      </div>
    </Grid>
  );
};

export { AssessorBoxes };
