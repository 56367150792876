import Layout from "@components/Layout";
import Title from "@components/Title";
import React from "react";
import { FormQuerySimulation } from "./FormQuery";
import { SimulationCostsProvider } from "./SimulationCostsContext";
import { TableCost } from "./TableCost";

const Container: React.FC = () => {
  return (
    <Layout enableMargins>
      <Title>Simular preços</Title>
      <FormQuerySimulation />
      <TableCost />
    </Layout>
  );
};

const SimulationCostsPage: React.FC = () => {
  return (
    <SimulationCostsProvider>
      <Container />
    </SimulationCostsProvider>
  );
};

export { SimulationCostsPage };
