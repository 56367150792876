import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IRemoveLoginImageService } from "../models/IRemoveLoginImageService";

@injectable()
export class RemoveLoginImageService implements IRemoveLoginImageService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(imageID: string): Promise<{ message: string }> {
    return this.httpInstance.delete<{ message: string }>(
      `/login-images/${imageID}`
    );
  }
}
