import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IQueryOrderDataDTO } from "../dtos/IQueryOrderDataDTO";
import { IQueryOrdersDTO } from "../dtos/IQueryOrdersDTO";
import { IQueryOrdersService } from "../models/IQueryOrdersService";

@injectable()
export class QueryOrdersService implements IQueryOrdersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IQueryOrdersDTO) {
    const orders = await this.httpInstance.get<IQueryOrderDataDTO[]>(
      "/orders",
      {
        params: data,
      }
    );

    return orders.map((order) => {
      return {
        ...order,
        deliveryDate: getCorrectDate(new Date(order.deliveryDate)),
        implantationDate: getCorrectDate(new Date(order.implantationDate)),
      };
    });
  }
}
