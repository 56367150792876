import AdvisorChart from "@components/FinancialDashboardCharts/AdvisorChart";
import CustomerGroupChart from "@components/FinancialDashboardCharts/CustomerGroupChart";
import DebitSegmentChart from "@components/FinancialDashboardCharts/DebitSegmentChart";
import DeliquencyRateChart from "@components/FinancialDashboardCharts/DeliquencyRateChart";
import ManagerChart from "@components/FinancialDashboardCharts/ManagerChart";
import OverallDelayRateChart from "@components/FinancialDashboardCharts/OverallDelayRateChart";
import RankingOfDebtorCustomersChart from "@components/FinancialDashboardCharts/RankingOfDebtorCustomersChart";
import TotalByMonthChart from "@components/FinancialDashboardCharts/TotalByMonthChart";
import { ChartContainer } from "@components/NewChartContainer";
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useFinancialDashboardContext } from "./FinancialDashboardContext";

const useStyles = makeStyles({
  chartContainer: {
    height: "70vh",
  },
});
const FinancialCharts: React.FC = () => {
  const classes = useStyles();
  const { query, loaders } = useFinancialDashboardContext();

  return (
    <>
      <Grid item xs={12} md={12}>
        <DeliquencyRateChart filters={query} />
      </Grid>
      <Grid item xs={12} md={6} className={classes.chartContainer}>
        <ChartContainer
          title="Partidas vencidas"
          loading={loaders.isAdvisorChartLoading && loaders.isFirstLoading}
        >
          <AdvisorChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} md={3} className={classes.chartContainer}>
        <ChartContainer
          title="Grupo de Cliente"
          loading={
            loaders.isCustomerGroupChartLoading && loaders.isFirstLoading
          }
        >
          <CustomerGroupChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid item xs={12} md={3} className={classes.chartContainer}>
        <ChartContainer
          title="Débito por Segmento"
          loading={loaders.isDebitSegmentChartLoading && loaders.isFirstLoading}
        >
          <DebitSegmentChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={classes.chartContainer}
        style={{ marginTop: "60px" }}
      >
        <ChartContainer
          title="Gerência"
          loading={loaders.isManagerChartLoading && loaders.isFirstLoading}
        >
          <ManagerChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={classes.chartContainer}
        style={{ marginTop: "60px" }}
      >
        <ChartContainer
          title="Débito por mês"
          loading={loaders.isTotalByMonthChartLoading && loaders.isFirstLoading}
        >
          <TotalByMonthChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={classes.chartContainer}
        style={{ marginTop: "60px" }}
      >
        <ChartContainer
          title="Ranking de clientes devedores"
          loading={
            loaders.isRankingOfDebtorCustomersChartLoading &&
            loaders.isFirstLoading
          }
        >
          <RankingOfDebtorCustomersChart filters={query} />
        </ChartContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={classes.chartContainer}
        style={{ marginTop: "60px" }}
      >
        <ChartContainer
          title="Índice de atraso geral"
          loading={
            loaders.isOverallDelayRateChartLoading && loaders.isFirstLoading
          }
        >
          <OverallDelayRateChart filters={query} />
        </ChartContainer>
      </Grid>
    </>
  );
};

export { FinancialCharts };
