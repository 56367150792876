import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { fileNameByHeaders, getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { IQuerySalesAnalyticsDTO } from "../dtos/IQuerySalesAnalyticsDTO";
import { IDownloadExcelSalesAnalyticsService } from "../models/IDownloadExcelSalesAnalyticsService";

@injectable()
export class DownloadExcelSalesAnalyticsService
  implements IDownloadExcelSalesAnalyticsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQuerySalesAnalyticsDTO
  ): Promise<[string, string | null]> {
    const { data: excelFile, headers } = await this.httpInstance.getWithHeaders<
      BinaryType
    >("/reports/campaignAnalytics", {
      params: {
        from: getCorrectDate(data.beginDate),
        to: getCorrectDate(data.endDate),
        partnerID: data.partnerID,
        companies: data.documents?.join(","),
        categories: data.categories?.join(","),
      },
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(
      new Blob([excelFile], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    return [url, fileNameByHeaders(headers)];
  }
}
