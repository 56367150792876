import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICnpjDTO } from "../dtos/ICnpjDTO";
import { IGetWalletAssessorService } from "../models/IGetWalletAssessorService";

@injectable()
export class GetWalletAssessorService implements IGetWalletAssessorService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(partnerID: string): Promise<ICnpjDTO[]> {
    const response = await this.httpInstance.get<ICnpjDTO[]>("/customer", {
      params: {
        partnerID,
      },
    });

    return response.map((customer) => {
      return {
        ...customer,
        CNPJ: customer.customerCNPJ,
        companyName: customer.customerName,
        first_nf: customer.first_nf ? new Date(customer.first_nf) : null,
        last_nf: customer.last_nf ? new Date(customer.last_nf) : null,
        totalCredit: Number(customer.totalCredit),
        usedLimit: Number(customer.usedLimit),
        createdAt: new Date(customer.createdAt),
      };
    });
  }
}
