import { formatISO } from "date-fns";
import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetHistoryActiveSettingsCostsDTO } from "../dtos/IGetHistoryActiveSettingsCostsDTO";
import {
  IGetHistoryActiveSettingsCostsParams,
  IGetHistoryActiveSettingsCostsService,
} from "../models/IGetHistoryActiveSettingsCostsService";

@injectable()
export class GetHistoryActiveSettingsCostsService
  implements IGetHistoryActiveSettingsCostsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IGetHistoryActiveSettingsCostsParams
  ): Promise<IGetHistoryActiveSettingsCostsDTO> {
    const resp = await this.httpInstance.get<IGetHistoryActiveSettingsCostsDTO>(
      "/settings-costs",
      {
        params: {
          ...data,
          from: formatISO(data.from, { representation: "date" }),
          to: formatISO(data.to, { representation: "date" }),
          action: "all",
        },
      }
    );

    resp.content = resp.content.map((cost) => {
      return {
        ...cost,
        createdAt: new Date(cost.createdAt),
        updatedAt: new Date(cost.updatedAt),
        deletedAt: cost.deletedAt ? new Date(cost.deletedAt) : null,
        start_at: new Date(cost.start_at),
        end_at: cost.end_at ? new Date(cost.end_at) : null,
      };
    });

    return resp;
  }
}
