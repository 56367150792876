import Layout from "@components/Layout";
import ROUTES from "@config/routes";
import { useUserState } from "@context/UserContext";
import { Button, Grid, Typography } from "@material-ui/core";
import { HomeDashboardProvider } from "@pages/Home/HomeDashboardContext";
import React from "react";
import { useHistory } from "react-router-dom";

const Container = () => {
  const userState = useUserState();
  const history = useHistory();

  return (
    <Layout disableFooter enableMargins fullHeight={false}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{ margin: "10rem 0" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "2.4rem",
                marginBottom: 10,
                flexWrap: "wrap",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Olá <b style={{ marginLeft: 4 }}>{userState.state.username}</b>,
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "2.4rem",
                  marginLeft: 4,
                }}
              >
                seja bem-vindo(a) ao portal de vendas.
              </Typography>
            </Typography>

            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "2.4rem",
                textAlign: "center",
              }}
            >
              Para acessar o dashboard
            </Typography>
            <Button
              style={{ marginLeft: 4 }}
              variant="contained"
              color="primary"
              onClick={() => history.push(ROUTES.USER_ROUTES.DASHBOARD)}
            >
              clique aqui
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

const Welcome: React.FC = () => {
  return (
    <HomeDashboardProvider>
      <Container />
    </HomeDashboardProvider>
  );
};

export default Welcome;
