import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IDocumentTypeDTO } from "../dtos/IDocumentTypeDTO";
import { IGroupCustomerFilterDTO } from "../dtos/IGroupCustomerFilterDTO";
import { IManagerFilterDTO } from "../dtos/IManagerFilterDTO";
import { IMonthFilterDTO } from "../dtos/IMonthFilterDTO";
import { IPaymentMethodFilterDTO } from "../dtos/IPaymentMethodFilterDTO";
import { ITermFilterDTO } from "../dtos/ITermFilterDTO";
import { IYearFilterDTO } from "../dtos/IYearFilterDTO";
import { IFinancialFiltersService } from "../models/IFinancialFiltersService";

@injectable()
export class FinancialFiltersService implements IFinancialFiltersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getGroupCustomerFilter(): Promise<IGroupCustomerFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "groupCustomer",
      },
    });
  }

  public async getManagerFilter(): Promise<IManagerFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "manager",
      },
    });
  }

  public async getTypeDocumentFilter(): Promise<IDocumentTypeDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "docType",
      },
    });
  }

  public async getPaymentMethodFilter(): Promise<IPaymentMethodFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "paymentMethod",
      },
    });
  }
  public async getYearFilter(): Promise<IYearFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "year",
      },
    });
  }

  public async getMonthFilter(): Promise<IMonthFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "month",
      },
    });
  }

  public async getTermFilter(): Promise<ITermFilterDTO[]> {
    return this.httpInstance.get("/dashboard/filter", {
      params: {
        action: "term",
      },
    });
  }
}
