import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import {
  IQueryOrdersByStatusDTO,
  IResultOrdersByStatusDTO,
} from "../dtos/IQueryOrdersByStatusDTO";
import { IQueryOrdersByStatus } from "../models/IQueryOrdersByStatus";

@injectable()
export class QueryOrdersByStatus implements IQueryOrdersByStatus {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute({
    page = 1,
    limit = 50,
    ...data
  }: IQueryOrdersByStatusDTO): Promise<IResultOrdersByStatusDTO> {
    const resp = await this.httpInstance.get<IResultOrdersByStatusDTO>(
      "/orders/status",
      {
        params: {
          beginDate: getCorrectDate(data.beginDate),
          endDate: getCorrectDate(data.endDate),
          managerID: data.managerID?.join(","),
          advisorID: data.advisorID?.join(","),
          customerIDs: data.customerID?.join(","),
          page,
          limit,
          status: data.status,
        },
      }
    );

    resp.content = resp.content.map((order) => {
      return {
        ...order,
        deliveryDate: new Date(order.deliveryDate),
        implantationDate: new Date(order.implantationDate),
      };
    });

    return resp;
  }
}
