import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IQueryInvoicesDataDTO } from "../dtos/IQueryInvoicesDataDTO";
import { IQueryInvoicesDTO } from "../dtos/IQueryInvoicesDTO";
import { IQueryInvoicesService } from "../models/IQueryInvoicesService";

@injectable()
export class QueryInvoicesService implements IQueryInvoicesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: IQueryInvoicesDTO
  ): Promise<IQueryInvoicesDataDTO[]> {
    const invoices = await this.httpInstance.get<IQueryInvoicesDataDTO[]>(
      "/invoices",
      {
        params: data,
      }
    );

    return invoices.map((invoice) => {
      return {
        ...invoice,
        emissionDate: new Date(invoice.emissionDate),
      };
    });
  }
}
