import * as Yup from "yup";

const FormQueryValidationSchema = Yup.object().shape({
  CNPJ: Yup.array().of(Yup.object()).required("Selecione um CNPJ"),
  situation: Yup.string()
    .oneOf(["open", "closed", "all"], "Selecione uma das 3 opções")
    .required("Selecione uma situação"),
  filterBy: Yup.string()
    .oneOf(["emissionDate", "dueDate"], "Selecione uma das 2 opções")
    .required("Selecione uma ordenação"),
  startDate: Yup.date()
    .nullable()
    .typeError("Digite uma data válida")
    .required("Selecione a data de ínicio"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "A data final não pode ser anterior a inicial")
    .typeError("Digite uma data válida")
    .nullable()
    .required("Selecione a data final"),
});

export default FormQueryValidationSchema;
