import { CircularProgress, styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyle = makeStyles({
  root: {
    display: "flex",
  },
  loadingCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: 8,
    width: 295,
    height: 135,
  },
  totalCard: {
    background: "white",
    borderRadius: 8,
    paddingTop: 25,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: 400,
    "& button": {
      outline: "none",
      display: "flex",
      justifyContent: "flex-end",
      fontFamily: "Open Sans",
      fontWeight: 700,
      fontSize: 12,
      color: "#D91F05",
      marginTop: 22,
      background: "#fff",
      border: 0,
      cursor: "pointer",
    },
    "& button:disabled": {
      color: "#B0C1E1",
      cursor: "not-allowed",
    },
  },
  disabledButton: {
    color: "#313B45",
    cursor: "none",
  },
  content: {
    display: "flex",
  },
  values: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    "& span:first-child": {
      color: "#313B45",
      fontFamily: "Noto Sans",
      fontWeight: 700,
      fontSize: 24,
    },
    "& span:last-child": {
      color: "#B0C1E1",
      fontFamily: "Open Sans, Roboto",
      fontWeight: 600,
      fontSize: 14,
    },
  },
});
interface IconProps {
  color?: string;
  background?: string;
  borderColor?: string;
}
const IconContainer = styled("div")((props: IconProps) => ({
  height: 52,
  width: 52,
  borderRadius: 4,
  backgroundColor: props.background,
  border: `1px solid ${props.borderColor}`,
  color: props.borderColor,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

interface TotalCardProps {
  value?: string;
  showValue?: boolean;
  description?: string;
  icon?: React.ReactNode;
  bgColor?: string;
  color?: string;
  loading?: boolean;
  onSeeDetailsClick?: React.MouseEventHandler<HTMLButtonElement>;
}
const TotalCard: React.FC<TotalCardProps> = (props) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      {props.loading ? (
        <div className={classes.loadingCard}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.totalCard}>
          <div className={classes.content}>
            <IconContainer background={props.bgColor} borderColor={props.color}>
              {props.icon}
            </IconContainer>
            <div className={classes.values}>
              <span>{props.showValue ? props.value : "-"}</span>
              <span>{props.description}</span>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              onClick={props.onSeeDetailsClick}
              disabled={!props.showValue}
            >
              Ver detalhes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

TotalCard.defaultProps = {
  loading: false,
  bgColor: "#f5f5f5",
  color: "#B0C1E1",
};

export default TotalCard;
