import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { LinearProgress } from "@material-ui/core";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import {
  HomeDashboardFilters,
  useHomeDashboard,
} from "@pages/Home/HomeDashboardContext";
import { ApexOptions } from "apexcharts";
import { differenceInMonths, format, parseISO } from "date-fns";
import groupby from "lodash.groupby";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";

const AssessorVolumeByDay: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const iocContext = useIoCContext();
  const { dateCharts } = useHomeDashboard();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchDataForMonthly = useFetchData(
    () => getDataChartService.getAssessorVolumeByDay({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchDataForPeriod = useFetchData(
    () =>
      getDataChartService.getAssessorVolumeByMonth({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  const renderMonth = useMemo(
    () =>
      Math.abs(
        differenceInMonths(
          dateCharts[dateCharts.selected].rawFrom,
          dateCharts[dateCharts.selected].rawEnd
        )
      ) >= 2,
    [dateCharts]
  );

  const dates = useMemo(() => {
    if (renderMonth === false) {
      if (!fetchDataForMonthly.value) {
        return [];
      }

      const dateIdx = fetchDataForMonthly.value!.columns.findIndex(
        (row) => row.name === "day"
      );

      const grouped = groupby(
        fetchDataForMonthly.value.rows,
        (row) => row[dateIdx]
      );

      return Object.keys(grouped)
        .map((key) => key)
        .reverse();
    }
    if (renderMonth) {
      if (!fetchDataForPeriod.value) {
        return [];
      }

      const dateIdx = fetchDataForPeriod.value!.columns.findIndex(
        (row) => row.name === "month"
      );

      const grouped = groupby(
        fetchDataForPeriod.value.rows,
        (row) => row[dateIdx]
      );

      return Object.keys(grouped)
        .map((key) => key)
        .reverse();
    }
    return [];
  }, [fetchDataForMonthly.value, fetchDataForPeriod.value, renderMonth]);

  const assessorData = useMemo(() => {
    if (renderMonth === false) {
      if (!fetchDataForMonthly.value) {
        return {};
      }

      const assessorNameIdx = fetchDataForMonthly.value!.columns.findIndex(
        (row) => row.name === "advisorName"
      );

      const grouped = groupby(
        fetchDataForMonthly.value.rows,
        (row) => row[assessorNameIdx]
      );

      Object.keys(grouped).forEach((key) => {
        grouped[key] = grouped[key].reverse();
      });

      return grouped;
    }
    if (renderMonth) {
      if (!fetchDataForPeriod.value) {
        return {};
      }

      const assessorNameIdx = fetchDataForPeriod.value!.columns.findIndex(
        (row) => row.name === "advisorName"
      );

      const grouped = groupby(
        fetchDataForPeriod.value.rows,
        (row) => row[assessorNameIdx]
      );

      Object.keys(grouped).forEach((key) => {
        grouped[key] = grouped[key].reverse();
      });

      return grouped;
    }
    return {};
  }, [fetchDataForMonthly.value, fetchDataForPeriod.value, renderMonth]);

  useEffect(() => {
    setOptions({
      stroke: { curve: "smooth" },
      tooltip: {
        y: {
          formatter: (value) =>
            new Intl.NumberFormat("pt-BR").format(value ? Number(value) : 0),
        },
      },
      xaxis: {
        categories: dates.map((date) =>
          renderMonth
            ? format(parseISO(date), "MM/yy")
            : format(parseISO(date), "dd/MM/yy")
        ),
      },
      // colors: appConfig.chartTheme.fill.colors,
    });
  }, [dates, renderMonth]);

  useEffect(() => {
    if (renderMonth) {
      if (!fetchDataForPeriod.value) return;
      const quantityIdx = fetchDataForPeriod.value!.columns.findIndex(
        (column) => column.name === "quantity"
      );
      setSeries(() =>
        Object.keys(assessorData).map((key) => {
          return {
            data: assessorData[key].map((row) => row[quantityIdx] as number),
            name: key,
          };
        })
      );
    } else {
      if (!fetchDataForMonthly.value) return;
      const quantityIdx = fetchDataForMonthly.value!.columns.findIndex(
        (column) => column.name === "quantity"
      );
      setSeries(() =>
        Object.keys(assessorData).map((key) => {
          return {
            data: assessorData[key].map((row) => row[quantityIdx] as number),
            name: key,
          };
        })
      );
    }
  }, [
    assessorData,
    fetchDataForMonthly.value,
    fetchDataForPeriod.value,
    renderMonth,
  ]);

  return (
    <>
      {fetchDataForMonthly.loading && <LinearProgress />}
      <Chart
        type="line"
        options={options}
        series={series}
        width="100%"
        height="100%"
      />
    </>
  );
};

export { AssessorVolumeByDay };
