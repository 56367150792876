import appConfig from "@config/appConfig";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { LinearProgress, useTheme } from "@material-ui/core";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const AssessorVolume: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const iocContext = useIoCContext();
  const theme = useTheme();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () => getDataChartService.getAssessorVolume({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const [options, setOptions] = useState<ApexOptions>({});
  const [series, setSeries] = useState<ApexAxisChartSeries>([]);

  useEffect(() => {
    if (!fetchData.value) return;
    const idx = fetchData.value!.columns.findIndex(
      (column) => column.name === "advisorName"
    );

    setOptions({
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: { colors: appConfig.barChartTheme.dataLabels.style.colors },
        formatter: (value) =>
          new Intl.NumberFormat("pt-BR").format(Number(value)),
      },
      tooltip: {
        y: {
          formatter: (value) =>
            new Intl.NumberFormat("pt-BR").format(Number(value)),
        },
      },
      xaxis: { categories: fetchData.value!.rows.map((row) => row[idx]) },
      colors: appConfig.chartTheme.fill.colors,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.sm,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: { orientation: "vertical" },
              },
            },
          },
        },
      ],
    });
  }, [fetchData.value, theme.breakpoints.values.sm]);

  useEffect(() => {
    if (!fetchData.value) return;
    setSeries(() => [
      {
        data: fetchData.value!.rows.map((row) => Math.trunc(row[2] / 1000)),
        name: "volume",
      },
    ]);
  }, [fetchData.value]);

  return (
    <>
      {fetchData.loading && <LinearProgress />}
      <Chart
        type="bar"
        options={options}
        series={series}
        width="100%"
        height="100%"
      />
    </>
  );
};

export { AssessorVolume };
