import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IUploadSheetCostsService } from "../models/IUploadSheetCostsService";

@injectable()
export class UploadSheetCostsService implements IUploadSheetCostsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(sheet: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", sheet);
    await this.httpInstance.post("/costs", formData);
  }
}
