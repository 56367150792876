import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      paper: {
        minWidth: "80vw",
        [theme.breakpoints.up("sm")]: { minWidth: "60vw" },
        [theme.breakpoints.up("md")]: { minWidth: "20vw" },
      },
      loading: {
        width: "3rem !important",
        height: "3rem !important",
      },
    })
);

export interface DialogSuccessUploadCostsProps
  extends Omit<DialogProps, "onClose"> {
  onClose(): void;
}

const DialogSuccessUploadCosts: React.FC<DialogSuccessUploadCostsProps> = ({
  children,
  onClose,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.paper }} {...props}>
      <DialogTitle>Planilha enviada com sucesso!</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogSuccessUploadCosts };
