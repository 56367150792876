import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import React from "react";
import { formatCurrency } from "@utils/index";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      negativeValue: { color: theme.palette.primary.main },
      spacingText: { margin: "1.5rem 0" },
      positiveValue: { color: theme.palette.secondary.main },
      spacingTextTwo: { margin: "1.5rem 0" },
      mediumValue: { color: theme.palette.success.main },
      spacingTextThree: { margin: "1.5rem 0" },
    })
);

const AbstractCustomerTable: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const iocContext = useIoCContext();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () =>
      getDataChartService.abstractCustomerData({
        ...filters,
        from: endOfDay(subDays(new Date(), 90)).toISOString(),
        to: startOfDay(new Date()).toISOString(),
      }),
    { useCallbackDeps: [filters], useEffectDeps: [filters] }
  );

  const columns: GridColDef[] = [
    {
      field: "customerID",
      headerName: "BP",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 100 : undefined,
    },
    {
      field: "companyName",
      headerName: "Razão Social",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 350 : undefined,
    },
    {
      field: "segment",
      headerName: "Segmento",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "avgRateSales",
      headerName: "Frequência de Compra",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "lastSaleInDays",
      headerName: "Dias Sem Compra",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "lastSale",
      headerName: "Última Compra",
      type: "date",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        props.value
          ? format(new Date(props.value as string), "dd/MM/yyyy")
          : "",
    },
    {
      field: "sumQuantity",
      headerName: "Volume Total",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "marginLitter",
      headerName: "Margem/L",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
      valueFormatter: (props) =>
        formatCurrency(props.value as number, {
          minimumFractionDigits: 4,
        }),
    },
    {
      field: "countSales",
      headerName: "Dias de Compras",
      type: "number",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 300 : undefined,
    },
  ];

  return (
    <>
      <Typography className={classes.spacingText} variant="h6" align="center">
        Últimos 90 dias - Top Clientes Dias Sem Compra/Frequência de Compra
      </Typography>
      <div style={{ height: "50vh" }}>
        <DataGrid
          columns={columns}
          rows={fetchData.value ? fetchData.value : []}
          loading={fetchData.loading}
          getRowId={(row) => row.customerID}
        />
      </div>
    </>
  );
};

export { AbstractCustomerTable };
