import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IQueryTitlesDataDTO } from "../dtos/IQueryTitlesDataDTO";
import { IQueryTitlesDTO } from "../dtos/IQueryTitlesDTO";
import { IQueryTitlesService } from "../models/IQueryTitlesService";

@injectable()
export class QueryTitlesService implements IQueryTitlesService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: IQueryTitlesDTO): Promise<IQueryTitlesDataDTO[]> {
    const titles = await this.httpInstance.get<IQueryTitlesDataDTO[]>(
      "/titles",
      {
        params: {
          ...data,
          action: "BP",
        },
      }
    );

    titles.sort((a, b) => {
      if (a.situation === "open" || a.situation === "partial") {
        return -1;
      }
      if (b.situation === "closed") {
        return 1;
      }
      return 0;
    });

    return titles.map((title) => {
      return {
        ...title,
        dueDate: new Date(title.dueDate),
        emissionDate: new Date(title.emissionDate),
      };
    });
  }
}
