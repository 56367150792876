import DragInDropBox from "@components/DragInDropBox";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { IUploadSheetSettingsCostsService } from "@modules/settingsCosts/models/IUploadSheetSettingsCostsService";
import AppError from "@utils/AppError";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      paper: {
        minWidth: "80vw",
        [theme.breakpoints.up("sm")]: { minWidth: "60vw" },
        [theme.breakpoints.up("md")]: { minWidth: "40vw" },
      },
      loading: {
        width: "3rem !important",
        height: "3rem !important",
      },
    })
);

export interface DialogUploadSettingsCostsProps
  extends Omit<DialogProps, "onClose"> {
  onClose(): void;
  onSuccess(): void;
}

const DialogUploadSettingsCosts: React.FC<DialogUploadSettingsCostsProps> = ({
  children,
  onSuccess,
  ...props
}) => {
  const classes = useStyles();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const uploadSheetCostsService = iocContext.serviceContainer.get<
    IUploadSheetSettingsCostsService
  >(Types.SettingsCosts.IUploadSheetSettingsCostsService);

  const SUPPORTED_FORMATS = ["application/.*sheet"];
  const yupValidationSend = Yup.object().shape({
    file: Yup.mixed()
      .test("fileType", "Tipo de arquivo não suportado", (value) => {
        if (value) {
          for (let patternStr of SUPPORTED_FORMATS) {
            let pattern = new RegExp(patternStr);
            const test = pattern.test(value.type);
            return test;
          }
        }
        return false;
      })
      .required("Anexe um arquivo válido"),
  });

  return (
    <Dialog classes={{ paper: classes.paper }} {...props}>
      <Formik
        validationSchema={yupValidationSend}
        initialValues={{ file: null }}
        onSubmit={async (values, context) => {
          try {
            await uploadSheetCostsService.execute(
              (values.file as unknown) as File
            );
            if (props.onClose) {
              props.onClose();
              onSuccess();
            }
          } catch (error) {
            if (error instanceof AppError) {
              return enqueueSnackbar(error.message, {
                variant: error.variant,
              });
            }
            enqueueSnackbar(
              "Ocorreu um erro ao enviar planilha, tente novamente.",
              { variant: "error" }
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form>
              <DialogTitle>
                Upload de planilha de configuração de margem
              </DialogTitle>
              <DialogContent>
                <DragInDropBox
                  helperText={errors.file ? errors.file : ""}
                  error={Boolean(touched.file && !!errors.file)}
                  onChange={(file) => setFieldValue("file", file)}
                  onTouch={() => setFieldTouched("file", true)}
                  touched={Boolean(touched.file)}
                  value={values.file}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  startIcon={
                    isSubmitting && (
                      <CircularProgress
                        color="secondary"
                        className={classes.loading}
                      />
                    )
                  }
                >
                  Enviar
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export { DialogUploadSettingsCosts };
