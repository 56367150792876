import {
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Footer from "../Footer";
import Header from "../Header";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      container: {},
      content: {},
      enableMargins: {
        margin: "0 1rem",
        paddingBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: { margin: "0 7rem" },
      },
      fullHeight: { height: "100%" },
    })
);

interface Props {
  disableFooter?: boolean;
  enableMargins?: boolean;
  fullHeight?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  disableFooter = false,
  enableMargins = false,
  fullHeight = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  return (
    <div
      className={clsx(classes.container, { [classes.fullHeight]: fullHeight })}
    >
      <Header />
      <div
        className={clsx(classes.content, { [classes.fullHeight]: fullHeight })}
      >
        {enableMargins ? (
          <div
            className={clsx(
              enableMargins && classes.enableMargins,
              fullHeight && classes.fullHeight
            )}
          >
            {children}
          </div>
        ) : (
          children
        )}
      </div>
      {!disableFooter && !isMobile && <Footer />}
    </div>
  );
};

export default Layout;
