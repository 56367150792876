import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  setHours,
  startOfDay,
  startOfHour,
  startOfMonth,
  subDays,
} from "date-fns";
import { inject, injectable } from "inversify";
import moment from "moment-timezone";
import { ITimeLimitLocationDTO } from "../dtos/ITimeLimitLocationDTO";
import { ITimeLimitLocationService } from "../models/ITimeLimitLocationService";

@injectable()
export class TimeLimitLocationService implements ITimeLimitLocationService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  private verifyDate(date: Date, hourServer: Date, freighType: "CIF" | "FOB") {
    // é domingo
    if (getDay(date) === 0) {
      return true;
    }

    if (isBefore(date, startOfDay(hourServer))) {
      return true;
    }

    // se for sábado
    if (getDay(date) === 6) {
      const isAfter19 = isAfter(
        hourServer,
        startOfHour(setHours(hourServer, 19))
      );
      const isFriday = getDay(hourServer) === 5;
      const isHourServerTomorrowOfSaturday = isSameDay(
        hourServer,
        subDays(date, 1)
      );

      if (isFriday && isAfter19 && isHourServerTomorrowOfSaturday) {
        return true;
      } else {
        return false;
      }
    }

    if (freighType === "CIF") {
      const sameDay = isSameDay(date, hourServer);
      if (sameDay) {
        if (isAfter(hourServer, startOfHour(setHours(hourServer, 13)))) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (freighType === "FOB") {
      const sameDay = isSameDay(date, hourServer);
      if (sameDay) {
        if (isAfter(hourServer, startOfHour(setHours(hourServer, 14)))) {
          return true;
        } else {
          return false;
        }
      }
    }

    return false;
  }

  public async execute(data: ITimeLimitLocationDTO) {
    const tzFormatted = `Etc/GMT${
      data.timeZone > 0 ? `+${data.timeZone}` : data.timeZone
    }`;

    const hourServer = moment(
      (await this.httpInstance.get<{ hour: string }>("/status")).hour
    )
      .tz(tzFormatted)
      .toDate();

    const startMonth = startOfMonth(data.date ? data.date : hourServer);
    const endMonth = endOfMonth(data.date ? data.date : hourServer);

    const daysMonth = eachDayOfInterval({ start: startMonth, end: endMonth });

    let dict: { [propName: string]: boolean } = {};

    for (let day of daysMonth) {
      dict[format(day, "dd/MM/yyyy")] = this.verifyDate(
        day,
        hourServer,
        data.freighType
      );
    }

    console.log("hourServer", hourServer);

    return dict;
  }
}
