import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { fileNameByHeaders } from "@utils/index";
import { inject, injectable } from "inversify";
import { IDownloadExcelWalletAssessorService } from "../models/IDownloadExcelWalletAssessorService";

@injectable()
export class DownloadExcelWalletAssessorService
  implements IDownloadExcelWalletAssessorService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    partnerID: string;
  }): Promise<[string, string | null]> {
    const { data: titleFile, headers } = await this.httpInstance.getWithHeaders<
      BinaryType
    >("/reports/customerPorftolio", {
      params: data,
      responseType: "arraybuffer",
    });

    let url = window.URL.createObjectURL(
      new Blob([titleFile], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    return [url, fileNameByHeaders(headers)];
  }
}
