import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveSettingsCostsDTO } from "../dtos/IGetActiveSettingsCostsDTO";
import { IEditActiveSettingsCostService } from "../models/IEditActiveSettingsCostService";

@injectable()
export class EditActiveSettingsCostService
  implements IEditActiveSettingsCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(
    activeCostID: string,
    data: Partial<Pick<IGetActiveSettingsCostsDTO, "start_at" | "end_at">>
  ): Promise<void> {
    return this.httpInstance.patch("/settings-costs/" + activeCostID, data);
  }
}
