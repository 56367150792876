import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { GridFilterModel, GridLinkOperator } from "@material-ui/data-grid";
import { IResultSalesAnalyticsDTO } from "@modules/orders/dtos/IQuerySalesAnalyticsDTO";
import { IQuerySalesAnalyticsService } from "@modules/orders/models/IQuerySalesAnalyticsService";
import { IClient } from "@utils/interfaces";
import { endOfDay, startOfDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IQuery } from "./interface";

interface FormQueryContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataProducts: string[] | null;
  dataQuery: IResultSalesAnalyticsDTO | null;
  searchFirstTime: boolean;
  query: IQuery & { categories: string[] | null };
  filterModel: GridFilterModel;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataProducts(data: string[] | null): void;
  setDataQuery(data: IResultSalesAnalyticsDTO | null): void;
  setSearchFirstTime(value: boolean): void;
  setQuery: React.Dispatch<
    React.SetStateAction<IQuery & { categories: string[] | null }>
  >;
}

const FormQueryContext = createContext<FormQueryContext>(
  {} as FormQueryContext
);

const FormQueryProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const userState = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const queryOrdersService = iocContext.serviceContainer.get<
    IQuerySalesAnalyticsService
  >(Types.Orders.IQuerySalesAnalyticsService);

  const [loading, setLoading] = useState(false);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataProducts, setDataProducts] = useState<string[] | null>(null);
  const [dataQuery, setDataQuery] = useState<IResultSalesAnalyticsDTO | null>(
    null
  );
  const [searchFirstTime, setSearchFirstTime] = useState(true);

  const [query, setQuery] = useState<IQuery & { categories: string[] | null }>({
    CNPJ: [],
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    page: 1,
    limit: 100,
    categories: [],
  });

  const fetchQuery = useCallback(async () => {
    try {
      setLoadingQuery(true);

      const queryResult = await queryOrdersService.execute({
        beginDate: query.startDate,
        endDate: query.endDate,
        partnerID: userState.state.bpID,
        documents: query.CNPJ.map((value) => value.CNPJ),
        categories:
          query.categories && query.categories.length > 0
            ? query.categories
            : undefined,
        page: query.page,
        limit: query.limit,
      });

      setDataQuery(queryResult);
    } catch (error) {
      console.error("error query my orders", error);
      enqueueSnackbar("Ocorreu um erro ao buscar as vendas", {
        variant: "error",
      });
    } finally {
      setLoadingQuery(false);
      setSearchFirstTime(false);
    }
  }, [
    enqueueSnackbar,
    query.CNPJ,
    query.categories,
    query.endDate,
    query.limit,
    query.page,
    query.startDate,
    queryOrdersService,
    userState.state.bpID,
  ]);

  useEffect(() => {
    queryOrdersService.getProducts().then(setDataProducts);
  }, [queryOrdersService]);

  useEffect(() => {
    fetchQuery();
  }, [fetchQuery]);

  useEffect(() => {
    // reseta a página para a inicial caso alguma variável mude
    setQuery((state) => ({ ...state, page: 1 }));
  }, [query.startDate, query.endDate, query.CNPJ]);

  const filterModel: GridFilterModel = useMemo(
    () => ({
      items: [
        {
          id: 1,
          columnField: "statusAtem",
          operatorValue: "equal",
        },
      ],
      linkOperator: GridLinkOperator.Or,
    }),
    []
  );

  return (
    <FormQueryContext.Provider
      value={{
        loading,
        dataCustomer,
        dataProducts,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        query,
        filterModel,
        setLoading,
        setLoadingQuery,
        setDataProducts,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setQuery,
      }}
    >
      {children}
    </FormQueryContext.Provider>
  );
};

const useFormQuery = (): FormQueryContext => {
  const context = useContext(FormQueryContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQuery deve ser utilizado dentro de um FormQueryProvider"
    );
  }
  return context;
};

export { FormQueryProvider, useFormQuery };
