import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { usePagination } from "@material-ui/lab/Pagination";
import React from "react";
import { TableRowData } from "./interface";

interface HeadCell {
  disablePadding: boolean;
  id: keyof TableRowData;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: "name", numeric: false, disablePadding: false, label: "Nome" },
  { id: "value", numeric: false, disablePadding: false, label: "Valor" },
];

function EnhancedTableHead(props: { classes: ReturnType<typeof useStyles> }) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.id === "name" ? "none" : "normal"}
            classes={{
              root: classes.cellRoot,
              paddingNone: classes.cellHead,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const List = styled("ul")({
  display: "flex",
  justifyContent: "end",
  listStyle: "none",
  padding: 0,
  margin: 0,
});

const Item = styled("li")((props: { selected: boolean }) => ({
  "& button": {
    outline: "none",
    cursor: "pointer",
    backgroundColor: props.selected ? "#F4F7F9" : "#fff",
    border: 0,
    color: props.selected ? "#D91F05" : "#B0C1E1",
    width: 32,
    height: 32,
    fontSize: 16,
    fontFamily: "Open Sans, Roboto",
    borderRadius: 4,
  },
}));

interface IPaginationCustomProps {
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
  pagesTotal: number;
}

function PaginationCustom(props: IPaginationCustomProps) {
  const { onPageChange, pagesTotal } = props;
  const { items } = usePagination({
    count: pagesTotal,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => {
    onPageChange(event, newPage);
  };

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? "bold" : undefined,
                }}
                disabled={item.disabled}
                onClick={(e) => {
                  handleChangePage(e, page - 1);
                  item.onClick(e);
                }}
              >
                {page}
              </button>
            );
          } else {
            const buttonType =
              type === "next" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />;

            children = (
              <IconButton
                disabled={item.disabled}
                type="button"
                onClick={(e) => {
                  handleChangePage(e, page - 1);
                  item.onClick(e);
                }}
              >
                {buttonType}
              </IconButton>
            );
          }

          return (
            <Item key={index} selected={selected}>
              {children}
            </Item>
          );
        })}
      </List>
    </nav>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 100,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    cellPadding: {
      paddingLeft: 35,
    },
    cellRoot: {
      fontFamily: "Open Sans",
      fontSize: 16,
      color: "#626166",
    },
    cellHead: {
      paddingLeft: 35,
      paddingBottom: 16,
      fontWeight: 600,
    },
  })
);

interface IFinancialTotalsTable {
  isLoading?: boolean;
  rows: TableRowData[];
  pagesTotal: number;
}

export default function FinancialTotalsTable(props: IFinancialTotalsTable) {
  const { isLoading, rows, pagesTotal } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(6);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={0}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead classes={classes} />
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced - table - checkbox - ${index} `;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell
                            align="left"
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            classes={{
                              root: classes.cellRoot,
                              paddingNone: classes.cellPadding,
                            }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            classes={{
                              root: classes.cellRoot,
                            }}
                          >
                            {row.value}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div style={{ flex: 1, marginTop: 102 }}></div>
          <div style={{ marginBottom: 32 }}>
            <PaginationCustom
              onPageChange={handleChangePage}
              pagesTotal={pagesTotal}
            />
          </div>
        </div>
      )}
    </>
  );
}
