import Layout from "@components/Layout";
import {
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import TodayIcon from "@material-ui/icons/Today";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addHours, endOfDay, format, isValid } from "date-fns";
import React from "react";
import { DailyCharts } from "./DailyCharts";
import { DialogFilters } from "./DialogFilters";
import {
  HomeDashboardProvider,
  useHomeDashboard,
} from "./HomeDashboardContext";
import { MonthlyCharts } from "./MonthlyCharts";

export const Container = () => {
  const { dateCharts, setDateCharts, setOpenFilters } = useHomeDashboard();

  return (
    <Layout disableFooter enableMargins fullHeight={false}>
      <DialogFilters />
      <Grid container spacing={2}>
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{ margin: "0.8rem 0" }}
        >
          <Grid item xs={12} sm={6} lg={3} container alignItems="center">
            <Tooltip title="filtros">
              <IconButton onClick={() => setOpenFilters(true)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <TextField
              select
              label="Período"
              value={dateCharts.selected}
              onChange={(e) =>
                setDateCharts((old) => ({
                  ...old,
                  selected: (e.target.value as unknown) as number,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TodayIcon />
                  </InputAdornment>
                ),
              }}
              style={{ marginLeft: "1rem", flex: 1 }}
            >
              <MenuItem value={0}>Hoje</MenuItem>
              <MenuItem value={1}>Este mês</MenuItem>
              <MenuItem value={2}>Personalizado</MenuItem>
            </TextField>
          </Grid>
          {dateCharts.selected === 2 && (
            <Grid item container xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    format="dd/MM/yyyy"
                    label="Início"
                    value={dateCharts[2].rawFrom}
                    clearable={false}
                    onChange={(date: any) => {
                      try {
                        if (isValid(date)) {
                          const fromDate = format(date, "yyyy-MM-dd");

                          // @ts-ignore
                          setDateCharts((oldState) => ({
                            ...oldState,
                            2: {
                              ...oldState[2],
                              from: fromDate,
                              rawFrom: date,
                            },
                          }));
                        }
                      } catch (error) {}
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    format="dd/MM/yyyy"
                    label="Fim"
                    clearable={false}
                    value={dateCharts[2].rawEnd}
                    onChange={(date: any) => {
                      try {
                        if (isValid(date)) {
                          const newDate = format(
                            addHours(endOfDay(date), 4),
                            "yyyy-MM-dd"
                          );
                          console.log(newDate);
                          // @ts-ignore
                          setDateCharts((oldState) => ({
                            ...oldState,
                            2: { ...oldState[2], end: newDate, rawEnd: date },
                          }));
                        }
                      } catch (error) {}
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        {dateCharts.selected === 0 && <DailyCharts />}
        {dateCharts.selected !== 0 && <MonthlyCharts />}
      </Grid>

      <Tooltip title="filtros">
        <Fab
          style={{ position: "fixed", bottom: "2rem", left: "2rem" }}
          color="primary"
          onClick={() => setOpenFilters(true)}
        >
          <FilterListIcon />
        </Fab>
      </Tooltip>
    </Layout>
  );
};

export const HomePage: React.FC = () => {
  return (
    <HomeDashboardProvider>
      <Container />
    </HomeDashboardProvider>
  );
};
