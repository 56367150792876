import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IRemoveActiveSettingsCostService } from "../models/IRemoveActiveSettingsCostService";

@injectable()
export class RemoveActiveSettingsCostService
  implements IRemoveActiveSettingsCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(activeCostID: string): Promise<void> {
    return this.httpInstance.delete("/settings-costs/" + activeCostID);
  }
}
