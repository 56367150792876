import { createStyles, makeStyles, Typography } from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      margin: "5rem 0",

      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

export interface NoDataProps {
  disableIcon?: boolean;
}

const NoData: React.FC<NoDataProps> = ({ disableIcon, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      {!disableIcon && <InboxIcon fontSize="large" />}
      <Typography align="center" variant="h6">
        {children}
      </Typography>
    </div>
  );
};

export default NoData;
