import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { StatusAtem } from "../dtos/IQueryOrderDataDTO";
import { IQueryOrdersByStatusDTO } from "../dtos/IQueryOrdersByStatusDTO";
import { IQueryOrdersByStatusAggregatorsService } from "../models/IQueryOrdersByStatusAggregatorsService";

@injectable()
export class QueryOrdersByStatusAggregatorsService
  implements IQueryOrdersByStatusAggregatorsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    data: Omit<IQueryOrdersByStatusDTO, "page" | "limit">
  ): Promise<{ name: StatusAtem; value: number, total: number }[]> {
    const resp = await this.httpInstance.get("/orders/status", {
      params: {
        beginDate: getCorrectDate(data.beginDate),
        endDate: getCorrectDate(data.endDate),
        managerID: data.managerID?.join(","),
        advisorID: data.advisorID?.join(","),
        customerIDs: data.customerID?.join(","),
        action: data.action,
        status: data.status,
      },
    });

    return resp;
  }
}
