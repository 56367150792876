import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IDocumentTypeDTO } from "@modules/financial/dtos/IDocumentTypeDTO";
import { IGroupCustomerFilterDTO } from "@modules/financial/dtos/IGroupCustomerFilterDTO";
import { IManagerFilterDTO } from "@modules/financial/dtos/IManagerFilterDTO";
import { IMonthFilterDTO } from "@modules/financial/dtos/IMonthFilterDTO";
import { IPaymentMethodFilterDTO } from "@modules/financial/dtos/IPaymentMethodFilterDTO";
import { ITermFilterDTO } from "@modules/financial/dtos/ITermFilterDTO";
import { IYearFilterDTO } from "@modules/financial/dtos/IYearFilterDTO";
import { IFinancialFiltersService } from "@modules/financial/models/IFinancialFiltersService";
import { endOfDay, startOfDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IFinancialDashboardChartQuery } from "../FinancialInstallments/interface";

interface IFinancialDashboardFilters {
  managerFilter: IManagerFilterDTO[] | null;
  groupCustomerFilter: IGroupCustomerFilterDTO[] | null;
  documentTypeFilter: IDocumentTypeDTO[] | null;
  paymentMethodFilter: IPaymentMethodFilterDTO[] | null;
  yearFilter: IYearFilterDTO[] | null;
  monthFilter: IMonthFilterDTO[] | null;
  termFilter: ITermFilterDTO[] | null;
}

interface IFinancialDashboardLoaders {
  isFirstLoading: boolean;
  isAdvisorChartLoading: boolean;
  isManagerChartLoading: boolean;
  isTotalByMonthChartLoading: boolean;
  isOverallDelayRateChartLoading: boolean;
  isDebitSegmentChartLoading: boolean;
  isRankingOfDebtorCustomersChartLoading: boolean;
  isCustomerGroupChartLoading: boolean;
  isTotalDebitChartLoading: boolean;
  isTotalExpiredChartLoading: boolean;
  isTotalNotExpiredChartLoading: boolean;
  isTotalsDetailsLoading: boolean;
  isInitialState: boolean;
  countRequestInTotalsDetails: number;
  isCurrentMonth: boolean;
}
interface IFinancialDashboard {
  filters: IFinancialDashboardFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFinancialDashboardFilters>>;
  loaders: IFinancialDashboardLoaders;
  setLoaders: React.Dispatch<React.SetStateAction<IFinancialDashboardLoaders>>;
  query: IFinancialDashboardChartQuery;
  setQuery: React.Dispatch<React.SetStateAction<IFinancialDashboardChartQuery>>;
  incrementCounter: () => void;
}

const FinancialDashboardContext = createContext<IFinancialDashboard>(
  {} as IFinancialDashboard
);

const FinancialDashboardProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const queryFinancialFiltersService = iocContext.serviceContainer.get<
    IFinancialFiltersService
  >(Types.Financial.IFinancialFiltersService);

  const [filters, setFilters] = useState<IFinancialDashboardFilters>({
    managerFilter: [],
    groupCustomerFilter: [],
    documentTypeFilter: [],
    paymentMethodFilter: [],
    yearFilter: [],
    monthFilter: [],
    termFilter: [],
  });

  const [loaders, setLoaders] = useState<IFinancialDashboardLoaders>({
    isFirstLoading: true,
    isAdvisorChartLoading: false,
    isManagerChartLoading: false,
    isTotalByMonthChartLoading: false,
    isOverallDelayRateChartLoading: false,
    isDebitSegmentChartLoading: false,
    isRankingOfDebtorCustomersChartLoading: false,
    isCustomerGroupChartLoading: false,
    isTotalDebitChartLoading: false,
    isTotalExpiredChartLoading: false,
    isTotalNotExpiredChartLoading: false,
    isTotalsDetailsLoading: false,
    isInitialState: true,
    countRequestInTotalsDetails: 0,
    isCurrentMonth: false,
  });

  const [query, setQuery] = useState<IFinancialDashboardChartQuery>({
    CNPJ: [],
    advisorID: [],
    customerIDs: [],
    groupCustomer: [],
    managerID: [],
    statusDoc: [],
    documentType: [],
    paymentMethod: [],
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    from: "",
    to: "",
  });

  const getManagerFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getManagerFilter();
      setFilters((state) => ({
        ...state,
        managerFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar gerentes!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getGroupCustomerFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getGroupCustomerFilter();
      setFilters((state) => ({
        ...state,
        groupCustomerFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar grupos de cliente!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getDocumentTypeFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getTypeDocumentFilter();
      setFilters((state) => ({
        ...state,
        documentTypeFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar tipos de documento!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getPaymentMethodFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getPaymentMethodFilter();
      setFilters((state) => ({
        ...state,
        paymentMethodFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar formas de pagamento!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getYearFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getYearFilter();
      setFilters((state) => ({
        ...state,
        yearFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar anos!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getMonthFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getMonthFilter();
      setFilters((state) => ({
        ...state,
        monthFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar meses!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  const getTermFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getTermFilter();
      setFilters((state) => ({
        ...state,
        termFilter: queryResult,
      }));
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar prazos!", {
        variant: "error",
      });
    }
  }, [queryFinancialFiltersService, enqueueSnackbar]);

  useEffect(() => {
    const fromMonth = new Date(`${query.from}T04:00:00.000Z`).getMonth() + 1;
    const fromYear = new Date(`${query.from}T04:00:00.000Z`).getFullYear();
    const toMonth = new Date(`${query.to}T04:00:00.000Z`).getMonth() + 1;
    const toDay = new Date(`${query.to}T04:00:00.000Z`).getDate();

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const isCurrentMonth =
      currentMonth === fromMonth &&
      toMonth === currentMonth + 1 &&
      toDay === 1 &&
      fromYear === currentYear;

    setLoaders((state) => ({
      ...state,
      isCurrentMonth: isCurrentMonth,
    }));
  }, [query.from, query.to]);

  useEffect(() => {
    if (loaders.isCurrentMonth) {
      setLoaders((state) => ({
        ...state,
        isInitialState: true,
      }));
    } else {
      setLoaders((state) => ({
        ...state,
        isInitialState: loaders.countRequestInTotalsDetails <= 3,
      }));
    }
  }, [loaders.countRequestInTotalsDetails, loaders.isCurrentMonth]);

  const incrementCounter = useCallback(() => {
    setLoaders((state) => ({
      ...state,
      countRequestInTotalsDetails: state.countRequestInTotalsDetails + 1,
    }));
  }, []);

  useEffect(() => {
    getGroupCustomerFilter();
    getManagerFilter();
    getDocumentTypeFilter();
    getPaymentMethodFilter();
    getYearFilter();
    getMonthFilter();
    getTermFilter();
  }, [
    getGroupCustomerFilter,
    getManagerFilter,
    getDocumentTypeFilter,
    getPaymentMethodFilter,
    getYearFilter,
    getMonthFilter,
    getTermFilter,
  ]);

  return (
    <FinancialDashboardContext.Provider
      value={{
        filters,
        setFilters,
        loaders,
        setLoaders,
        query,
        setQuery,
        incrementCounter,
      }}
    >
      {children}
    </FinancialDashboardContext.Provider>
  );
};

const useFinancialDashboardContext = () => {
  const context = useContext(FinancialDashboardContext);

  if (Object.values(context).length === 0) {
    throw new Error(
      "FinalcialContext deve ser utilizado dentro de um FinancialProvider"
    );
  }

  return context;
};

export { useFinancialDashboardContext, FinancialDashboardProvider };
