import "./animate.css";
import React from "react";
import { Box } from "@material-ui/core";
import logoATEM from "../../assets/logo-atem.png";

const DashLoading: React.FC = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
    >
      <div className={"pulse"}>
        <img src={logoATEM} width={70} height={48} alt="logo atem" />
      </div>
    </Box>
  );
};

export default DashLoading;
