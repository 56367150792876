import DashLoading from "@components/DashLoading";
import appConfig from "@config/appConfig";
import { useAuth } from "@context/AuthContext/AuthContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IBpDTO } from "@modules/user/dtos/IBpDTO";
import { ICnpjDTO } from "@modules/user/dtos/ICnpjDTO";
import { IGetAssessorUsersService } from "@modules/user/models/IGetAssessorUsersService";
import { IGetWalletAssessorService } from "@modules/user/models/IGetWalletAssessorService";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import State from "./State";
import Actions from "./actions/actions";
import UserReducer from "./reducer";

type Dispatch = (action: Actions) => void;

const UserContext = React.createContext<
  | {
      state: State;
      hasPermission: (role: string) => boolean;
    }
  | undefined
>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function useUserState() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw Error("useUserState deve ser utilizado dentro de um UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (!context) {
    throw Error("useUserDispatch deve ser utilizado dentro de um UserProvider");
  }
  return context;
}

const UserProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const getWalletAssessorService = iocContext.serviceContainer.get<
    IGetWalletAssessorService
  >(Types.Users.IGetWalletAssessorService);
  const getAssessorsUsersService = iocContext.serviceContainer.get<
    IGetAssessorUsersService
  >(Types.Users.IGetAssessorUsersService);

  const userData = useAuth();
  const mountedRef = useRef({ bpID: userData.bpID, mounted: true });

  const [state, dispatch] = useReducer(UserReducer, {
    listCNPJ: userData.listCNPJ as ICnpjDTO[],
    username: userData.name,
    userID: userData.userID,
    email: userData.email,
    roles: userData.roles,
    isAdmin: userData.isAdmin,
    token: userData.token,
    bpID: userData.bpID,
    type: userData.type,
    keycloakUserType: userData.type,
    loadingCNPJ: true,
    bpName: userData.name,
    bpSelected: { bpName: "", bpID: userData.bpID, type: userData.type },
    assessorList: [],
    loadingAssessorList: true,
    SYSTEM_MODULES: userData.SYSTEM_MODULES,
  });

  const getWalletAssessor = useCallback(async () => {
    try {
      dispatch({ type: "APP_SET_LOADING_LIST_CNPJ", payload: true });
      const wallet = await getWalletAssessorService.execute(state.bpID);
      // caso o bpID tenha mudado, não se altera para um bpID antigo
      if (mountedRef.current.bpID === state.bpID) {
        dispatch({
          type: "APP_SET_USER_LIST_CNPJ",
          payload: { listCNPJ: wallet },
        });
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro ao buscar carteira de clientes, recarregando página",
        { variant: "error" }
      );
      setTimeout(() => window.location.reload(), 300000);
    } finally {
      dispatch({ type: "APP_SET_LOADING_LIST_CNPJ", payload: false });
    }
  }, [enqueueSnackbar, getWalletAssessorService, state.bpID]);

  const getAssessorsUsers = useCallback(async () => {
    try {
      dispatch({ type: "APP_SET_ASSESSOR_LOADING_LIST", payload: true });
      const resp = await getAssessorsUsersService.execute();
      const filtered = resp.filter((ele) =>
        Boolean(userData.listAssessorIDAccepted.find((id) => id === ele.bpID))
      );
      const assessorSelected = filtered.find(
        (assessor) => assessor.bpID === userData.bpID
      ) as IBpDTO;
      // verifica se já existe o acessor no local storage, e o coloca como selecionado
      const selectedBeforeBP = localStorage.getItem(
        appConfig.keyStorage.bpSelected(userData.userID)
      );
      if (selectedBeforeBP) {
        dispatch({
          type: "APP_SET_ASSESSOR",
          payload: JSON.parse(selectedBeforeBP),
        });
      } else {
        dispatch({
          type: "APP_SET_ASSESSOR",
          payload: { ...assessorSelected, userID: userData.userID },
        });
      }
      dispatch({ type: "APP_SET_ASSESSOR_LIST", payload: filtered });
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro ao buscar Assessores Relacionados a conta, recarregando página",
        { variant: "error" }
      );
      setTimeout(() => window.location.reload(), 300000);
    } finally {
      dispatch({ type: "APP_SET_ASSESSOR_LOADING_LIST", payload: false });
    }
  }, [
    enqueueSnackbar,
    getAssessorsUsersService,
    userData.bpID,
    userData.listAssessorIDAccepted,
    userData.userID,
  ]);

  const hasPermission = useCallback(
    (role: string): boolean => {
      const hasRole = state.SYSTEM_MODULES.includes(role);

      return hasRole || state.isAdmin;
    },
    [state.SYSTEM_MODULES, state.isAdmin]
  );

  useEffect(() => {
    mountedRef.current = { mounted: true, bpID: state.bpID };
    getWalletAssessor();
    return () => {
      mountedRef.current = { mounted: false, bpID: state.bpID };
    };
  }, [getWalletAssessor, state.bpID]);

  useEffect(() => {
    getAssessorsUsers();
  }, [getAssessorsUsers]);

  return (
    <UserContext.Provider value={{ state, hasPermission }}>
      <UserDispatchContext.Provider value={dispatch}>
        {userData.token ? children : <DashLoading />}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export { UserProvider, useUserDispatch, useUserState };
