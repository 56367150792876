import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IRemoveHandoutService } from "../models/IRemoveHandoutService";

@injectable()
export class RemoveHandoutService implements IRemoveHandoutService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(handoutID: string) {
    const removed = await this.httpInstance.delete<{ message: string }>(
      `/handouts/${handoutID}`
    );

    return removed;
  }
}
