import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { getCorrectDate } from "@utils/index";
import { inject, injectable } from "inversify";
import { IQueryFinancialInstallmentsDataDTO } from "../dtos/IFinancialInstallmentsDTO";
import { IQueryFinancialInstallments } from "../dtos/IQueryFinancialInstallments";
import { IQueryTotalValueStatusDoc } from "../dtos/IQueryTotalValueStatusDoc";
import { ITotalValueStatusDocDTO } from "../dtos/ITotalValueStatusDocDTO";
import { IFinancialInstallmentsService } from "../models/IFinancialInstallmentsService";

@injectable()
export class FinancialInstallmentsService
  implements IFinancialInstallmentsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getInstallments(
    data: IQueryFinancialInstallments
  ): Promise<IQueryFinancialInstallmentsDataDTO> {
    return this.httpInstance.get("/financialDocs", {
      params: {
        beginDate: getCorrectDate(data.beginDate),
        endDate: getCorrectDate(data.endDate),
        advisorID: data.advisorID?.join(","),
        customerIDs: data.customerIDs?.join(","),
        groupCustomer: data.groupCustomer.join(","),
        managerID: data.managerID.join(","),
        statusDoc: data.statusDoc.join(","),
        page: data.page,
        limit: data.limit,
      },
    });
  }

  public async getTotalValueByStatusDoc(
    data: IQueryTotalValueStatusDoc
  ): Promise<ITotalValueStatusDocDTO[]> {
    return this.httpInstance.get("/financialDocs", {
      params: {
        beginDate: getCorrectDate(data.beginDate),
        endDate: getCorrectDate(data.endDate),
        advisorID: data.advisorID.join(","),
        customerIDs: data.customerIDs?.join(","),
        groupCustomer: data.groupCustomer?.join(","),
        statusDoc: data.statusDoc.join(","),
        action: "total-value-by-statusdoc",
      },
    });
  }
}
