import { BoxInfo } from "@components/BoxInfo";
import { Slider } from "@components/Slider";
import { SlideItem } from "@components/Slider/SlideItem";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import TableChartIcon from "@material-ui/icons/TableChart";
import {
  IGetChartDataService,
  ProductGroupResponse,
  ProductStatusVolumeResponse,
} from "@modules/dashboard/models/IGetChartDataService";
import {
  HomeDashboardFilters,
  useHomeDashboard,
} from "@pages/Home/HomeDashboardContext";
import { formatCurrency, formatPercent } from "@utils/index";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import moment from "moment";
import {
  TypeColumnDialog,
  SetColumnHeaderTable,
} from "@utils/setColumnHeaderTable";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      gridBox: {
        margin: "0.5rem 0",
        overflowX: "hidden",
        flexDirection: "column",
        "& > div": { padding: "1rem" },
      },
      paperDialog: { [theme.breakpoints.up("sm")]: { minWidth: "85vw" } },
      tableContainer: {
        height: "100%",
        [theme.breakpoints.up("sm")]: { height: "50vh" },
      },
      marginPositive: { color: theme.palette.success.main },
      marginNegative: { color: theme.palette.error.main },
    })
);

interface DialogTableProps {
  productGroupData: ProductGroupDataWithLitterMargin | null;
  open: boolean;
  onClose: () => void;
  from: string;
  to: string;
}

const DialogTable: React.FC<DialogTableProps> = ({
  open,
  onClose,
  productGroupData,
  from,
  to,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const data = useMemo(() => {
    if (!productGroupData) return [];

    return Object.keys(productGroupData).map((key) => {
      return {
        id: key,
        ...productGroupData[key],
      };
    });
  }, [productGroupData]);

  const flagYearSameOrAfter2022 =
    moment(from).isSameOrAfter(moment("2022-01-01"), "years") &&
    moment(to).isSameOrAfter(moment("2022-01-01"), "years");

  const flagFromToSameMonth = moment(from).isSame(
    moment(moment(to).subtract(1, "days")),
    "months"
  );

  const columns: GridColDef[] =
    flagYearSameOrAfter2022 && flagFromToSameMonth
      ? [
          {
            field: "id",
            headerName: "Produto",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "litterMargin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousMonth",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_MONTH
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "performancePreviousMonth",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_MONTH
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "performancePreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          // {
          //   field: "marginPreviousMonth",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_MONTH
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
          // {
          //   field: "marginPreviousYear",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_YEAR
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
        ]
      : flagYearSameOrAfter2022 && !flagFromToSameMonth
      ? [
          {
            field: "id",
            headerName: "Produto",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "litterMargin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "quantityPreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "performancePreviousYear",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "D",
              TypeColumnDialog.PREVIOUS_YEAR
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) => formatPercent(props.value as number),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },

          // {
          //   field: "marginPreviousYear",
          //   headerName: SetColumnHeaderTable.setColumnDialogTable(
          //     from,
          //     to,
          //     "M",
          //     TypeColumnDialog.PREVIOUS_YEAR
          //   ),
          //   type: "number",
          //   width: isMobile ? 180 : undefined,
          //   flex: isMobile ? undefined : 1,
          //   valueFormatter: (props) =>
          //     formatCurrency(props.value as number, {
          //       minimumFractionDigits: 4,
          //     }),
          //   cellClassName: (props) =>
          //     props.value
          //       ? clsx({
          //           [classes.marginNegative]: (props.value as number) < 0,
          //         })
          //       : "",
          // },
        ]
      : [
          {
            field: "id",
            headerName: "Produto",
            width: isMobile ? 250 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "value",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "V",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
          },
          {
            field: "litterMargin",
            headerName: SetColumnHeaderTable.setColumnDialogTable(
              from,
              to,
              "M",
              TypeColumnDialog.CURRENT
            ),
            type: "number",
            width: isMobile ? 180 : undefined,
            flex: isMobile ? undefined : 1,
            valueFormatter: (props) =>
              formatCurrency(props.value as number, {
                minimumFractionDigits: 4,
              }),
            cellClassName: (props) =>
              props.value
                ? clsx({
                    [classes.marginNegative]: (props.value as number) < 0,
                  })
                : "",
          },
        ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      classes={{ paper: classes.paperDialog }}
    >
      <DialogTitle>Produtos</DialogTitle>
      <DialogContent>
        <DialogContentText>V = Volume</DialogContentText>
        <DialogContentText>M = Margem por litro</DialogContentText>
        <DialogContentText>
          D = Desempenho relacionado do período selecionado com anterior
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <div className={classes.tableContainer}>
          <DataGrid
            columns={columns}
            rows={data}
            disableSelectionOnClick
            rowsPerPageOptions={[25]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface ProductGroupDataWithLitterMargin {
  [propName: string]: {
    value: number;
    litterMargin: number;
    quantityPreviousMonth: number;
    marginPreviousMonth: number;
    quantityPreviousYear: number;
    marginPreviousYear: number;
    performancePreviousMonth: number;
    performancePreviousYear: number;
  };
}

const ProductBoxes: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const iocContext = useIoCContext();
  const { dateCharts } = useHomeDashboard();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () =>
      getDataChartService.getProductStatusVolume({
        ...filters,
        from,
        to,
      }),
    {
      useCallbackDeps: [filters, from, to],
      useEffectDeps: [filters, from, to],
    }
  );
  const fetchGroupProduct = useFetchData(
    () =>
      getDataChartService.getProductGroup({
        ...filters,
        from,
        to,
      }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const productGroupData: ProductGroupDataWithLitterMargin | null = useMemo(() => {
    if (dateCharts.selected !== 0) {
      if (!fetchGroupProduct.value) {
        return null;
      }
    } else {
      if (!fetchData.value) {
        return null;
      }
    }

    let data:
      | ProductStatusVolumeResponse
      | ProductGroupResponse = fetchData.value!;

    if (dateCharts.selected !== 0) {
      data = fetchGroupProduct.value!;
    }

    const nameIdx = data.columns.findIndex(
      (column) => column.name === "productGroup"
    );
    const quantityIdx = data.columns.findIndex(
      (column) => column.name === "quantity"
    );
    const litterMarginIdx = data.columns.findIndex(
      (column) => column.name === "litterMargin"
    );
    const quantityPreviousMonthIdx = data.columns.findIndex(
      (column) => column.name === "quantityPreviousMonth"
    );
    const marginPreviousMonthIdx = data.columns.findIndex(
      (column) => column.name === "litterMarginPreviousMonth"
    );
    const marginPreviousYearIdx = data.columns.findIndex(
      (column) => column.name === "litterMarginPreviousYear"
    );
    const quantityPreviousYearIdx = data.columns.findIndex(
      (column) => column.name === "quantityPreviousYear"
    );

    // @ts-ignore
    const groupProduct: ProductGroupDataWithLitterMargin = data.rows.reduce(
      // @ts-ignore
      (a, b) => {
        if (a[b[nameIdx]]) {
          a[b[nameIdx]] = {
            value: a[b[nameIdx]] + (b[quantityIdx] as number),
            litterMargin: b[litterMarginIdx],
            quantityPreviousMonth: b[quantityPreviousMonthIdx],
            marginPreviousMonth: b[marginPreviousMonthIdx],
            quantityPreviousYear: b[quantityPreviousYearIdx],
            marginPreviousYear: b[marginPreviousYearIdx],
            performancePreviousMonth: SetColumnHeaderTable.setPercentual(
              a[b[nameIdx]] + (b[quantityIdx] as number),
              b[quantityPreviousMonthIdx]
            ),
            performancePreviousYear: SetColumnHeaderTable.setPercentual(
              a[b[nameIdx]] + (b[quantityIdx] as number),
              b[quantityPreviousYearIdx]
            ),
          };
        } else {
          a[b[nameIdx]] = {
            value: b[quantityIdx] as number,
            litterMargin: b[litterMarginIdx],
            quantityPreviousMonth: b[quantityPreviousMonthIdx],
            marginPreviousMonth: b[marginPreviousMonthIdx],
            quantityPreviousYear: b[quantityPreviousYearIdx],
            marginPreviousYear: b[marginPreviousYearIdx],
            performancePreviousMonth: SetColumnHeaderTable.setPercentual(
              b[quantityIdx] as number,
              b[quantityPreviousMonthIdx]
            ),
            performancePreviousYear: SetColumnHeaderTable.setPercentual(
              b[quantityIdx] as number,
              b[quantityPreviousYearIdx]
            ),
          };
        }

        return a;
      },
      {} as ProductGroupDataWithLitterMargin
    );
    return groupProduct;
  }, [dateCharts.selected, fetchData.value, fetchGroupProduct.value]);

  const products = useMemo(() => {
    if (!productGroupData) return [];

    return Object.keys(productGroupData).map((key) => {
      return {
        name: key,
        value: productGroupData[key].value,
        litterMargin: productGroupData[key].litterMargin,
      };
    });
  }, [productGroupData]);

  const [openDialog, setOpenDialog] = useState(false);

  const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];

  return (
    <Grid container justify="space-evenly" className={classes.gridBox}>
      <Slider showMore={!isMobile} slideQuantity={4} disableAutoPass>
        {products.map((product, idx) => {
          return (
            <SlideItem key={idx} index={idx}>
              <BoxInfo
                colorBoxIcon={colors[idx % colors.length]}
                title={product.name}
                value={new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 0,
                }).format(product.value)}
                margin={product.litterMargin}
                icon={<LocalGasStationIcon style={{ color: "#fff" }} />}
                loading={fetchData.loading}
              />
            </SlideItem>
          );
        })}
      </Slider>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Chip
          label="Ver Tabela"
          size="small"
          color="primary"
          variant="outlined"
          icon={<TableChartIcon />}
          onClick={() => setOpenDialog(true)}
        />
        <DialogTable
          productGroupData={productGroupData}
          onClose={() => setOpenDialog(false)}
          open={openDialog}
          from={from}
          to={to}
        />
      </div>
    </Grid>
  );
};

export { ProductBoxes };
