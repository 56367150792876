import { FormControl, MenuItem, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import * as React from "react";
import { useRef, useState } from "react";

const useStyles = makeStyles({
  formControl: {
    flexDirection: "row",
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 32,
    marginBottom: 10,
  },
  rootSelect: {
    background: "white",
    "&:focus": {
      backgroundColor: "white",
    },
    textAlign: "right",
    color: "#D91F05",
    borderStyle: "none",
    fontSize: "12px",
    fontWeight: 600,
  },
  option: {
    backgroundColor: "#F4F7F9",
  },
  iconSelect: {
    color: "#D91F05",
  },

  monthYearHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32,
    marginBottom: 5,
    "& span": {
      margin: 0,
    },
  },
  monthCard: {
    padding: "0 0.8em",
    background: "#F4F7F9",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#626166",
    cursor: "pointer",
    marginRight: 16,
    textTransform: "capitalize",
    width: 42,
    height: 89,
    "& span:first-child": {
      fontSize: 12,
      marginBottom: 7,
    },
    "& span:last-child": {
      fontSize: 16,
    },
  },

  selectedMonthCard: {
    border: "1px solid #D91F05",
    color: "#D91F05",
  },
  monthList: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    overflow: "hidden",
  },
  monthSlider: {
    display: "flex",
    alignItems: "center",
  },
});

interface MonthCheckboxCustomProps {
  month: string;
  year: string;
  isSelected: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface ISelectItem {
  id: string;
  desc: string;
}

interface ISelectProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  items: ISelectItem[];
}
export interface IDatePickerCheckBox {
  id: string;
  desc: string;
  checked: boolean;
}

interface DatePickerCustomProps {
  formTitle: string;
  selectProps: ISelectProps;
  list: IDatePickerCheckBox[];
  setList: React.Dispatch<React.SetStateAction<IDatePickerCheckBox[]>>;
}

const DatePickerCustom = (props: DatePickerCustomProps) => {
  const { formTitle, selectProps, list, setList } = props;
  const classes = useStyles();
  const [scrollValue, setScrollValue] = useState<number>(0);
  const [showRightScroll, setShowRightScroll] = useState<boolean>(true);
  const itemList = useRef<HTMLDivElement>();

  const MonthCheckboxCustom: React.FC<MonthCheckboxCustomProps> = (props) => {
    return (
      <div
        className={clsx(
          classes.monthCard,
          props.isSelected && classes.selectedMonthCard
        )}
        onClick={props.onClick}
      >
        {props.year !== "-1" && <span>{props.year}</span>}
        <span>{props.month}</span>
      </div>
    );
  };

  const handleOnScroll = () => {
    let sum =
      (itemList.current?.offsetWidth ?? 0) +
      (itemList.current?.scrollLeft ?? 0);

    setShowRightScroll(sum !== (itemList.current?.scrollWidth ?? 0));
    setScrollValue(itemList.current?.scrollLeft ?? 0);
  };

  const handleScroll = async (isRight: boolean) => {
    const value = (itemList.current?.scrollLeft ?? 0) + 30 * (isRight ? 1 : -1);

    itemList.current?.scrollTo({
      behavior: "smooth",
      left: value,
    });
  };

  const handleMonthClick = (id: string, index: number) => {
    let isToCheck = false;
    const isFirst = isFirstToSelect();
    const hasBefore = hasCheckedBefore(index);
    const hasAfter = hasCheckedAfter(index);
    const indexLast = indexLastChecked();

    const newValue = list.map((item, i) => {
      if (isFirst) {
        return { ...item, checked: item.id === id };
      }

      if (hasBefore && hasAfter) {
        return { ...item, checked: i >= index ? false : item.checked };
      }

      if (hasBefore) {
        if (item.checked) {
          isToCheck = true;
        }

        if (item.id === id) {
          isToCheck = false;
        }

        return { ...item, checked: item.id === id ? !item.checked : isToCheck };
      }

      if (hasAfter) {
        if (item.id === id) {
          isToCheck = true;
        }

        if (indexLast + 1 === i) {
          isToCheck = false;
        }

        return { ...item, checked: item.id === id ? !item.checked : isToCheck };
      }
      return item;
    });

    setList(newValue);
  };

  const hasCheckedBefore = (index: number) => {
    const firstIndex = list.findIndex((item) => item.checked);

    return firstIndex < index;
  };

  const hasCheckedAfter = (index: number) => {
    const firstIndex = list.slice(index).findIndex((item) => item.checked);

    return firstIndex >= 0;
  };

  const isFirstToSelect = () =>
    list.find((item) => item.checked) ? false : true;

  const indexLastChecked = () => {
    const newList = list.map((x) => x);

    const lastItemCheched = newList.reverse().find((item) => item.checked);

    return list.indexOf(lastItemCheched!);
  };

  return (
    <div>
      <div className={classes.monthYearHeader}>
        <span className={classes.label}>{formTitle}</span>
        <FormControl className={classes.formControl}>
          <Select
            autoWidth
            disableUnderline
            value={selectProps.value}
            onChange={(e) => selectProps.setValue(e.target.value as string)}
            IconComponent={(props) => <ExpandMore color="primary" {...props} />}
            classes={{
              root: classes.rootSelect,
              icon: classes.iconSelect,
            }}
          >
            <MenuItem value="-1" className={classes.option}>
              Apenas anos
            </MenuItem>
            {selectProps.items.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                className={classes.option}
              >
                {item.desc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.monthSlider}>
        {scrollValue > 0 ? (
          <ArrowBackIosRoundedIcon
            onClick={() => handleScroll(false)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <></>
        )}
        <div
          // @ts-ignore
          ref={itemList}
          className={classes.monthList}
          onScroll={handleOnScroll}
        >
          {list.map((item, i) => (
            <MonthCheckboxCustom
              key={item.id}
              year={selectProps.value}
              month={item.desc.toLowerCase()}
              isSelected={item.checked}
              onClick={() => handleMonthClick(item.id, i)}
            />
          ))}
        </div>
        {showRightScroll ? (
          <ArrowForwardIosRoundedIcon
            onClick={() => handleScroll(true)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DatePickerCustom;
