import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveCostsDTO } from "../dtos/IGetActiveCostsDTO";
import { IGetActiveCostsService } from "../models/IGetActiveCostsService";

@injectable()
export class GetActiveCostsService implements IGetActiveCostsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<IGetActiveCostsDTO[]> {
    const resp = await this.httpInstance.get<IGetActiveCostsDTO[]>("/costs", {
      params: {
        action: "all-aggregated",
      },
    });

    return resp.map((cost) => {
      return {
        ...cost,
        createdAt: new Date(cost.createdAt),
        updatedAt: new Date(cost.updatedAt),
        deletedAt: cost.deletedAt ? new Date(cost.deletedAt) : null,
        start_at: new Date(cost.start_at),
        end_at: cost.end_at ? new Date(cost.end_at) : null,
      };
    });
  }
}
