import baseRetiradaImg from "@assets/base-de-retirada.png";
import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import { useUserState } from "@context/UserContext";
import {
  Chip,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RoomIcon from "@material-ui/icons/Room";
import { FreightType } from "@utils/interfaces";
import clsx from "clsx";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSimulationsCostsContext } from "./SimulationCostsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      itemSelect: {
        display: "flex",
        alignItems: "flex-start",
      },
      iconNotDisabled: {
        opacity: "0.6",
      },
      iconsDisabled: {
        opacity: "0.12",
      },
      titleDisabled: { opacity: "0.6" },
    })
);

const FormQuerySimulation: React.FC = () => {
  const classes = useStyles();
  const simulationCostsContext = useSimulationsCostsContext();
  const userState = useUserState();

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);

  const renderAddress = () => {
    if (!simulationCostsContext.dataCustomer) return null;
    return simulationCostsContext.dataCustomer.address.map((ele) => {
      return (
        <MenuItem key={ele.id} value={ele.id} className={classes.itemSelect}>
          {ele.street} - {ele.city} - {ele.UF}
        </MenuItem>
      );
    });
  };
  const renderWithdrawBasis = () => {
    if (!simulationCostsContext.dataCustomer) return null;

    return simulationCostsContext.dataCustomer.withdrawBasis.map((ele) => {
      return (
        <MenuItem
          key={`${ele.filialID}-${ele.store}-${ele.branchName}`}
          value={`${ele.filialID}-${ele.store}-${ele.branchName}`}
          className={classes.itemSelect}
        >
          <strong>{ele.name}</strong>-{ele.branchName}
        </MenuItem>
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple={false}
          onChange={(value) => simulationCostsContext.setCnpjCustomer(value)}
          value={simulationCostsContext.cnpjCustomer}
          options={userState.state.listCNPJ}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
        />
      </Grid>
      <Grid container item xs={12} sm={4} alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !simulationCostsContext.cnpjCustomer ||
            simulationCostsContext.loadingCustomerData
          }
        >
          <InputLabel>Endereço para entrega</InputLabel>
          <Select
            onChange={({ target }) => {
              if (simulationCostsContext.dataCustomer) {
                const address = simulationCostsContext.dataCustomer.address.find(
                  (ele) => ele.id === target.value
                );

                simulationCostsContext.setAddressSelected(address!);

                if (address?.filialIdDefault) {
                  const store = simulationCostsContext.dataCustomer.withdrawBasis.find(
                    (withdrawBasis) => {
                      return withdrawBasis.filialID === address.filialIdDefault;
                    }
                  );
                  simulationCostsContext.setFilialId(store!.filialID);
                  simulationCostsContext.setStoreID(store!.store);
                  simulationCostsContext.setBranchName(store!.branchName);
                }
              }
            }}
            value={
              simulationCostsContext.addressSelected
                ? simulationCostsContext.addressSelected.id
                : ""
            }
            name="address"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <RoomIcon />
              </InputAdornment>
            }
            endAdornment={
              simulationCostsContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress style={{ width: 30, height: 30 }} />
                </InputAdornment>
              )
            }
          >
            {simulationCostsContext.cnpjCustomer && renderAddress()}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4} container alignItems="center">
        <FormControl
          fullWidth
          disabled={
            !Boolean(simulationCostsContext.cnpjCustomer) ||
            simulationCostsContext.loadingCustomerData
          }
        >
          <InputLabel>Centro</InputLabel>
          <Select
            value={`${simulationCostsContext.filialId}-${simulationCostsContext.storeID}-${simulationCostsContext.branchName}`}
            onChange={({ target }) => {
              simulationCostsContext.setFilialId(
                (target.value as string).split("-")[0]
              );
              simulationCostsContext.setStoreID(
                (target.value as string).split("-")[1]
              );
              simulationCostsContext.setBranchName(
                (target.value as string).split("-")[2]
              );
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            startAdornment={
              <InputAdornment position="start">
                <img
                  width="24"
                  src={baseRetiradaImg}
                  alt="base de retirada ícone"
                  className={clsx({
                    [classes.iconNotDisabled]:
                      Boolean(simulationCostsContext.cnpjCustomer) &&
                      !simulationCostsContext.loadingCustomerData,
                    [classes.iconsDisabled]:
                      !Boolean(simulationCostsContext.cnpjCustomer) ||
                      simulationCostsContext.loadingCustomerData,
                  })}
                />
              </InputAdornment>
            }
            endAdornment={
              simulationCostsContext.loadingCustomerData && (
                <InputAdornment position="end">
                  <CircularProgress style={{ width: 30, height: 30 }} />
                </InputAdornment>
              )
            }
          >
            {renderWithdrawBasis()}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2}>
        <FormControl
          disabled={
            !Boolean(simulationCostsContext.dataCustomer) ||
            simulationCostsContext.loadingCustomerData
          }
        >
          <FormLabel component="legend">Tipo de Frete</FormLabel>
          <RadioGroup
            aria-label="freightType"
            name="freightType"
            value={simulationCostsContext.freightType}
            onChange={({ target }) =>
              simulationCostsContext.setFreightType(target.value as FreightType)
            }
            row
          >
            <FormControlLabel
              value="CIF"
              control={<Radio color="primary" />}
              label="CIF"
            />
            <FormControlLabel
              value="FOB"
              control={<Radio color="primary" />}
              label="FOB"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Typography
          className={clsx({
            [classes.titleDisabled]: !Boolean(
              simulationCostsContext.dataCustomer
            ),
          })}
        >
          Segmento:
        </Typography>
        <Typography>
          {simulationCostsContext.dataCustomer?.segment.description}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {simulationCostsContext.dataCustomer &&
          simulationCostsContext.dataCustomer.products.map((product) => {
            return (
              <Chip
                color="primary"
                icon={<AddIcon />}
                clickable
                onClick={() =>
                  simulationCostsContext.setProductsSimulation((oldState) => {
                    const filtered = oldState.filter(
                      (productFilter) => productFilter.id === product.id
                    );
                    if (filtered.length < 2) {
                      return [...oldState, { ...product, key: uuidv4() }];
                    } else {
                      return [...oldState];
                    }
                  })
                }
                variant="outlined"
                label={`${product.id} - ${product.description}`}
                style={{ margin: "1rem" }}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

export { FormQuerySimulation };
