import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveSettingsCostsDTO } from "../dtos/IGetActiveSettingsCostsDTO";
import { IGetActiveSettingsCostsService } from "../models/IGetActiveSettingsCostsService";

@injectable()
export class GetActiveSettingsCostsService
  implements IGetActiveSettingsCostsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(): Promise<IGetActiveSettingsCostsDTO[]> {
    console.log("active settings costs dto called ");
    const resp = await this.httpInstance.get<IGetActiveSettingsCostsDTO[]>(
      "/settings-costs",
      {
        params: {
          action: "all-aggregated",
        },
      }
    );

    return resp.map((cost) => {
      return {
        ...cost,
        createdAt: new Date(cost.createdAt),
        updatedAt: new Date(cost.updatedAt),
        deletedAt: cost.deletedAt ? new Date(cost.deletedAt) : null,
        start_at: new Date(cost.start_at),
        end_at: cost.end_at ? new Date(cost.end_at) : null,
      };
    });
  }
}
