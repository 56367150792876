import moment from "moment";

export enum TypeColumnDialog {
  "CURRENT",
  "PREVIOUS_MONTH",
  "PREVIOUS_YEAR",
}

export class SetColumnHeaderTable {
  public static setPercentual(a: number, b: number) {
    return a > b ? 1 - b / a : (1 - a / b) * -1;
  }

  public static setColumnDialogTable(
    from: string,
    to: string,
    unity: string,
    type: TypeColumnDialog
  ) {
    const fromMoment = moment(from);
    const toMoment = moment(moment(to).subtract(1, "days"));

    const flagThisMonth =
      moment().startOf("month").isSame(fromMoment, "day") &&
      moment().endOf("month").isSame(toMoment, "day");

    switch (type) {
      case TypeColumnDialog.CURRENT:
        return flagThisMonth
          ? `${unity} 01~${moment().format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years") &&
            fromMoment.isSame(toMoment, "months")
          ? `${unity} ${fromMoment.format("DD")}~${moment(
              moment(to).subtract(1, "days")
            ).format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years")
          ? `${unity} ${fromMoment.format("DD/MMM")}~${moment(
              moment(to).subtract(1, "days")
            ).format("DD/MMM/YY")}`
          : `${unity} ${moment(moment(from).subtract(1, "days")).format(
              "DD/MMM/YY"
            )}~${moment(moment(to).subtract(1, "days")).format("DD/MMM/YY")}`;
      case TypeColumnDialog.PREVIOUS_MONTH:
        return flagThisMonth
          ? `${unity} 01~${moment().subtract(1, "months").format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years") &&
            fromMoment.isSame(toMoment, "months")
          ? `${unity} ${fromMoment.format("DD")}~${moment(
              moment(to).subtract(1, "days")
            )
              .subtract(1, "months")
              .format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years")
          ? `${unity} ${fromMoment.format("DD/MMM")}~${moment(
              moment(to).subtract(1, "days")
            )
              .subtract(1, "months")
              .format("DD/MMM/YY")}`
          : `${unity} ${moment(moment(from).subtract(1, "days"))
              .subtract(1, "months")
              .format("DD/MMM/YY")}~${moment(moment(to).subtract(1, "days"))
              .subtract(1, "months")
              .format("DD/MMM/YY")}`;
      case TypeColumnDialog.PREVIOUS_YEAR:
        return flagThisMonth
          ? `${unity} 01~${moment().subtract(1, "years").format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years") &&
            fromMoment.isSame(toMoment, "months")
          ? `${unity} ${fromMoment.format("DD")}~${moment(
              moment(to).subtract(1, "days")
            )
              .subtract(1, "years")
              .format("DD/MMM/YY")}`
          : fromMoment.isSame(toMoment, "years")
          ? `${unity} ${fromMoment.format("DD/MMM")}~${moment(
              moment(to).subtract(1, "days")
            )
              .subtract(1, "years")
              .format("DD/MMM/YY")}`
          : `${unity} ${moment(moment(from).subtract(1, "days"))
              .subtract(1, "years")
              .format("DD/MMM/YY")}~${moment(moment(to).subtract(1, "days"))
              .subtract(1, "years")
              .format("DD/MMM/YY")}`;
    }
  }
}
