import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IListLogsDataDTO } from "../dtos/IListLogsDataDTO";
import { IListLogsService } from "../models/IListLogsService";

@injectable()
export class ListLogsService implements IListLogsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: {
    offset: number;
    limit: number;
  }): Promise<IListLogsDataDTO> {
    const logs = await this.httpInstance.get("/logs", {
      params: { ...data },
    });

    return logs;
  }
}
