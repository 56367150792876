import Layout from "@components/Layout";
import Title from "@components/Title";
import {
  Button,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridColumnIcon } from "@material-ui/data-grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isBefore, isSameDay } from "date-fns";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import { v4 } from "uuid";

import {
  UserAccessesProvider,
  useUserAccessesContext,
} from "./UserAccessesCostsContext";

const Container: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const userAccessesContext = useUserAccessesContext();

  const columnsUserAccesses: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueGetter: (params) => {
        return params.row.beat.name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueGetter: (params) => {
        return params.row.beat.email;
      },
    },
    {
      field: "screen",
      headerName: "Tela",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      valueGetter: (params) => {
        return params.row.beat.screen;
      },
    },
    {
      field: "date",
      headerName: "Data de acesso",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueGetter: (params) => {
        return moment(params.row.when).format("DD/MM/YYYY");
      },
    },
    {
      field: "time",
      headerName: "Hora de acesso",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueGetter: (params) => {
        return moment(params.row.when).format("HH:mm");
      },
    },
  ];

  const rowsOrdered = useMemo(() => {
    const order = [...userAccessesContext.rowsUserAccesses.content];
    console.log({ order });
    return order.map((e) => ({ ...e, id: v4() }));
  }, [userAccessesContext.rowsUserAccesses.content]);

  return (
    <Layout enableMargins>
      <Title>Histórico de acessos dos usuários</Title>

      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        style={{ margin: "1rem" }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            clearable={false}
            format="dd/MM/yyyy"
            label="Início"
            value={userAccessesContext.pagination.from}
            onChange={(date) => {
              if (date) {
                userAccessesContext.setPagination((oldState) => ({
                  ...oldState,
                  from: date,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <KeyboardDatePicker
            fullWidth
            label="Fim"
            autoOk
            format="dd/MM/yyyy"
            value={userAccessesContext.pagination.to}
            onChange={(date) => {
              if (date) {
                userAccessesContext.setPagination((oldState) => ({
                  ...oldState,
                  to: date,
                }));
              }
            }}
            shouldDisableDate={(day) => {
              if (day && userAccessesContext.pagination.from) {
                if (isSameDay(day, userAccessesContext.pagination.from)) {
                  return false;
                }
                return isBefore(day, userAccessesContext.pagination.from);
              }
              return false;
            }}
          />
        </Grid>
        <Grid item container justify="center" xs={12}>
          <Button
            variant="contained"
            startIcon={<GridColumnIcon />}
            style={{ margin: "1rem" }}
            color="primary"
            onClick={() => userAccessesContext.downloadUserAccesses()}
            endIcon={
              userAccessesContext.loadingExcel && (
                <CircularProgress
                  style={{ height: "2.5rem", width: "2.5rem" }}
                  color="secondary"
                />
              )
            }
          >
            Baixar Excel
          </Button>
        </Grid>
      </Grid>
      <div style={{ height: "100%" }}>
        <DataGrid
          columns={columnsUserAccesses}
          rows={rowsOrdered}
          loading={userAccessesContext.loadingTable}
          error={userAccessesContext.errorLoadingTable ? true : null}
          autoPageSize
          pagination
          paginationMode="server"
          onPageSizeChange={(limit) =>
            userAccessesContext.setPagination((oldState) => ({
              ...oldState,
              limit,
            }))
          }
          onPageChange={(page) =>
            userAccessesContext.setPagination((oldState) => ({
              ...oldState,
              page: page + 1,
            }))
          }
          pageSize={userAccessesContext.pagination.limit}
          page={userAccessesContext.pagination.page - 1}
          rowCount={userAccessesContext.rowsUserAccesses.total}
        />
      </div>
    </Layout>
  );
};

const UserAccessesPage: React.FC = () => {
  return (
    <UserAccessesProvider>
      <Container />
    </UserAccessesProvider>
  );
};

export { UserAccessesPage };
