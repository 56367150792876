import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import qrCodeImg from "@assets/qr-code.png";
import qrCodeBelemImg from "@assets/qrcode-belem.png";
import qrCodePvhImg from "@assets/qrcode-pvh.png";
import CallCenterData from "@components/CallCenterData";
import Layout from "@components/Layout";
import Title from "@components/Title";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      paddingTop: "3.6rem",
      width: "min-content",
    },
    callCenterData: {
      marginLeft: "4.2rem",
      paddingTop: "1.6rem",
    },
    callCenterName: {
      fontSize: "1.6rem",
      marginLeft: "1.4rem",
    },
    grid: {
      margin: "0 7rem",
      width: "calc(100% - 14rem)",
    },
    content: {
      margin: "2rem 0",
    },
  })
);

const CallCenters: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.content}>
        <Title title="Centrais de Atendimento" />
        <Grid className={classes.grid} container justify="space-evenly">
          <Grid item>
            <Card className={classes.card} elevation={8}>
              <CardContent>
                <Typography className={classes.callCenterName} variant="button">
                  Manaus - AM
                </Typography>
                <div className={classes.callCenterData}>
                  <CallCenterData
                    phone="(92) 2125-0050"
                    email="pedidos@atem.com.br"
                    whatsapp={{
                      link: "https://wa.me/message/DYGMEFNPZXYZO1",
                      phone: "(92) 9 8123-9462",
                    }}
                    qrcode={qrCodeImg}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card} elevation={8}>
              <CardContent>
                <Typography className={classes.callCenterName} variant="button">
                  BELÉM E MIRITITUBA - PA
                </Typography>
                <div className={classes.callCenterData}>
                  <CallCenterData
                    phone="(92) 2125-0050"
                    email="pedidos@atem.com.br"
                    whatsapp={{
                      link: "https://wa.me/message/MITRHAVZFOC7D1",
                      phone: "(91) 9 8412-4536",
                    }}
                    qrcode={qrCodeBelemImg}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card} elevation={8}>
              <CardContent>
                <Typography
                  align="center"
                  className={classes.callCenterName}
                  variant="button"
                >
                  Cruzeiro do Sul (AC), Porto Velho (RO), Cuiabá (MT)
                </Typography>
                <div className={classes.callCenterData}>
                  <CallCenterData
                    phone="(92) 2125-0050"
                    email="pedidos@atem.com.br"
                    whatsapp={{
                      link: "https://wa.me/message/WOZYMCGM2V7HH1",
                      phone: "(69) 9 9211-5768",
                    }}
                    qrcode={qrCodePvhImg}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default CallCenters;
