import {
  Box,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Skeleton } from "@material-ui/lab";
import { formatCurrency } from "@utils/index";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      boxIcon: { borderRadius: theme.shape.borderRadius * 2, padding: "1rem" },
      spacing: { "& > div": { margin: "1rem" } },
      icon: {
        fontSize: "2rem",
        [theme.breakpoints.up("sm")]: { fontSize: "3.4rem" },
      },
      value: {
        fontSize: "2.4rem",
        fontWeight: "bold",
        [theme.breakpoints.up("sm")]: { fontSize: "3.2rem" },
      },
      title: {
        fontSize: "1.4rem",
        fontWeight: "normal",
        [theme.breakpoints.up("sm")]: { fontSize: "1.6rem" },
      },
      marginValue: { fontSize: "1.4rem" },
      marginPositive: { color: theme.palette.success.main },
      marginNegative: { color: theme.palette.error.main },
    })
);

export interface BoxInfoProps {
  colorBoxIcon: string;
  icon: React.ReactNode;
  value: string;
  title: string;
  showDownUpValue?: boolean;
  isDownUpValue?: boolean;
  loading?: boolean;
  margin?: number;
}

const BoxInfo: React.FC<BoxInfoProps> = ({
  colorBoxIcon,
  icon,
  value,
  title,
  loading,
  margin,
  showDownUpValue,
  isDownUpValue,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card>
      <Grid container className={classes.spacing} alignItems="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          className={clsx(classes.boxIcon)}
          style={{
            backgroundColor: theme.palette.hexToRGBWithAlpha(colorBoxIcon, 0.5),
            width: "auto",
          }}
        >
          {
            // @ts-ignore
            React.cloneElement(icon, { className: classes.icon })
          }
        </Grid>
        <Grid container direction="column" style={{ width: "auto" }}>
          <Typography
            variant="h4"
            className={clsx(
              classes.value,
              showDownUpValue
                ? {
                    [classes.marginNegative]: isDownUpValue! === false,
                    [classes.marginPositive]: isDownUpValue,
                  }
                : null
            )}
          >
            {loading ? <Skeleton /> : value}
          </Typography>
          {margin ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowUpwardIcon
                style={{
                  fontSize: "1.4rem",
                  transform: margin < 0 ? "rotate(180deg)" : "rotate(0deg)",
                }}
                className={clsx({
                  [classes.marginNegative]: margin < 0,
                  [classes.marginPositive]: margin > 0,
                })}
              />
              <Typography
                className={clsx(classes.marginValue, {
                  [classes.marginNegative]: margin < 0,
                  [classes.marginPositive]: margin > 0,
                })}
              >
                {formatCurrency(margin, { minimumFractionDigits: 4 })}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {title.length > 20 ? (
            <Tooltip title={title}>
              <Typography variant="h6" className={classes.title}>
                {title.length > 20 ? title.slice(0, 20) + "..." : title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export { BoxInfo };
