import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ICreateHandoutAckDTO } from "../dtos/ICreateHandoutAckDTO";
import { ICreateHandoutAckService } from "../models/ICreateHandoutAckService";

@injectable()
export class CreateHandoutAckService implements ICreateHandoutAckService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateHandoutAckDTO) {
    await this.httpInstance.post("/handouts/ack", data);
  }
}
