import Layout from "@components/Layout";
import Title from "@components/Title";
import { Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { formatCurrency } from "@utils/index";
import { format } from "date-fns";
import React, { useMemo } from "react";
import {
  ActiveCostsProvider,
  useActiveCostsContext,
} from "./ActiveCostsContext";
import { DialogEditCostActive } from "./DialogEditCostActive";
import { DialogRemoveCostActive } from "./DialogRemoveCostActive";

const Container: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const activeCostsContext = useActiveCostsContext();

  const rowsOrdered = useMemo(() => {
    const order = [...activeCostsContext.rowsActiveCosts];
    order.sort((a, b) => {
      return +b.start_at - +a.start_at;
    });
    return order;
  }, [activeCostsContext.rowsActiveCosts]);

  const columnsActiveCosts: GridColDef[] = [
    {
      field: "center",
      headerName: "Centro",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "store",
      headerName: "Depósito",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "state",
      headerName: "UF",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 200 : undefined,
    },
    {
      field: "material",
      headerName: "Material",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 350 : undefined,
    },
    {
      field: "type",
      headerName: "Tipo",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
    },
    {
      field: "value",
      headerName: "Valor",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "number",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number, {
          minimumFractionDigits: 6,
          maximumFractionDigits: 6,
        }),
    },
    {
      field: "start_at",
      headerName: "Data de Início",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueFormatter: (props) => format(props.value as Date, "dd/MM/yyyy"),
    },
    {
      field: "end_at",
      headerName: "Data de Fim",
      align: "center",
      headerAlign: "center",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      type: "date",
      valueFormatter: (props) =>
        props.value ? format(props.value as Date, "dd/MM/yyyy") : "",
    },
    {
      field: "actions",
      headerName: " ",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      filterable: false,
      sortable: false,
      renderCell: (props) => {
        return (
          <Grid container justify="center">
            <IconButton
              onClick={() => {
                activeCostsContext.setOpenDialogEdit({
                  id: props.id as string,
                  open: true,
                });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                activeCostsContext.setOpenDialogDelete({
                  id: props.id as string,
                  open: true,
                })
              }
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  return (
    <Layout enableMargins>
      <Title>Consultar custos ativos</Title>

      <div style={{ height: "100%" }}>
        <DataGrid
          columns={columnsActiveCosts}
          rows={rowsOrdered}
          loading={activeCostsContext.loadingTable}
          error={activeCostsContext.errorLoadingTable ? true : null}
        />
      </div>
      <DialogRemoveCostActive />
      <DialogEditCostActive />
    </Layout>
  );
};

const ActiveCostsPage: React.FC = () => {
  return (
    <ActiveCostsProvider>
      <Container />
    </ActiveCostsProvider>
  );
};

export { ActiveCostsPage };
