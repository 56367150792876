import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { IDownloadPDFInvoicesService } from "@modules/invoices/models/IDownloadPDFInvoicesService";
import AppError from "@utils/AppError";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useFormQueryInvoices } from "./FormQueryInvoicesContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogTitle: {
      borderBottom: "1px solid rgba(0,0,0,0.12)",
    },
    dialogContent: {
      paddingTop: "2.4rem",
    },
  })
);

const ModalDownload: React.FC = () => {
  const classes = useStyles();

  const formQueryInvoicesContext = useFormQueryInvoices();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const downloaInvoicesService = iocContext.serviceContainer.get<
    IDownloadPDFInvoicesService
  >(Types.Invoices.IDownloadPDFInvoicesService);

  const { invoicesSelectedToDownload } = formQueryInvoicesContext;

  const [checkbox, setCheckbox] = useState({
    XML: false,
    PDF: false,
  });
  const [loadingDownloadNFEs, setLoadingDownloadNFEs] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  const downloadNFEs = useCallback(async () => {
    try {
      setLoadingDownloadNFEs(true);

      const getAllInvoicesSelected = Object.keys(invoicesSelectedToDownload)
        .map((key) => {
          if (invoicesSelectedToDownload[key]) {
            const title = formQueryInvoicesContext.dataQuery.find(
              (title) => title.id === key
            );
            if (title) {
              return title;
            }
          }
          return null;
        })
        .filter((ele) => ele !== null)
        .map((title) => {
          if (title) {
            return title.accessKey;
          }
          return "";
        });

      const url = await downloaInvoicesService.execute({
        codes: getAllInvoicesSelected as string[],
        type:
          checkbox.PDF && checkbox.XML ? "all" : checkbox.PDF ? "pdf" : "xml",
      });

      window.open(url);
    } catch (error) {
      console.error("error", error);
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao baixar a(s) nota(s)", {
        variant: "error",
      });
    } finally {
      setLoadingDownloadNFEs(false);
    }
  }, [
    checkbox.PDF,
    checkbox.XML,
    downloaInvoicesService,
    enqueueSnackbar,
    formQueryInvoicesContext.dataQuery,
    invoicesSelectedToDownload,
  ]);

  const { XML, PDF } = checkbox;
  const error = [XML, PDF].filter((v) => v).length === 0;

  return (
    <Dialog open={formQueryInvoicesContext.openModalDownload}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h6" style={{ color: "black", fontWeight: "bold" }}>
          Baixar itens em formato:
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl error={error} component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={XML} onChange={handleChange} name="XML" />
              }
              label="XML"
            />
            <FormControlLabel
              control={
                <Checkbox checked={PDF} onChange={handleChange} name="PDF" />
              }
              label="PDF"
            />
          </FormGroup>
          <FormHelperText>{error ? "Selecione um formato" : ""}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => formQueryInvoicesContext.setOpenModalDownload(false)}
        >
          Cancelar
        </Button>
        <Button
          disabled={error}
          color="primary"
          variant="contained"
          onClick={downloadNFEs}
          startIcon={
            loadingDownloadNFEs && (
              <CircularProgress
                color="secondary"
                style={{ height: "2.5rem", width: "2.5rem" }}
              />
            )
          }
        >
          Baixar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDownload;
