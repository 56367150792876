import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IGetHistoryActiveSettingsCostsDTO } from "@modules/settingsCosts/dtos/IGetHistoryActiveSettingsCostsDTO";
import { IDataEditActiveSettingsCosts } from "@modules/settingsCosts/models/IEditActiveSettingsCostService";
import { IGetHistoryActiveSettingsCostsService } from "@modules/settingsCosts/models/IGetHistoryActiveSettingsCostsService";
import AppError from "@utils/AppError";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

export interface ActiveCostsContextProps {
  rowsHistoryCosts: IGetHistoryActiveSettingsCostsDTO;
  loadingTable: boolean;
  openDialogDelete: DialogDeleteProps;
  openDialogEdit: DialogEditProps;
  pagination: Pagination;
  errorLoadingTable: boolean;
  setOpenDialogDelete: React.Dispatch<React.SetStateAction<DialogDeleteProps>>;
  setOpenDialogEdit: React.Dispatch<React.SetStateAction<DialogEditProps>>;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  fetchHistoryCosts: () => Promise<void>;
}

export const HistorySettingsCostsContext = React.createContext(
  {} as ActiveCostsContextProps
);

interface DialogDeleteProps {
  open: boolean;
  id: string | null;
}
interface DialogEditProps {
  open: boolean;
  id: string | null;
  data: IDataEditActiveSettingsCosts | null;
}
interface Pagination {
  page: number;
  limit: number;
  from: Date;
  to: Date;
}

export const HistorySettingsCostsProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const getHistoryCostsService = iocContext.serviceContainer.get<
    IGetHistoryActiveSettingsCostsService
  >(Types.SettingsCosts.IGetHistoryActiveSettingsCostsService);

  const [pagination, setPagination] = useState<Pagination>({
    limit: 10,
    page: 1,
    from: startOfMonth(new Date()),
    to: endOfMonth(addMonths(new Date(), 3)),
  });
  const [rowsHistoryCosts, setRowsHistoryCosts] = useState<
    IGetHistoryActiveSettingsCostsDTO
  >({ content: [], total: 0, page: 0 });
  const [loadingTable, setLoadingTable] = useState(true);
  const [openDialogDelete, setOpenDialogDelete] = useState<DialogDeleteProps>({
    open: false,
    id: null,
  });
  const [openDialogEdit, setOpenDialogEdit] = useState<DialogEditProps>({
    open: false,
    id: null,
    data: null,
  });
  const [errorLoadingTable, setErrorLoadingTable] = useState(false);

  const fetchHistoryCosts = useCallback(async () => {
    try {
      setLoadingTable(true);
      const resp = await getHistoryCostsService.execute({
        from: pagination.from,
        to: pagination.to,
        limit: pagination.limit,
        page: pagination.page,
      });
      setRowsHistoryCosts(resp);
      setErrorLoadingTable(false);
    } catch (error) {
      if (error instanceof AppError) {
        enqueueSnackbar(error.message, { variant: error.variant });
      } else {
        enqueueSnackbar("Erro ao baixar dados de custos", { variant: "error" });
      }
      setErrorLoadingTable(true);
    } finally {
      setLoadingTable(false);
    }
  }, [
    enqueueSnackbar,
    getHistoryCostsService,
    pagination.from,
    pagination.limit,
    pagination.page,
    pagination.to,
  ]);

  useEffect(() => {
    fetchHistoryCosts();
  }, [fetchHistoryCosts]);

  return (
    <HistorySettingsCostsContext.Provider
      value={{
        rowsHistoryCosts,
        loadingTable,
        openDialogDelete,
        openDialogEdit,
        pagination,
        errorLoadingTable,
        setOpenDialogDelete,
        setOpenDialogEdit,
        fetchHistoryCosts,
        setPagination,
      }}
    >
      {children}
    </HistorySettingsCostsContext.Provider>
  );
};

export const useHistorySettingsCostsContext = () => {
  const context = React.useContext(HistorySettingsCostsContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useHistoryCostsContext não pode ser utilizado fora de um HistoryCostsProvider"
    );
  }
  return context;
};
