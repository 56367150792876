import razaoSocialImg from "@assets/razao-social.png";
import { SelectAutocomplete } from "@components/SelectAutocomplete";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IDownloadExcelSalesAnalyticsService } from "@modules/orders/models/IDownloadExcelSalesAnalyticsService";
import AppError from "@utils/AppError";
import { downloadFile } from "@utils/index";
import clsx from "clsx";
import { isBefore, isSameDay } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useFormQuery } from "./FormQueryContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    iconNotDisabled: {
      opacity: "0.6",
    },
    labelChip: { marginBottom: "0.5rem" },
    containerChip: {
      "& :not(:first-child)": {
        marginLeft: "4rem",
      },
    },
    selectedChip: {
      backgroundColor: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      "&:focus": {
        backgroundColor: theme.palette.primaryLight.main,
      },
    },
  })
);

const FormQuery: React.FC = () => {
  const classes = useStyles();
  const userState = useUserState();
  const formQueryContext = useFormQuery();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const downloadExcelSalesAnalytics = iocContext.serviceContainer.get<
    IDownloadExcelSalesAnalyticsService
  >(Types.Orders.IDownloadExcelSalesAnalyticsService);

  const [openDialogCNPJ, setOpenDialogCNPJ] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const downloadExcel = useCallback(async () => {
    if (!formQueryContext.query.startDate || !formQueryContext.query.endDate)
      return;
    try {
      setLoadingExcel(true);
      const [url, fileName] = await downloadExcelSalesAnalytics.execute({
        beginDate: formQueryContext.query.startDate,
        endDate: formQueryContext.query.endDate,
        partnerID: userState.state.bpID,
        categories: formQueryContext.query.categories || [],
        documents: formQueryContext.query.CNPJ.map((e) => e.CNPJ).filter(
          (e) => e
        ) as string[],
      });
      if (url) {
        downloadFile(url, fileName);
      } else {
        enqueueSnackbar("Sem resultados para os clientes selecionados", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.log(error);
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar(
        "Ocorreu um erro ao baixar arquivo excel, tente novamente.",
        { variant: "error" }
      );
    } finally {
      setLoadingExcel(false);
    }
  }, [
    downloadExcelSalesAnalytics,
    enqueueSnackbar,
    formQueryContext.query.CNPJ,
    formQueryContext.query.categories,
    formQueryContext.query.endDate,
    formQueryContext.query.startDate,
    userState.state.bpID,
  ]);

  return (
    <Grid container justify="center" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <SelectAutocomplete
          openDialog={openDialogCNPJ}
          setOpenDialog={setOpenDialogCNPJ}
          multiple
          onChange={(value) =>
            formQueryContext.setQuery((state) => ({ ...state, CNPJ: value }))
          }
          value={formQueryContext.query.CNPJ}
          options={userState.state.listCNPJ.filter(
            (e) => e.isParticipatingInTheCurrentMarketingPlan
          )}
          loading={userState.state.loadingCNPJ}
          title="Cliente"
          allSelected={
            formQueryContext.query.CNPJ.length ===
            userState.state.listCNPJ.length
          }
          getOptionKey={(value) => value.customerID}
          getOptionLabel={(value) =>
            `${value.customerID} - ${value.companyName}`
          }
          startIcon={
            <img
              width="24"
              src={razaoSocialImg}
              alt="Cliente ícone"
              className={classes.iconNotDisabled}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <KeyboardDatePicker
          autoOk
          fullWidth
          clearable={false}
          format="dd/MM/yyyy"
          label="Início"
          value={formQueryContext.query.startDate}
          onChange={(date) => {
            if (date) {
              formQueryContext.setQuery((state) => ({
                ...state,
                startDate: date,
              }));
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <KeyboardDatePicker
          fullWidth
          label="Fim"
          autoOk
          format="dd/MM/yyyy"
          value={formQueryContext.query.endDate}
          onChange={(date) => {
            if (date)
              formQueryContext.setQuery((state) => ({
                ...state,
                endDate: date,
              }));
          }}
          shouldDisableDate={(day) => {
            if (day && formQueryContext.query.startDate) {
              if (isSameDay(day, formQueryContext.query.startDate)) {
                return false;
              }
              return isBefore(day, formQueryContext.query.startDate);
            }
            return false;
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel className={classes.labelChip}>Filtrar por</InputLabel>
        <Grid container className={classes.containerChip}>
          {formQueryContext.dataProducts &&
            formQueryContext.dataProducts.map((product) => (
              <Chip
                component="span"
                label={product}
                className={clsx({
                  [classes.selectedChip]: (
                    formQueryContext.query.categories || []
                  ).includes(product),
                })}
                onClick={() =>
                  formQueryContext.setQuery((state) => ({
                    ...state,
                    categories:
                      state.categories && state.categories.includes(product)
                        ? (state.categories || []).filter((e) => e !== product)
                        : [...(state.categories || []), product],
                  }))
                }
              />
            ))}
        </Grid>
      </Grid>

      <Grid item container justify="center" alignItems="center" xs={12}>
        <Button
          variant="contained"
          startIcon={<GridOnIcon />}
          style={{ margin: "1rem" }}
          color="primary"
          onClick={downloadExcel}
          endIcon={
            loadingExcel && (
              <CircularProgress
                style={{ height: "2.5rem", width: "2.5rem" }}
                color="secondary"
              />
            )
          }
        >
          Baixar Excel
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormQuery;
