import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveSettingsCostsDTO } from "../dtos/IGetActiveSettingsCostsDTO";
import { ISearchSettingsCostServiceDTO } from "../dtos/ISearchSettingsCostServiceDTO";
import { ISearchSettingsCostService } from "../models/ISearchSettingsCostService";

@injectable()
export class SearchSettingsCostService implements ISearchSettingsCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(
    data: ISearchSettingsCostServiceDTO
  ): Promise<IGetActiveSettingsCostsDTO[]> {
    return this.httpInstance.get<IGetActiveSettingsCostsDTO[]>(
      "/settings-costs",
      {
        params: { ...data, material: data.material.join(",") },
      }
    );
  }
}
