import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUnlockUserService } from "../models/IUnlockUserService";

@injectable()
export class UnlockUserService implements IUnlockUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(userID: string): Promise<{ message: string }> {
    return this.httpInstance.patch<{ message: string }>(
      `/users/unlock/${userID}`
    );
  }
}
