import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IChangePasswordService } from "../models/IChangePasswordService";

@injectable()
export class ChangePasswordService implements IChangePasswordService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(password: string): Promise<{ message: string }> {
    const response = await this.httpInstance.put<{ message: string }>(
      "/users/change-password",
      {
        password,
      }
    );

    return response;
  }
}
