import Layout from "@components/NewLayout";
import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { FinancialCharts } from "./FinancialCharts";
import { FinancialDashboardProvider } from "./FinancialDashboardContext";
import FinancialDashboardFilters from "./FinancialDashboardFilters";

const useStyles = makeStyles({
  addFilter: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 48,
    "& span:first-child": {
      fontSize: 24,
      color: "#313B45",
      fontWeight: 700,
    },
    "& span:last-child": {
      fontSize: 16,
      color: "#D91F05",
      fontWeight: 600,
      cursor: "pointer",
    },
  },
});

const Container: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.addFilter}>
        <span>Clientes inadimplentes</span>
        <span onClick={() => setOpen(!open)}>ADICIONAR FILTROS</span>
      </div>
      <FinancialDashboardFilters openDialog={open} setOpenDialog={setOpen} />
      <Grid container spacing={2}>
        <FinancialCharts />
      </Grid>
      <div style={{ height: 30 }}></div>
    </Layout>
  );
};

const FinancialDashboard: React.FC = () => {
  return (
    <FinancialDashboardProvider>
      <Container />
    </FinancialDashboardProvider>
  );
};

export { FinancialDashboard };
