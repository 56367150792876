import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { IUploadTitleImageService } from "@modules/titles/models/IUploadTitleImageService";
import AppError from "@utils/AppError";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import DragInDropBox from "../../../components/DragInDropBox";
import { useFormQueryTitle } from "./formQueryTitleContext";
import FormUploadValidationSchema from "./FormUploadValidationSchema";

const useStyles = makeStyles((theme) =>
  createStyles({
    centerBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxUpload: {
      paddingTop: "2.4rem",
      paddingBottom: "3.2rem",

      border: "0.2rem dashed rgba(0,0,0,0.12)",
      borderRadius: theme.shape.borderRadius,

      "& svg": {
        fontSize: "4rem",
      },
    },
    boxUploadInvalidFile: {
      border: "0.2rem dashed rgba(0,0,0,0.12)",
      borderColor: theme.palette.primary.main,
    },
    bounceUpload: {
      animationName: "$bounce",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
    },
    bounceInDragging: {
      animationName: "$bounceIn",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationDirection: "alternate",
    },
    input: {
      display: "none",
    },
    "@keyframes bounce": {
      from: {
        transform: "translateY(0px)",
      },
      to: {
        transform: "translateY(-15px)",
      },
    },
    "@keyframes bounceIn": {
      "0%, 20%, 40%, 60%, 80%, 100%": {
        transitionTimingFunction: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
      },
      "0%": {
        opacity: 0,
        transform: "scale3d(.3, .3, .3)",
      },
      "20%": {
        transform: "scale3d(1.1, 1.1, 1.1)",
      },
      "40%": {
        transform: "scale3d(.9, .9, .9)",
      },
      "60%": {
        transform: "scale3d(1.03, 1.03, 1.03)",
      },
      "80%": {
        transform: "scale3d(.97, .97, .97)",
      },
      "100%": {
        transform: "scale3d(1, 1, 1)",
      },
    },
    dialogTitle: {
      borderBottom: "1px solid rgba(0,0,0,0.12)",
    },
    dialogContent: {
      paddingTop: "2.4rem",
    },
  })
);

const ModalUpload: React.FC = () => {
  const classes = useStyles();

  const formQueryTitleContext = useFormQueryTitle();
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingUpload, setLoadingUpload] = useState(false);

  const initialValues = {
    comments: "",
    file: null,
  };

  return (
    <Dialog open={formQueryTitleContext.openModalUpload.openModalUpload}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant="h5" style={{ color: "black", fontWeight: "bold" }}>
          Enviar Comprovante
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              try {
                setLoadingUpload(true);
                const uploadTitleImageService = iocContext.serviceContainer.get<
                  IUploadTitleImageService
                >(Types.Titles.IUploadTitleImageService);

                const image = await uploadTitleImageService.execute({
                  CNPJ: formQueryTitleContext.openModalUpload.data.CNPJ,
                  atemID: formQueryTitleContext.openModalUpload.data.atemID,
                  file: (values.file as unknown) as File,
                  year: formQueryTitleContext.openModalUpload.data.year,
                  invoiceID:
                    formQueryTitleContext.openModalUpload.data.invoiceID,
                  comments: values.comments,
                });

                const idx = formQueryTitleContext.dataQuery.findIndex(
                  (title) =>
                    title.id ===
                    formQueryTitleContext.openModalUpload.data.invoiceID
                );

                if (idx > -1) {
                  const dataQuery = [...formQueryTitleContext.dataQuery];
                  dataQuery[idx].imageTitle = {
                    id: image.id,
                    link: image.link,
                    userSeen: "",
                  };
                  formQueryTitleContext.setDataQuery(dataQuery);
                }

                enqueueSnackbar("Upload feito com sucesso", {
                  variant: "success",
                });

                formQueryTitleContext.setOpenModalUpload({
                  ...formQueryTitleContext.openModalUpload,
                  openModalUpload: false,
                });
              } catch (error) {
                if (error instanceof AppError) {
                  return enqueueSnackbar(error.message, {
                    variant: error.variant,
                  });
                }
                enqueueSnackbar("Ocorreu um erro ao enviar arquivo.", {
                  variant: "error",
                });
              } finally {
                setLoadingUpload(false);
              }
            }}
            validationSchema={FormUploadValidationSchema}
          >
            {(props) => (
              <Form id="formUpload">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography component="span">
                      Adicionar comentário
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="comments"
                      id="comments"
                      as={TextField}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">Comprovante</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DragInDropBox
                      error={Boolean(props.touched.file) && !!props.errors.file}
                      helperText={
                        props.touched.file && props.errors.file
                          ? props.errors.file
                          : ""
                      }
                      value={props.values.file}
                      onChange={(file) => props.setFieldValue("file", file)}
                      onTouch={() => props.setFieldTouched("file", true)}
                      touched={Boolean(props.touched.file)}
                    />
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: "1.2rem", marginTop: "0.8rem" }}>
                  Os formatos aceitos são .pdf e imagens
                </Typography>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={() =>
                      formQueryTitleContext.setOpenModalUpload({
                        ...formQueryTitleContext.openModalUpload,
                        openModalUpload: false,
                      })
                    }
                  >
                    Fechar
                  </Button>
                  <Button
                    disabled={!Boolean(props.values.file)}
                    type="submit"
                    form="formUpload"
                    color="primary"
                    startIcon={
                      loadingUpload && (
                        <CircularProgress
                          color="secondary"
                          style={{ height: "2.5rem", width: "2.5rem" }}
                        />
                      )
                    }
                  >
                    Enviar
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalUpload;
