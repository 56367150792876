export const OrdersType = {
  IQueryOrdersService: Symbol("IQueryOrdersService"),
  ICreateOrderService: Symbol("ICreateOrderService"),
  ICreatePDFOrdersService: Symbol("ICreatePDFOrdersService"),
  IGetClientInfoService: Symbol("IGetClientInfoService"),
  IGetPriceProductsService: Symbol("IGetPriceProductsService"),
  ITimeLimitLocationService: Symbol("ITimeLimitLocationService"),
  ICancelOrderService: Symbol("ICancelOrderService"),
  IQueryOrdersByStatus: Symbol("IQueryOrdersByStatus"),
  IQuerySalesAnalyticsService: Symbol("IQuerySalesAnalyticsService"),
  ICreatePDFOrdersByStatusService: Symbol("ICreatePDFOrdersByStatusService"),
  IDownloadExcelOrdersByStatusService: Symbol(
    "IDownloadExcelOrdersByStatusService"
  ),
  IDownloadExcelOrdersMarginByStatusService: Symbol(
    "IDownloadExcelOrdersMarginByStatusService"
  ),
  IDownloadExcelSalesAnalyticsService: Symbol(
    "IDownloadExcelSalesAnalyticsService"
  ),
  IGetOrdersAggregatedPerDayService: Symbol(
    "IGetOrdersAggregatedPerDayService"
  ),
  IQueryOrdersByStatusAggregatorsService: Symbol(
    "IQueryOrdersByStatusAggregatorsService"
  ),
};
