import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { ICnpjDTO } from "@modules/user/dtos/ICnpjDTO";
import AppError from "@utils/AppError";
import { FreightType, IAddress, IClient, IProduct } from "@utils/interfaces";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface SimulationCostsContext {
  loadingCustomerData: boolean;
  loadingPrices: boolean;
  loadingFirstQuery: boolean;
  dataCustomer: IClient | null;
  freightType: FreightType | null;
  cnpjCustomer: ICnpjDTO | null;
  addressSelected: IAddress | null;
  filialId: string | null;
  storeID: string | null;
  branchName: string | null;
  productsSimulation: IProductPriceMargin[];
  setDataCustomer: React.Dispatch<React.SetStateAction<IClient | null>>;
  setLoadingCustomerData: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingPrices: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingFirstQuery: React.Dispatch<React.SetStateAction<boolean>>;
  setCnpjCustomer: React.Dispatch<React.SetStateAction<ICnpjDTO | null>>;
  setAddressSelected: React.Dispatch<React.SetStateAction<IAddress | null>>;
  setFilialId: React.Dispatch<React.SetStateAction<string | null>>;
  setBranchName: React.Dispatch<React.SetStateAction<string | null>>;
  setStoreID: React.Dispatch<React.SetStateAction<string | null>>;
  setFreightType: React.Dispatch<React.SetStateAction<FreightType | null>>;
  setProductsSimulation: React.Dispatch<
    React.SetStateAction<IProductPriceMargin[]>
  >;
  fetchCnpjData: () => void;
}

export interface IProductPriceMargin extends IProduct {
  key: string;
}

const SimulationCostsContext = createContext<SimulationCostsContext>(
  {} as SimulationCostsContext
);

const SimulationCostsProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const { enqueueSnackbar } = useSnackbar();
  const userState = useUserState();

  const [loadingCustomerData, setLoadingCustomerData] = useState(false);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [loadingFirstQuery, setLoadingFirstQuery] = useState(true);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [cnpjCustomer, setCnpjCustomer] = useState<ICnpjDTO | null>(null);
  const [freightType, setFreightType] = useState<FreightType | null>(null);
  const [addressSelected, setAddressSelected] = useState<IAddress | null>(null);
  const [filialId, setFilialId] = useState<string | null>(null);
  const [storeID, setStoreID] = useState<string | null>(null);
  const [branchName, setBranchName] = useState<string | null>(null);

  const [productsSimulation, setProductsSimulation] = useState<
    IProductPriceMargin[]
  >([]);

  const getClientInfoService = iocContext.serviceContainer.get<
    IGetClientInfoService
  >(Types.Orders.IGetClientInfoService);

  const fetchCnpjData = useCallback(async () => {
    if (!cnpjCustomer) return;
    try {
      setLoadingCustomerData(true);
      const CNPJData = await getClientInfoService.execute(
        cnpjCustomer.CNPJ,
        true
      );
      setDataCustomer(CNPJData);
      setFreightType(CNPJData.freightType);
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }

      enqueueSnackbar("Ocorreu um erro ao buscar dados do cliente", {
        variant: "error",
      });
    } finally {
      setLoadingCustomerData(false);
      setLoadingFirstQuery(false);
    }
  }, [cnpjCustomer, enqueueSnackbar, getClientInfoService]);

  useEffect(() => {
    if (cnpjCustomer !== null && dataCustomer) {
      const withdrawBasis = dataCustomer.withdrawBasis.find((withdrawBasis) =>
        withdrawBasis.shippingPoint.includes("D1")
      );
      setFilialId(withdrawBasis!.filialID);
      setStoreID(withdrawBasis!.store);
      setBranchName(withdrawBasis!.branchName);

      if (dataCustomer.address.length === 1) {
        setAddressSelected(dataCustomer.address[0]);
      }
    }
  }, [cnpjCustomer, dataCustomer]);

  useEffect(() => {
    setDataCustomer(null);
    setLoadingFirstQuery(true);
    setFilialId("");
    setStoreID("");
    setBranchName("");
    setAddressSelected(null);
    setProductsSimulation([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjCustomer, userState.state.bpSelected]);

  useEffect(() => {
    // caso o assessor mude, então limpa-se o campo de CNPJs selecionados
    setCnpjCustomer(null);
  }, [userState.state.bpSelected]);

  useEffect(() => {
    fetchCnpjData();
  }, [fetchCnpjData]);

  return (
    <SimulationCostsContext.Provider
      value={{
        loadingFirstQuery,
        loadingCustomerData,
        dataCustomer,
        loadingPrices,
        freightType,
        cnpjCustomer,
        addressSelected,
        filialId,
        storeID,
        productsSimulation,
        branchName,
        setBranchName,
        setLoadingCustomerData,
        setDataCustomer,
        setLoadingPrices,
        setLoadingFirstQuery,
        setCnpjCustomer,
        setAddressSelected,
        fetchCnpjData,
        setFreightType,
        setFilialId,
        setStoreID,
        setProductsSimulation,
      }}
    >
      {children}
    </SimulationCostsContext.Provider>
  );
};

const useSimulationsCostsContext = () => {
  const context = useContext(SimulationCostsContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useSimulationsCostsContext deve ser utilizado dentro de um SimulationCostsProvider"
    );
  }
  return context;
};

export { SimulationCostsProvider, useSimulationsCostsContext };
