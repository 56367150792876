import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { IEditActiveCostService } from "@modules/costs/models/IEditActiveCostService";
import AppError from "@utils/AppError";
import { formatCurrency } from "@utils/index";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { useActiveCostsContext } from "./ActiveCostsContext";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      loading: {
        width: "2rem !important",
        height: "2rem !important",
      },
    })
);

const DialogEditCostActive: React.FC = ({ children, ...props }) => {
  const classes = useStyles();
  const activeCostsContext = useActiveCostsContext();
  const { enqueueSnackbar } = useSnackbar();
  const iocContext = useIoCContext();

  const editActiveCostService = iocContext.serviceContainer.get<
    IEditActiveCostService
  >(Types.Costs.IEditActiveCostService);

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [suggestionPriceFormatted, setSuggestionPriceFormatted] = useState<
    string | null
  >(null);

  const newValue = useMemo(() => {
    if (!suggestionPriceFormatted) return null;
    return parseFloat(suggestionPriceFormatted.replace(/\D+/g, "")) / 1000000;
  }, [suggestionPriceFormatted]);

  const onClose = useCallback(() => {
    activeCostsContext.setOpenDialogEdit({ id: null, open: false });
    setSuggestionPriceFormatted(null);
  }, [activeCostsContext]);

  const editCost = useCallback(async () => {
    if (!activeCostsContext.openDialogEdit.id) return;
    if (!newValue) return;
    try {
      setLoadingEdit(true);
      await editActiveCostService.execute(
        activeCostsContext.openDialogEdit.id,
        { value: newValue }
      );
      enqueueSnackbar("Custo alterado com sucesso!", { variant: "success" });
      activeCostsContext.fetchActiveCosts();
      onClose();
    } catch (error) {
      if (error instanceof AppError) {
        return enqueueSnackbar(error.message, { variant: error.variant });
      }
      enqueueSnackbar("Ocorreu um erro ao alterar custo ativo", {
        variant: "error",
      });
    } finally {
      setLoadingEdit(false);
    }
  }, [
    activeCostsContext,
    editActiveCostService,
    enqueueSnackbar,
    newValue,
    onClose,
  ]);

  return (
    <Dialog open={activeCostsContext.openDialogEdit.open}>
      <DialogTitle>Deseja editar o custo?</DialogTitle>
      <DialogContent>
        <TextField
          label="Valor"
          value={suggestionPriceFormatted}
          onChange={(event) => {
            const realValue =
              parseFloat(String(event.target.value).replace(/\D+/g, "")) /
              1000000;

            const formated = formatCurrency(realValue, {
              minimumFractionDigits: 6,
            });
            setSuggestionPriceFormatted(formated);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={editCost}
          variant="contained"
          color="primary"
          startIcon={
            loadingEdit && (
              <CircularProgress className={classes.loading} color="secondary" />
            )
          }
        >
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogEditCostActive };
