import {
  AccumulatedIcon,
  CloseIcon,
  ExpiredIcon,
  NotExpiredIcon,
} from "@components/Icons";
import TotalCard from "@components/TotalCard";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import { Grid } from "@material-ui/core";
import { StatusDoc } from "@modules/financial/dtos/IFinancialInstallmentsDTO";
import { IChartDataService } from "@modules/financial/models/IChartDataService";
import { useFinancialDashboardContext } from "@pages/Financial/FinancialDashboard/FinancialDashboardContext";
import FinancialTotalsDetails from "@pages/Financial/FinancialDashboard/FinancialTotalsDetails";
import { IFinancialDashboardChartQuery } from "@pages/Financial/FinancialInstallments/interface";
import {
  formatCurrency,
  getBgColorTotalCard,
  getColorTotalCard,
} from "@utils/index";
import format from "date-fns/format";
import React, { useEffect, useState } from "react";

const DeliquencyRateChart: React.FC<{
  filters: IFinancialDashboardChartQuery;
}> = ({ filters }) => {
  const iocContext = useIoCContext();

  const getChartService = iocContext.serviceContainer.get<IChartDataService>(
    Types.Financial.IChartDataService
  );

  const { query } = useFinancialDashboardContext();
  const initialValue = -1;
  const [expired, setExpired] = useState<number>(initialValue);
  const [notExpired, setNotExpired] = useState<number>(initialValue);
  const [debit, setDebit] = useState<number>(initialValue);
  const [accumulated, setAccumulated] = useState<number>(initialValue);

  const [openExpiredDrawer, setOpenExpiredDrawer] = useState<boolean>(false);
  const [openNotExpiredDrawer, setOpenNotExpiredDrawer] = useState<boolean>(
    false
  );
  const [openDebitDrawer, setOpenDebitDrawer] = useState<boolean>(false);
  const [openAccumulatedDrawer, setOpenAccumulatedDrawer] = useState<boolean>(
    false
  );

  const fetchAccumulated = useFetchData(
    () =>
      getChartService.getAccumlulated({
        ...filters,
      }),
    {
      useCallbackDeps: [filters],
      useEffectDeps: [filters],
    }
  );

  useEffect(() => {
    if (fetchAccumulated.value && fetchAccumulated.value.rows.length > 0) {
      const filterQueryRows = fetchAccumulated.value.rows.filter((item) =>
        item.every((value) => value != null)
      );

      const accumulatedTotal = cardTotal("EXPIRED", filterQueryRows);

      setAccumulated(accumulatedTotal);
    }
  }, [fetchAccumulated.value]);

  const fetchTotalDebit = useFetchData(
    () =>
      getChartService.getDebitRate({
        ...filters,
      }),
    {
      useCallbackDeps: [filters],
      useEffectDeps: [filters],
    }
  );

  useEffect(() => {
    if (fetchTotalDebit.value) {
      const filterQueryRows = fetchTotalDebit.value.rows.filter((item) =>
        item.every((value) => value != null)
      );

      const total = cardTotal("NOT_EXPIRED", filterQueryRows);
      setDebit(total);
    }
  }, [fetchTotalDebit.value]);

  const fetchTotalExpired = useFetchData(
    () =>
      getChartService.getExpiredRate({
        ...filters,
      }),
    {
      useCallbackDeps: [filters],
      useEffectDeps: [filters],
    }
  );

  useEffect(() => {
    if (fetchTotalExpired.value) {
      const filterQueryRows = fetchTotalExpired.value.rows.filter((item) =>
        item.every((value) => value != null)
      );

      const total = cardTotal("EXPIRED", filterQueryRows);

      setExpired(total);
    }
  }, [fetchTotalExpired.value]);

  const fetchTotalNotExpired = useFetchData(
    () =>
      getChartService.getNotExpiredRate({
        ...filters,
      }),
    {
      useCallbackDeps: [filters],
      useEffectDeps: [filters],
    }
  );

  useEffect(() => {
    if (fetchTotalNotExpired.value) {
      const filterQueryRows = fetchTotalNotExpired.value.rows.filter((item) =>
        item.every((value) => value != null)
      );

      const notExpiredTotal = cardTotal("NOT_EXPIRED", filterQueryRows);

      setNotExpired(notExpiredTotal);
    }
  }, [fetchTotalNotExpired.value]);

  const cardTotal = (type: string, list: any[][]): number => {
    const listFilter = list
      .filter((item) => item[0] === type)
      .map((item) => item[1] as number);

    const total = listFilter.length ? listFilter[0] : 0;

    return total;
  };

  const getFormattedDate = () => {
    if (filters.from === "" || filters.to === "") return "";

    const formatFrom = format(
      new Date(query.from + "T04:00:00.000Z"),
      "dd/MM/yy"
    );
    const formatTo = format(new Date(query.to + "T03:59:59.999Z"), "dd/MM/yy");

    return `${formatFrom} a ${formatTo}`;
  };

  const getIconTotalCard = (
    situationTitle: StatusDoc,
    width?: number,
    height?: number
  ): React.ReactNode => {
    switch (situationTitle) {
      case "EXPIRED":
        return <ExpiredIcon style={{ width, height }} />;
      case "NOT_EXPIRED":
        return <NotExpiredIcon style={{ width, height }} />;
      case "CLOSE":
        return <CloseIcon style={{ width, height }} />;
      case "ACCUMULATED":
        return <AccumulatedIcon style={{ width, height }} />;
    }
  };

  const TotalCardCustom: React.FC<{
    desc: string;
    type: StatusDoc;
    value: number;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    showValue: boolean;
  }> = ({ desc, type, value, onClick, showValue }) => {
    return (
      <TotalCard
        description={desc}
        value={formatCurrency(value)}
        icon={getIconTotalCard(type)}
        color={getColorTotalCard(type)}
        bgColor={getBgColorTotalCard(type)}
        onSeeDetailsClick={onClick}
        showValue={showValue}
      />
    );
  };

  const totalCardList = [
    {
      openDrawer: openAccumulatedDrawer,
      setOpenDrawer: setOpenAccumulatedDrawer,
      loading: fetchAccumulated.loading,
      desc: `Vencidos ${getFormattedDate()}`,
      value: accumulated,
      type: "ACCUMULATED" as StatusDoc,
      closeOtherDrawers: () => {
        setOpenExpiredDrawer(false);
        setOpenNotExpiredDrawer(false);
        setOpenDebitDrawer(false);
      },
      showValue: true,
    },
    {
      openDrawer: openDebitDrawer,
      setOpenDrawer: setOpenDebitDrawer,
      loading: fetchTotalDebit.loading,
      desc: "Débitos de hoje",
      value: debit,
      type: "CLOSE" as StatusDoc,
      closeOtherDrawers: () => {
        setOpenExpiredDrawer(false);
        setOpenNotExpiredDrawer(false);
        setOpenAccumulatedDrawer(false);
      },
      showValue: true,
    },
    {
      openDrawer: openExpiredDrawer,
      setOpenDrawer: setOpenExpiredDrawer,
      loading: fetchTotalExpired.loading,
      desc: "Vencidos (30 dias)",
      value: expired,
      type: "EXPIRED" as StatusDoc,
      closeOtherDrawers: () => {
        setOpenDebitDrawer(false);
        setOpenNotExpiredDrawer(false);
        setOpenAccumulatedDrawer(false);
      },
      showValue: true,
    },
    {
      openDrawer: openNotExpiredDrawer,
      setOpenDrawer: setOpenNotExpiredDrawer,
      loading: fetchTotalNotExpired.loading,
      desc: "A vencer (30 dias)",
      value: notExpired,
      type: "NOT_EXPIRED" as StatusDoc,
      closeOtherDrawers: () => {
        setOpenExpiredDrawer(false);
        setOpenDebitDrawer(false);
        setOpenAccumulatedDrawer(false);
      },
      showValue: true,
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        alignItems={"center"}
        style={{ marginBottom: 50 }}
      >
        {totalCardList.map((item) => {
          if (item.loading || item.value === -1 || query.from.length === 0) {
            return (
              <Grid item xs={12} md={6} lg={3}>
                <TotalCard loading={true} />
              </Grid>
            );
          }
          return (
            <Grid item xs={12} md={6} lg={3}>
              <FinancialTotalsDetails
                openDialog={item.openDrawer}
                setOpenDialog={item.setOpenDrawer}
                iconBgColor={getBgColorTotalCard(item.type)}
                iconColor={getColorTotalCard(item.type)}
                title={item.desc}
                value={formatCurrency(item.value)}
                icon={getIconTotalCard(item.type, 48, 48)}
                type={item.type}
                filters={filters}
              />
              <TotalCardCustom
                desc={item.desc}
                value={item.value}
                type={item.type}
                onClick={() => {
                  item.setOpenDrawer(true);
                  item.closeOtherDrawers();
                }}
                showValue={item.showValue}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DeliquencyRateChart;
