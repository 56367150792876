import { Box, BoxProps, IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React, { useMemo } from "react";
import {
  SliderContext,
  SliderProvider,
  SliderProviderProps,
} from "./SlideContext";
import { SlideIndicator } from "./SlideIndicator";

export interface SliderProps extends Omit<SliderProviderProps, "slideCount"> {
  showIndicator?: boolean;
  boxProps?: BoxProps;
}

const Slider: React.FC<SliderProps> = ({
  showIndicator = true,
  children,
  boxProps,
  ...props
}) => {
  const originalChildren = useMemo(() => {
    return children;
  }, [children]);

  return (
    <SliderProvider slideCount={React.Children.count(children)} {...props}>
      <SliderContext.Consumer>
        {(values) => {
          const childrenArr = React.Children.toArray(originalChildren);
          const childrenSorted = values.showSlideInShowMore.map((index) => {
            return childrenArr[index];
          });

          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: values.showMore ? "row" : "column",
              }}
            >
              {values.showMore && childrenArr.length >= values.slideQuantity && (
                <IconButton onClick={() => values.changeSlide(-1)}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              )}
              <Box
                {...boxProps}
                sx={{
                  ...boxProps?.sx,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  width: "100%",
                  height: values.maxHeight > 0 ? values.maxHeight : "auto",
                }}
              >
                {childrenArr.length >= values.slideQuantity
                  ? childrenSorted
                  : childrenArr}
              </Box>
              {values.showMore && childrenArr.length >= values.slideQuantity && (
                <IconButton onClick={() => values.changeSlide(1)}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              )}
              {showIndicator && !values.showMore && <SlideIndicator />}
            </Box>
          );
        }}
      </SliderContext.Consumer>
    </SliderProvider>
  );
};

export { Slider };
