import NoData from "@components/NoData";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
} from "@material-ui/data-grid";
import { IGetActiveCostsDTO } from "@modules/costs/dtos/IGetActiveCostsDTO";
import { ISearchCostService } from "@modules/costs/models/ISearchCostService";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import {
  checkCityZF,
  formatCurrency,
  formatCurrencyPriceProduct,
  getOperacionalCost,
} from "@utils/index";
import { IAddress, IClient, IProduct, Price } from "@utils/interfaces";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppointmentPriceContext } from "./AppointmentPriceContext";
import { IQuery } from "./interface";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperTable: {
      marginTop: "5rem",
      overflow: "hidden",
      overflowX: "scroll",

      "&::-webkit-scrollbar": {
        background: theme.palette.grey[300],
        height: "0.7rem",
        borderRadius: ".4rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.grey[900],
        borderRadius: ".4rem",
      },
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    positiveMargin: {
      color: theme.palette.success.dark,
      fontWeight: "bold",
    },
    negativeMargin: {
      color: theme.palette.error.light,
      fontWeight: "bold",
    },
  })
);

interface IProductWithPrice extends IProduct {
  [propName: string]: any;
}

const TablePrices: React.FC = () => {
  const classes = useStyles();
  const appointmentContext = useAppointmentPriceContext();
  const iocContext = useIoCContext();
  const { values } = useFormikContext<IQuery>();
  const { enqueueSnackbar } = useSnackbar();
  const userState = useUserState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const getPriceProductService = iocContext.serviceContainer.get<
    IGetPriceProductsService
  >(Types.Orders.IGetPriceProductsService);
  const getCostProductService = iocContext.serviceContainer.get<
    ISearchCostService
  >(Types.Costs.ISearchCostService);

  const [productsWithPrice, setProductsWithPrice] = useState<
    IProductWithPrice[]
  >([]);
  const [costProducts, setCostProducts] = useState<IGetActiveCostsDTO[]>([]);
  const [loadingPrice, setLoadingPrice] = useState(true);

  const maximumPayCond = useMemo(() => {
    if (!appointmentContext.dataCustomer) return 0;
    return appointmentContext.dataCustomer.products.reduce((a, b) => {
      if (b.payCond) {
        if (a < b.payCond) {
          return b.payCond;
        }
      } else {
        if (a < (appointmentContext.dataCustomer as IClient).payCond) {
          return (appointmentContext.dataCustomer as IClient).payCond;
        }
      }
      return a;
    }, 0);
  }, [appointmentContext.dataCustomer]);

  const getProductsWithPrice = useCallback(async () => {
    if (!appointmentContext.dataCustomer) return [];
    if (!appointmentContext.dataCustomer) return [];
    if (!values.address) return [];

    const products = appointmentContext.dataCustomer.products.map((product) => {
      Array.from(Array(maximumPayCond + 1).keys()).forEach((ele, idx) => {
        // @ts-ignore
        product[`price-${idx}`] = null;
      });
      return product as IProductWithPrice;
    });

    try {
      setLoadingPrice(true);
      const promisesPrice = products.flatMap(async (product) => {
        /**
         * se o produto não tiver o payCond, utiliza-se o payCond do cliente
         */
        const payCond = product.payCond
          ? product.payCond
          : (appointmentContext.dataCustomer as IClient).payCond;

        const arr = [];
        for (
          let conditionPayment = 0;
          conditionPayment <= payCond;
          conditionPayment++
        ) {
          const price = await getPriceProductService.execute({
            addressID: (values.address as IAddress).id,
            customerID: (appointmentContext.dataCustomer as IClient).id,
            deadlinePayment: conditionPayment,
            filialID: values.filialID,
            productID: product.id,
            transportationZone: (values.address as IAddress).transportationZone,
          });
          arr.push({
            name: `${(appointmentContext.dataCustomer as IClient).id}-${
              product.id
            }-${conditionPayment}`,
            price,
          });
        }

        return arr;
      });
      const resolved = await Promise.all(promisesPrice);
      const flatten = resolved.flatMap((resolved) => resolved);

      const productsPrice = products.map((product) => {
        const payCond = product.payCond
          ? product.payCond
          : (appointmentContext.dataCustomer as IClient).payCond;

        for (
          let conditionPayment = 0;
          conditionPayment <= payCond;
          conditionPayment++
        ) {
          const idx = `price-${conditionPayment}`;
          const name = `${(appointmentContext.dataCustomer as IClient).id}-${
            product.id
          }-${conditionPayment}`;

          const finded = flatten.find(
            (flattenPrice) => flattenPrice.name === name
          );
          if (!finded) {
            product[idx] = null;
          } else {
            product[idx] = finded.price || "--";
          }
        }

        return { ...product };
      });
      setProductsWithPrice(productsPrice);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao consultar preço, tente novamente", {
        variant: "error",
      });
    } finally {
      setLoadingPrice(false);
    }
  }, [
    appointmentContext.dataCustomer,
    enqueueSnackbar,
    getPriceProductService,
    maximumPayCond,
    values.address,
    values.filialID,
  ]);

  const getCosts = useCallback(async () => {
    try {
      /**
       * Loading costs
       */
      const costs = await getCostProductService.execute({
        center: values.filialID,
        material: (appointmentContext.dataCustomer as IClient).products.map(
          (product) => product.id
        ),
        state: checkCityZF(
          values.address!.city,
          values.filialID,
          values.address!.UF
        )
          ? "ZF"
          : (values.address?.UF as string),
        ...(values.storeID !== "null" ? { store: values.storeID } : {}),
      });
      setCostProducts(costs);
    } catch (error) {}
  }, [
    appointmentContext.dataCustomer,
    getCostProductService,
    values.address,
    values.filialID,
    values.storeID,
  ]);

  useEffect(() => {
    // caso o cliente ou assessor mude
    setProductsWithPrice([]);
  }, [values.CNPJ, userState.state.bpSelected]);

  useEffect(() => {
    getCosts();
    getProductsWithPrice();
  }, [getCosts, getProductsWithPrice]);

  const rows = useMemo(() => {
    if (!appointmentContext.dataCustomer) return [];
    return productsWithPrice.map((prod) => {
      const product = { ...prod };
      const payCond = product.payCond
        ? product.payCond
        : (appointmentContext.dataCustomer as IClient).payCond;

      const costProduct = costProducts.find(
        (product) => product.material === prod.id
      );

      for (let i = 0; i <= payCond; i++) {
        const prodPrice = product[`price-${i}`] as Price | null;
        if (!prodPrice) {
          product[`price-${i}`] = null;
          return product;
        }
        if (prodPrice.price === 0) {
          // caso não tenha preço para o payCond i
          product[`price-${i}`] = null;
        } else {
          if (values.freightType === "CIF") {
            // se o frete for CIF, soma-se com o frete do produto do payCond i
            product[`price-${i}`] =
              prodPrice.price +
              prodPrice.freight +
              getOperacionalCost({
                branchName: values.branchName,
                filialID: values.filialID,
              });
          } else {
            // se o frete for FOB, não há soma de frete
            product[`price-${i}`] =
              prodPrice.price +
              getOperacionalCost({
                branchName: values.branchName,
                filialID: values.filialID,
              });
          }

          // cálculo da margem é somente sobre o payCond 0 (á vista)
          if (costProduct && product["price-0"]) {
            if (values.freightType === "CIF") {
              // A margem não deve conter o valor de frete, logo se o frete é CIF, remove-se o valor
              // remove-se também o valor do custo operacional da margem
              product.margin =
                product["price-0"] -
                costProduct.value -
                prodPrice.freight -
                getOperacionalCost({
                  branchName: values.branchName,
                  filialID: values.filialID,
                });
            } else {
              product.margin =
                product["price-0"] -
                costProduct.value -
                getOperacionalCost({
                  branchName: values.branchName,
                  filialID: values.filialID,
                });
            }
          } else {
            // caso não possua o payCond 0 (á vista), a margem também não existe
            product.margin = null;
          }
        }
      }

      return product;
    });
  }, [
    appointmentContext.dataCustomer,
    costProducts,
    productsWithPrice,
    values,
  ]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    { field: "description", headerName: "Produto", width: 350 },
    {
      field: "margin",
      headerName: "Margem",
      width: 150,
      type: "number",
      cellClassName: (params: GridCellParams) =>
        clsx({
          [classes.positiveMargin]: (params.value as number) > 0,
          [classes.negativeMargin]: (params.value as number) < 0,
        }),
      valueFormatter: (props) =>
        props.value
          ? formatCurrency(props.getValue(props.id, props.field) as number, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })
          : "-",
    },
    ...Array.from(Array(maximumPayCond + 1).keys()).map(
      (ele, idx): GridColDef => {
        return {
          field: `price-${idx}`,
          headerName:
            idx === 0 ? "À vista" : idx === 1 ? "1 dia" : `${idx} dias`,
          width: 150,
          headerAlign: "center",
          align: "center",
          valueFormatter: (props) =>
            props.getValue(props.id, props.field)
              ? formatCurrencyPriceProduct(
                  props.getValue(props.id, props.field) as number
                )
              : "-",
        };
      }
    ),
  ];

  return appointmentContext.loadingFirstQuery ? (
    <NoData>Selecione os campos para ver os resultados</NoData>
  ) : (
    <div style={{ flex: 1 }}>
      <div style={{ height: isMobile ? 600 : "100%" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          loading={loadingPrice}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay>
                <NoData>Não há produtos cadastrados para vendas.</NoData>
              </GridOverlay>
            ),
          }}
        />
      </div>
    </div>
  );
};

export { TablePrices };
