import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { ICreateHandoutDTO } from "../dtos/ICreateHandoutDTO";
import { ICreateHandoutService } from "../models/ICreateHandoutService";

@injectable()
export class CreateHandoutService implements ICreateHandoutService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateHandoutDTO) {
    await this.httpInstance.post("/handouts", data);
  }
}
