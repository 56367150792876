import { IQueryInvoicesDataDTO } from "@modules/invoices/dtos/IQueryInvoicesDataDTO";
import { IClient } from "@utils/interfaces";
import React, { createContext, useCallback, useContext, useState } from "react";

interface FormQueryInvoicesContext {
  loading: boolean;
  loadingQuery: boolean;
  dataCustomer: IClient | null;
  dataQuery: IQueryInvoicesDataDTO[];
  searchFirstTime: boolean;
  openModalDownload: boolean;
  invoicesSelectedToDownload: IInvoicesSelecteToDownload;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  setDataQuery(data: IQueryInvoicesDataDTO[]): void;
  setSearchFirstTime(value: boolean): void;
  setOpenModalDownload(value: boolean): void;
  setInvoicesSelectedToDownload(data: IInvoicesSelecteToDownload): void;
}

const FormQueryInvoicesContext = createContext<FormQueryInvoicesContext>(
  {} as FormQueryInvoicesContext
);

export interface IInvoicesSelecteToDownload {
  [propName: string]: boolean;
}

const FormQueryInvoicesProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);
  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [dataQuery, setDataQuery] = useState<IQueryInvoicesDataDTO[]>([]);
  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [openModalDownload, setOpenModalDownload] = useState(false);

  const [invoicesSelectedToDownload, setInvoicesSelectedToDownload] = useState<
    IInvoicesSelecteToDownload
  >({});

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  return (
    <FormQueryInvoicesContext.Provider
      value={{
        loading,
        dataCustomer,
        loadingQuery,
        dataQuery,
        searchFirstTime,
        openModalDownload,
        invoicesSelectedToDownload,
        setLoading,
        setLoadingQuery,
        setDataCustomer,
        setDataQuery,
        setSearchFirstTime,
        setOpenModalDownload,
        setInvoicesSelectedToDownload,
      }}
    >
      {children}
    </FormQueryInvoicesContext.Provider>
  );
};

const useFormQueryInvoices = (): FormQueryInvoicesContext => {
  const context = useContext(FormQueryInvoicesContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useFormQueryInvoices deve ser utilizado dentro de um FormQueryInvoicesContext"
    );
  }
  return context;
};

export { useFormQueryInvoices, FormQueryInvoicesProvider };
