import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

import { ReactComponent as shoppingCartImg } from "../../assets/shopping-cart.svg";
import { ReactComponent as cancelImg } from "../../assets/cancel.svg";
import { ReactComponent as whatsappImg } from "../../assets/whatsapp.svg";
import { ReactComponent as gasStationImg } from "../../assets/gas-station.svg";

import { ReactComponent as HomeSvg } from "../../assets/sidebar/home.svg";
import { ReactComponent as EditSvg } from "../../assets/sidebar/edit.svg";
import { ReactComponent as ChecklistSvg } from "../../assets/sidebar/checklist.svg";
import { ReactComponent as CoustSvg } from "../../assets/sidebar/cousts.svg";
import { ReactComponent as LockSvg } from "../../assets/sidebar/lock.svg";
import { ReactComponent as ChartSvg } from "../../assets/sidebar/chart.svg";
import { ReactComponent as HelpSvg } from "../../assets/sidebar/help.svg";
import { ReactComponent as LogoutSvg } from "../../assets/sidebar/logout.svg";

import { ReactComponent as CloseSvg } from "../../assets/charts/close.svg";
import { ReactComponent as ExpiredSvg } from "../../assets/charts/expired.svg";
import { ReactComponent as NotExpiredSvg } from "../../assets/charts/not_expired.svg";
import { ReactComponent as AccumulatedSvg } from "../../assets/charts/accumulated.svg";

export const ShoppingCartIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={shoppingCartImg}
      viewBox="0 0 475.084 475.085"
      {...props}
    />
  );
};

export const CancelIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={cancelImg} viewBox="0 0 511.76 511.76" {...props} />
  );
};

export const WhatsappIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={whatsappImg} viewBox="0 0 512 512" {...props} />;
};

export const GasStationIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={gasStationImg} viewBox="0 0 512 512" {...props} />;
};

export const HomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={HomeSvg}
      viewBox="0 0 16 15"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const EditIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={EditSvg}
      viewBox="0 0 16 14"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const ChecklistIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={ChecklistSvg}
      viewBox="0 0 12 16"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const CoustIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={CoustSvg}
      viewBox="0 0 16 16"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const LockIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={LockSvg}
      viewBox="0 0 14 17"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const ChartIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={ChartSvg}
      viewBox="0 0 16 16"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const HelpIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={HelpSvg}
      viewBox="0 0 16 16"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const LogoutIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={LogoutSvg}
      viewBox="0 0 16 13.8"
      fontSize="small"
      {...props}
      style={{ fontSize: "1.8rem" }}
    />
  );
};

export const CloseIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={CloseSvg} viewBox="0 0 24 24" {...props} />;
};

export const ExpiredIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={ExpiredSvg} viewBox="0 0 28 31" {...props} />;
};

export const NotExpiredIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={NotExpiredSvg} viewBox="0 0 28 31" {...props} />;
};

export const AccumulatedIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={AccumulatedSvg} viewBox="0 0 28 31" {...props} />;
};
