import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IClient } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IGetClientInfoService } from "../models/IGetClientInfoService";

@injectable()
export class GetClientInfoService implements IGetClientInfoService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(
    CNPJ: string,
    requestToPrice?: boolean
  ): Promise<IClient> {
    const customer = await this.httpInstance.get<IClient>(`/customer/${CNPJ}`, {
      params: { priceRequest: requestToPrice },
    });
    return customer;
  }
}
