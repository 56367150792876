import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { AggregateQuoteSaleResponse } from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { CircularProgress, Grid } from "@material-ui/core";
import { formatToFixed } from "@utils/index";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      redValue: { color: theme.palette.primary.main },
      yellowValue: { color: theme.palette.secondary.main },
      greenValue: { color: theme.palette.success.main },
      spacingText: { margin: "1.5rem 0" },
      textProduct: { fontWeight: "bold" },
      backgroundHeader: { backgroundColor: "#fdf79c" },
    })
);

interface IResultQuoteCenter {
  centerID: string;
  productGroup: string;
}

const TableQuotes: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
  center: string;
  aggregate: AggregateQuoteSaleResponse[];
  loading: boolean;
}> = ({ center, aggregate, loading }) => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: "productGroup",
      minWidth: 130,
      flex: 1,
      renderHeader: (params) => <strong>{"Produto:"}</strong>,
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.textProduct]: props.value as string,
            })
          : "",
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "forecast",
      renderHeader: (params) => <strong>{"Previsão Mês:"}</strong>,
      type: "number",
      minWidth: 170,
      flex: 1,
      valueGetter: (params) => {
        return params.row.month
          ? params.row.month.forecast
          : params.row.totalForecast;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "accumulatedGoal",
      renderHeader: (params) => <strong>{"Meta acumulada:"}</strong>,
      type: "number",
      minWidth: 170,
      flex: 1,
      valueGetter: (params) => {
        return params.row.month
          ? params.row.month.accumulatedGoal
          : params.row.totalAccumulatedGoal;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "accomplishedAccumulated",
      renderHeader: (params) => <strong>{"Real Acumulado:"}</strong>,
      type: "number",
      minWidth: 170,
      flex: 1,
      valueGetter: (params) => {
        return params.row.month
          ? params.row.month.accomplishedAccumulated
          : params.row.totalAccomplishedAccumulated;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "accomplishedPercentage",
      renderHeader: (params) => <strong>{"% Realizada:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueFormatter: (props) => formatToFixed(props.value as number),
      valueGetter: (params) => {
        return params.row.month
          ? params.row.month.accomplishedPercentage
          : params.row.totalAccomplishedPercentage;
      },
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.redValue]:
                (props.value as number) >= 0 && (props.value as number) <= 69,
              [classes.yellowValue]:
                (props.value as number) > 69 && (props.value as number) < 90,
              [classes.greenValue]: (props.value as number) >= 90,
            })
          : "",
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "differenceGoalAccomplishedAccumulated",
      renderHeader: (params) => <strong>{"Dif: Meta X Acum.:"}</strong>,
      type: "number",
      minWidth: 190,
      flex: 1,
      valueGetter: (params) => {
        return params.row.month
          ? params.row.month.differenceGoalAccomplishedAccumulated
          : params.row.totalDifferenceGoalAccomplishedAccumulated;
      },
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.redValue]: (props.value as number) < 0,
              [classes.greenValue]: (props.value as number) > 0,
            })
          : "",
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "quote",
      renderHeader: (params) => <strong>{"Cota Dia:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.day ? params.row.day.quote : params.row.totalQuote;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "sale",
      renderHeader: (params) => <strong>{"Venda Dia:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.day ? params.row.day.sale : params.row.totalSale;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "balance",
      renderHeader: (params) => <strong>{"Saldo:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.day
          ? params.row.day.balance
          : params.row.totalBalance;
      },
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.redValue]: (props.value as number) < 0,
              [classes.greenValue]: (props.value as number) > 0,
            })
          : "",
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "billed",
      renderHeader: (params) => <strong>{"Faturado:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.day ? params.row.day.billed : params.row.totalBilled;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
    {
      field: "toProfit",
      renderHeader: (params) => <strong>{"A Faturar:"}</strong>,
      type: "number",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.day
          ? params.row.day.toProfit
          : params.row.totalToProfit;
      },
      headerClassName: (props) => classes.backgroundHeader,
    },
  ];

  let data = aggregate.filter((sub) => sub.centerID === center);

  return (
    <>
      <Typography className={classes.spacingText} variant="h6" align="center">
        <b>Cota mês/dia</b>
      </Typography>
      <div style={{ height: "70vh" }}>
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <DataGrid
            columns={columns}
            rowsPerPageOptions={[10, 20, 30]}
            rows={aggregate ? data : []}
            getRowId={(row) => row.productGroup}
          />
        )}
      </div>
    </>
  );
};

export { TableQuotes };
