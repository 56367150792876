import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import { Types } from "@ioc/types";
import {
  IQueryFinancialInstallmentsDataDTO,
  StatusDoc,
} from "@modules/financial/dtos/IFinancialInstallmentsDTO";
import { IGroupCustomerFilterDTO } from "@modules/financial/dtos/IGroupCustomerFilterDTO";
import { IManagerFilterDTO } from "@modules/financial/dtos/IManagerFilterDTO";
import { ITotalValueStatusDocDTO } from "@modules/financial/dtos/ITotalValueStatusDocDTO";
import { IFinancialFiltersService } from "@modules/financial/models/IFinancialFiltersService";
import { IFinancialInstallmentsService } from "@modules/financial/models/IFinancialInstallmentsService";
import { IUploadTitleImageDTO } from "@modules/titles/dtos/IUploadTitleImageDTO";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IFinancialInstallmentsQuery } from "./interface";

interface IOpenModalUpload {
  openModalUpload: boolean;
  data: Omit<IUploadTitleImageDTO, "file">; //TO DO: VERFICAR INTERFACE PARA USAR PRÓPRIA
}

interface Image {
  link: string;
  id: string;
}

interface IFinancialInstallments {
  loading: boolean;
  loadingQuery: boolean;
  dataQuery: IQueryFinancialInstallmentsDataDTO | null;
  totalValueByStatusDoc: ITotalValueStatusDocDTO[] | null;
  groupCustomerFilter: IGroupCustomerFilterDTO[] | null;
  managerFilter: IManagerFilterDTO[] | null;
  statusFilter: StatusDoc[];
  query: IFinancialInstallmentsQuery;
  searchFirstTime: boolean;
  openModalUpload: IOpenModalUpload;
  image: Image;
  openModalImage: boolean;
  setLoading(loading: boolean): void;
  setLoadingQuery(loading: boolean): void;
  setDataQuery(data: IQueryFinancialInstallmentsDataDTO | null): void;
  setTotalValueByStatusDoc(data: ITotalValueStatusDocDTO[] | null): void;
  setGroupCustomerFilter(data: IGroupCustomerFilterDTO[] | null): void;
  setManagerFilter(data: IManagerFilterDTO[] | null): void;
  setStatusFilter(data: StatusDoc[]): void;
  setQuery: React.Dispatch<React.SetStateAction<IFinancialInstallmentsQuery>>;
  setSearchFirstTime(value: boolean): void;
  setOpenModalUpload(value: IOpenModalUpload): void;
  setImage(value: Image): void;
  setOpenModalImage(value: boolean): void;
}

const FinancialInstallmentsContext = createContext<IFinancialInstallments>(
  {} as IFinancialInstallments
);

const FinancialInstallmentsProvider: React.FC = ({ children }) => {
  const iocContext = useIoCContext();
  const userState = useUserState();

  const queryFinancialInstallmentsService = iocContext.serviceContainer.get<
    IFinancialInstallmentsService
  >(Types.Financial.IFinancialInstallmentsService);

  const queryFinancialFiltersService = iocContext.serviceContainer.get<
    IFinancialFiltersService
  >(Types.Financial.IFinancialFiltersService);

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoadingState] = useState(false);
  const [loadingQuery, setLoadingQueryState] = useState(false);

  const [
    dataQuery,
    setDataQuery,
  ] = useState<IQueryFinancialInstallmentsDataDTO | null>(null);

  const [totalValueByStatusDoc, setTotalValueByStatusDoc] = useState<
    ITotalValueStatusDocDTO[] | null
  >(null);

  const [groupCustomerFilter, setGroupCustomerFilter] = useState<
    IGroupCustomerFilterDTO[] | null
  >(null);

  const [managerFilter, setManagerFilter] = useState<
    IManagerFilterDTO[] | null
  >(null);

  const [statusFilter, setStatusFilter] = useState<StatusDoc[]>([
    "EXPIRED",
    "NOT_EXPIRED",
    "CLOSE",
  ]);

  const [searchFirstTime, setSearchFirstTime] = useState(true);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState<IOpenModalUpload>({
    data: { year: "", atemID: "", CNPJ: "", invoiceID: "" },
    openModalUpload: false,
  });
  const [image, setImage] = useState<Image>({} as Image);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingQuery = useCallback((loading: boolean) => {
    setLoadingQueryState(loading);
  }, []);

  const [query, setQuery] = useState<IFinancialInstallmentsQuery>({
    CNPJ: [],
    advisorID: [],
    customerIDs: [],
    groupCustomer: [],
    managerID: [],
    statusDoc: [],
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    page: 1,
    limit: 10,
  });

  const fetchQuery = useCallback(async () => {
    try {
      setLoadingQuery(true);
      const queryResult = await queryFinancialInstallmentsService.getInstallments(
        {
          CNPJ: query.CNPJ.map((company) => company.customerID),
          advisorID: query.advisorID.map((value) => value.bpID),
          customerIDs: query.CNPJ.map((company) => company.customerID),
          groupCustomer: query.groupCustomer.map((customer) => customer.id),
          managerID: query.managerID.map((manager) => manager.id),
          statusDoc: query.statusDoc,
          beginDate: query.startDate,
          endDate: query.endDate,
          page: query.page ?? 1,
          limit: query.limit ?? 10,
        }
      );
      setDataQuery(queryResult);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao buscar partidas", {
        variant: "error",
      });
    } finally {
      setLoadingQuery(false);
      setSearchFirstTime(false);
    }
  }, [
    enqueueSnackbar,
    setLoadingQuery,
    queryFinancialInstallmentsService,
    query.advisorID,
    query.CNPJ,
    query.groupCustomer,
    query.managerID,
    query.statusDoc,
    query.startDate,
    query.endDate,
    query.limit,
    query.page,
  ]);

  const getTotalValueStatusDoc = useCallback(async () => {
    try {
      const queryResult = await queryFinancialInstallmentsService.getTotalValueByStatusDoc(
        {
          advisorID: query.advisorID.map((value) => value.bpID),
          customerIDs: query.CNPJ.map((company) => company.customerID),
          groupCustomer: query.groupCustomer.map((value) => value.id),
          statusDoc: query.statusDoc,
          beginDate: query.startDate,
          endDate: query.endDate,
        }
      );
      setTotalValueByStatusDoc(queryResult);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar valores totais!", {
        variant: "error",
      });
    }
  }, [
    enqueueSnackbar,
    queryFinancialInstallmentsService,
    query.advisorID,
    query.CNPJ,
    query.groupCustomer,
    query.statusDoc,
    query.startDate,
    query.endDate,
  ]);

  const getGroupCustomerFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getGroupCustomerFilter();
      setGroupCustomerFilter(queryResult);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar grupos de cliente!", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, queryFinancialFiltersService]);

  const getManagerFilter = useCallback(async () => {
    try {
      const queryResult = await queryFinancialFiltersService.getManagerFilter();
      setManagerFilter(queryResult);
    } catch (error) {
      enqueueSnackbar("Ocorreu um erro ao carregar gerentes!", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, queryFinancialFiltersService]);

  useEffect(() => {
    getGroupCustomerFilter();
    getManagerFilter();
  }, [getGroupCustomerFilter, getManagerFilter]);

  useEffect(() => {
    getTotalValueStatusDoc();
  }, [getTotalValueStatusDoc]);

  useEffect(() => {
    fetchQuery();
  }, [fetchQuery]);

  useEffect(() => {
    setQuery((state) => ({ ...state, page: 1 }));
  }, [query.startDate, query.endDate, query.CNPJ]);

  useEffect(() => {
    setQuery((old) => ({ ...old, advisorID: userState.state.assessorList }));
  }, [userState.state.assessorList]);

  useEffect(() => {
    setQuery((old) => ({ ...old, statusDoc: statusFilter }));
  }, [statusFilter]);

  return (
    <FinancialInstallmentsContext.Provider
      value={{
        loading,
        loadingQuery,
        dataQuery,
        totalValueByStatusDoc,
        groupCustomerFilter,
        managerFilter,
        statusFilter,
        query,
        searchFirstTime,
        openModalUpload,
        image,
        openModalImage,
        setLoading,
        setLoadingQuery,
        setDataQuery,
        setTotalValueByStatusDoc,
        setGroupCustomerFilter,
        setManagerFilter,
        setStatusFilter,
        setQuery,
        setSearchFirstTime,
        setOpenModalUpload,
        setImage,
        setOpenModalImage,
      }}
    >
      {children}
    </FinancialInstallmentsContext.Provider>
  );
};

const useFinancialInstallmentsContext = () => {
  const context = useContext(FinancialInstallmentsContext);

  if (Object.values(context).length === 0) {
    throw new Error(
      "FinancialInstallmentsContext deve ser utilizado dentro de um FinancialInstallmentsProvider"
    );
  }

  return context;
};

export { FinancialInstallmentsProvider, useFinancialInstallmentsContext };
