import { ReactComponent as BranchIcon } from "@assets/source-branch.svg";
import { DialogSuccessUploadCosts } from "@components/Header/DialogSuccessUploadCosts";
import { DialogUploadCosts } from "@components/Header/DialogUploadCosts";
import appConfig from "@config/appConfig";
import ROUTES from "@config/routes";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserDispatch, useUserState } from "@context/UserContext";
import { TypesRolesPermissions } from "@context/UserContext/RolesPermissions";
import { Types } from "@ioc/types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { AccountBalanceWallet, Payment } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EmailIcon from "@material-ui/icons/Email";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import HistoryIcon from "@material-ui/icons/History";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PageviewIcon from "@material-ui/icons/Pageview";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import {
  ICallJobsService,
  JobService,
} from "@modules/jobs/models/ICallJobsService";
import { getRedirectUrl } from "@utils/index";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      height: "7.2rem",
      width: "7.2rem",

      marginTop: "3.2rem",
      marginBottom: "3.2rem",
    },
    userName: {
      fontSize: "2.4rem",
      fontWeight: "bold",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "0.8rem",
    },
    containerDrawer: {
      margin: "0 1.6rem",
      width: "80vw",
      [theme.breakpoints.up("md")]: { width: "20vw" },
    },
    divider: { margin: "2rem 0" },
    listItem: {
      fontSize: "1.6rem",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "0.8rem",
      borderRadius: "0.5rem",
      "& img": {
        filter:
          "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%)",
      },
    },
    listItemSelected: {
      backgroundColor: `${theme.palette.primaryLight.main} !important`,
      color: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& img": {
        filter:
          "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
      },
    },
    listItemHover: {
      "&:hover": {
        backgroundColor: theme.palette.primaryLight.main,
        color: theme.palette.primary.main,
        "& img": {
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
    },
    autocompleteRoot: { width: "100%", marginTop: "1rem" },
    matchText: { color: theme.palette.primary.main },
    accordionRoot: { "&:before": { display: "none" } },
    accordionSumaryRoot: {
      padding: 0,
      "&.Mui-expanded": { minHeight: "auto" },
    },
    accordionSumaryContent: {
      margin: 0,
      "&.Mui-expanded": { margin: 0 },
    },
    accordionDetailsRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      width: "100%",
      "& > .MuiList-root": { width: "100%" },
    },
  })
);

interface Props {
  open: boolean;
  setOpen: Function;
}

const ListDrawerUser: React.FC = () => {
  const iocContext = useIoCContext();
  const location = useLocation();
  const classes = useStyles();
  const userState = useUserState();

  const [openDialogSend, setOpenDialogSend] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);

  const [isLoadingCallJobService, setIsLoadingCallJobService] = useState(false);

  const snackbar = useSnackbar();
  const callJobsService = iocContext.serviceContainer.get<ICallJobsService>(
    Types.Jobs.ICallJobsService
  );

  return (
    <List>
      <ListItem
        button
        className={classes.listItem}
        classes={{
          selected: classes.listItemSelected,
          button: classes.listItemHover,
        }}
        onClick={() => {
          window.location.href = getRedirectUrl();
        }}
      >
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        Programação rodoviária
      </ListItem>
      <ListItem
        button
        className={classes.listItem}
        classes={{
          selected: classes.listItemSelected,
          button: classes.listItemHover,
        }}
        selected={location.pathname === ROUTES.USER_ROUTES.SIMULATION_COSTS}
        component={Link}
        to={ROUTES.USER_ROUTES.SIMULATION_COSTS}
      >
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        Simular preços
      </ListItem>
      <Accordion square elevation={0} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          classes={{
            content: classes.accordionSumaryContent,
            root: classes.accordionSumaryRoot,
          }}
        >
          <ListItem
            button
            className={classes.listItem}
            style={{ marginBottom: 0 }}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            Consultas
          </ListItem>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <List>
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_PRICE
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_PRICE}
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              Consultar preços
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_SALES
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_SALES}
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              Consultar vendas
            </ListItem>

            {userState.hasPermission(
              TypesRolesPermissions.ROLE_SALES_MARKETING_ACCESS
            ) ? (
              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                selected={
                  location.pathname ===
                  ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN
                }
                component={Link}
                to={ROUTES.USER_ROUTES.APPOINTMENT_MARKETING_PLAN}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                Consultar vendas/marketing
              </ListItem>
            ) : (
              <></>
            )}

            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname ===
                ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_PRICE_CUSTOMER}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              Consultar preços de cliente
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_WALLET
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_WALLET}
            >
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              Consultar carteira
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_TITLE
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_TITLE}
            >
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              Partidas individuais por cliente
            </ListItem>
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.APPOINTMENT_INVOICES
              }
              component={Link}
              to={ROUTES.USER_ROUTES.APPOINTMENT_INVOICES}
            >
              <ListItemIcon>
                <FindInPageIcon />
              </ListItemIcon>
              Consultar NFs
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      {userState.hasPermission(TypesRolesPermissions.ROLE_PRICING) ? (
        <Accordion
          square
          elevation={0}
          classes={{ root: classes.accordionRoot }}
        >
          <AccordionSummary
            classes={{
              content: classes.accordionSumaryContent,
              root: classes.accordionSumaryRoot,
            }}
          >
            <ListItem
              button
              className={classes.listItem}
              style={{ marginBottom: 0 }}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              Custos
            </ListItem>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <List>
              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                onClick={() => setOpenDialogSend(true)}
              >
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                Cadastrar custos
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                selected={location.pathname === ROUTES.USER_ROUTES.ATIVE_COSTS}
                component={Link}
                to={ROUTES.USER_ROUTES.ATIVE_COSTS}
              >
                <ListItemIcon>
                  <PageviewIcon />
                </ListItemIcon>
                Consultar custos ativos
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                selected={
                  location.pathname === ROUTES.USER_ROUTES.HISTORY_COSTS
                }
                component={Link}
                to={ROUTES.USER_ROUTES.HISTORY_COSTS}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                Consultar historico / agendado
              </ListItem>

              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                selected={
                  location.pathname === ROUTES.USER_ROUTES.SETTINGS_COSTS
                }
                component={Link}
                to={ROUTES.USER_ROUTES.SETTINGS_COSTS}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                Consultar configuração de margem ativas
              </ListItem>

              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                selected={
                  location.pathname ===
                  ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS
                }
                component={Link}
                to={ROUTES.USER_ROUTES.HISTORY_SETTINGS_COSTS}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                Consultar configuração de margem historico / agendado
              </ListItem>

              <ListItem
                button
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                  button: classes.listItemHover,
                }}
                onClick={() => setOpenDialogSend(true)}
              >
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                Cadastrar configuração de margem
              </ListItem>
            </List>

            <DialogUploadCosts
              open={openDialogSend}
              onClose={() => setOpenDialogSend(false)}
              onSuccess={() => setOpenDialogSuccess(true)}
            />
            <DialogSuccessUploadCosts
              open={openDialogSuccess}
              onClose={() => setOpenDialogSuccess(false)}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {userState.hasPermission(TypesRolesPermissions.ROLE_VIEW_ACCESSES) ? (
        <ListItem
          button
          className={classes.listItem}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
          selected={location.pathname === ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
          component={Link}
          to={ROUTES.ADMIN_ROUTES.VIEW_ACCESSES}
        >
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          Gerenciar Acessos dos Assessores
        </ListItem>
      ) : (
        <></>
      )}

      {userState.hasPermission(TypesRolesPermissions.ROLE_TRIGGER_JOBS) ? (
        <ListItem
          button
          disabled={isLoadingCallJobService}
          onClick={async () => {
            try {
              setIsLoadingCallJobService(true);
              await callJobsService.execute(JobService.ANP_CUSTOMER_JOB);
              await new Promise((res) => setTimeout(res, 1000));
              snackbar.enqueueSnackbar(
                "A requisição foi enviada, logo os emails serão disparados.",
                { variant: "success" }
              );
            } catch (err) {
              snackbar.enqueueSnackbar(
                "Ocorreu um erro no envio da requisição, contate o suporte.",
                { variant: "error" }
              );
            } finally {
              setIsLoadingCallJobService(false);
            }
          }}
          className={classes.listItem}
          classes={{
            selected: classes.listItemSelected,
            button: classes.listItemHover,
          }}
        >
          <ListItemIcon>
            {isLoadingCallJobService ? (
              <CircularProgress color="primary" size={20}></CircularProgress>
            ) : (
              <EmailIcon />
            )}
          </ListItemIcon>
          Enviar relatório SAP x ANP
        </ListItem>
      ) : (
        <></>
      )}

      <Accordion square elevation={0} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          classes={{
            content: classes.accordionSumaryContent,
            root: classes.accordionSumaryRoot,
          }}
        >
          <ListItem
            button
            className={classes.listItem}
            style={{ marginBottom: 0 }}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
          >
            <ListItemIcon>
              <Payment />
            </ListItemIcon>
            Financeiro
          </ListItem>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <List>
            {/* <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD
              }
              component={Link}
              to={ROUTES.USER_ROUTES.FINANCIAL_DASHBOARD}
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              Dashboard
            </ListItem> */}
            <ListItem
              button
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
                button: classes.listItemHover,
              }}
              selected={
                location.pathname === ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS
              }
              component={Link}
              to={ROUTES.USER_ROUTES.FINANCIAL_INSTALLMENTS}
            >
              <ListItemIcon>
                <AccountBalanceWallet />
              </ListItemIcon>
              Consultar Partidas
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </List>
  );
};

const DrawerMenu: React.FC<Props> = ({ open, setOpen }) => {
  const classes = useStyles();
  const location = useLocation();
  const userState = useUserState();
  console.log(
    "🚀 ~ file: index.tsx:622 ~ userState:",
    userState.hasPermission(TypesRolesPermissions.ROLE_PRICING)
  );
  const dispatchUser = useUserDispatch();

  const getInitialLettersName = (name: string) => {
    const splitedName = name.split(" ");
    let letters = "";
    for (let splited of splitedName) {
      letters += splited[0];
    }
    return letters;
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen((oldValue: boolean) => !oldValue)}
    >
      <div className={classes.containerDrawer}>
        <Avatar className={classes.avatar}>
          {getInitialLettersName(userState.state.username)}
        </Avatar>
        <Typography className={classes.userName}>
          {userState.state.username}
        </Typography>
        <Typography>Manaus/Amazonas</Typography>

        <Autocomplete
          value={
            {
              ...userState.state.assessorList.find(
                (e) => e.bpName === userState.state.bpSelected.bpName
              ),
              userID: userState.state.userID,
            } as any
          }
          options={userState.state.assessorList.map((e) => {
            return { ...e, userID: userState.state.userID };
          })}
          className={classes.autocompleteRoot}
          getOptionLabel={(option: any) => `${option.bpID} - ${option.bpName}`}
          loading={userState.state.loadingAssessorList}
          onChange={(e: any, value: any) => {
            e.stopPropagation();
            dispatchUser({
              type: "APP_SET_ASSESSOR",
              payload: {
                bpID: value.bpID,
                type: value.type,
                bpName: value.bpName,
                userID: userState.state.userID,
              },
            });
          }}
          onClick={(e) => e.stopPropagation()}
          onClose={(e) => e.stopPropagation()}
          onBlur={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          renderOption={(option, { inputValue }) => {
            const matches = match(
              `${option.bpID} - ${option.bpName}`,
              inputValue
            );
            const parts = parse(`${option.bpID} - ${option.bpName}`, matches);
            return (
              <React.Fragment>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                      className={clsx(part.highlight && classes.matchText)}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </React.Fragment>
            );
          }}
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label="Carteira" color="primary" />
          )}
        />

        <Divider className={classes.divider} />

        <ListDrawerUser />

        <Divider className={classes.divider} />

        <Grid
          container
          justify="space-around"
          alignItems="center"
          direction="column"
        >
          <Grid item container justify="center">
            <Typography>Versão:</Typography>

            <Icon style={{ marginLeft: "1rem" }} component={BranchIcon} />
            <Typography>{appConfig.version.number}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              Atualizado em: {format(appConfig.version.date, "dd/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <List>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            selected={location.pathname === ROUTES.USER_ROUTES.CALL_CENTERS}
            component={Link}
            to={ROUTES.USER_ROUTES.CALL_CENTERS}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            Central de Atendimento
          </ListItem>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            selected={location.pathname === ROUTES.USER_ROUTES.CHANGE_PASSWORD}
            component={Link}
            to={ROUTES.USER_ROUTES.CHANGE_PASSWORD}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            Trocar Senha
          </ListItem>
          <ListItem
            button
            className={clsx(classes.listItem)}
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemHover,
            }}
            component="a"
            href={
              process.env.REACT_APP_ENVIRONMENT === "homologation"
                ? appConfig.cognito.urlLogout.homologation
                : process.env.NODE_ENV === "production"
                ? appConfig.cognito.urlLogout.production
                : appConfig.cognito.urlLogout.development
            }
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            Sair
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
