import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import {
  GetCentersDTO,
  GetManagersDTO,
  GetProductsDTO,
  GetSegmentsDTO,
  GetUFsDTO,
  IGetFilterOptionsService,
} from "../models/IGetFilterOptionsService";

@injectable()
export class GetFilterOptionsService implements IGetFilterOptionsService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getProducts(): Promise<GetProductsDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=product");
  }
  public async getCenters(): Promise<GetCentersDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=center");
  }
  public async getSegments(): Promise<GetSegmentsDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=segment");
  }
  public async getUFs(): Promise<GetUFsDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=uf");
  }
  public async getManagers(): Promise<GetManagersDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=manager");
  }
  public async getGroupProducts(): Promise<GetProductsDTO[]> {
    return this.httpInstance.get("/dashboard/filter?action=groupProduct");
  }
}
