import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IRemoveActiveCostService } from "../models/IRemoveActiveCostService";

@injectable()
export class RemoveActiveCostService implements IRemoveActiveCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(activeCostID: string): Promise<void> {
    return this.httpInstance.delete("/costs/" + activeCostID);
  }
}
