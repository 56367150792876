import { BoxInfo } from "@components/BoxInfo";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import { HomeDashboardFilters } from "@pages/Home/HomeDashboardContext";
import { formatCurrency } from "@utils/index";
import clsx from "clsx";
import React, { useMemo } from "react";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      gridBox: { margin: "0.5rem 0", "& > div": { margin: "1rem" } },
      paperTable: {
        padding: "1rem",
        height: "120vh",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: { height: "80vh", flex: 1 },
      },
      marginPositive: { color: theme.palette.success.main },
      marginNegative: { color: theme.palette.error.main },
    })
);

interface TableCustomerData {
  FT: {
    total: number;
    rows: { name: string; value: number; id: string; litterMargin: number[] }[];
  };
  LIB: { total: number; rows: { name: string; value: number; id: string }[] };
  BL: { total: number; rows: { name: string; value: number; id: string }[] };
  PJ: { total: number; rows: { name: string; value: number; id: string }[] };
}
interface TableProductData {
  FT: { rows: { name: string; value: number; id: string }[] };
  LIB: { rows: { name: string; value: number; id: string }[] };
  BL: { rows: { name: string; value: number; id: string }[] };
  PJ: { rows: { name: string; value: number; id: string }[] };
}

const StatusBoxes: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const iocContext = useIoCContext();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchData = useFetchData(
    () => getDataChartService.getStatusVolume({ ...filters, from, to }),
    {
      useCallbackDeps: [filters, from, to],
      useEffectDeps: [filters, from, to],
    }
  );
  const fetchCustomerVolumeData = useFetchData(
    () => getDataChartService.getCustomerStatusVolume({ ...filters, from, to }),
    {
      useCallbackDeps: [filters, from, to],
      useEffectDeps: [filters, from, to],
    }
  );
  const fetchProductStatusVolumeData = useFetchData(
    () => getDataChartService.getProductStatusVolume({ ...filters, from, to }),
    {
      useCallbackDeps: [filters, from, to],
      useEffectDeps: [filters, from, to],
    }
  );

  const fetchMarginLitter = useFetchData(
    () => getDataChartService.getMarginLitter({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const tableProductData: TableProductData = useMemo(() => {
    if (!fetchProductStatusVolumeData.value) {
      return {
        FT: { rows: [] },
        BL: { rows: [] },
        LIB: { rows: [] },
        PJ: { rows: [] },
      };
    }

    const statusIdx = fetchProductStatusVolumeData.value.columns.findIndex(
      (column) => column.name === "status"
    );
    const nameIdx = fetchProductStatusVolumeData.value.columns.findIndex(
      (column) => column.name === "productGroup"
    );
    const quantityIdx = fetchProductStatusVolumeData.value.columns.findIndex(
      (column) => column.name === "quantity"
    );
    const litterMarginIdx = fetchProductStatusVolumeData.value.columns.findIndex(
      (column) => column.name === "litterMargin"
    );

    const tableData: TableProductData = {
      FT: {
        rows: fetchProductStatusVolumeData.value.rows
          .filter((row) => row[statusIdx] === "FT" || row[statusIdx] === "DEV")
          .map((row) => ({
            id: row[nameIdx] as string,
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
            litterMargin: row[litterMarginIdx] as number,
          }))
          .reduce((a, b) => {
            const idx = a.findIndex((row) => row.id === b.id);
            if (idx > -1) {
              a[idx] = {
                ...a[idx],
                value: a[idx].value + b.value,
              };
              if (b.value > 0) {
                a[idx].litterMargin = [b.litterMargin];
              }
            } else {
              if (b.value > 0) {
                a.push({ ...b, litterMargin: [b.litterMargin] });
              } else {
                a.push({ ...b, litterMargin: [0] });
              }
            }
            return a;
          }, [] as { id: string; name: string; value: number; litterMargin: number[] }[]),
      },
      BL: {
        rows: fetchProductStatusVolumeData.value.rows
          .filter((row) => row[statusIdx] === "BL")
          .map((row) => ({
            id: row[nameIdx] as string,
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
          })),
      },
      LIB: {
        rows: fetchProductStatusVolumeData.value.rows
          .filter((row) => row[statusIdx] === "LIB")
          .map((row) => ({
            id: row[nameIdx] as string,
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
          })),
      },
      PJ: {
        rows: fetchProductStatusVolumeData.value.rows
          .filter((row) => row[statusIdx] === "LIB" || row[statusIdx] === "FT")
          .map((row) => ({
            id: row[nameIdx] as string,
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
          }))
          .reduce((a, b) => {
            const idx = a.findIndex((row) => row.id === b.id);
            if (idx > -1) {
              a[idx] = { ...a[idx], value: a[idx].value + b.value };
            } else {
              a.push(b);
            }
            return a;
          }, [] as { id: string; name: string; value: number }[]),
      },
    };

    return tableData;
  }, [fetchProductStatusVolumeData.value]);

  const tableData: TableCustomerData = useMemo(() => {
    if (!fetchCustomerVolumeData.value) {
      return {
        FT: { total: 0, rows: [] },
        LIB: { total: 0, rows: [] },
        BL: { total: 0, rows: [] },
        PJ: { total: 0, rows: [] },
      };
    }

    const statusIdx = fetchCustomerVolumeData.value.columns.findIndex(
      (column) => column.name === "status"
    );
    const nameIdx = fetchCustomerVolumeData.value.columns.findIndex(
      (column) => column.name === "customerName"
    );
    const quantityIdx = fetchCustomerVolumeData.value.columns.findIndex(
      (column) => column.name === "quantity"
    );
    const idIdx = fetchCustomerVolumeData.value.columns.findIndex(
      (column) => column.name === "customerID"
    );
    const litterMarginIdx = fetchCustomerVolumeData.value.columns.findIndex(
      (column) => column.name === "litterMargin"
    );

    const tableData: TableCustomerData = {
      FT: {
        total: fetchCustomerVolumeData.value.rows.reduce(
          (a, b) =>
            b[statusIdx] === "FT" || b[statusIdx] === "DEV" ? a + 1 : a,
          0
        ),
        rows: fetchCustomerVolumeData.value.rows
          .filter((row) => row[statusIdx] === "FT" || row[statusIdx] === "DEV")
          .map((row) => ({
            id: row[nameIdx] as string,
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
            litterMargin: row[litterMarginIdx] as number,
          }))
          .reduce((a, b) => {
            const idx = a.findIndex((row) => row.id === b.id);
            if (idx > -1) {
              a[idx] = {
                ...a[idx],
                value: a[idx].value + b.value,
              };
              if (b.value > 0) {
                a[idx].litterMargin = [b.litterMargin];
              }
            } else {
              if (b.value > 0) {
                a.push({ ...b, litterMargin: [b.litterMargin] });
              } else {
                a.push({ ...b, litterMargin: [0] });
              }
            }
            return a;
          }, [] as { id: string; name: string; value: number; litterMargin: number[] }[]),
      },
      LIB: {
        total: fetchCustomerVolumeData.value.rows.reduce(
          (a, b) => (b[statusIdx] === "LIB" ? a + 1 : a),
          0
        ),
        rows: fetchCustomerVolumeData.value.rows
          .filter((row) => row[statusIdx] === "LIB")
          .map((row) => ({
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
            id: row[idIdx] as string,
          })),
      },
      BL: {
        total: fetchCustomerVolumeData.value.rows.reduce(
          (a, b) => (b[statusIdx] === "BL" ? a + 1 : a),
          0
        ),
        rows: fetchCustomerVolumeData.value.rows
          .filter((row) => row[statusIdx] === "BL")
          .map((row) => ({
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
            id: row[idIdx] as string,
          })),
      },
      PJ: {
        total: fetchCustomerVolumeData.value.rows.reduce(
          (a, b) =>
            b[statusIdx] === "FT" || b[statusIdx] === "LIB" ? a + 1 : a,
          0
        ),
        rows: fetchCustomerVolumeData.value.rows
          .filter((row) => row[statusIdx] === "LIB" || row[statusIdx] === "FT")
          .map((row) => ({
            name: row[nameIdx] as string,
            value: row[quantityIdx] as number,
            id: row[idIdx] as string,
          }))
          .reduce((a, b) => {
            const idx = a.findIndex((row) => row.id === b.id);
            if (idx > -1) {
              a[idx] = { ...a[idx], value: a[idx].value + b.value };
            } else {
              a.push(b);
            }
            return a;
          }, [] as { name: string; value: number; id: string }[]),
      },
    };

    return tableData;
  }, [fetchCustomerVolumeData.value]);

  const columns: GridColDef[] = [
    { headerName: "Cliente", field: "name", flex: 1 },
    { headerName: "Quantidade", field: "value", width: 120, type: "number" },
  ];
  const columnsFT: GridColDef[] = [
    { headerName: "Cliente", field: "name", flex: 1 },
    {
      headerName: "Margem/L",
      field: "litterMargin",
      width: 120,
      type: "number",
      valueGetter: (props) =>
        props.value
          ? (props.value as number[]).reduce((a, b) => a + b, 0) /
            (props.value as number[]).length
          : 0,
      valueFormatter: (props) =>
        props.value
          ? formatCurrency(props.value as number, { minimumFractionDigits: 4 })
          : formatCurrency(0, { minimumFractionDigits: 4 }),
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.marginNegative]: (props.value as number) < 0,
              [classes.marginPositive]: (props.value as number) > 0,
            })
          : "",
    },
    { headerName: "Quantidade", field: "value", width: 120, type: "number" },
  ];
  const columnsProduct: GridColDef[] = [
    { headerName: "Produto", field: "name", flex: 1 },
    { headerName: "Quantidade", field: "value", width: 120, type: "number" },
  ];
  const columnsProductFT: GridColDef[] = [
    { headerName: "Produto", field: "name", flex: 1 },
    {
      headerName: "Margem/L",
      field: "litterMargin",
      width: 120,
      type: "number",
      valueFormatter: (props) =>
        props.value
          ? formatCurrency(props.value as number, { minimumFractionDigits: 4 })
          : formatCurrency(0, { minimumFractionDigits: 4 }),
      cellClassName: (props) =>
        props.value
          ? clsx({
              [classes.marginNegative]: (props.value as number) < 0,
              [classes.marginPositive]: (props.value as number) > 0,
            })
          : "",
    },
    { headerName: "Quantidade", field: "value", width: 120, type: "number" },
  ];

  const valueBoxes = useMemo(() => {
    const formatNumber = (x: number) =>
      new Intl.NumberFormat("pt-BR", { maximumFractionDigits: 0 }).format(x);

    if (!fetchData.value)
      return {
        LIB: "0",
        FT: { value: "0", litterMargin: 0 },
        BL: "0",
        PJ: "0",
      };

    const litterMarginIdx = fetchData.value.columns.findIndex(
      (column) => column.name === "litterMargin"
    );
    const statusIdx = fetchData.value.columns.findIndex(
      (column) => column.name === "status"
    );
    const quantityIdx = fetchData.value.columns.findIndex(
      (column) => column.name === "quantity"
    );

    const LIB = fetchData.value.rows.find((row) => row[statusIdx] === "LIB");
    const FT = fetchData.value.rows.reduce(
      (a, b) =>
        b[statusIdx] === "FT" || b[statusIdx] === "DEV"
          ? a + (b[quantityIdx] as number)
          : a,
      0
    );
    const FTData = fetchData.value.rows.find((row) => row[statusIdx] === "FT");

    const BL = fetchData.value.rows.find((row) => row[statusIdx] === "BL");
    const PJ = fetchData.value.rows
      .filter((row) => row[statusIdx] === "LIB" || row[statusIdx] === "FT")
      .map((row) => row[quantityIdx] as number)
      .reduce((a, b) => a + b, 0);

    return {
      LIB: LIB ? formatNumber(LIB[quantityIdx] as number) : "0",
      FT: {
        value: FT ? formatNumber(FT) : "0",
        litterMargin: (FTData ? FTData[litterMarginIdx] : 0) as number,
      },
      BL: BL ? formatNumber(BL[quantityIdx] as number) : "0",
      PJ: PJ ? formatNumber(PJ) : "0",
    };
  }, [fetchData.value]);

  return (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      justify="space-evenly"
      className={classes.gridBox}
    >
      <Paper className={classes.paperTable}>
        <BoxInfo
          colorBoxIcon={theme.palette.success.main}
          icon={<DoneAllIcon style={{ color: "#fff" }} />}
          value={valueBoxes.FT.value}
          margin={fetchMarginLitter.value ? fetchMarginLitter.value : 0}
          title="Faturado"
          loading={fetchData.loading}
        />
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columnsProductFT}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            loading={fetchProductStatusVolumeData.loading}
            rows={tableProductData["FT"].rows}
          />
        </Box>
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columnsFT}
            disableSelectionOnClick
            pageSize={25}
            rowsPerPageOptions={[25]}
            loading={fetchCustomerVolumeData.loading}
            rows={tableData["FT"].rows}
          />
        </Box>
      </Paper>
      <Paper className={classes.paperTable}>
        <BoxInfo
          colorBoxIcon={theme.palette.warning.main}
          icon={<CheckCircleIcon style={{ color: "#fff" }} />}
          value={valueBoxes.LIB}
          title="Liberado"
          loading={fetchData.loading}
        />
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columnsProduct}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            loading={fetchProductStatusVolumeData.loading}
            rows={tableProductData["LIB"].rows}
          />
        </Box>
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            loading={fetchCustomerVolumeData.loading}
            disableSelectionOnClick
            rows={tableData["LIB"].rows}
          />
        </Box>
      </Paper>
      <Paper className={classes.paperTable}>
        <BoxInfo
          colorBoxIcon={theme.palette.error.main}
          icon={<BlockIcon style={{ color: "#fff" }} />}
          value={valueBoxes.BL}
          title="Bloqueado"
          loading={fetchData.loading}
        />
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columnsProduct}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            loading={fetchProductStatusVolumeData.loading}
            rows={tableProductData["BL"].rows}
          />
        </Box>
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            loading={fetchCustomerVolumeData.loading}
            disableSelectionOnClick
            rows={tableData["BL"].rows}
          />
        </Box>
      </Paper>
      <Paper className={classes.paperTable}>
        <BoxInfo
          colorBoxIcon={theme.palette.info.main}
          icon={<TrendingUpIcon style={{ color: "#fff" }} />}
          value={valueBoxes.PJ}
          title="Projeção"
          loading={fetchData.loading}
        />
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columnsProduct}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            loading={fetchProductStatusVolumeData.loading}
            rows={tableProductData["PJ"].rows}
          />
        </Box>
        <Box style={{ flex: 1, marginTop: "1rem" }}>
          <DataGrid
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            loading={fetchCustomerVolumeData.loading}
            disableSelectionOnClick
            rows={tableData["PJ"].rows}
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export { StatusBoxes };
