import { createStyles, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    infoContainer: {
      display: "flex",
      alignItems: "center",
    },
    infoBold: {
      fontWeight: "bold",
      fontSize: "1.2rem",
      color: "#212121",
    },
    infoLight: {
      fontWeight: "lighter",
      fontSize: "1rem",
    },
    icon: {
      fontSize: "2.5rem",
      color: "rgb(252,196,49)",
    },
    infoTextContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "1rem",
    },
    info: {
      padding: "0.5rem",
      display: "flex",
      justifyContent: "space-around",
      backgroundColor: "#f1eded",
    },
  })
);

const Footer: React.FC = (props) => {
  const classes = useStyles();
  return (
    <footer>
      <div className={classes.info}>
        <div className={classes.infoContainer}>
          <ScheduleIcon className={classes.icon} />
          <div className={classes.infoTextContainer}>
            <Typography className={classes.infoBold} component="span">
              Seg - Sex: 7:00 - 17:00
            </Typography>
            <Typography component="span" className={classes.infoLight}>
              Atendimentos personalizados
            </Typography>
          </div>
        </div>

        <div className={classes.infoContainer}>
          <LocationOnIcon className={classes.icon} />
          <div className={classes.infoTextContainer}>
            <Typography className={classes.infoBold}>
              Rua Pajurá, 103 - Vila Buriti, CEP: 69072-065
            </Typography>
            <Typography component="span" className={classes.infoLight}>
              Manaus - AM
            </Typography>
          </div>
        </div>

        <div className={classes.infoContainer}>
          <PhoneInTalkIcon className={classes.icon} />
          <div className={classes.infoTextContainer}>
            <Typography className={classes.infoBold} component="span">
              +55 (92) 2125-0050
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
