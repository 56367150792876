import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IGetActiveCostsDTO } from "../dtos/IGetActiveCostsDTO";
import { ISearchCostServiceDTO } from "../dtos/ISearchCostServiceDTO";
import { ISearchCostService } from "../models/ISearchCostService";

@injectable()
export class SearchCostService implements ISearchCostService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(data: ISearchCostServiceDTO): Promise<IGetActiveCostsDTO[]> {
    return this.httpInstance.get<IGetActiveCostsDTO[]>("/costs", {
      params: { ...data, type: "pb", material: data.material.join(",") },
    });
  }
}
