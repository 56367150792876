import NoData from "@components/NoData";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { formatCurrency, formatPercent, maskCNPJ } from "@utils/index";
import React from "react";
import { useFormQuery } from "./FormQueryContext";

const TableSales: React.FC = () => {
  const formQueryContext = useFormQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });

  const columns: GridColDef[] = [
    {
      field: "bpid",
      headerName: "BP",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Razão social",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "document",
      headerName: "CNPJ",
      width: 150,
      headerAlign: "center",
      valueFormatter: (props) =>
        maskCNPJ(props.getValue(props.id, props.field) as string),
      align: "center",
    },
    {
      field: "partner",
      headerName: "Assessor",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "manager",
      headerName: "Gerente",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "segment",
      headerName: "Segmento",
      width: 150,
      headerAlign: "center",
      valueFormatter: (props) =>
        maskCNPJ(props.getValue(props.id, props.field) as string),
      align: "center",
    },
    {
      field: "city",
      headerName: "Cidade",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "uf",
      headerName: "UF",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "product",
      headerName: "Produto",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "quantity",
    //   headerName: "Quantidade",
    //   width: 150,
    //   headerAlign: "right",
    //   align: "right",
    //   valueFormatter: (props) =>
    //     new Intl.NumberFormat("pt-BR").format(
    //       props.getValue(props.id, props.field) as number
    //     ),
    // },
    {
      field: "volume",
      headerName: "Volume Atem",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        new Intl.NumberFormat("pt-BR").format(
          props.getValue(props.id, props.field) as number
        ),
    },
    {
      field: "poshcashVolume",
      headerName: "Volume Campanha",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        new Intl.NumberFormat("pt-BR").format(
          props.getValue(props.id, props.field) as number
        ),
    },
    {
      field: "volumeProportion",
      headerName: "Participação Campanha no Volume",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatPercent(props.getValue(props.id, props.field) as number, 2),
    },
    {
      field: "avgPrice",
      headerName: "Média de Preço Atem",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
    },
    {
      field: "poshcashAvgPrice",
      headerName: "Média de Preço p/ Consumidor",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
    },

    {
      field: "total",
      headerName: "Total Atem (R$)",
      width: 250,
      headerAlign: "center",
      align: "center",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
    },
    {
      field: "poshcashTotal",
      headerName: "Total da Campanha (R$)",
      width: 250,
      headerAlign: "right",
      align: "right",
      valueFormatter: (props) =>
        formatCurrency(props.getValue(props.id, props.field) as number),
    },
  ];

  return formQueryContext.dataQuery &&
    formQueryContext.dataQuery.content.length === 0 ? (
    <NoData>Não foram encontrados resultados</NoData>
  ) : (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div style={{ height: isMobile ? 600 : "100%" }}>
        <DataGrid
          columns={columns}
          loading={formQueryContext.loadingQuery}
          rows={
            formQueryContext.dataQuery ? formQueryContext.dataQuery.content : []
          }
          getRowId={(row) => `${row.bpid}:${row.product}`}
          rowCount={formQueryContext.dataQuery?.total}
          pageSize={formQueryContext.query.limit}
          page={formQueryContext.query.page - 1}
          pagination
          paginationMode="server"
          onPageSizeChange={(pageSize) => {
            formQueryContext.setQuery((state) => ({
              ...state,
              limit: pageSize,
            }));
          }}
          onPageChange={(newPage) => {
            formQueryContext.setQuery((state) => ({
              ...state,
              page: newPage + 1,
            }));
          }}
          filterModel={formQueryContext.filterModel}
        />
      </div>
    </div>
  );
};

export default TableSales;
