import { inject, injectable } from "inversify";
import { Types } from "../../../ioc/types";
import { IHttpService } from "../../infra/http/models/IHttpService";
import { IHandoutsDataDTO } from "../dtos/IHandoutsDataDTO";
import { IGetHandoutsSeenOrNotService } from "../models/IGetHandoutsSeenOrNotService";

@injectable()
export class GetHandoutsSeenOrNotService
  implements IGetHandoutsSeenOrNotService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(userID: string) {
    const handouts = await this.httpInstance.get<IHandoutsDataDTO[]>(
      "/handouts",
      {
        params: {
          userID,
          actions: "seen-or-not",
        },
      }
    );
    return handouts;
  }
}
