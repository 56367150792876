import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    iframe: { borderWidth: 0, width: "100%", height: "100%" },
    title: {
      fontSize: "1.6rem",
      fontWeight: 600,
      color: "#313B45",
    },
    chartContainer: {
      height: "100%",
      width: "100%",
    },
    containerScroll: {
      width: "400vw",
      height: "100%",
    },
  })
);

export interface ChartContainerProps {
  loading?: boolean;
  title?: string;
  scrollInMobile?: boolean;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  loading,
  children,
  scrollInMobile,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <span className={classes.title}>{title?.toLocaleUpperCase()}</span>
      <Paper
        style={{
          height: "100%",
          display: "flex",
          borderRadius: 8,
          marginTop: 16,
        }}
        elevation={0}
      >
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              className={classes.chartContainer}
              style={{
                overflowX: scrollInMobile && isMobile ? "scroll" : "hidden",
              }}
            >
              {scrollInMobile && isMobile ? (
                <Box className={classes.containerScroll}>{children}</Box>
              ) : (
                children
              )}
            </Box>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export { ChartContainer };
