import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { IUser } from "@utils/interfaces";
import { inject, injectable } from "inversify";
import { IListUsersService } from "../models/IListUsersService";

@injectable()
export class ListUsersService implements IListUsersService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(search?: string): Promise<IUser[]> {
    return this.httpInstance.get<IUser[]>("/users", {
      params: {
        search,
      },
    });
  }
}
