import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IUploadLoginImageService } from "../models/IUploadLoginImageService";

@injectable()
export class UploadLoginImageService implements IUploadLoginImageService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    await this.httpInstance.post(`/login-images`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
