import { BoxInfo } from "@components/BoxInfo";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useFetchData } from "@hooks/FetchData";
import { Types } from "@ioc/types";
import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import {
  IGetChartDataService,
  ProductGroupResponse,
  ProductStatusVolumeResponse,
} from "@modules/dashboard/models/IGetChartDataService";
import {
  HomeDashboardFilters,
  useHomeDashboard,
} from "@pages/Home/HomeDashboardContext";
import { formatCurrency } from "@utils/index";
import clsx from "clsx";
import React, { useMemo } from "react";
import { ProductGroupDataWithLitterMargin } from "./ProductBoxes";

const useStyles = makeStyles(
  ({ typography: { pxToRem, ...typography }, ...theme }) =>
    createStyles({
      gridBox: { margin: "0.5rem 0", "& > div": { margin: "1rem" } },
      absolutePositionCircular: {
        top: 0,
        left: 0,
        position: "absolute",
      },
      backgroundCircular: { color: "#fff" },
    })
);

const TotalizersProductBoxes: React.FC<{
  filters: HomeDashboardFilters;
  from: string;
  to: string;
}> = ({ filters, from, to }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const iocContext = useIoCContext();
  const { dateCharts } = useHomeDashboard();

  const getDataChartService = iocContext.serviceContainer.get<
    IGetChartDataService
  >(Types.Dashboard.IGetChartDataService);

  const fetchDataForDaily = useFetchData(
    () => getDataChartService.getProductStatusVolume({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchDataForMonthly = useFetchData(
    () => getDataChartService.getProductGroup({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchAvgRateSales = useFetchData(
    () =>
      getDataChartService.getAvgRateSales({
        ...filters,
        from,
        to,
      }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchSalesProjection = useFetchData(
    () =>
      getDataChartService.getSalesProjection({
        ...filters,
        from,
        to,
      }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchMarginLitter = useFetchData(
    () => getDataChartService.getMarginLitter({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const fetchTargetSales = useFetchData(
    () => getDataChartService.getTargetSales({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );
  const fetchTargetSalesPerformance = useFetchData(
    () =>
      getDataChartService.getTargetSalesPerformance({ ...filters, from, to }),
    { useCallbackDeps: [filters, from, to], useEffectDeps: [filters, from, to] }
  );

  const productGroupData: ProductGroupDataWithLitterMargin | null = useMemo(() => {
    if (dateCharts.selected !== 0) {
      if (!fetchDataForMonthly.value) {
        return null;
      }
    } else {
      if (!fetchDataForDaily.value) {
        return null;
      }
    }

    let data:
      | ProductGroupResponse
      | ProductStatusVolumeResponse = fetchDataForDaily.value!;
    if (dateCharts.selected !== 0) {
      data = fetchDataForMonthly.value!;
    }

    const nameIdx = data.columns.findIndex(
      (column) => column.name === "productGroup"
    );
    const quantityIdx = data.columns.findIndex(
      (column) => column.name === "quantity"
    );
    const litterMarginIdx = data.columns.findIndex(
      (column) => column.name === "litterMargin"
    );

    // @ts-ignore
    const groupProduct: ProductGroupDataWithLitterMargin = data.rows.reduce(
      // @ts-ignore
      (a, b) => {
        if (a[b[nameIdx]]) {
          a[b[nameIdx]] = {
            value: a[b[nameIdx]] + (b[quantityIdx] as number),
            litterMargin: b[litterMarginIdx],
          };
        } else {
          a[b[nameIdx]] = {
            value: b[quantityIdx] as number,
            litterMargin: b[litterMarginIdx],
          };
        }

        return a;
      },
      {} as ProductGroupDataWithLitterMargin
    );

    return groupProduct;
  }, [dateCharts.selected, fetchDataForDaily, fetchDataForMonthly.value]);

  const products = useMemo(() => {
    if (!productGroupData) return [];

    return Object.keys(productGroupData).map((key) => {
      return {
        name: key,
        value: productGroupData[key].value,
        litterMargin: productGroupData[key].litterMargin,
      };
    });
  }, [productGroupData]);

  const colors = [
    "#008FFB",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    theme.palette.primary.main,
  ];

  return (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      justify="space-evenly"
      className={classes.gridBox}
    >
      <BoxInfo
        colorBoxIcon={colors[0]}
        title="Total"
        value={new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
        }).format(products.reduce((a, b) => a + b.value, 0))}
        icon={<LocalGasStationIcon style={{ color: "#fff" }} />}
        loading={fetchDataForDaily.loading || fetchDataForMonthly.loading}
      />
      <BoxInfo
        colorBoxIcon={colors[1]}
        title="Margem/L"
        // fetchMarginLitter.value ? fetchMarginLitter.value : 0
        value={formatCurrency(
          fetchMarginLitter.value ? fetchMarginLitter.value : 0,
          { minimumFractionDigits: 4 }
        )}
        icon={<AttachMoneyIcon style={{ color: "#fff" }} />}
        loading={fetchMarginLitter.loading}
        showDownUpValue
        isDownUpValue={
          (fetchMarginLitter.value ? fetchMarginLitter.value : 0) > 0
        }
      />
      {/*<BoxInfo*/}
      {/*  colorBoxIcon={colors[1]}*/}
      {/*  title="Margem/L"*/}
      {/*  value={formatCurrency(*/}
      {/*    products.reduce((a, b) => a + b.litterMargin, 0) / products.length,*/}
      {/*    { minimumFractionDigits: 4 }*/}
      {/*  )}*/}
      {/*  icon={<AttachMoneyIcon style={{ color: "#fff" }} />}*/}
      {/*  loading={fetchAvgRateSales.loading}*/}
      {/*  showDownUpValue*/}
      {/*  isDownUpValue={*/}
      {/*    products.reduce((a, b) => a + b.litterMargin, 0) / products.length > 0*/}
      {/*      ? true*/}
      {/*      : false*/}
      {/*  }*/}
      {/*/>*/}
      <BoxInfo
        colorBoxIcon={colors[2]}
        title="Venda Média/Dia"
        value={new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
        }).format(fetchAvgRateSales.value ? fetchAvgRateSales.value : 0)}
        icon={<MultilineChartIcon style={{ color: "#fff" }} />}
        loading={fetchAvgRateSales.loading}
      />
      <BoxInfo
        colorBoxIcon={colors[3]}
        title="Projeção de vendas"
        value={new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
        }).format(fetchSalesProjection.value ? fetchSalesProjection.value : 0)}
        icon={<TrendingUpIcon style={{ color: "#fff" }} />}
        loading={fetchSalesProjection.loading}
      />
      <BoxInfo
        colorBoxIcon={colors[4]}
        title="Meta"
        value={new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
        }).format(fetchTargetSales.value ? fetchTargetSales.value : 0)}
        icon={<AssessmentIcon style={{ color: "#fff" }} />}
        loading={fetchTargetSales.loading}
      />
      <BoxInfo
        colorBoxIcon={colors[5]}
        title="Cumprimento de Meta"
        value={new Intl.NumberFormat("pt-BR", {
          minimumFractionDigits: 0,
          style: "percent",
        }).format(
          fetchTargetSalesPerformance.value
            ? fetchTargetSalesPerformance.value
            : 0
        )}
        icon={
          <div style={{ position: "relative", display: "flex" }}>
            <CircularProgress
              variant="determinate"
              disableShrink
              value={100}
              className={clsx(
                classes.backgroundCircular,
                classes.absolutePositionCircular
              )}
            />

            <CircularProgress
              variant="determinate"
              value={
                fetchTargetSalesPerformance.value
                  ? fetchTargetSalesPerformance.value > 1
                    ? 100
                    : fetchTargetSalesPerformance.value * 100
                  : 0
              }
              color="primary"
            />
          </div>
        }
        loading={fetchTargetSalesPerformance.loading}
      />
    </Grid>
  );
};

export { TotalizersProductBoxes };
