import { IGetUserAccessesService } from "@modules/admin/models/IGetUserAccessesService";
import { GetUserAccessesService } from "@modules/admin/services/GetUserAccessesService";
import { IEditActiveCostService } from "@modules/costs/models/IEditActiveCostService";
import { IGetActiveCostsService } from "@modules/costs/models/IGetActiveCostsService";
import { IGetHistoryActiveCostsService } from "@modules/costs/models/IGetHistoryActiveCostsService";
import { IRemoveActiveCostService } from "@modules/costs/models/IRemoveActiveCostService";
import { ISearchCostService } from "@modules/costs/models/ISearchCostService";
import { IUploadSheetCostsService } from "@modules/costs/models/IUploadSheetCostsService";
import { EditActiveCostService } from "@modules/costs/services/EditActiveCostService";
import { GetActiveCostsService } from "@modules/costs/services/GetActiveCostsService";
import { GetHistoryActiveCostsService } from "@modules/costs/services/GetHistoryActiveCostsService";
import { RemoveActiveCostService } from "@modules/costs/services/RemoveActiveCostService";
import { SearchCostService } from "@modules/costs/services/SearchCostService";
import { UploadSheetCostsService } from "@modules/costs/services/UploadSheetCostsService";
import { IGetChartDataService } from "@modules/dashboard/models/IGetChartDataService";
import { IGetFilterOptionsService } from "@modules/dashboard/models/IGetFilterOptionsService";
import { GetChartDataService } from "@modules/dashboard/services/GetChartDataService";
import { GetFilterOptionsService } from "@modules/dashboard/services/GetFilterOptionsService";
import { IDownloadPDFInvoicesService } from "@modules/invoices/models/IDownloadPDFInvoicesService";
import { IQueryInvoicesService } from "@modules/invoices/models/IQueryInvoicesService";
import { DownloadPDFInvoicesService } from "@modules/invoices/services/IDownloadPDFInvoicesService";
import { QueryInvoicesService } from "@modules/invoices/services/QueryInvoicesService";
import { ICallJobsService } from "@modules/jobs/models/ICallJobsService";
import { CallJobsService } from "@modules/jobs/services/CallJobsService";
import { IListLoginImageService } from "@modules/loginImages/models/IListLoginImageService";
import { IRemoveLoginImageService } from "@modules/loginImages/models/IRemoveLoginImageService";
import { IUploadLoginImageService } from "@modules/loginImages/models/IUploadLoginImageService";
import { ListLoginImageService } from "@modules/loginImages/service/ListLoginImagesService";
import { RemoveLoginImageService } from "@modules/loginImages/service/RemoveLoginImageService";
import { UploadLoginImageService } from "@modules/loginImages/service/UploadLoginImageService";
import { IListLogsService } from "@modules/logs/models/IListLogsService";
import { ListLogsService } from "@modules/logs/service/ListLogsService";
import { ICancelOrderService } from "@modules/orders/models/ICancelOrderService";
import { ICreatePDFOrdersByStatusService } from "@modules/orders/models/ICreatePDFOrdersByStatusService";
import { IDownloadExcelOrdersByStatusService } from "@modules/orders/models/IDownloadExcelOrdersByStatusService";
import { IDownloadExcelSalesAnalyticsService } from "@modules/orders/models/IDownloadExcelSalesAnalyticsService";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { IGetOrdersAggregatedPerDayService } from "@modules/orders/models/IGetOrdersAggregatedPerDayService";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import { IQueryOrdersByStatus } from "@modules/orders/models/IQueryOrdersByStatus";
import { IQueryOrdersByStatusAggregatorsService } from "@modules/orders/models/IQueryOrdersByStatusAggregatorsService";
import { IQuerySalesAnalyticsService } from "@modules/orders/models/IQuerySalesAnalyticsService";
import { ITimeLimitLocationService } from "@modules/orders/models/ITimeLimitLocationService";
import { CancelOrderService } from "@modules/orders/services/CancelOrderService";
import { CreatePDFOrdersByStatusService } from "@modules/orders/services/CreatePDFOrdersByStatusService";
import { DownloadExcelOrdersByStatusService } from "@modules/orders/services/DownloadExcelOrdersByStatusService";
import { DownloadExcelSalesAnalyticsService } from "@modules/orders/services/DownloadExcelSalesAnalyticsService";
import { GetClientInfoService } from "@modules/orders/services/GetClientInfoService";
import { GetOrdersAggregatedPerDayService } from "@modules/orders/services/GetOrdersAggregatedPerDayService";
import { GetPriceProductsService } from "@modules/orders/services/GetPriceProductsService";
import { QueryOrdersByStatus } from "@modules/orders/services/QueryOrdersByStatus";
import { QueryOrdersByStatusAggregatorsService } from "@modules/orders/services/QueryOrdersByStatusAggregatorsService";
import { QuerySalesAnalyticsService } from "@modules/orders/services/QuerySalesAnalyticsService";
import { TimeLimitLocationService } from "@modules/orders/services/TimeLimitLocationService";
import { ICreatePDFTitlesService } from "@modules/titles/models/ICreatePDFTitlesService";
import { IDownloadPDFBoletoTitlesService } from "@modules/titles/models/IDownloadPDFBoletoTitlesService";
import { IDownloadTitlesService } from "@modules/titles/models/IDownloadTitlesService";
import { IGetTitleImagesSeenOrNotService } from "@modules/titles/models/IGetTitleImagesSeenOrNotService";
import { IMarkImageAsSeenService } from "@modules/titles/models/IMarkImageAsSeenService";
import { IQueryTitlesService } from "@modules/titles/models/IQueryTitlesService";
import { IUploadTitleImageService } from "@modules/titles/models/IUploadTitleImageService";
import { CreatePDFTitlesService } from "@modules/titles/services/CreatePDFTitlesService";
import { DownloadPDFBoletoTitlesService } from "@modules/titles/services/DownloadPDFBoletoTitlesService";
import { DownloadTitlesService } from "@modules/titles/services/DownloadTitlesService";
import { GetTitleImagesSeenOrNotService } from "@modules/titles/services/GetTitleImagesSeenOrNotService";
import { MarkImageAsSeenService } from "@modules/titles/services/MarkImageAsSeenService";
import { QueryTitlesService } from "@modules/titles/services/QueryTitlesService";
import { UploadTitleImageService } from "@modules/titles/services/UploadTitleImageService";
import { IBlockUserService } from "@modules/user/models/IBlockUserService";
import { IChangePasswordService } from "@modules/user/models/IChangePasswordService";
import { ICreateUserService } from "@modules/user/models/ICreateUserService";
import { IDownloadExcelWalletAssessorService } from "@modules/user/models/IDownloadExcelWalletAssessorService";
import { IGetAssessorUsersService } from "@modules/user/models/IGetAssessorUsersService";
import { IGetTokenToKibanaChartsService } from "@modules/user/models/IGetTokenToKibanaChartsService";
import { IGetWalletAssessorService } from "@modules/user/models/IGetWalletAssessorService";
import { IListUsersService } from "@modules/user/models/IListUsersService";
import { ISendEmailToNotVerifiedEmailUsers } from "@modules/user/models/ISendEmailToNotVerifiedEmailUsers";
import { IUnlockUserService } from "@modules/user/models/IUnlockUserService";
import { IUpdateUserService } from "@modules/user/models/IUpdateUserService";
import { BlockUserService } from "@modules/user/services/BlockUserService";
import { ChangePasswordService } from "@modules/user/services/ChangePasswordService";
import { CreateUserService } from "@modules/user/services/CreateUserService";
import { DownloadExcelWalletAssessorService } from "@modules/user/services/DownloadExcelWalletAssessorService";
import { GetAssessorUsersService } from "@modules/user/services/GetAssessorUsersService";
import { GetTokenToKibanaChartsService } from "@modules/user/services/GetTokenToKibanaChartsService";
import { GetWalletAssessorService } from "@modules/user/services/GetWalletAssessorService";
import { ListUsersService } from "@modules/user/services/ListUsersInterface";
import { SendEmailToNotVerifiedEmailUsers } from "@modules/user/services/SendEmailToNotVerifiedEmailUsers";
import { UnlockUserService } from "@modules/user/services/UnlockUserService";
import { UpdateUSerService } from "@modules/user/services/UpdateUserService";
import { Container } from "inversify";
import { ICreateHandoutAckService } from "../modules/handouts/models/ICreateHandoutAckService";
import { ICreateHandoutService } from "../modules/handouts/models/ICreateHandoutService";
import { IGetHandoutsSeenOrNotService } from "../modules/handouts/models/IGetHandoutsSeenOrNotService";
import { IRemoveHandoutService } from "../modules/handouts/models/IRemoveHandoutService";
import { CreateHandoutAckService } from "../modules/handouts/services/CreateHandoutAckService";
import { CreateHandoutService } from "../modules/handouts/services/CreateHandoutService";
import { GetHandoutsSeenOrNotService } from "../modules/handouts/services/GetHandoutsSeenOrNotService";
import { RemoveHandoutService } from "../modules/handouts/services/RemoveHandoutService";
import AxiosHttpService from "../modules/infra/http/implementations/AxiosHttpService";
import { IHttpService } from "../modules/infra/http/models/IHttpService";
import { ICreateOrderService } from "../modules/orders/models/ICreateOrderService";
import { ICreatePDFOrdersService } from "../modules/orders/models/ICreatePDFOrdersService";
import { IQueryOrdersService } from "../modules/orders/models/IQueryOrdersService";
import { CreateOrderService } from "../modules/orders/services/CreateOrderService";
import { CreatePDFOrdersService } from "../modules/orders/services/CreatePDFOrdersService";
import { QueryOrdersService } from "../modules/orders/services/QueryOrdersService";
import { IEditActiveSettingsCostService } from "@modules/settingsCosts/models/IEditActiveSettingsCostService";
import { IGetActiveSettingsCostsService } from "@modules/settingsCosts/models/IGetActiveSettingsCostsService";
import { IGetHistoryActiveSettingsCostsService } from "@modules/settingsCosts/models/IGetHistoryActiveSettingsCostsService";
import { IRemoveActiveSettingsCostService } from "@modules/settingsCosts/models/IRemoveActiveSettingsCostService";
import { ISearchSettingsCostService } from "@modules/settingsCosts/models/ISearchSettingsCostService";
import { IUploadSheetSettingsCostsService } from "@modules/settingsCosts/models/IUploadSheetSettingsCostsService";
import { EditActiveSettingsCostService } from "@modules/settingsCosts/services/EditActiveSettingsCostService";
import { GetActiveSettingsCostsService } from "@modules/settingsCosts/services/GetActiveSettingsCostsService";
import { GetHistoryActiveSettingsCostsService } from "@modules/settingsCosts/services/GetHistoryActiveSettingsCostsService";
import { RemoveActiveSettingsCostService } from "@modules/settingsCosts/services/RemoveActiveSettingsCostService";
import { SearchSettingsCostService } from "@modules/settingsCosts/services/SearchSettingsCostService";
import { UploadSheetSettingsCostsService } from "@modules/settingsCosts/services/UploadSheetSettingsCostsService";

import { Types } from "./types";
import { DownloadExcelOrdersMarginByStatusService } from "@modules/orders/services/DownloadExcelOrdersMarginByStatusService";
import { IFinancialInstallmentsService } from "@modules/financial/models/IFinancialInstallmentsService";
import { FinancialInstallmentsService } from "@modules/financial/services/FinancialInstallmentsService";
import { IDownloadFinancialInstallmentsService } from "@modules/financial/models/IDownloadFinancialInstallmentsService";
import { DownloaFinancialInstallmentsService } from "@modules/financial/services/DownloaFinancialInstallmentsService";
import { IFinancialFiltersService } from "@modules/financial/models/IFinancialFiltersService";
import { FinancialFiltersService } from "@modules/financial/services/FinancialFiltersService";
import { IChartDataService } from "@modules/financial/models/IChartDataService";
import { ChartDataService } from "@modules/financial/services/ChartDataService";
import { IGetCentersService } from "@modules/user/models/IGetCentersService";
import { GetCentersService } from "@modules/user/services/GetCentersService";

const appIocContainer = new Container({ defaultScope: "Singleton" });

appIocContainer.bind<IHttpService>(Types.IHttpService).to(AxiosHttpService);

appIocContainer
  .bind<IQueryOrdersService>(Types.Orders.IQueryOrdersService)
  .to(QueryOrdersService);

appIocContainer
  .bind<ICreateOrderService>(Types.Orders.ICreateOrderService)
  .to(CreateOrderService);

appIocContainer
  .bind<ICreatePDFOrdersService>(Types.Orders.ICreatePDFOrdersService)
  .to(CreatePDFOrdersService);

appIocContainer
  .bind<IGetHandoutsSeenOrNotService>(
    Types.Handouts.IGetHandoutsSeenOrNotService
  )
  .to(GetHandoutsSeenOrNotService);

appIocContainer
  .bind<IRemoveHandoutService>(Types.Handouts.IRemoveHandoutService)
  .to(RemoveHandoutService);

appIocContainer
  .bind<ICreateHandoutService>(Types.Handouts.ICreateHandoutService)
  .to(CreateHandoutService);

appIocContainer
  .bind<ICreateHandoutAckService>(Types.Handouts.ICreateHandoutAckService)
  .to(CreateHandoutAckService);

appIocContainer
  .bind<IQueryTitlesService>(Types.Titles.IQueryTitlesService)
  .to(QueryTitlesService);

appIocContainer
  .bind<IQueryInvoicesService>(Types.Invoices.IQueryInvoicesService)
  .to(QueryInvoicesService);

appIocContainer
  .bind<IDownloadPDFBoletoTitlesService>(
    Types.Titles.IDownloadPDFBoletoTitlesService
  )
  .to(DownloadPDFBoletoTitlesService);

appIocContainer
  .bind<IDownloadPDFInvoicesService>(Types.Invoices.IDownloadPDFInvoicesService)
  .to(DownloadPDFInvoicesService);

appIocContainer
  .bind<IGetTitleImagesSeenOrNotService>(
    Types.Titles.IGetTitleImagesSeenOrNotService
  )
  .to(GetTitleImagesSeenOrNotService);

appIocContainer
  .bind<IMarkImageAsSeenService>(Types.Titles.IMarkImageAsSeenService)
  .to(MarkImageAsSeenService);

appIocContainer
  .bind<IUploadTitleImageService>(Types.Titles.IUploadTitleImageService)
  .to(UploadTitleImageService);

appIocContainer
  .bind<IGetClientInfoService>(Types.Orders.IGetClientInfoService)
  .to(GetClientInfoService);

appIocContainer
  .bind<IGetPriceProductsService>(Types.Orders.IGetPriceProductsService)
  .to(GetPriceProductsService);

appIocContainer
  .bind<IListUsersService>(Types.Users.IListUsersService)
  .to(ListUsersService);

appIocContainer
  .bind<ICreateUserService>(Types.Users.ICreateUserService)
  .to(CreateUserService);

appIocContainer
  .bind<IBlockUserService>(Types.Users.IBlockUserService)
  .to(BlockUserService);

appIocContainer
  .bind<IUnlockUserService>(Types.Users.IUnlockUserService)
  .to(UnlockUserService);

appIocContainer
  .bind<IUpdateUserService>(Types.Users.IUpdateUSerService)
  .to(UpdateUSerService);

appIocContainer
  .bind<IListLoginImageService>(Types.LoginImages.IListLoginImageService)
  .to(ListLoginImageService);

appIocContainer
  .bind<IRemoveLoginImageService>(Types.LoginImages.IRemoveLoginImageService)
  .to(RemoveLoginImageService);

appIocContainer
  .bind<IUploadLoginImageService>(Types.LoginImages.IUploadLoginImageService)
  .to(UploadLoginImageService);

appIocContainer
  .bind<ITimeLimitLocationService>(Types.Orders.ITimeLimitLocationService)
  .to(TimeLimitLocationService);

appIocContainer
  .bind<IListLogsService>(Types.Logs.IListLogsService)
  .to(ListLogsService);

appIocContainer
  .bind<ISendEmailToNotVerifiedEmailUsers>(
    Types.Users.ISendEmailToNotVerifiedEmailUsers
  )
  .to(SendEmailToNotVerifiedEmailUsers);

appIocContainer
  .bind<IChangePasswordService>(Types.Users.IChangePasswordService)
  .to(ChangePasswordService);

appIocContainer
  .bind<ICreatePDFTitlesService>(Types.Titles.ICreatePDFTitlesService)
  .to(CreatePDFTitlesService);

appIocContainer
  .bind<ICancelOrderService>(Types.Orders.ICancelOrderService)
  .to(CancelOrderService);

appIocContainer
  .bind<IQueryOrdersByStatus>(Types.Orders.IQueryOrdersByStatus)
  .to(QueryOrdersByStatus);

appIocContainer
  .bind<ICreatePDFOrdersByStatusService>(
    Types.Orders.ICreatePDFOrdersByStatusService
  )
  .to(CreatePDFOrdersByStatusService);

appIocContainer
  .bind<IGetWalletAssessorService>(Types.Users.IGetWalletAssessorService)
  .to(GetWalletAssessorService);

appIocContainer
  .bind<IDownloadExcelOrdersByStatusService>(
    Types.Orders.IDownloadExcelOrdersByStatusService
  )
  .to(DownloadExcelOrdersByStatusService);

appIocContainer
  .bind<IDownloadExcelOrdersByStatusService>(
    Types.Orders.IDownloadExcelOrdersMarginByStatusService
  )
  .to(DownloadExcelOrdersMarginByStatusService);

appIocContainer
  .bind<IDownloadExcelSalesAnalyticsService>(
    Types.Orders.IDownloadExcelSalesAnalyticsService
  )
  .to(DownloadExcelSalesAnalyticsService);

appIocContainer
  .bind<IDownloadTitlesService>(Types.Titles.IDownloadTitlesService)
  .to(DownloadTitlesService);

appIocContainer
  .bind<IDownloadExcelWalletAssessorService>(
    Types.Users.IDownloadExcelWalletAssessorService
  )
  .to(DownloadExcelWalletAssessorService);

appIocContainer
  .bind<IGetAssessorUsersService>(Types.Users.IGetAssessorUsersService)
  .to(GetAssessorUsersService);

appIocContainer
  .bind<IGetOrdersAggregatedPerDayService>(
    Types.Orders.IGetOrdersAggregatedPerDayService
  )
  .to(GetOrdersAggregatedPerDayService);

appIocContainer
  .bind<IQueryOrdersByStatusAggregatorsService>(
    Types.Orders.IQueryOrdersByStatusAggregatorsService
  )
  .to(QueryOrdersByStatusAggregatorsService);

appIocContainer
  .bind<IQuerySalesAnalyticsService>(Types.Orders.IQuerySalesAnalyticsService)
  .to(QuerySalesAnalyticsService);

appIocContainer
  .bind<IGetTokenToKibanaChartsService>(
    Types.Users.IGetTokenToKibanaChartsService
  )
  .to(GetTokenToKibanaChartsService);

appIocContainer
  .bind<IUploadSheetCostsService>(Types.Costs.IUploadSheetCostsService)
  .to(UploadSheetCostsService);

appIocContainer
  .bind<IGetActiveCostsService>(Types.Costs.IGetActiveCostsService)
  .to(GetActiveCostsService);

appIocContainer
  .bind<ISearchCostService>(Types.Costs.ISearchCostService)
  .to(SearchCostService);

appIocContainer
  .bind<IRemoveActiveCostService>(Types.Costs.IRemoveActiveCostService)
  .to(RemoveActiveCostService);

appIocContainer
  .bind<IGetHistoryActiveCostsService>(
    Types.Costs.IGetHistoryActiveCostsService
  )
  .to(GetHistoryActiveCostsService);

appIocContainer
  .bind<IEditActiveCostService>(Types.Costs.IEditActiveCostService)
  .to(EditActiveCostService);

appIocContainer
  .bind<IGetUserAccessesService>(Types.Admin.IGetUserAccessesService)
  .to(GetUserAccessesService);

appIocContainer
  .bind<IGetFilterOptionsService>(Types.Dashboard.IGetFilterOptionsService)
  .to(GetFilterOptionsService);

appIocContainer
  .bind<ICallJobsService>(Types.Jobs.ICallJobsService)
  .to(CallJobsService);

appIocContainer
  .bind<IGetChartDataService>(Types.Dashboard.IGetChartDataService)
  .to(GetChartDataService);

appIocContainer
  .bind<IUploadSheetSettingsCostsService>(
    Types.SettingsCosts.IUploadSheetSettingsCostsService
  )
  .to(UploadSheetSettingsCostsService);

appIocContainer
  .bind<IGetActiveSettingsCostsService>(
    Types.SettingsCosts.IGetActiveSettingsCostsService
  )
  .to(GetActiveSettingsCostsService);

appIocContainer
  .bind<ISearchSettingsCostService>(
    Types.SettingsCosts.ISearchSettingsCostService
  )
  .to(SearchSettingsCostService);

appIocContainer
  .bind<IRemoveActiveSettingsCostService>(
    Types.SettingsCosts.IRemoveActiveSettingsCostService
  )
  .to(RemoveActiveSettingsCostService);

appIocContainer
  .bind<IGetHistoryActiveSettingsCostsService>(
    Types.SettingsCosts.IGetHistoryActiveSettingsCostsService
  )
  .to(GetHistoryActiveSettingsCostsService);

appIocContainer
  .bind<IEditActiveSettingsCostService>(
    Types.SettingsCosts.IEditActiveSettingsCostService
  )
  .to(EditActiveSettingsCostService);

appIocContainer
  .bind<IFinancialInstallmentsService>(
    Types.Financial.IFinancialInstallmentsService
  )
  .to(FinancialInstallmentsService);

appIocContainer
  .bind<IDownloadFinancialInstallmentsService>(
    Types.Financial.IDownloadFinancialInstallmentsService
  )
  .to(DownloaFinancialInstallmentsService);

appIocContainer
  .bind<IFinancialFiltersService>(Types.Financial.IFinancialFiltersService)
  .to(FinancialFiltersService);

appIocContainer
  .bind<IChartDataService>(Types.Financial.IChartDataService)
  .to(ChartDataService);

appIocContainer
  .bind<IGetCentersService>(Types.Users.IGetCentersService)
  .to(GetCentersService);

export { appIocContainer };
