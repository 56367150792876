import { format } from "date-fns";
import { injectable } from "inversify";
import groupBy from "lodash.groupby";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import logoATEM from "../../../assets/logo-atem.png";
import { formatCurrency, maskCNPJ, nameStatusAtem } from "../../../utils";
import { StatusAtem } from "../dtos/IQueryOrderDataDTO";
import { IResultByStatusDTO } from "../dtos/IQueryOrdersByStatusDTO";
import { ICreatePDFOrdersByStatusService } from "../models/ICreatePDFOrdersByStatusService";

@injectable()
export class CreatePDFOrdersByStatusService
  implements ICreatePDFOrdersByStatusService {
  public async execute(
    data: IResultByStatusDTO[],
    queryData: { beginDate: Date; endDate: Date }
  ) {
    (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

    const tableData = data.map((order, idx) => {
      const inplantationDate = new Date(order.implantationDate);
      const inplantationDateDtOnly = new Date(
        inplantationDate.valueOf() +
          inplantationDate.getTimezoneOffset() * 60 * 1000
      );

      return [
        { text: idx, style: "tableCell" },
        { text: order.filialID, style: "tableCell" },
        {
          text: format(inplantationDateDtOnly, "dd/MM/yyyy"),
          style: "tableCell",
        },
        { text: order.numberOrder, style: "tableCell" },
        { text: maskCNPJ(order.companyName), style: "tableCell" },
        { text: order.freightType, style: "tableCell" },
        { text: `${order.payment}-${order.payCond}`, style: "tableCell" },
        {
          text: `${order.productID} - ${order.productName}`,
          style: "tableCell",
        },
        { text: formatCurrency(order.price), style: "tableCell" },
        { text: order.quantity.toLocaleString("pt-BR"), style: "tableCell" },
        { text: formatCurrency(order.totalPrice), style: "tableCell" },
        {
          text: nameStatusAtem(order.statusAtem),
          style: "tableCell",
        },
      ];
    });

    const totalPrice = data.reduce((a, b) => a + b.totalPrice, 0);
    const quantityTotal = data.reduce((a, b) => a + b.quantity, 0);

    const groupedCNPJ = groupBy(data, "CNPJ");
    const groupedByProduct = Object.keys(groupedCNPJ).reduce<{
      [propName: string]: {
        [propName: string]: IResultByStatusDTO[];
      };
    }>((object, CNPJ) => {
      return {
        ...object,
        [CNPJ]: groupBy(groupedCNPJ[CNPJ], "productID"),
      };
    }, {});

    const groupedByStatus = groupBy(data, "statusAtem");
    const groupedByStatusAndProduct = Object.keys(groupedByStatus).reduce<{
      [status: string]: { [productName: string]: IResultByStatusDTO[] };
    }>((obj, status) => {
      return {
        ...obj,
        [status]: groupBy(groupedByStatus[status], "productName"),
      };
    }, {});
    const productNames = new Set(data.map((ele) => ele.productName));
    //@ts-ignore
    const productNamesArr = [...productNames];

    console.log("groupedByStatusAndProduct", [
      ...productNamesArr.map((productName) => {
        return [
          { text: productName, style: "header" },
          ...Object.keys(groupedByStatus).map((statusName) => {
            const dataArr = groupedByStatusAndProduct[statusName][productName];
            return {
              text: dataArr
                ? formatCurrency(dataArr.reduce((a, b) => a + b.quantity, 0))
                : "--",
              style: "header",
            };
          }),
        ];
      }),
    ]);

    const docDefinition: TDocumentDefinitions = {
      pageOrientation: "landscape",
      pageSize: "A4",
      content: [
        {
          image: "logoATEM",
          height: 35,
          width: 73,
          alignment: "center",
        },
        {
          text: "Faturamento",
          alignment: "center",
          bold: true,
          fontSize: 18,
          margin: [0, 5],
        },
        {
          text: `Vendas por cliente de ${format(
            queryData.beginDate,
            "dd/MM/yyyy"
          )} a ${format(queryData.endDate, "dd/MM/yyyy")}`,
          alignment: "center",
          bold: true,
          margin: [0, 5],
        },
        {
          table: {
            headerRows: 1,
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "*",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            body: [
              [
                { text: "#", style: "header" },
                { text: "Filial", style: "header" },
                { text: "Data de implantação", style: "header" },
                { text: "Pedido", style: "header" },
                { text: "Razão social", style: "header" },
                { text: "Frete", style: "header" },
                { text: "Pgto", style: "header" },
                { text: "Produto", style: "header" },
                { text: "Vl. unitário", style: "header" },
                { text: "Volume (L)", style: "header" },
                { text: "Vl. total", style: "header" },
                { text: "Status ATEM", style: "header" },
              ],
              ...tableData,
            ],
          },
        },
        ...Object.keys(groupedByProduct).flatMap((CNPJ) => {
          const total = Object.keys(groupedByProduct[CNPJ])
            .map((productList) => {
              const quantity = groupedByProduct[CNPJ][productList].reduce(
                (a, b) => a + b.quantity,
                0
              );
              const totalPrice = groupedByProduct[CNPJ][productList].reduce(
                (a, b) => a + b.totalPrice,
                0
              );

              return {
                product: productList,
                quantity,
                totalPrice,
              };
            })
            .reduce(
              (a, b) => {
                return {
                  totalPrice: a.totalPrice + b.totalPrice,
                  quantity: a.quantity + b.quantity,
                };
              },
              { quantity: 0, totalPrice: 0 }
            );

          return [
            {
              text: `CNPJ: ${maskCNPJ(CNPJ)} (${
                groupedCNPJ[CNPJ][0].companyName
              })`,
              style: "CNPJStyle",
            },
            {
              table: {
                headerRows: 1,
                body: [
                  [
                    { text: "Produto", style: "header" },
                    { text: "Volume (L)", style: "header" },
                    { text: "Total", style: "header" },
                  ],
                  ...Object.keys(groupedByProduct[CNPJ]).map((productList) => {
                    const productName = `${groupedByProduct[CNPJ][productList][0].productID} - ${groupedByProduct[CNPJ][productList][0].productName}`;
                    return [
                      {
                        style: "tableCell",
                        text: productName,
                      },
                      {
                        style: "tableCell",
                        text: groupedByProduct[CNPJ][productList]
                          .reduce((a, b) => a + b.quantity, 0)
                          .toLocaleString("pt-BR"),
                      },
                      {
                        style: "tableCell",
                        text: formatCurrency(
                          groupedByProduct[CNPJ][productList].reduce(
                            (a, b) => a + b.totalPrice,
                            0
                          )
                        ),
                      },
                    ];
                  }),
                  [
                    { text: "Total", style: "header" },
                    {
                      text: total.quantity.toLocaleString("pt-BR"),
                      style: "header",
                    },
                    {
                      text: formatCurrency(total.totalPrice),
                      style: "header",
                    },
                  ],
                ],
              },
            },
          ];
        }),
        {
          margin: [0, 30, 0, 0],
          table: {
            headerRows: 1,
            body: [
              [
                { text: "Volume total (L)", style: "header" },
                { text: "Total", style: "header" },
              ],
              [
                {
                  text: quantityTotal.toLocaleString("pt-BR"),
                  style: "header",
                },
                {
                  text: formatCurrency(totalPrice),
                  style: "header",
                },
              ],
            ],
          },
        },
        {
          margin: [0, 30, 0, 0],
          table: {
            headerRows: 1,
            body: [
              [
                { text: " ", style: "header" },
                ...Object.keys(groupedByStatus).map((statusName) => {
                  return [
                    {
                      text: `${nameStatusAtem(statusName as StatusAtem)} (L)`,
                      style: "header",
                    },
                  ];
                }),
              ],
              ...productNamesArr.map((productName) => {
                return [
                  { text: productName, style: "header" },
                  ...Object.keys(groupedByStatus).map((statusName) => {
                    const dataArr =
                      groupedByStatusAndProduct[statusName][productName];
                    return {
                      text: dataArr
                        ? dataArr
                            .reduce((a, b) => a + b.quantity, 0)
                            .toLocaleString("pt-BR")
                        : "--",
                      style: "tableCell",
                    };
                  }),
                ];
              }),
              [
                { text: "Total", style: "header" },
                ...Object.keys(groupedByStatus).map((statusName) => {
                  return [
                    {
                      text: groupedByStatus[statusName]
                        .reduce((a, b) => a + b.quantity, 0)
                        .toLocaleString("pt-BR"),
                      style: "header",
                    },
                  ];
                }),
              ],
            ],
          },
        },
      ],

      styles: {
        header: {
          bold: true,
          fontSize: 12,
          alignment: "center",
        },
        tableCell: {
          fontSize: 10,
          alignment: "center",
        },
        CNPJStyle: {
          bold: true,
          margin: [0, 30, 0, 0],
        },
      },
      images: {
        logoATEM,
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    return new Promise<void>((resolve, reject) => {
      pdfDocGenerator.download("orders.pdf", () => resolve());
    });
  }
}
